import { PLATFORM } from 'dataSource'
import { SUPPORTED_CUSTOM_SPEND_PLATFORMS, CUSTOM_SOURCE_TYPES } from 'attributionModel/constants'

import {
  Flyout,
  Divider,
  Accordion,
  Typography,
  ChevronDownIcon,
  Grid,
  Radio,
  SelectInput,
  SelectItem,
  Button,
  Autocomplete,
  styled
} from '@precis-digital/kurama'
import FormItem from 'shared/components/FormItem'
import { FlyoutFooter } from 'shared/components/Flyout'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useQueryClientAccountPlatform } from 'dataSource/api'
import { PlatformAvatar } from '.'
import { Controller, useForm } from 'react-hook-form'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import { useState, useMemo } from 'react'
import { ASSETS_BASE_URL } from 'shared/constants'

import { useQueryGcpMetadata } from 'connectors/api'
import { useQueryGcpDatasetMetadata } from 'attributionModel/api'

const GOOGLE_CLOUD_ICON_URL = `${ASSETS_BASE_URL}/gcp.svg`
const GOOGLE_CLOUD_BIGQUERY_URL = `${ASSETS_BASE_URL}/google_bigquery.svg`

export interface CustomDataSourcesFormData {
  accountName?: string
  selectedPlatform: typeof PLATFORM.GOOGLE_BIGQUERY | typeof PLATFORM.GOOGLE_SHEETS
  accountId: number
  worksheetId?: string
  datasetId?: string
  tableId?: string
  type: string
}

interface CustomSpendSourceProps {
  onSubmit?: (data: CustomDataSourcesFormData) => void
}

const SetupCustomDataSourcesFlyout = ({ onSubmit }: CustomSpendSourceProps): React.ReactElement => {
  const { t } = useTranslation('attributionModel')
  const screen = useOverScreen('attributionCustomDataSourcesFlyout')
  const { currentClient } = useCurrentClient()

  const [selectedPlatform, setSelectedPlatform] = useState<typeof PLATFORM.GOOGLE_BIGQUERY>(PLATFORM.GOOGLE_BIGQUERY)

  const [selectedAccountId, setSelectedAccountId] = useState<number | undefined>(undefined)

  const [selectedDataset, setSelectedDataset] = useState<string | undefined>(undefined)

  const { data: accounts, isLoading: accountsLoading } = useQueryClientAccountPlatform(currentClient.id, 'gcp')

  const { getValues, setValue, control, handleSubmit, formState } = useForm<CustomDataSourcesFormData>()

  const formData = getValues()

  const handleValidForm = (data: CustomDataSourcesFormData): void => {
    onSubmit?.(data)
  }

  const gcpAccounts = useMemo(() => {
    if (accountsLoading || accounts == null) {
      return []
    }
    return accounts.map((account) => ({
      value: account.externalAccountId,
      label: `${account.name} (${account.externalAccountId})`,
      credentialsId: account.credentialsId,
      entityId: Number(account.id)
    }))
  }, [accounts, accountsLoading])

  const chosenGcpTargetAccount = gcpAccounts.find((account) => account.value === formData.accountName)

  const { data: gcpMetadata, isLoading: isLoadingBigqueryDatasets } = useQueryGcpMetadata(
    currentClient.id,
    selectedAccountId
  )

  const { data: gcpDatasetMetadata, isLoading: isLoadingBigqueryDatasetMetadata } = useQueryGcpDatasetMetadata(
    currentClient.id,
    chosenGcpTargetAccount?.entityId,
    selectedDataset
  )

  const datasetOptions = useMemo(() => {
    if (gcpMetadata == null) return []
    return gcpMetadata.bigqueryDatasets.map((dataset) => ({
      value: dataset.datasetId,
      label: dataset.datasetId
    }))
  }, [gcpMetadata])

  const tableOptions = useMemo(() => {
    if (gcpDatasetMetadata == null) return []
    return gcpDatasetMetadata.bigqueryTables.map((table) => ({
      value: table.tableId,
      label: table.tableId
    }))
  }, [gcpDatasetMetadata])

  return (
    <Flyout
      onClose={(): void => {
        screen.remove()
      }}
      isExpanded={screen.visible}
    >
      <Typography variant="h2">{t('customDataSources.title')}</Typography>
      <Divider />
      <StyledAccordion
        Summary={<Typography variant="h3">{t('customDataSources.selectDataSource')}</Typography>}
        Details={
          <>
            {' '}
            {SUPPORTED_CUSTOM_SPEND_PLATFORMS.map((account, index) => (
              <Grid
                key={account.platform}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid display="flex" flexDirection="row" gap="10px">
                  <PlatformAvatar account={account} />
                  <Typography variant="body2">{account.label}</Typography>
                </Grid>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { name, value } }) => {
                    return (
                      <StyledRadio
                        value={account.platform}
                        onClick={() => {
                          setValue(name, account.platform)
                          setSelectedPlatform(account.platform)
                        }}
                        checked={account.platform === value}
                      />
                    )
                  }}
                  name="selectedPlatform"
                />
              </Grid>
            ))}
          </>
        }
        expandIcon={<ChevronDownIcon />}
        expanded
      />
      <Divider />
      <StyledAccordion
        Summary={<Typography variant="h3">{t('customDataSources.selectCustomSettings')}</Typography>}
        Details={
          <>
            {selectedPlatform === PLATFORM.GOOGLE_BIGQUERY && (
              <>
                <Grid margin="16px 0px">
                  <FormItem
                    label={t('customDataSources.gcpProjectId')}
                    labelIcon={GOOGLE_CLOUD_ICON_URL}
                    name="accountName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <SelectInput
                          fullWidth
                          {...field}
                          value={field.value ?? ''}
                          onChange={(event) => {
                            field.onChange(event)
                            setSelectedDataset(undefined)
                            const selectedGcpAccount = gcpAccounts.find(
                              (account) => account.value === event.target.value
                            )
                            if (selectedGcpAccount !== undefined) {
                              setValue('accountId', selectedGcpAccount.entityId)
                              setSelectedAccountId(selectedGcpAccount.entityId)
                            }
                          }}
                        >
                          {gcpAccounts.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectInput>
                      )
                    }}
                    currentValue={
                      gcpAccounts.find((option) => option.value === getValues('accountName'))?.label ??
                      getValues('accountName')
                    }
                    isCreateEvent
                    helperText={t('customDataSources.gcpProjectHelperText')}
                  />
                </Grid>
                <Grid margin="16px 0px">
                  <FormItem
                    label={t('customDataSources.bigqueryDatasetId')}
                    labelIcon={GOOGLE_CLOUD_BIGQUERY_URL}
                    name="datasetId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <Autocomplete
                          options={datasetOptions}
                          value={{ value: field.value ?? '', label: field.value ?? '' }}
                          placeholder={t('customDataSources.bigqueryDatasetId')}
                          multiple={false}
                          fullWidth
                          allowCustomInput={false}
                          onChange={(event, newValue) => {
                            field.onChange(newValue?.value ?? '')
                            setSelectedDataset(newValue?.value ?? '')
                          }}
                          isLoadingOptions={isLoadingBigqueryDatasets}
                          loadingText={t('form.targetDatasetLoadingText')}
                        />
                      )
                    }}
                    currentValue={getValues('datasetId')}
                    isCreateEvent
                    helperText={t('customDataSources.gcpDatasetHelperText')}
                  />
                </Grid>
                <Grid margin="16px 0px">
                  <FormItem
                    label={t('customDataSources.bigqueryTableId')}
                    labelIcon={GOOGLE_CLOUD_BIGQUERY_URL}
                    name="tableId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <Autocomplete
                          options={tableOptions}
                          value={{ value: field.value ?? '', label: field.value ?? '' }}
                          placeholder={t('customDataSources.bigqueryTableId')}
                          multiple={false}
                          fullWidth
                          allowCustomInput={false}
                          onChange={(event, newValue) => {
                            field.onChange(newValue?.value ?? '')
                          }}
                          isLoadingOptions={isLoadingBigqueryDatasetMetadata}
                          loadingText={t('form.targetTableLoadingText')}
                        />
                      )
                    }}
                    currentValue={getValues('tableId')}
                    isCreateEvent
                    helperText={t('customDataSources.gcpTableHelperText')}
                  />
                </Grid>
                <Grid margin="16px 0px">
                  <FormItem
                    label={t('customDataSources.customSourceType')}
                    name="type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <SelectInput
                          fullWidth
                          {...field}
                          onChange={(event) => {
                            field.onChange(event)
                            setValue<'type'>('type', event.target.value as string)
                          }}
                        >
                          {CUSTOM_SOURCE_TYPES.map((option) => {
                            return (
                              <SelectItem key={option.value} value={option.value}>
                                {option.label}
                              </SelectItem>
                            )
                          })}
                        </SelectInput>
                      )
                    }}
                    currentValue={getValues('type')}
                    isCreateEvent
                    helperText={t('customDataSources.customSourceTypeHelperText')}
                  />
                </Grid>
              </>
            )}
          </>
        }
        expandIcon={<ChevronDownIcon />}
      />
      <FlyoutFooter>
        <Divider />
        <Button
          fullWidth
          disabled={!formState.isValid}
          variant="filled"
          onClick={() => {
            void handleSubmit(handleValidForm)()
            screen.remove()
          }}
        >
          {t('customDataSources.saveSpendSource')}
        </Button>
      </FlyoutFooter>
    </Flyout>
  )
}

const StyledAccordion = styled(Accordion)(() => ({
  '::before': {
    backgroundColor: 'transparent'
  },
  'div:first-of-type[role="region"] > div': {
    padding: '0 0 0 0'
  }
}))

const StyledRadio = styled(Radio)(() => ({
  padding: '9px 0px 9px 0px'
}))

export default OverScreen.create(SetupCustomDataSourcesFlyout)
