import {
  Divider,
  Flyout,
  Grid,
  defaultTheme as theme,
  styled,
  Typography,
  DropdownList,
  DropdownItem,
  Button,
  Checkbox,
  Input,
  SearchIcon,
  DropdownListSubCategory,
  Avatar,
  Skeleton,
  type MultiSelectDropdownItem
} from '@precis-digital/kurama'
import { useEffect, useState } from 'react'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import { type TFunction } from 'i18next'
import { isNilOrEmpty, objectKeys, sortOptionInAlphabeticalOrder } from 'shared/utils'
import { groupBy } from 'ramda'
import { useCurrentClient } from 'shared/context/ClientContext'
import { getPlatformDetailsByPlatform } from 'dataSource'
import { CONVERSION_SOURCE_TO_DATASOURCE_MAP } from 'home/constants'
import { type ReportType } from 'shared/api/analytics'
import { formatToNumber } from 'shared/numberFormat'
import { type ReportParameters } from 'home/reportTemplateParameters'
import { useHomePageData } from 'home/hooks/useHomePageData'

const spacing = theme.spacing

interface Option {
  value: string
  label: string
}

export interface EventOption extends Option {
  subCategory?: string
  iconUrl?: string
  numberOfConversions?: number | null
}

export interface ConversionEventsFlyoutProps {
  currentEventOptions: EventOption[] | undefined
  setCurrentEventOptions: (eventOptions: EventOption[]) => void
  allEventsOption: EventOption
  selectedEvents: MultiSelectDropdownItem[]
  setSelectedEvents: (selectedEvents: MultiSelectDropdownItem[]) => void
  reportId: string
  startDate: string
  endDate: string
  reportType: ReportType
  conversionEventVariables: ReportParameters['conversionEventVariables']
  storeSelectedConversionEvents: ReportParameters['storeSelectedConversionEvents']
  t: TFunction<'home'>
}

export const ConversionEventsFlyout = ({
  currentEventOptions,
  selectedEvents,
  setSelectedEvents,
  allEventsOption,
  setCurrentEventOptions,
  reportId,
  reportType,
  startDate,
  endDate,
  t,
  conversionEventVariables,
  storeSelectedConversionEvents
}: ConversionEventsFlyoutProps): React.ReactElement => {
  const screen = useOverScreen('conversionEventsFlyout')

  const { currentClient } = useCurrentClient()

  const [newlySelectedEvents, setNewlySelectedEvents] = useState<MultiSelectDropdownItem[]>(selectedEvents)
  const [eventOptions, setEventOptions] = useState<EventOption[] | undefined>(currentEventOptions)
  const [searchValue, setSearchValue] = useState('')

  const {
    isLoadingConversionNamesAndSources,
    conversionNameAndSources = [],
    getConversionNamesAndSources
  } = useHomePageData()

  useEffect(() => {
    if (isNilOrEmpty(currentEventOptions)) {
      getConversionNamesAndSources({
        id: reportId,
        clientId: currentClient.id,
        targetCurrencyCode: currentClient.currency,
        report: reportType,
        filters: [
          {
            field: 'date',
            operator: 'BETWEEN',
            value: [startDate, endDate]
          }
        ],
        ...conversionEventVariables
      })
    }
  }, [
    currentClient.currency,
    currentClient.id,
    currentEventOptions,
    getConversionNamesAndSources,
    reportId,
    startDate,
    endDate,
    reportType,
    conversionEventVariables
  ])

  useEffect(() => {
    if (conversionNameAndSources.length > 0) {
      const conversionNameAndSourceOptions = conversionNameAndSources.map((conversionNameAndSource) => {
        return {
          label: conversionNameAndSource.conversionName,
          value: conversionNameAndSource.conversionName.toString(),
          subCategory: conversionNameAndSource.conversionSource,
          numberOfConversions: conversionNameAndSource.sumConversions
        }
      })

      setEventOptions(conversionNameAndSourceOptions)
      setCurrentEventOptions(conversionNameAndSourceOptions)
    }
  }, [conversionNameAndSources, setCurrentEventOptions])

  const handleClose = (): void => {
    screen.remove()
  }

  const handleOptionClick = (option: EventOption): void => {
    setNewlySelectedEvents((prev) => {
      let currentlySelectedEvents = prev

      const isOptionOrAllOptionsSelection =
        prev.find(
          (item) =>
            (item.value === option.value && item.subCategory === option.subCategory) ||
            item.value === allEventsOption.value
        ) == null

      currentlySelectedEvents = !isOptionOrAllOptionsSelection
        ? prev.filter(
            (item) =>
              (item.value !== option.value || item.subCategory !== option.subCategory) &&
              item.value !== allEventsOption.value
          )
        : [...prev, option]

      return currentlySelectedEvents
    })
  }

  const handleSave = (): void => {
    const hasAllEventsOption = newlySelectedEvents.find((item) => item.value === allEventsOption.value) != null
    const eventsToSave = hasAllEventsOption ? [] : newlySelectedEvents
    setSelectedEvents(eventsToSave)
    storeSelectedConversionEvents?.(reportId, eventsToSave)
    screen.remove()
  }

  const handleHeaderControlChange = (): void => {
    setNewlySelectedEvents((prev) => {
      let currentlySelectedEvents = prev

      const hasAllEventsOption = newlySelectedEvents.find((item) => item.value === allEventsOption.value) != null

      currentlySelectedEvents = hasAllEventsOption ? [] : [allEventsOption, ...(filteredOptions ?? [])]

      return currentlySelectedEvents
    })
  }

  eventOptions?.sort(sortOptionInAlphabeticalOrder)
  const filteredOptions = eventOptions?.filter((option) => {
    return option.label.toLowerCase().includes(searchValue.toLowerCase())
  })

  const optionsWithoutCategory = filteredOptions?.filter((option) => option.subCategory == null) ?? []

  const groupedOptionsByCategory = groupBy(
    (option) => option?.subCategory ?? '',
    filteredOptions?.filter((option) => option.subCategory != null) ?? []
  )

  let headerControlValue: 'checked' | 'indeterminate' | 'unchecked' = 'unchecked'

  const hasAllEventsOption = newlySelectedEvents.find((item) => item.value === allEventsOption.value) != null

  if (hasAllEventsOption) {
    headerControlValue = 'checked'
  } else if (newlySelectedEvents.length > 0) {
    headerControlValue = 'indeterminate'
  }

  return (
    <Flyout closeText={t('buttons.close')} onClose={handleClose} isExpanded={screen.visible}>
      <Typography variant="h2">{t('conversionEvents')}</Typography>
      <Divider />
      {isLoadingConversionNamesAndSources ? (
        <Grid container flexDirection="column" gap="8px">
          <Skeleton height="60px" />

          <Grid container flexDirection="column" gap="8px">
            <Skeleton height="40px" />
            <Skeleton height="40px" />
            <Skeleton height="40px" />
            <Skeleton height="40px" />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid paddingBottom="70px">
            <Input
              assistive
              name="conversionEvent"
              value={searchValue}
              fullWidth
              placeholder="Find conversion event"
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              icon={<SearchIcon />}
            />
            <DropdownList
              header={allEventsOption.label}
              headerControlValue={headerControlValue}
              headerControlOnChange={handleHeaderControlChange}
              style={{ padding: 0, paddingLeft: theme.spacing(2), paddingTop: theme.spacing(2) }}
            >
              {optionsWithoutCategory.map((option) => (
                <DropdownItem
                  key={option.value}
                  onClick={() => {
                    handleOptionClick(option)
                  }}
                  control={
                    <Checkbox
                      checked={
                        newlySelectedEvents.find(
                          (event) => event.value === option.value && event.subCategory === option.subCategory
                        ) != null
                      }
                    />
                  }
                >
                  {option.label}
                </DropdownItem>
              ))}
              {objectKeys(groupedOptionsByCategory).map((groupedOptionKey) => {
                const platformDetails = getPlatformDetailsByPlatform(
                  CONVERSION_SOURCE_TO_DATASOURCE_MAP[groupedOptionKey]
                )
                return (
                  <DropdownListSubCategory header={platformDetails.label} key={groupedOptionKey}>
                    {groupedOptionsByCategory[groupedOptionKey].map((option) => {
                      return (
                        <DropdownItem
                          icon={<Avatar size="small" kind="image" imageUrl={platformDetails.iconUrl ?? ''} />}
                          key={option.value}
                          onClick={() => {
                            handleOptionClick(option)
                          }}
                          control={
                            <Checkbox
                              checked={
                                newlySelectedEvents.find(
                                  (event) => event.value === option.value && event.subCategory === option.subCategory
                                ) != null
                              }
                            />
                          }
                          annotation={t('filter.numberOfConversions', {
                            number: formatToNumber(option.numberOfConversions ?? 0, false)
                          })}
                        >
                          {option.label}
                        </DropdownItem>
                      )
                    })}
                  </DropdownListSubCategory>
                )
              })}
            </DropdownList>
          </Grid>

          <StyledFlyoutFooter>
            <Divider />
            <Grid display="flex" justifyContent="flex-end" gap="8px">
              <Button
                onClick={(): void => {
                  screen.remove()
                }}
                variant="outlined"
              >
                {t('buttons.cancel')}
              </Button>
              <Button onClick={handleSave} variant="filled">
                {t('buttons.updateSettings')}
              </Button>
            </Grid>
          </StyledFlyoutFooter>
        </>
      )}
    </Flyout>
  )
}

export default OverScreen.create(ConversionEventsFlyout)

const StyledFlyoutFooter = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.white0,
  position: 'fixed',
  paddingBottom: spacing(2),
  bottom: 0,
  width: '22rem'
}))
