import {
  Typography,
  defaultTheme as theme,
  BarChart,
  Grid,
  type BadgeColor,
  Badge,
  MultiSelectDropdown,
  Tooltip
} from '@precis-digital/kurama'
import { useTranslation } from 'shared/translations'
import Dots from 'shared/components/Loader/Dots'
import ChartToolTip from 'shared/components/ChartToolTip'
import { getFormattedValue } from 'shared/numberFormat'
import { type AttributionBaseReport } from 'attributionModel/transformations'
import { type SelectMetricsType } from 'attributionModel/components/Dashboard'
import type { RequireAtLeastOne } from 'type-fest'
import ChartLegend from 'shared/components/ChartLegend'
import { objectKeys } from 'shared/utils'
import { type AttributionModelType } from './DetailedReportUtils'
import { GA4_EXCLUSION_LIST, RBA_MODEL_TYPE } from 'attributionModel/constants'
import { useCurrentClient } from 'shared/context/ClientContext'
import { colorsLineChart } from './ProgressOverTime'
import { useState } from 'react'
import { type TFunction } from 'i18next'

const xtickCharLimit = 16

export type BarChartAttributionBaseReport = Omit<AttributionBaseReport, 'date'> & {
  ga4DefaultAttributionRevenue: number
  ga4DefaultAttributionConversions: number
  ga4LndaAttributionRevenue: number
  ga4LndaAttributionConversions: number
  lndaRevenue: number
  lndaConversions: number
}

interface BarData {
  [key: string]: number | string
  x: string
}

export type BarChartChannelRecord = RequireAtLeastOne<
  {
    revenue?: number
    conversions?: number
    x: string
  },
  'revenue' | 'conversions'
>

export type BarChartChannelRecords = BarChartChannelRecord[]

const colorsBarChart: Partial<Record<BadgeColor, string>> = colorsLineChart

export const getComparisonModel = (
  t: TFunction<'attributionModel'>,
  currentModelType: AttributionModelType,
  hasValidFilter: boolean,
  currentClientId: string
): string[] | undefined => {
  if (GA4_EXCLUSION_LIST.includes(currentClientId)) {
    return undefined
  } else if (currentModelType === 'rba') {
    return !hasValidFilter
      ? undefined
      : [t('dashboard.modelTypeTitles.ga4Default'), t('dashboard.modelTypeTitles.ga4Lnda')]
  } else {
    return [t('dashboard.modelTypeTitles.lnda')]
  }
}

export default function BarChartComparison({
  currentReport,
  isDataLoaded,
  selectedMetrics,
  currencyCode,
  currentModelType,
  hasValidFilter,
  configName
}: {
  currentReport: BarChartAttributionBaseReport[]
  selectedMetrics: SelectMetricsType
  isDataLoaded: boolean
  currencyCode: string
  currentModelType: AttributionModelType
  hasValidFilter: boolean
  configName: string
}): React.ReactElement {
  const { t } = useTranslation('attributionModel')
  const { currentClient } = useCurrentClient()
  const isRBA = currentModelType === RBA_MODEL_TYPE

  // move this...
  const modelMetricFieldMap = {
    [`${t('dashboard.modelTypeTitles.ga4Default') as string}_revenue`]: 'ga4DefaultAttributionRevenue',
    [`${t('dashboard.modelTypeTitles.ga4Default') as string}_conversions`]: 'ga4DefaultAttributionConversions',
    [`${t('dashboard.modelTypeTitles.ga4Lnda') as string}_revenue`]: 'ga4LndaAttributionRevenue',
    [`${t('dashboard.modelTypeTitles.ga4Lnda') as string}_conversions`]: 'ga4LndaAttributionConversions',
    [`${t('dashboard.modelTypeTitles.lnda') as string}_revenue`]: 'lndaRevenue',
    [`${t('dashboard.modelTypeTitles.lnda') as string}_conversions`]: 'lndaConversions'
  }

  const comparisonModel = getComparisonModel(t, currentModelType, hasValidFilter, currentClient.id.toString())
  const noModelToCompare = comparisonModel === undefined

  const [selectedModels, setSelectedModels] = useState<string[]>(comparisonModel ?? [])

  const multiChannelRecords: BarData[] = currentReport
    .map((record) => {
      const aggregatedRecord: BarData = {
        current: record[selectedMetrics] ?? 0,
        x: record.channel
      }

      selectedModels.forEach((model) => {
        const key = `${model}_${selectedMetrics}` as keyof typeof modelMetricFieldMap
        const fieldName = modelMetricFieldMap[key]

        aggregatedRecord[model] = record[fieldName as keyof typeof record] ?? 0
      })

      return aggregatedRecord
    })
    .sort((a, b) => Number(b.current ?? 0) - Number(a.current ?? 0))

  const typeOfProgressValue: string =
    selectedMetrics === 'revenue' ? t('dashboard.revenue') : t('dashboard.conversions')

  const tooltipBody = (key: string): string | undefined => {
    if (key === 'current') {
      return typeOfProgressValue
    } else if (comparisonModel?.includes(key) ?? false) {
      return `${typeOfProgressValue} • ${key}`
    }
    return undefined
  }

  return (
    <Grid container>
      <Typography variant="h2">{t('dashboard.aggregatedChannelData.title')}</Typography>

      <Grid container item xs={12} marginTop={theme.spacing(4)} gap={theme.spacing(4)} alignItems="center" />
      {isDataLoaded && isRBA && (
        <Grid item container alignItems="center" spacing={2} marginBottom={theme.spacing(4)}>
          <Grid item>
            <Typography variant="body2">{t('dashboard.showMe')}</Typography>
          </Grid>
          <Grid item>
            <Tooltip kind="singleline" title={t('dashboard.noModelToCompare')} disabled={!noModelToCompare}>
              <Grid item>
                <MultiSelectDropdown
                  options={comparisonModel?.map((model) => ({ label: model, value: model })) ?? []}
                  value={selectedModels}
                  onChange={(_, selectedItems) => {
                    const selectedValues = selectedItems.map((item) => item.value)
                    setSelectedModels(selectedValues)
                  }}
                  title={t('dashboard.selectAnotherModel')}
                  buttonTitle={t('dashboard.allModels')}
                  buttonWidth="200px"
                  disabled={noModelToCompare}
                />
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      )}

      {!isDataLoaded && (
        <Grid container justifyContent="center">
          <Dots />
        </Grid>
      )}
      {isDataLoaded && multiChannelRecords.length === 0 && (
        <Grid container margin="50px 0" justifyContent="center">
          <Typography variant="h4">{t('dashboard.aggregatedChannelData.noData')}</Typography>
        </Grid>
      )}
      {isDataLoaded && multiChannelRecords.length > 0 && (
        <Grid height="438px" width="100%">
          {comparisonModel !== undefined && (
            <ChartLegend
              selectedRecords={{
                [configName]: {
                  badgeColor: objectKeys(colorsBarChart)[0]
                },
                ...selectedModels.reduce<Record<string, { badgeColor: BadgeColor }>>((acc, model, index) => {
                  acc[model] = {
                    badgeColor: objectKeys(colorsBarChart)[index + 1]
                  }
                  return acc
                }, {})
              }}
            />
          )}
          <BarChart
            data={multiChannelRecords}
            margin={{
              left: 50,
              right: 5,
              top: 50,
              bottom: 50
            }}
            getConfigByDataKey={(dataKey) => ({
              barColor:
                comparisonModel != null
                  ? Object.values(colorsBarChart)[selectedModels.indexOf(dataKey as string) + 1]
                  : Object.values(colorsBarChart)[0]
            })}
            renderTooltip={({ dataKey, payload }) => {
              return (
                <ChartToolTip
                  {...{
                    customBody: (
                      <span>
                        {Object.keys(payload)
                          .filter((key) => key !== 'x')
                          .map((key) => (
                            <div
                              key={key}
                              style={{
                                width: '326px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <div style={{ marginRight: '8px' }}>
                                <Badge
                                  color={
                                    key === 'current'
                                      ? objectKeys(colorsBarChart)[0]
                                      : objectKeys(colorsBarChart)[selectedModels.indexOf(key) + 1]
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  marginRight: '16px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {tooltipBody(key)}
                              </div>
                              <div style={{ marginLeft: 'auto', marginRight: '32px', whiteSpace: 'nowrap' }}>
                                {getFormattedValue({
                                  value: payload[key] as number,
                                  id: selectedMetrics,
                                  currencyCode
                                })}
                              </div>
                            </div>
                          ))}
                      </span>
                    ),
                    title: payload.x.toString()
                  }}
                />
              )
            }}
            xAxis={{
              tickLine: false,
              angle: -45,
              height: 100,
              textAnchor: 'end',
              fontSize: 14,
              tickFormatter: (value: string) => {
                // only show first 16 characters to fit in window size
                if (value.length > xtickCharLimit) {
                  return value.slice(0, xtickCharLimit - 3) + '...'
                }
                return value
              }
            }}
            yAxis={{
              label: {
                value: typeOfProgressValue
              },
              tick: {
                textAnchor: 'middle'
              },
              tickFormatter: (value: string) =>
                getFormattedValue({ value: Number(value), id: selectedMetrics, currencyCode, compact: true })
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}
