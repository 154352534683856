import { PLATFORM } from 'dataSource'
import { type Platforms } from 'shared/api/accounts'

export interface Metric {
  label: string
  id: string
  type: 'number' | 'currency'
}

export const DEFAULT_METRIC: Metric = {
  label: '',
  id: '',
  type: 'number'
}

export const DATA_SOURCE_METRICS: Record<Platforms, Metric[]> = {
  [PLATFORM.GOOGLE]: [
    {
      label: 'Impressions',
      id: 'metrics__impressions',
      type: 'number'
    },
    {
      label: 'Clicks',
      id: 'metrics__clicks',
      type: 'number'
    },
    {
      label: 'Cost',
      id: 'metrics__cost',
      type: 'currency'
    },
    {
      label: 'Conversions',
      id: 'metrics__conversions',
      type: 'number'
    },
    {
      label: 'Conversion value',
      id: 'metrics__conversions_value',
      type: 'currency'
    },
    {
      label: 'All conversions',
      id: 'metrics__all_conversions',
      type: 'number'
    },
    {
      label: 'All conversion value',
      id: 'metrics__all_conversions_value',
      type: 'currency'
    }
  ],
  [PLATFORM.FACEBOOK]: [
    {
      label: 'Impressions',
      id: 'impressions',
      type: 'number'
    },
    {
      label: 'Clicks',
      id: 'clicks',
      type: 'number'
    },
    {
      label: 'Spend',
      id: 'spend',
      type: 'currency'
    },
    {
      label: 'Purchases',
      id: 'purchase',
      type: 'number'
    },
    {
      label: 'Purchase value',
      id: 'purchase_value',
      type: 'currency'
    }
  ],
  [PLATFORM.MICROSOFT_ADVERTISING]: [
    {
      label: 'Impressions',
      id: 'Impressions',
      type: 'number'
    },
    {
      label: 'Clicks',
      id: 'Clicks',
      type: 'number'
    },
    {
      label: 'Spend',
      id: 'Spend',
      type: 'currency'
    }
  ],
  [PLATFORM.DV360]: [
    {
      label: 'Impressions',
      id: 'metric_impressions',
      type: 'number'
    },
    {
      label: 'Clicks',
      id: 'metric_clicks',
      type: 'number'
    },
    {
      label: 'Cost',
      id: 'metric_revenue_advertiser',
      type: 'currency'
    },
    {
      label: 'Conversions',
      id: 'metric_total_conversions',
      type: 'number'
    },
    {
      label: 'Conversion value',
      id: 'metric_cm360_post_click_revenue',
      type: 'currency'
    },
    {
      label: 'Conversion value (view-through)',
      id: 'metric_cm360_post_view_revenue',
      type: 'currency'
    }
  ],
  [PLATFORM.LINKEDIN]: [
    {
      label: 'Impressions',
      id: 'impressions',
      type: 'number'
    },
    {
      label: 'Clicks',
      id: 'clicks',
      type: 'number'
    },
    {
      label: 'Spend',
      id: 'costInLocalCurrency',
      type: 'currency'
    },
    {
      label: 'Conversions',
      id: 'externalWebsiteConversions',
      type: 'number'
    },
    {
      label: 'Conversion value',
      id: 'conversionValueInLocalCurrency',
      type: 'currency'
    }
  ],
  [PLATFORM.TIKTOK]: [
    {
      label: 'Impressions',
      id: 'impressions',
      type: 'number'
    },
    {
      label: 'Clicks',
      id: 'clicks',
      type: 'number'
    },
    {
      label: 'Spend',
      id: 'spend',
      type: 'currency'
    },
    {
      label: 'Onsite Shopping Conversions',
      id: 'onsite_shopping',
      type: 'number'
    },
    {
      label: 'Onsite Shopping Conversion Value',
      id: 'total_onsite_shopping_value',
      type: 'currency'
    }
  ],
  [PLATFORM.ANALYTICS]: [
    {
      label: 'Sessions',
      id: 'sessions',
      type: 'number'
    },
    {
      label: 'Bounces',
      id: 'bounces',
      type: 'number'
    },
    {
      label: 'New users',
      id: 'newUsers',
      type: 'number'
    },
    {
      label: 'Pageviews',
      id: 'pageviews',
      type: 'number'
    },
    {
      label: 'Transactions',
      id: 'transactions',
      type: 'number'
    },
    {
      label: 'Transaction revenue',
      id: 'transactionRevenue',
      type: 'currency'
    }
  ],
  [PLATFORM.GA4]: [
    {
      label: 'Sessions',
      id: 'sessions',
      type: 'number'
    },
    {
      label: 'Engaged sessions',
      id: 'engagedSessions',
      type: 'number'
    },
    {
      label: 'First time purchasers',
      id: 'firstTimePurchasers',
      type: 'number'
    },
    {
      label: 'New users',
      id: 'newUsers',
      type: 'number'
    },
    {
      label: 'Pageviews',
      id: 'screenPageViews',
      type: 'number'
    }
  ],
  [PLATFORM.SNAPCHAT]: [
    {
      label: 'Impressions',
      id: 'impressions',
      type: 'number'
    },
    {
      label: 'Swipe ups',
      id: 'swipes',
      type: 'number'
    },
    {
      label: 'Spend',
      id: 'spend',
      type: 'currency'
    },
    {
      label: 'Purchases',
      id: 'conversion_purchases',
      type: 'number'
    },
    {
      label: 'Purchase value',
      id: 'conversion_purchases_value',
      type: 'currency'
    }
  ],
  [PLATFORM.GOOGLE_SEARCH_CONSOLE]: [],
  [PLATFORM.MERCHANT_CENTER]: [],
  [PLATFORM.GCP]: [],
  [PLATFORM.GOOGLE_SHEETS]: [],
  [PLATFORM.AMAZON]: [],
  [PLATFORM.CRITEO]: [],
  [PLATFORM.GOOGLE_BIGQUERY]: []
}

export const DEFAULT_INSPECT_RESULTS_TABLE_METRICS: Record<Platforms, string[]> = {
  [PLATFORM.GOOGLE]: ['metrics__impressions', 'metrics__clicks', 'metrics__cost'],
  [PLATFORM.FACEBOOK]: ['impressions', 'clicks', 'spend'],
  [PLATFORM.AMAZON]: ['impressions', 'clicks', 'cost'],
  [PLATFORM.CRITEO]: ['Displays', 'Clicks', 'Visits', 'CostPerVisit'],
  [PLATFORM.MICROSOFT_ADVERTISING]: ['Impressions', 'Clicks', 'Spend'],
  [PLATFORM.DV360]: ['metric_impressions', 'metric_clicks', 'metric_revenue_advertiser'],
  [PLATFORM.LINKEDIN]: ['impressions', 'clicks', 'costInLocalCurrency'],
  [PLATFORM.TIKTOK]: ['impressions', 'clicks', 'spend'],
  [PLATFORM.ANALYTICS]: ['sessions', 'bounces', 'transactionRevenue'],
  [PLATFORM.GA4]: ['sessions', 'screenPageViews'],
  [PLATFORM.GOOGLE_SEARCH_CONSOLE]: [],
  [PLATFORM.SNAPCHAT]: ['impressions', 'swipes', 'spend'],
  [PLATFORM.MERCHANT_CENTER]: [],
  [PLATFORM.GCP]: [],
  [PLATFORM.GOOGLE_SHEETS]: [],
  [PLATFORM.GOOGLE_BIGQUERY]: []
}
