import type { AttributionResp } from 'shared/api/attributionModels'

export const hasValidFilters = (integratedAttribution: AttributionResp): boolean => {
  if (
    integratedAttribution?.analyticsAccount?.filterConditions
      ?.map(
        (filter) =>
          filter.filterConditionSqlEventAttributionConnector === undefined &&
          filter.filterConditionSqlSessionAttributionConnector === undefined
      )
      .includes(true) ??
    false
  ) {
    return false
  } else {
    return true
  }
}
