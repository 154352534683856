export const MIMIR_BASE_AGENT_ID = 'mimir'

export const MEMORY_LENGTH = 20

export enum ChatMessageRole {
  user = 'user',
  system = 'system',
  assistant = 'assistant'
}

export enum DocumentSourceType {
  confluence = 'confluence',
  slack = 'slack',
  google_doc = 'google_doc',
  google_slide = 'google_slide',
  web_page = 'web_page',
  intercom = 'intercom', // documents from Intercom Articles powering the Learning Center
  teamwork_spaces = 'teamwork_spaces'
}

export const MIMIR_AGENT_OPTIONS = [
  {
    name: 'Mimir',
    description: 'The generic Mimir can answer questions about all topics.',
    id: MIMIR_BASE_AGENT_ID,
    avatarUrl: '',
    category: 'Starters',
    disabled: false,
    hasSearch: true
  },
  {
    name: 'Mimir Code',
    description: 'Mimir Code is a specialised version of Mimir for coding asistance.',
    id: 'mimir-code',
    avatarUrl: '',
    category: 'Starters',
    disabled: false,
    hasSearch: false
  },
  {
    name: 'Mimir SEO',
    description: 'Mimir SEO is an expert within Search Engine Optimisation.',
    id: 'mimir-seo',
    avatarUrl: '',
    category: 'Topic Experts',
    disabled: false,
    hasSearch: true
  },
  {
    name: 'Mimir Paid Search',
    description: 'Mimir Paid Search is an expert within marketing on search engines.',
    id: 'mimir-paid-search',
    avatarUrl: '',
    category: 'Topic Experts',
    disabled: true,
    hasSearch: true
  },
  {
    name: 'Mimir Paid Social',
    description: 'Mimir Paid Social is an expert within marketing on social media.',
    id: 'mimir-paid-social',
    avatarUrl: '',
    category: 'Topic Experts',
    disabled: true,
    hasSearch: true
  },
  {
    name: 'Mimir Legal',
    description: 'Mimir Legal is an internal Precis assistant that helps with legal questions.',
    id: 'mimir-legal',
    avatarUrl: '',
    category: 'Internal Precis Agents',
    disabled: true,
    hasSearch: true
  },
  {
    name: 'Mimir HR',
    description: 'Mimir HR is an internal Precis assistant that helps with HR questions.',
    id: 'mimir-hr',
    avatarUrl: '',
    category: 'Internal Precis Agents',
    disabled: true,
    hasSearch: true
  }
]
