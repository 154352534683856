import {
  AttributionModelsIcon,
  BudgetOptimiserIcon,
  MimirIcon,
  PresentationBoardIcon,
  EnhancedSignalsIcon,
  ConnectDataSourcesIcon
} from '@precis-digital/kurama'
import { type ReactElement } from 'react-markdown/lib/react-markdown'
import { APP_ROUTES } from '../routes'

export interface AlvieAppMeta {
  id: AlvieAppId
  icon: ReactElement
  uri: string
}

export type AlvieAppId =
  | 'precis-reporting-solutions'
  | 'bifrost-extract-connectors'
  | 'attribution-models'
  | 'budget-optimiser'
  | 'mimir'
  | 'enhanced-signals'

export const ALVIE_APPS_REGISTRY: Record<AlvieAppId, AlvieAppMeta> = {
  'precis-reporting-solutions': {
    id: 'precis-reporting-solutions',
    icon: <PresentationBoardIcon />,
    uri: APP_ROUTES.reportingSolutions.basePage
  },
  'bifrost-extract-connectors': {
    id: 'bifrost-extract-connectors',
    icon: <ConnectDataSourcesIcon />,
    uri: APP_ROUTES.connectors.basePage
  },
  'attribution-models': {
    id: 'attribution-models',
    icon: <AttributionModelsIcon />,
    uri: APP_ROUTES.attributionModels.basePage
  },
  'budget-optimiser': {
    id: 'budget-optimiser',
    icon: <BudgetOptimiserIcon />,
    uri: APP_ROUTES.budgetOptimiser.basePage
  },
  mimir: {
    id: 'mimir',
    icon: <MimirIcon />,
    uri: APP_ROUTES.mimir.basePage
  },
  'enhanced-signals': {
    id: 'enhanced-signals',
    icon: <EnhancedSignalsIcon />,
    uri: APP_ROUTES.enhancedSignals.basePage
  }
}
