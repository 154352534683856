import { ASSETS_BASE_URL } from 'shared/constants'

import { PLATFORM } from 'dataSource/constants'

export const CHANNEL = 'channel'
export const ADSPEND = 'adSpend'
export const REVENUE = 'revenue'
export const ROAS = 'roas'
export const CONVERSIONS = 'conversions'
export const IMPRESSIONS = 'impressions'
export const COSTPERACTION = 'costPerAction'
export const ALLCHANNELS = 'allChannels'
export const METRIC = 'metric'
export const ALLMETRICS = 'allMetrics'

export const DIFFERENCE = 'difference'
export const FIRSTSUBHEADER = 'firstHeader'
export const SECONDSUBHEADER = 'secondHeader'

export const attributionTableID = (modelId: number): string => `ia_credit-allocator_${modelId}__output__main_v1`
export const budgetOptimiserTableID = (modelId: number): string => `ia_curves_${modelId}_optimals_v1`

export const BIGQUERY_SPEND_PLATFORM = {
  label: 'Google Bigquery',
  platform: PLATFORM.GOOGLE_BIGQUERY,
  iconUrl: `${ASSETS_BASE_URL}/google_bigquery.svg`,
  commonIconUrl: `${ASSETS_BASE_URL}/google.svg`,
  oauthUrl: 'fake_url_for_now',
  isSupportedByChannelGrouping: true,
  supportsRecommendationsEngine: false,
  externalEntityLabel: 'views',
  externalEntitiesLabel: 'views',
  supportsServiceAccountAccess: false,
  isManualAccountInput: false
}

export const SUPPORTED_CUSTOM_SPEND_PLATFORMS = [BIGQUERY_SPEND_PLATFORM]

export const DEFINED_MODELS = {
  lnda: {
    value: 'lnda',
    label: 'LNDA'
  },
  ga4Default: {
    value: 'ga4Default',
    label: 'GA4 Default'
  },
  ga4Lnda: {
    value: 'ga4Lnda',
    label: 'GA4 Session-scoped'
  }
} as const

export const CUSTOM_SOURCE_TYPES = [
  { label: 'Online spend', value: 'online_spend' },
  { label: 'Offline spend', value: 'offline_spend' }
]

export const GA4_EXCLUSION_LIST: string[] = ['5158340522934272']

export const DEFAULT_BQ_DATASET = 'alvie_attribution'

export const RBA_MODEL_TYPE = 'rba'
