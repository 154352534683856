// import { generateOauthLoginUrl } from 'dataSource/oauth'
import type { Platforms } from 'shared/api/accounts'
import { ASSETS_BASE_URL } from 'shared/constants'
import config from 'shared/config'

const DASHBOARD_URL = config('NEXT_PUBLIC_DASHBOARD_URL') as string

export const generateOauthLoginUrl = (platform: string): string => {
  return `${DASHBOARD_URL}/api/data-source/oauth/login/${platform}`
}

export interface IPlatform {
  label: string
  externalEntityLabel: string
  externalEntitiesLabel: string
  platform: Platforms
  iconUrl: string
  commonIconUrl: string
  oauthUrl: string
  isSupportedByChannelGrouping: boolean
  supportsRecommendationsEngine: boolean
  uiUrlTemplate?: string
  supportsServiceAccountAccess: boolean
  isManualAccountInput: boolean
}

export const PLATFORM = {
  AMAZON: 'amazon',
  CRITEO: 'criteo',
  FACEBOOK: 'facebook',
  GCP: 'gcp',
  GOOGLE: 'google',
  ANALYTICS: 'analytics',
  GA4: 'ga4',
  DV360: 'dv360',
  MICROSOFT_ADVERTISING: 'microsoft_advertising',
  LINKEDIN: 'linkedin',
  MERCHANT_CENTER: 'merchant_center',
  GOOGLE_SEARCH_CONSOLE: 'google_search_console',
  SNAPCHAT: 'snapchat',
  TIKTOK: 'tiktok',
  GOOGLE_SHEETS: 'google_sheets',
  GOOGLE_BIGQUERY: 'google_bigquery'
} as const

export const PLATFORMS: IPlatform[] = [
  {
    label: 'Google Analytics UA',
    platform: PLATFORM.ANALYTICS,
    iconUrl: `${ASSETS_BASE_URL}/google_analytics_ua.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/google.svg`,
    oauthUrl: generateOauthLoginUrl('google'),
    isSupportedByChannelGrouping: true,
    supportsRecommendationsEngine: false,
    externalEntityLabel: 'views',
    externalEntitiesLabel: 'views',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'Google Analytics 4',
    platform: PLATFORM.GA4,
    iconUrl: `${ASSETS_BASE_URL}/google_analytics_4.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/google.svg`,
    oauthUrl: generateOauthLoginUrl('google'),
    isSupportedByChannelGrouping: true,
    supportsRecommendationsEngine: false,
    externalEntityLabel: 'property',
    externalEntitiesLabel: 'properties',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'Google Ads',
    platform: PLATFORM.GOOGLE,
    iconUrl: `${ASSETS_BASE_URL}/google_ads.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/google.svg`,
    oauthUrl: generateOauthLoginUrl('google'),
    isSupportedByChannelGrouping: true,
    supportsRecommendationsEngine: true,
    externalEntitiesLabel: 'accounts',
    uiUrlTemplate: 'https://ads.google.com/aw/accounts?account_id={accountId}',
    externalEntityLabel: 'account',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'Amazon Ads',
    platform: PLATFORM.AMAZON,
    iconUrl: `${ASSETS_BASE_URL}/amazon_ads.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/amazon_ads.svg`,
    oauthUrl: generateOauthLoginUrl('amazon'),
    isSupportedByChannelGrouping: false,
    supportsRecommendationsEngine: false,
    externalEntityLabel: 'account',
    externalEntitiesLabel: 'accounts',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'Criteo',
    platform: PLATFORM.CRITEO,
    iconUrl: `${ASSETS_BASE_URL}/criteo.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/criteo.svg`,
    oauthUrl: generateOauthLoginUrl('criteo'),
    isSupportedByChannelGrouping: false,
    supportsRecommendationsEngine: false,
    externalEntityLabel: 'account',
    externalEntitiesLabel: 'accounts',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'DV360',
    platform: PLATFORM.DV360,
    iconUrl: `${ASSETS_BASE_URL}/dv360.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/google.svg`,
    oauthUrl: generateOauthLoginUrl('google'),
    isSupportedByChannelGrouping: true,
    supportsRecommendationsEngine: true,
    externalEntitiesLabel: 'accounts',
    uiUrlTemplate: 'https://displayvideo.google.com/ng_nav/home_v3',
    externalEntityLabel: 'account',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'Google Search Console',
    platform: PLATFORM.GOOGLE_SEARCH_CONSOLE,
    iconUrl: `${ASSETS_BASE_URL}/google_search_console.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/google.svg`,
    oauthUrl: generateOauthLoginUrl('google'),
    isSupportedByChannelGrouping: false,
    supportsRecommendationsEngine: false,
    externalEntityLabel: 'property',
    externalEntitiesLabel: 'properties',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'Google Merchant Center',
    platform: PLATFORM.MERCHANT_CENTER,
    iconUrl: `${ASSETS_BASE_URL}/merchant_center.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/google.svg`,
    oauthUrl: generateOauthLoginUrl('google'),
    isSupportedByChannelGrouping: false,
    supportsRecommendationsEngine: false,
    externalEntityLabel: 'account',
    externalEntitiesLabel: 'accounts',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'Google Cloud Platform',
    platform: PLATFORM.GCP,
    iconUrl: `${ASSETS_BASE_URL}/gcp.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/google.svg`,
    oauthUrl: generateOauthLoginUrl('google'),
    isSupportedByChannelGrouping: false,
    supportsRecommendationsEngine: false,
    externalEntityLabel: 'project',
    externalEntitiesLabel: 'projects',
    supportsServiceAccountAccess: true,
    isManualAccountInput: false
  },
  {
    label: 'Microsoft Ads',
    platform: PLATFORM.MICROSOFT_ADVERTISING,
    iconUrl: `${ASSETS_BASE_URL}/microsoft_ads.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/microsoft_ads.svg`,
    oauthUrl: generateOauthLoginUrl('microsoft_advertising'),
    isSupportedByChannelGrouping: true,
    supportsRecommendationsEngine: true,
    externalEntitiesLabel: 'accounts',
    uiUrlTemplate: 'https://ads.microsoft.com/',
    externalEntityLabel: 'account',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'LinkedIn',
    platform: PLATFORM.LINKEDIN,
    iconUrl: `${ASSETS_BASE_URL}/linkedin.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/linkedin.svg`,
    oauthUrl: generateOauthLoginUrl('linkedin'),
    isSupportedByChannelGrouping: true,
    supportsRecommendationsEngine: false,
    externalEntityLabel: 'account',
    externalEntitiesLabel: 'accounts',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'Facebook Ads',
    platform: PLATFORM.FACEBOOK,
    iconUrl: `${ASSETS_BASE_URL}/facebook_ads.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/facebook_ads.svg`,
    oauthUrl: generateOauthLoginUrl('facebook'),
    isSupportedByChannelGrouping: true,
    supportsRecommendationsEngine: true,
    externalEntitiesLabel: 'accounts',
    uiUrlTemplate: 'https://www.facebook.com/adsmanager/manage/campaigns?act={accountId}',
    externalEntityLabel: 'account',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'Snapchat',
    platform: PLATFORM.SNAPCHAT,
    iconUrl: `${ASSETS_BASE_URL}/snapchat.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/snapchat.svg`,
    oauthUrl: generateOauthLoginUrl('snapchat'),
    isSupportedByChannelGrouping: true,
    supportsRecommendationsEngine: false,
    externalEntityLabel: 'account',
    externalEntitiesLabel: 'accounts',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'Tiktok',
    platform: PLATFORM.TIKTOK,
    iconUrl: `${ASSETS_BASE_URL}/tiktok.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/tiktok.svg`,
    oauthUrl: generateOauthLoginUrl('tiktok'),
    isSupportedByChannelGrouping: true,
    supportsRecommendationsEngine: false,
    externalEntityLabel: 'account',
    externalEntitiesLabel: 'accounts',
    supportsServiceAccountAccess: false,
    isManualAccountInput: false
  },
  {
    label: 'Google Sheets',
    platform: 'google_sheets',
    iconUrl: `${ASSETS_BASE_URL}/google_sheets.svg`,
    commonIconUrl: `${ASSETS_BASE_URL}/google.svg`,
    oauthUrl: generateOauthLoginUrl('google'),
    isSupportedByChannelGrouping: false,
    supportsRecommendationsEngine: false,
    externalEntityLabel: 'spreadsheet',
    externalEntitiesLabel: 'spreadsheets',
    supportsServiceAccountAccess: false,
    isManualAccountInput: true
  }
]
export const OAUTH_CALLBACK = 'oauth/callback?credential_id='
export const CREDENTIAL_ID_KEY = 'credential_id'
export const ERROR_KEY = 'error'
