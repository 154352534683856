import Link, { type LinkProps } from 'next/link'
import { type NextRouter, useRouter } from 'next/router'
import { type PropsWithChildren, type ReactNode, type ComponentType } from 'react'
import { CLIENT_ID_QUERY_PARAM } from 'shared/routes'
import { type ParsedUrlQuery } from 'querystring'
import { not } from 'ramda'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useBreadcrumbsPathProps } from 'shared/hooks/useBreadcrumbsPathProps'
import { styled } from '@precis-digital/kurama'

type Push = (query: ParsedUrlQuery, reset?: boolean) => Promise<void>

export interface BreadcrumbPathProps {
  label: string
  path: string
}

const DEFAULT_QUERY = [CLIENT_ID_QUERY_PARAM]
export const getDefaultQuery = (router: NextRouter): ParsedUrlQuery => {
  const query = router?.query ?? {}
  return {
    ...(query[CLIENT_ID_QUERY_PARAM] != null ? { [CLIENT_ID_QUERY_PARAM]: query[CLIENT_ID_QUERY_PARAM] } : {})
  }
}

export const useClearQueries = (): (() => Promise<boolean>) => {
  const router = useRouter()
  return async () => {
    return await router.push({
      pathname: router.pathname,
      query: {}
    })
  }
}

interface DefaultQueryLinkProps extends LinkProps {
  query?: ParsedUrlQuery
}
export const RetainDefaultQueryLink = ({
  href,
  query,
  ...props
}: PropsWithChildren<DefaultQueryLinkProps>): React.ReactElement => {
  const router = useRouter()
  const pathname = typeof href === 'object' ? href.pathname : href

  return (
    <Link
      {...props}
      href={{
        pathname,
        query: {
          ...getDefaultQuery(router),
          ...query
        }
      }}
    />
  )
}

export const UnStyledRetainDefaultQueryLink = styled(RetainDefaultQueryLink)<{ disabled?: boolean }>(
  ({ disabled }) => ({
    color: 'inherit',
    textDecoration: 'inherit',
    cursor: 'inherit',
    width: '100%',
    pointerEvents: disabled === true ? 'none' : 'auto'
  })
)

export const useQueryString = (): { query: ParsedUrlQuery; pushQuery: Push } => {
  const router = useRouter()
  const query = router?.query ?? {}

  const pushQuery: Push = async (query, reset): Promise<void> => {
    const defaultQuery = getDefaultQuery(router)
    if (reset === true) {
      Object.keys(router.query).forEach((id) => {
        if (not(DEFAULT_QUERY.includes(id)) && not(router.pathname.includes(id))) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete router.query[id]
        }
      })
    }

    const newQuery = {
      ...router.query,
      ...defaultQuery,
      ...query
    }

    await router?.replace(
      {
        pathname: router.pathname,

        query: newQuery
      },
      undefined,
      { shallow: false }
    )
  }

  return { query, pushQuery }
}

type Reload = () => void

export const useReload = (): Reload => {
  const router = useRouter()
  return router.reload
}

type GoBack = () => void
export const useGoBack = (): GoBack => {
  const router = useRouter()
  return router.back
}

export const useChangePath = (): {
  changePath: (url?: string, query?: ParsedUrlQuery, replace?: boolean) => Promise<void>
  pathname: string
  populatedPathName: string
} => {
  const router = useRouter()
  const pathname = router?.pathname ?? ''
  const populatedPathName = router?.asPath?.split('?')?.[0] ?? ''

  const changePath = async (url?: string, query?: ParsedUrlQuery, replace?: boolean): Promise<void> => {
    const mergedQuery: ParsedUrlQuery = { ...getDefaultQuery(router), ...query }
    const newQuery: ParsedUrlQuery = {}

    Object.entries(mergedQuery).forEach(([key, value]) => {
      if (value != null) newQuery[key] = value
    })

    if (replace === true) {
      await router.replace(
        {
          pathname: url ?? pathname,

          query: newQuery
        },
        undefined,
        { shallow: true }
      )
    } else {
      await router.push({
        pathname: url ?? pathname,

        query: newQuery
      })
    }
  }

  return { changePath, pathname, populatedPathName }
}

export const useOpenCentralAppLink = (): { openCentralAppLink: (url: string) => void } => {
  const { currentClient } = useCurrentClient()

  const openCentralAppLink = (url: string): void => {
    if (window != null) {
      window.open(`${url}?client_id=${currentClient.id}`, '_blank')
    }
  }

  return { openCentralAppLink }
}

interface UseBreadcrumbsProps {
  currentPath: string
  pathProps: BreadcrumbPathProps[]
  Link: ComponentType<{ href: string; children: ReactNode }>
}

export const useBreadcrumbs = (): UseBreadcrumbsProps => {
  const router = useRouter()

  const pathProps = useBreadcrumbsPathProps(router)

  return {
    currentPath: router.pathname,
    pathProps,
    Link: (props) => {
      return <RetainDefaultQueryLink href={props.href}>{props.children}</RetainDefaultQueryLink>
    }
  }
}
