import {
  FIRSTSUBHEADER as tmpFIRSTSUBHEADER,
  SECONDSUBHEADER as tmpSECONDSUBHEADER,
  DIFFERENCE as tmpDIFFERENCE
} from 'shared/reactTable/constants'

export const CHANNEL = 'channel'
export const ALLCHANNELS = 'allChannels'
export const METRIC = 'metric'
export const ALLMETRICS = 'allMetrics'
export const ROAS = 'roas'
export const CONVERSIONS = 'conversions'
export const COSTPERACTION = 'costPerAction'
export const ADSPEND = 'adSpend'
export const REVENUE = 'revenue'
export const DIFFERENCE = tmpDIFFERENCE
export const FIRSTSUBHEADER = tmpFIRSTSUBHEADER
export const SECONDSUBHEADER = tmpSECONDSUBHEADER
export const ALLDIMENSIONS = 'allDimensions'
export const DIMENSION = 'dimensions'
export const CAMPAIGN = 'campaign'
export const RECOMMENDATION = 'recommendations'
export const ACTION = 'action'
export const PLATFORM = 'platform'
export const DATASOURCES = 'dataSources'
export const PLATFORM_CHANNEL_GROUPING = 'platformChannelGrouping'
export const MAPPED_OPTIMAL_COLUMN_NAME = {
  [ADSPEND]: 'optSpend',
  [REVENUE]: 'optRevenue',
  [ROAS]: 'optRoas',
  [CONVERSIONS]: 'optConversions',
  [COSTPERACTION]: 'optCpa'
}

export const DEFAULT_BQ_DATASET = 'alvie_budget_optimiser'
