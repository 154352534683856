import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  type ClientSideFilters,
  type GroupedCard,
  type MutationFunction,
  type PlatformList
} from 'recommendations/types'
import {
  type FeedResp,
  type FeedDistinctPlatforminResp,
  type FeedPlatforms,
  type AnalyticsReq,
  type Filter,
  type AnalyticsReqOrderBy
} from 'shared/api/analytics'
import { filterBySeverity, getFeed, groupCards, sortByTimestampAndSeverity } from 'recommendations/utils'
import { useMutationActivityFeed } from 'recommendations/api'
import { factorToReduceMaxCharactersBy, rules } from 'recommendations/constants'
import RecommendationCard from './components/RecommendationCard'
import { type Client } from 'shared/context/ClientContext'
import { useTranslation } from 'shared/translations'
import { type ClientAccountsResp } from 'shared/api/accounts'
import { type UseMutateFunction } from 'react-query'
import { type ClientError } from 'shared/api/fetch'
import OverScreen from 'shared/overScreens'
import { sendGTMEvent } from 'shared/googleTagManager'

type UseFilterByPlatform = [
  Record<PlatformList, boolean>,
  React.Dispatch<React.SetStateAction<Record<FeedPlatforms, boolean>>>
]
export const useFilterByPlatform = (platformList: FeedDistinctPlatforminResp[]): UseFilterByPlatform => {
  const [selectedPlatform, setSelectedPlatform] = React.useState<Record<string, boolean>>(() => {
    const results: Record<string, boolean> = {}
    if (platformList.length === 0) {
      return results
    }
    platformList.forEach((platform) => {
      results[platform.distinctPlatform] = true
    })
    return results
  })
  useEffect(() => {
    if (platformList.length > 0) {
      const results: Record<string, boolean> = {}
      platformList.forEach((platform) => {
        results[platform.distinctPlatform] = true
      })
      setSelectedPlatform(results)
    }
  }, [platformList])

  return [selectedPlatform, setSelectedPlatform]
}

type UseSortBy = [string, React.Dispatch<React.SetStateAction<string>>]

export const useSortBy = (): UseSortBy => {
  const [sortBy, setSortBy] = React.useState<string>('timestamp')
  return [sortBy, setSortBy]
}

export const useResizeCard = (
  width: number
): { cardContainerWidth: number; cardRef: React.RefObject<HTMLDivElement> } => {
  const [cardContainerWidth, setCardContainerWidth] = useState<number>(width)
  const cardRef: React.RefObject<HTMLDivElement> = useRef(null)
  useEffect(() => {
    const handleResize = (): void => {
      if (cardRef.current != null) {
        setCardContainerWidth(window.innerWidth)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)

    if (cardRef.current != null) {
      resizeObserver.observe(cardRef.current)
      handleResize()
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [cardContainerWidth])

  return { cardContainerWidth, cardRef }
}

interface Options {
  filters?: Filter[]
  orderBy?: AnalyticsReqOrderBy
}
interface UseFeedProps {
  currentClient: Client
  dataSources: {
    data: ClientAccountsResp[] | undefined
    isLoading: boolean
    isSuccess: boolean
  }
  options?: Options
  customFilters?: {
    filters: ClientSideFilters
  }
  shouldTruncateText?: boolean
}
interface UseFeedReturn {
  cards: Array<JSX.Element | null> | null
  feedData: FeedResp[]
  groupedData: GroupedCard[]
  isSuccess: boolean
  isError: boolean
  fetchFeed: UseMutateFunction<FeedResp[], ClientError, AnalyticsReq, unknown>
  cardsContainerRef: React.RefObject<HTMLDivElement>
}
export const useFeed = ({
  currentClient,
  dataSources,
  options,
  customFilters,
  shouldTruncateText = true
}: UseFeedProps): UseFeedReturn => {
  const { t } = useTranslation('recommendation')
  const { cardContainerWidth, cardRef } = useResizeCard(500)
  const lineHeight = 20
  const maxCharacters = Math.floor(cardContainerWidth / (lineHeight * factorToReduceMaxCharactersBy))
  const { mutate: fetchFeed, isSuccess, isError, data: feedData } = useMutationActivityFeed()
  const isDataLoaded = isSuccess || isError
  useEffect(() => {
    if (dataSources.isSuccess && dataSources.data != null && dataSources.data.length > 0) {
      getFeed(fetchFeed as MutationFunction, options)(currentClient)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding options to dependency array puts the component in an infinite loop
  }, [fetchFeed, currentClient, dataSources.data])

  const groupedData = useMemo(() => {
    let cards = groupCards(feedData ?? [], dataSources.data)
    if (customFilters != null) {
      if (customFilters.filters.severity != null) {
        cards = filterBySeverity(cards, customFilters.filters.severity)
      }
    }
    return cards
  }, [feedData, dataSources.data, customFilters])

  const handleRecommendationPopupClick = (cardData: GroupedCard): void => {
    sendGTMEvent({
      event: 'recommendations_popup_open',
      product: 'recommendations',
      recommendation_rule_id: cardData.ruleId
    })
    void OverScreen.show('recommendationsPopup', cardData)
  }

  const feed = useMemo(() => {
    const sortedGroupedData = groupedData.slice().sort((a, b) => sortByTimestampAndSeverity(a, b))
    if (dataSources.isSuccess && dataSources.data != null && dataSources.data.length === 0) {
      return []
    }
    return isDataLoaded
      ? sortedGroupedData.length > 0
        ? sortedGroupedData.map((card) => {
            const ruleId = card.ruleId
            const cardRule = rules[ruleId]
            if (cardRule == null) {
              return null
            }
            return (
              <RecommendationCard
                onClick={() => {
                  handleRecommendationPopupClick(card)
                }}
                data={card}
                maxCharacters={maxCharacters}
                cardContainerWidth={cardContainerWidth}
                key={card.messageId}
                t={t}
                shouldTruncateText={shouldTruncateText}
              />
            )
          })
        : []
      : null
  }, [isDataLoaded, maxCharacters, cardContainerWidth, t, groupedData, shouldTruncateText, dataSources])
  return {
    cards: feed,
    feedData: feedData ?? [],
    groupedData,
    isSuccess,
    isError,
    fetchFeed,
    cardsContainerRef: cardRef
  }
}
