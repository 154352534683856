import { prop, groupBy, pipe, sum, pluck, map, curry } from 'ramda'
import {
  formatDateToString,
  getCorrespondingPreviousPeriodDate,
  getCorrespondingPreviousYearDate
} from 'shared/dateFns'
import { type TimePeriodType } from '../components/StartPage'
import { format } from 'date-fns'
import { formDataKey } from '../components/utils'
import { type ProgressOverTimeRecords } from 'home/components/ProgressOvertime'
import { type ReportingBaseReport } from './reportingBase'
import { type ChannelGroupingPerformanceReport } from './channelGrouping'
import { type OriginalRow } from 'shared/reactTable/types'
import { type Metric } from 'home/reportTemplateParameters'

export type TypeOfProgress = 'conversionValue' | 'conversions' | 'spend' | 'roas' | 'costPerAction'

export type Report = ReportingBaseReport | ChannelGroupingPerformanceReport

const groupByChannel = groupBy<{ channel: string }>(prop('channel'))

const groupByMarket = groupBy<{ market: string }>(prop('market'))

// @ts-expect-error - This is a valid key, but TS doesn't know that
export const total = curry((reports: Report[], field: string): number => pipe(pluck(field), sum)(reports) as number)

export type FilterIds = 'markets' | 'channels'

export type Filters = Record<
  FilterIds,
  Set<{
    id: string
    name: string
  }>
>

export const transformToFilters = <
  T extends {
    channel: string
    market: string
  }
>(
  records: T[]
): Filters => {
  return {
    markets: new Set(Object.keys(groupByMarket(records)).map((market: string) => ({ name: market, id: market }))),

    channels: new Set(Object.keys(groupByChannel(records)).map((channel: string) => ({ name: channel, id: channel })))
  }
}

export type CalculateTotalsFn<TReport extends Report, TMetric extends Metric> = ({
  records,
  metrics,
  market,
  channel
}: {
  records: TReport[]
  metrics: TMetric[]
  market?: string
  channel?: string
}) => Record<string, number>

export type TransformToTimeSeriesByMetricAndBreakdownFn<TReport extends Report, TMetric extends Metric> = ({
  records,
  previousRecords,
  metrics,
  channels,
  markets,
  daysRange,
  isComparisonMode,
  selectedPeriodType
}: {
  records: TReport[]
  previousRecords: TReport[]
  metrics: TMetric[]
  channels: string[]
  markets: string[]
  daysRange: number
  isComparisonMode: boolean
  selectedPeriodType: TimePeriodType
}) => ProgressOverTimeRecords

export type TransformToDetailedDataFn<TReport extends Report> = ({
  reports,
  reportsForPreviousTimePeriod,
  selectedDimensions
}: {
  reports: TReport[]
  reportsForPreviousTimePeriod: TReport[]
  selectedDimensions: Array<'market' | 'channel'>
}) => OriginalRow[]

export const transformToTimeSeriesByMetricAndBreakdown = <
  TReport extends ReportingBaseReport | ChannelGroupingPerformanceReport,
  TMetric extends Metric
>({
  records,
  previousRecords,
  metrics,
  channels,
  markets,
  daysRange,
  isComparisonMode,
  selectedPeriodType,
  calculateTotals
}: {
  records: TReport[]
  previousRecords: TReport[]
  metrics: TMetric[]
  channels: string[]
  markets: string[]
  daysRange: number
  isComparisonMode: boolean
  selectedPeriodType: TimePeriodType
  calculateTotals: CalculateTotalsFn<TReport, TMetric>
}): ProgressOverTimeRecords => {
  const breakdownCount = channels.length + markets.length

  const hasBreakdown = breakdownCount > 0

  const filterByBreakdown = (record: Report): boolean => {
    return !hasBreakdown || channels.includes(record.channel) || markets.includes(record.market)
  }

  const filteredRecords = records.filter(filterByBreakdown)

  const previousFilteredRecords = breakdownCount > 2 ? [] : previousRecords.filter(filterByBreakdown)

  filteredRecords.sort(
    (firstRecord, secondRecord) =>
      new Date(firstRecord.createdAt).getTime() - new Date(secondRecord.createdAt).getTime()
  )

  const reportsGroupedByDate = groupBy<TReport>((record) => record.createdAt, filteredRecords)

  const previousReportsGroupedByDate = groupBy<TReport>(
    (record) => format(new Date(record.createdAt), 'yyyy-MM-dd'),
    previousFilteredRecords
  )

  const totalOnReportTypeByDate: Record<string, Record<string, number>> = {}

  const previousTotalOnReportTypeByDate: Record<string, Record<string, number>> = {}

  const dates = Object.keys(reportsGroupedByDate)

  dates.forEach((date) => {
    const records = reportsGroupedByDate[date]
    const previousDate =
      selectedPeriodType === 'previous_period'
        ? getCorrespondingPreviousPeriodDate(date, daysRange)
        : getCorrespondingPreviousYearDate(date)

    totalOnReportTypeByDate[date] = {
      ...(!hasBreakdown && { ...calculateTotals({ records, metrics }) }),
      ...markets.reduce((acc, market) => ({ ...acc, ...calculateTotals({ records, metrics, market }) }), {}),
      ...channels.reduce((acc, channel) => ({ ...acc, ...calculateTotals({ records, metrics, channel }) }), {})
    }

    if (isComparisonMode) {
      previousTotalOnReportTypeByDate[previousDate] = {
        ...(!hasBreakdown && {
          ...calculateTotals({ records: previousReportsGroupedByDate[previousDate] ?? [], metrics })
        }),
        ...markets.reduce(
          (acc, market) => ({
            ...acc,
            ...calculateTotals({ records: previousReportsGroupedByDate[previousDate] ?? [], metrics, market })
          }),
          {}
        ),
        ...channels.reduce(
          (acc, channel) => ({
            ...acc,
            ...calculateTotals({ records: previousReportsGroupedByDate[previousDate] ?? [], metrics, channel })
          }),
          {}
        )
      }
    }
  })

  const breakdowns = [...markets, ...channels]

  const results = map((date) => {
    return {
      x: formatDateToString({ date, targetFormat: 'MMM do' }),
      fullDate: date,
      ...metrics.reduce<Record<string, string | number>>((acc, metric) => {
        if (totalOnReportTypeByDate[date][metric] != null) {
          const dataKey = formDataKey({ metric })

          acc[dataKey] = totalOnReportTypeByDate[date][metric]

          if (isComparisonMode) {
            const correspondingPreviousDate =
              selectedPeriodType === 'previous_period'
                ? getCorrespondingPreviousPeriodDate(date, daysRange)
                : getCorrespondingPreviousYearDate(date)

            const previousDataKey = formDataKey({ metric, isPreviousMetric: true })

            acc[previousDataKey] = previousTotalOnReportTypeByDate[correspondingPreviousDate][metric] ?? 0
          }
        }

        breakdowns.forEach((breakdown) => {
          const breakdownDataKey = formDataKey({ metric, breakdown })

          if (totalOnReportTypeByDate[date][breakdownDataKey] == null) {
            return acc
          }
          acc[breakdownDataKey] = totalOnReportTypeByDate[date][breakdownDataKey]

          if (breakdowns.length <= 2 && isComparisonMode) {
            const correspondingPreviousDate =
              selectedPeriodType === 'previous_period'
                ? getCorrespondingPreviousPeriodDate(date, daysRange)
                : getCorrespondingPreviousYearDate(date)

            const previousBreakdownDataKey = formDataKey({ metric, breakdown, isPreviousMetric: true })
            acc[previousBreakdownDataKey] =
              previousTotalOnReportTypeByDate[correspondingPreviousDate][breakdownDataKey] ?? 0
          }
        })
        return acc
      }, {})
    }
  }, dates)

  return results
}

export const filterReportsByDateRange = (reports: Report[], startDate: string, endDate: string): Report[] => {
  return reports.filter((report) => {
    const reportDate = new Date(report.createdAt)
    return reportDate >= new Date(startDate) && reportDate <= new Date(endDate)
  })
}
