import { type FlowChartEdge, type BadgeColor } from '@precis-digital/kurama'
import {
  API_CONFIG_STATUSES,
  type DagService,
  type DagChildrenStatusItem,
  type DagStatusResp,
  type E2EStatus
} from './api/analytics'
import { objectKeys } from './utils'

export const STATUSES = {
  active: 'active',
  paused: 'paused',
  error: 'error',
  running: 'running',
  new: 'new',
  unknown: 'unknown',
  connected: 'connected',
  disconnected: 'disconnected'
} as const

export type ConfigStatus = keyof typeof STATUSES

export const STATUS_COLOR_MAP: Partial<Record<ConfigStatus, BadgeColor>> = {
  active: 'success',
  running: 'warning',
  new: 'info',
  error: 'error',
  connected: 'success',
  disconnected: 'error'
}

export const STATUSES_WITH_TOOLTIP: ConfigStatus[] = [
  STATUSES.error,
  STATUSES.unknown,
  STATUSES.running,
  STATUSES.active,
  STATUSES.connected,
  STATUSES.disconnected
]

export const ORDERED_TAB_STATUSES = [STATUSES.active, STATUSES.paused, STATUSES.running, STATUSES.error]

export const getStatusFromAPIStatusData = (runSchedule: boolean, e2eStatus: E2EStatus | undefined): ConfigStatus => {
  if (runSchedule) {
    if (e2eStatus == null || e2eStatus === API_CONFIG_STATUSES.fetch_failed) {
      return STATUSES.unknown
    } else if (e2eStatus === API_CONFIG_STATUSES.completed) {
      return STATUSES.active
    } else if (e2eStatus === API_CONFIG_STATUSES.running || e2eStatus === API_CONFIG_STATUSES.queued) {
      return STATUSES.running
    }
  } else {
    return STATUSES.paused
  }

  return STATUSES.error
}

/***
 MW 2024-05-09

 Due to unreliability in the Marketing Evaluation Pipeline, we need to change the status of the pipeline to running if the status is error.
 This givest the engineering team more time to investigate the issue and fix it without the pipeline alerting the user.
 hopefully, we can remove this in the future.
 ***/
export const getStatusMarketingEvaluation = (runSchedule: boolean, e2eStatus: E2EStatus | undefined): ConfigStatus => {
  const defaultStatus = getStatusFromAPIStatusData(runSchedule, e2eStatus)
  if (defaultStatus === STATUSES.error) {
    return STATUSES.running
  }
  return defaultStatus
}

export const ErrorCode = {
  ERR100: 'ERR100',
  ERR200: 'ERR200',
  ERR300: 'ERR300',
  ERR400: 'ERR400',
  ERR002: 'ERR002',
  ERR003: 'ERR003',
  ERR004: 'ERR004',
  ERR005: 'ERR005',
  ERR006: 'ERR006',
  ERR007: 'ERR007',
  ERR999: 'ERR999',
  ERR101: 'ERR101',
  ERR102: 'ERR102',
  ERR103: 'ERR103',
  ERR104: 'ERR104',
  ERR105: 'ERR105',
  ERR106: 'ERR106',
  ERR107: 'ERR107',
  ERR201: 'ERR201',
  ERR202: 'ERR202',
  ERR203: 'ERR203',
  ERR204: 'ERR204',
  ERR302: 'ERR302',
  ERR303: 'ERR303'
} as const
export type ErrorCodeType = (typeof ErrorCode)[keyof typeof ErrorCode]

export const defaultErrorCode: ErrorCodeType = ErrorCode.ERR999

export type IntercomArticleId =
  | 206420
  | 206895
  | 206843
  | 206574
  | 206896
  | 206897
  | 206844
  | 206845
  | 206575
  | 206576
  | 206898
  | 206421
  | 206422
  | 206423
  | 206424
  | 183187
  | 206899
  | 206900
  | 206846
  | 206847
  | 206976
  | 212382
  | 215279
  | 215282

export const ERROR_CODE_DETAILS_MAP: Record<
  ErrorCodeType,
  { translationPlaceholders: string[]; intercomArticleId: IntercomArticleId }
> = {
  [ErrorCode.ERR100]: { translationPlaceholders: [], intercomArticleId: 206420 },
  [ErrorCode.ERR200]: { translationPlaceholders: [], intercomArticleId: 206895 },
  [ErrorCode.ERR300]: { translationPlaceholders: [], intercomArticleId: 206843 },
  [ErrorCode.ERR400]: { translationPlaceholders: [], intercomArticleId: 206574 },
  [ErrorCode.ERR002]: { translationPlaceholders: [], intercomArticleId: 206896 },
  [ErrorCode.ERR003]: { translationPlaceholders: ['pipelineLabel'], intercomArticleId: 206897 },
  [ErrorCode.ERR004]: { translationPlaceholders: [], intercomArticleId: 206844 },
  [ErrorCode.ERR005]: { translationPlaceholders: ['pipelineLabel'], intercomArticleId: 206845 },
  [ErrorCode.ERR006]: { translationPlaceholders: [], intercomArticleId: 206575 },
  [ErrorCode.ERR007]: { translationPlaceholders: ['pipelineLabel'], intercomArticleId: 206576 },
  [ErrorCode.ERR101]: { translationPlaceholders: [], intercomArticleId: 206898 },
  [ErrorCode.ERR102]: { translationPlaceholders: [], intercomArticleId: 206421 },
  [ErrorCode.ERR103]: { translationPlaceholders: ['targetGCPProjectID'], intercomArticleId: 206422 },
  [ErrorCode.ERR104]: { translationPlaceholders: ['targetGCPProjectID'], intercomArticleId: 206423 },
  [ErrorCode.ERR105]: { translationPlaceholders: ['targetGCPProjectID'], intercomArticleId: 206424 },
  [ErrorCode.ERR106]: { translationPlaceholders: ['targetGCPProjectID'], intercomArticleId: 183187 },
  [ErrorCode.ERR107]: { translationPlaceholders: ['targetGCPProjectID'], intercomArticleId: 212382 },
  [ErrorCode.ERR201]: { translationPlaceholders: ['errorMessage'], intercomArticleId: 206899 },
  [ErrorCode.ERR202]: { translationPlaceholders: [], intercomArticleId: 206900 },
  [ErrorCode.ERR203]: { translationPlaceholders: [], intercomArticleId: 215279 },
  [ErrorCode.ERR204]: { translationPlaceholders: [], intercomArticleId: 215282 },
  [ErrorCode.ERR302]: { translationPlaceholders: [], intercomArticleId: 206846 },
  [ErrorCode.ERR303]: { translationPlaceholders: [], intercomArticleId: 206847 },
  [ErrorCode.ERR999]: { translationPlaceholders: [], intercomArticleId: 206976 }
}

export interface ErrorDetails {
  translationPlaceholders: Record<string, string>
  intercomArticleId: IntercomArticleId
}
export type TranslationPlaceholders = 'targetGCPProjectID' | 'pipelineLabel' | 'errorMessage'

export const getErrorCodeDetails = (
  errorCode: ErrorCodeType | null | undefined,
  errorMessage: string | null | undefined,
  targetGCPProjectID: string | null | undefined,
  pipelineLabel: string | null | undefined
): ErrorDetails | null => {
  if (errorCode === undefined || errorCode === null) {
    return null
  }

  const errorDetails = ERROR_CODE_DETAILS_MAP[errorCode]

  const { translationPlaceholders, intercomArticleId } = errorDetails
  const resolvedPlaceholders: Partial<Record<TranslationPlaceholders, string>> = {}

  if (
    translationPlaceholders.includes('targetGCPProjectID') &&
    targetGCPProjectID !== undefined &&
    targetGCPProjectID !== null
  ) {
    resolvedPlaceholders.targetGCPProjectID = targetGCPProjectID
  }

  if (translationPlaceholders.includes('pipelineLabel') && pipelineLabel !== undefined && pipelineLabel !== null) {
    resolvedPlaceholders.pipelineLabel = pipelineLabel
  }

  if (translationPlaceholders.includes('errorMessage') && errorMessage !== undefined && errorMessage !== null) {
    resolvedPlaceholders.errorMessage = errorMessage
  }

  return {
    translationPlaceholders: resolvedPlaceholders as Record<TranslationPlaceholders, string>,
    intercomArticleId
  }
}

export interface DagNode {
  id: string
  position: { x: number; y: number }
  data: {
    status: E2EStatus
    lastUpdated: string | null
    configId: string
    service: DagService
    message: string
    error: string
    errorCode: ErrorCodeType | null
    errorMessage: string | null
  }
  level: number
}

export const transformDAGStatusToNodesAndEdges = (
  dagStatus: DagStatusResp
): { nodes: DagNode[]; edges: FlowChartEdge[] } => {
  const nodes: DagNode[] = []
  const edges: FlowChartEdge[] = []
  const queue: Array<{ node: DagStatusResp | DagChildrenStatusItem; parentId: string | null; level: number }> = [
    { node: dagStatus, parentId: null, level: 0 }
  ]
  const levels: Record<number, DagNode[]> = {}

  while (queue.length > 0) {
    const firstItemInQueue = queue.shift()

    if (firstItemInQueue == null) break

    const { node, parentId, level } = firstItemInQueue

    if (levels[level] == null) {
      levels[level] = []
    }

    const nodeId = `${node.configId}-${node.service}`
    const nodeData: DagNode = {
      id: nodeId,
      data: {
        service: node.service,
        status: node.status,
        lastUpdated: node.lastUpdated,
        configId: node.configId,
        message: node.message,
        error: node.error,
        errorCode: node.errorCode,
        errorMessage: node.errorMessage
      },
      position: { x: -level * 320, y: levels[level].length * 100 },
      level
    }
    nodes.push(nodeData)
    levels[level].push(nodeData)

    if (parentId !== null) {
      const edgeData = {
        id: `${nodeId}-${parentId}`,
        source: nodeId,
        target: parentId
      }
      edges.push(edgeData)
    }

    if (node.children != null) {
      if (Array.isArray(node.children)) {
        node.children.forEach((child) => {
          queue.push({ node: child, parentId: nodeId, level: level + 1 })
        })
      } else {
        queue.push({ node: node.children, parentId: nodeId, level: level + 1 })
      }
    }
  }

  const levelKeys = objectKeys(levels)

  let highestAverageYLevelKey = 0
  let highestAverageY = 0

  levelKeys.forEach((key) => {
    let avgYLevel = 0

    const level = levels[key]

    avgYLevel = level.reduce((sum, node) => sum + node.position.y, 0) / level.length

    if (avgYLevel > highestAverageY) {
      highestAverageY = avgYLevel
      highestAverageYLevelKey = key
    }
  })

  for (const key of levelKeys) {
    levels[key].forEach((node, index) => {
      const middleIndex = (levels[highestAverageYLevelKey].length - 1) / 2
      node.position.y =
        key !== highestAverageYLevelKey ? highestAverageY + (middleIndex + index) * 100 : highestAverageY + index * 100
    })
  }

  return { nodes, edges }
}
