import { useRouter } from 'next/router'
import { omit } from 'ramda'
import { useCallback, useState, useRef, useEffect } from 'react'

// const getParams = () => {
//   const params = Object.fromEntries(new URLSearchParams(location.search))
//   return params
// }

/**
 * A hook that implements functionality similar to useState, but uses the URL query parameters as the source of truth.
 * It also updates the URL query parameters when the state is updated.
 * It's implemented using the NextJS router, so it's only available on the client.
 *
 */
export const useQueryParameterState = <T>(
  key: string,
  defaultValue?: T,
  options?: {
    /**
     * If true, the query parameter will be removed from the URL when the state is set to the default value.
     * Defaults to true.
     */
    removeWhenDefault?: boolean
    /**
     * If true, the query parameter will be updated with a random delay to help manage race conditions.
     * Defaults to true.
     */
    haveRandomDelay?: boolean
  }
): [T, (value: T) => void] => {
  const router = useRouter()
  const { removeWhenDefault = true, haveRandomDelay = true } = options ?? {}

  const [value, setValue] = useState<T>(() => {
    const queryValue = router.query[key]
    if (queryValue != null) {
      try {
        // Try parsing the queryValue as JSON
        return JSON.parse(Array.isArray(queryValue) ? queryValue[0] : queryValue)
      } catch (error) {
        // If JSON parsing fails, treat it as a plain string
        return Array.isArray(queryValue) ? queryValue[0] : queryValue
      }
    }
    return defaultValue
  })

  // Use a ref to store the current value for comparison
  const valueRef = useRef(value)

  useEffect(() => {
    valueRef.current = value
  }, [value])

  const setValueAndQueryParameter = useCallback(
    (newValue: T) => {
      if (JSON.stringify(newValue) === JSON.stringify(valueRef.current)) {
        return
      }
      setValue(newValue)
      /// there's a race codnition here where 2 close state updates
      // can cause the query state to be incosnsistent, because router.replace is async
      // https://github.com/vercel/next.js/discussions/57329
      // https://www.reddit.com/r/nextjs/comments/1dcott6/url_updates_not_synchronous_with_routerreplace/

      //  add a random delay to the state update in the url to help manage race conditions
      //  also use the url Params directly from window.location.search to avoid the race condition with the router not having the current values

      let delay = 0
      if (haveRandomDelay) {
        delay = Math.random() * 1000
      }

      setTimeout(() => {
        const params = Object.fromEntries(new URLSearchParams(location.search))
        const newParams: Record<string, string | string[] | undefined> = {
          ...params
        }

        // check for any dynamic route params and add them to the newParams
        Object.keys(router.query).forEach((k) => {
          if (router.pathname.includes(`[${k}]`)) {
            newParams[k] = router.query[k]
          }
        })

        if (JSON.stringify(newValue) === JSON.stringify(defaultValue) && removeWhenDefault) {
          const newQuery = omit([key], newParams)

          void router.replace(
            {
              pathname: router.pathname,
              query: newQuery
            },
            undefined,
            { shallow: true }
          )
        } else {
          newParams[key] = JSON.stringify(newValue)
          void router.replace(
            {
              pathname: router.pathname,
              query: newParams
            },
            undefined,
            { shallow: true }
          )
        }
      }, delay)
    },
    [defaultValue, key, removeWhenDefault, router, valueRef, haveRandomDelay]
  )

  return [value, setValueAndQueryParameter]
}
