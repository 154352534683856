import { getStoredAuthToken, removeStoredAuthToken, storeAuthToken } from 'auth/authToken'
import { filter } from 'ramda'

import { shutdown as shutDownIntercom } from 'shared/intercom'

import type { ReactNode } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { type auth, type fetch } from 'shared/api'
import { get, removeAll as removeAllFromCookieStore, store } from 'shared/cookieStore'
import { noop, noopAsync, redirectToPage } from 'shared/utils'
import { UseQueryLoggedInUser, useSignIn, useSignUp } from '../../auth/api'
import { APP_ROUTES } from 'shared/routes'

type mode = 'sign-in' | 'sign-up'
type tokenProviders = 'google'

type AuthError = fetch.ClientError | null
export interface User {
  email: string
  displayName?: string
  name: string
  mode?: mode
  idTokenProvider?: tokenProviders
  idToken?: string
  id?: string
  hasInternalFeatureAccess?: boolean
}

interface IAuthContext {
  authenticated: boolean
  currentUser?: User
  updateUserName: (name: string) => void

  login: (user: auth.AuthSignInReq) => Promise<void>
  signup: (user: auth.AuthSignUpReq) => Promise<void>
  logOut: (redirectUrl?: string) => void
  mustAuth: boolean

  // when we just started
  // login attempt or a we are currently checking logged in user

  isLoading: boolean

  // a user successfully logs in or on reload we are able to retrieve the current logged in useer
  isSuccess: boolean

  authToken: string

  error: AuthError
  signInError: AuthError
  signUpError: AuthError
  alreadyLoggedInError: AuthError
}

const defaultValue: IAuthContext = {
  authenticated: false,
  currentUser: {
    email: '',
    name: '',
    displayName: '',
    idToken: '',
    hasInternalFeatureAccess: false
  },
  updateUserName: noop,
  login: noopAsync,
  logOut: noop,
  signup: noopAsync,
  mustAuth: false,
  isLoading: true,
  isSuccess: false,
  authToken: '',
  signInError: null,
  signUpError: null,
  alreadyLoggedInError: null,
  error: null
}

const first = (list: AuthError[]): AuthError => list[0]
const getCurrentAuthError = filter((error: AuthError) => error?.status_code != null)

interface Props {
  children: ReactNode
}

const AuthContext = createContext<IAuthContext>(defaultValue)

export const useAuth = (): IAuthContext => useContext<IAuthContext>(AuthContext)

interface ISaveCurrentUser {
  userName: string
  userEmail: string
  userId?: string
  expires?: string
}
export const saveCurrentUser = ({ userName, userEmail, userId, expires }: ISaveCurrentUser): void => {
  store('userName', userName, expires)
  store('userEmail', userEmail, expires)
  if (userId != null) store('userId', userId, expires)
}

const getCurrentUserFromCookieStore = (): User => {
  return {
    name: get('userName') ?? '',
    email: get('userEmail') ?? '',
    ...(get('userId') != null && get('userId') !== '' && { id: get('userId') })
  }
}

const clearStore = (): void => {
  removeAllFromCookieStore()
}

export const AuthProvider = (props: Props): React.ReactElement => {
  const [authenticated, setAuthenticated] = useState(defaultValue.authenticated)
  const [currentUser, setCurrentUser] = useState<User>(defaultValue.currentUser as User)
  const [authToken, setAuthToken] = useState<string>('')

  useEffect(() => {
    setCurrentUser({ ...defaultValue.currentUser, ...getCurrentUserFromCookieStore() })
    setAuthToken(getStoredAuthToken() ?? '')
  }, [])

  const {
    mutateAsync: createNewLogin,
    isSuccess: isSignInSuccessful,
    isLoading: isSigningIn,
    error: signInError
  } = useSignIn()

  const {
    mutateAsync: createNewUser,
    isSuccess: isSignUpSuccessful,
    isLoading: isSigningUp,
    error: signUpError
  } = useSignUp()

  //  should call on on reload of page when access token already exist
  //  should not be triggered when logging in for the first time.
  const {
    data: alreadyLoggedInUser,
    isSuccess: isAlreadyLoggedUser,
    isLoading: isCheckingAlreadyLoggedUser,
    error: alreadyLoggedInError
  } = UseQueryLoggedInUser(authToken)

  const doAwayWithToken = (): void => {
    removeStoredAuthToken()
    setAuthToken('')
  }

  const login = async (user: auth.AuthSignInReq): Promise<void> => {
    doAwayWithToken()
    await createNewLogin(user, {
      onSuccess: (newUser) => {
        setAuthenticated(true)
        setCurrentUser(newUser)
        storeAuthToken(newUser.accessToken, newUser.expires)
        setAuthToken(newUser.accessToken)
        saveCurrentUser({ userName: newUser.name, userEmail: newUser.email, expires: newUser.expires })
      }
    })
  }

  const [isCreatingNewUser, setIsCreatingNewUser] = useState(false)

  const signup = async (user: auth.AuthSignUpReq): Promise<void> => {
    doAwayWithToken()
    setIsCreatingNewUser(true)
    await createNewUser(user, {
      onSuccess: (newUser) => {
        setAuthenticated(true)
        setCurrentUser(newUser)
        storeAuthToken(newUser.accessToken, newUser.expires)
        setAuthToken(newUser.accessToken)
        saveCurrentUser({ userName: newUser.name, userEmail: newUser.email, expires: newUser.expires })
      },
      onSettled: () => {
        setIsCreatingNewUser(false)
      }
    })
  }

  useEffect(() => {
    if (isAlreadyLoggedUser) {
      setAuthenticated(true)
      setCurrentUser(
        Object.assign(
          {
            id: alreadyLoggedInUser.userId,
            email: alreadyLoggedInUser.userEmail,
            hasInternalFeatureAccess: alreadyLoggedInUser.hasInternalFeatureAccess
          },
          getCurrentUserFromCookieStore()
        )
      )
      store('userId', alreadyLoggedInUser.userId)
    }
  }, [alreadyLoggedInUser, isAlreadyLoggedUser])

  const logOut = (redirectUrl: string = APP_ROUTES.auth.signInPage): void => {
    clearStore()
    setAuthenticated(false)
    setCurrentUser(defaultValue.currentUser as User)
    shutDownIntercom()
    redirectToPage(redirectUrl)
  }

  const updateUserName = (name: string): void => {
    setCurrentUser(
      Object.assign(getCurrentUserFromCookieStore(), {
        name,
        id: alreadyLoggedInUser?.userId,
        email: alreadyLoggedInUser?.userEmail,
        hasInternalFeatureAccess: alreadyLoggedInUser?.hasInternalFeatureAccess
      })
    )
  }

  const isSuccess = isAlreadyLoggedUser || isSignInSuccessful || isSignUpSuccessful
  const isLoading = isCheckingAlreadyLoggedUser || isSigningIn || isSigningUp || isCreatingNewUser

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        currentUser: currentUser?.email === '' ? undefined : currentUser,
        updateUserName,
        login,
        signup,
        logOut,
        authToken,
        mustAuth: !isSuccess,
        isLoading,
        error: first(getCurrentAuthError([alreadyLoggedInError, signInError, signUpError])),
        signInError,
        signUpError,
        alreadyLoggedInError,
        isSuccess
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContext
