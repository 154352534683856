import { type Platforms } from 'shared/api/accounts'

export const infiniteScrollSupportMap: Record<Platforms, boolean> = {
  google_sheets: false,
  amazon: false,
  criteo: false,
  analytics: false,
  ga4: false,
  google: false,
  dv360: false,
  google_search_console: false,
  merchant_center: false,
  gcp: false,
  microsoft_advertising: false,
  linkedin: false,
  snapchat: false,
  tiktok: false,
  facebook: false,
  google_bigquery: false
}
