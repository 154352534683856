import { PLATFORM as DATA_SOURCE_PLATFORM, PLATFORMS } from 'dataSource/constants'
import { type Platforms } from 'shared/api/accounts'
import { ASSETS_BASE_URL } from 'shared/constants'

export const PLATFORM = 'platform'
export const ALLPLATFORMS = 'allPlatforms'

export const REPORT = 'report'
export const ALLREPORTS = 'allReports'

export const DATASOURCE = 'dataSource'
export const ALLDATASOURCES = 'allDataSources'

// This constants should be used anywhere the DataSourceType is tested against a string
export const ConnectorPlatformTypes = {
  AHREFS: 'ahrefs',
  AMAZON: 'amazon',
  BING: 'bing',
  CRITEO: 'criteo',
  DV360: 'dv360',
  FACEBOOK: 'facebook',
  GOOGLE_ADS: 'gads',
  GOOGLE_ANALYTICS: 'google_analytics',
  GOOGLE_ANALYTICS_4: 'ga4',
  GOOGLE_SEARCH_CONSOLE: 'google_search_console',
  GOOGLE_SHEETS: 'google_sheets',
  LINKEDIN: 'linkedin',
  MERCHANT_CENTER: 'merchant_center',
  SHOPIFY: 'shopify',
  SNAPCHAT: 'snapchat',
  TIKTOK: 'tiktok'
} as const

export type ConnectorPlatformType = (typeof ConnectorPlatformTypes)[keyof typeof ConnectorPlatformTypes]

export const ADDITIONAL_CONNECTOR_PLATFORM = {
  [ConnectorPlatformTypes.SHOPIFY]: ConnectorPlatformTypes.SHOPIFY,
  [ConnectorPlatformTypes.AHREFS]: ConnectorPlatformTypes.AHREFS
} as const

export type AdditionalConnectorPlatforms = keyof typeof ADDITIONAL_CONNECTOR_PLATFORM

export const WIP_CONNECTOR_PLATFORM = [] as const

export const SYSTEM_GENERATED_CONNECTORS_BQ_PROJECT_ID = 'pd-data-lake-01'

export const DEFAULT_BQ_DATASET = 'alvie_connectors'

export const SERVICE_DATASOURCE_MAP = {
  [ConnectorPlatformTypes.GOOGLE_ANALYTICS]: DATA_SOURCE_PLATFORM.ANALYTICS,
  [ConnectorPlatformTypes.GOOGLE_ADS]: DATA_SOURCE_PLATFORM.GOOGLE,
  [ConnectorPlatformTypes.BING]: DATA_SOURCE_PLATFORM.MICROSOFT_ADVERTISING,
  ...ADDITIONAL_CONNECTOR_PLATFORM
} as const

export interface ConnectorPlatformsMeta {
  label: string
  platform: AdditionalConnectorPlatforms
  iconUrl: string
  implemented?: boolean
}

export const ADDITIONAL_CONNECTOR_PLATFORMS_META: ConnectorPlatformsMeta[] = [
  {
    label: 'Shopify',
    platform: ADDITIONAL_CONNECTOR_PLATFORM.shopify,
    iconUrl: `${ASSETS_BASE_URL}/shopify.svg`
  },
  {
    label: 'Ahrefs',
    platform: ADDITIONAL_CONNECTOR_PLATFORM.ahrefs,
    iconUrl: `${ASSETS_BASE_URL}/ahrefs.svg`
  }
]

export const MERCHANT_CENTER = 'Merchant Center'

export const SUPPORTED_PLATFORMS = [
  ...[...PLATFORMS, ...ADDITIONAL_CONNECTOR_PLATFORMS_META].filter(
    (platform) => platform.platform !== DATA_SOURCE_PLATFORM.GCP
  )
]

export const INTERNAL_USE_ONLY_PLATFORMS: Array<Platforms | AdditionalConnectorPlatforms> = [
  ADDITIONAL_CONNECTOR_PLATFORM.shopify,
  ADDITIONAL_CONNECTOR_PLATFORM.ahrefs,
  ...WIP_CONNECTOR_PLATFORM
]
