import ChannelGroupingPerformanceReportV2 from 'reportingSolution/reportTemplates/channelGroupingPerformanceReportv2'
import FacebookVisualCreativeReport from 'reportingSolution/reportTemplates/facebookVisualCreativeReport'
import ShoppingInsightBestSellerReport from 'reportingSolution/reportTemplates/shoppingInsightBestsellerReport'
import GAdsKeywordQualityScore from 'reportingSolution/reportTemplates/gadsKeywordsQualityScore'
import ShoppingInsightReportV2 from 'reportingSolution/reportTemplates/shoppingInsightReportV2'
import AttributionComparisonReport from 'reportingSolution/reportTemplates/attributionComparisonReport'
import pmmReport from 'reportingSolution/reportTemplates/pmmReport'
import PMaxBreakdownReportV2 from 'reportingSolution/reportTemplates/pmaxBreakdownReportV2'
import dv360performanceReport from 'reportingSolution/reportTemplates/dv360PerformanceReport'
import blackFridayBaseReport from 'reportingSolution/reportTemplates/blackFridayBaseReport'
import ArimaPlusReport from 'reportingSolution/reportTemplates/arimaPlusReport'
import ReportingBaseReport from 'reportingSolution/reportTemplates/reportingBaseReport'
import SearchSynergiesReport from 'reportingSolution/reportTemplates/searchSynergiesReport'
import CreativeInsightsReport from 'reportingSolution/reportTemplates/creativeInsightsReport'

export const CONFIRMATION_DELETE_WORD = 'DELETE'

export const PLATFORM = 'platform'
export const ALLPLATFORMS = 'allPlatforms'

export const GA4_MEASUREMENT_ID = 'G-F3BRHF1KER'

export const DEFAULT_BQ_DATASET = 'alvie_reporting_solutions'

export const DEFAULT_CURRENCY_OPTIONS = [
  {
    value: 'SEK',
    label: 'Swedish Kroner (SEK)',
    icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197564.png'
  },
  {
    value: 'DKK',
    label: 'Danish Kroner (DKK)',
    icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197565.png'
  },
  {
    value: 'NOK',
    label: 'Norwegian Kroner (NOK)',
    icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197579.png'
  },
  {
    value: 'EUR',
    label: 'Euros (EUR)',
    icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197615.png'
  },
  {
    value: 'USD',
    label: 'US Dollar (USD)',
    icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197484.png'
  },
  {
    value: 'GBP',
    label: 'British Pound (GBP)',
    icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197374.png'
  }
]

export const REPORT = 'report'
export const ALLREPORTS = 'allReports'

export const DATASOURCE = 'dataSource'
export const ALLDATASOURCES = 'allDataSources'

export const ADDITIONAL_CONNECTOR_PLATFORM = {
  shopify: 'shopify',
  snapchat: 'snapchat',
  tiktok: 'tiktok',
  ahrefs: 'ahrefs'
} as const

export type ConnectorPlatforms = keyof typeof ADDITIONAL_CONNECTOR_PLATFORM

export interface Sources {
  reports: string[]
  ia_configs?: object[]
  optional?: boolean
}

export interface DropdownOption {
  value: string
  label: string
  labelAnnotation?: string
  icon_url?: string
  img_src?: string
  group?: string
}

export interface CaseStatementDimension {
  value: string
  label: string
  type: string
}

export interface InputValueField {
  label: string
  id: string
}

export interface InputSchema {
  id: string
  type: 'string' | 'dropdown' | 'date' | 'integer' | 'case_statement' | 'json_object_array'
  options?: DropdownOption[]
  dimensions?: CaseStatementDimension[]
  defaultValue?: string | number | string[]
  defaultValues?: object[]
  label: string
  helperText?: string
  tooltip?: string
  required: boolean
  mutable: boolean
  inputFormat?: string
  multiple?: boolean
  inputValueFields?: InputValueField[]
  minRange?: number
  maxRange?: number
}

export type InputSchemas = Record<string, InputSchema[]>

interface Documentation {
  type: string
  link: string
  link_description: string
}

interface Author {
  name: string
  email: string
  image_url: string
}

export interface DashboardDataSource {
  alias: string
  type: string
  tableId: string
  isPartitioned: string
  refreshFields: string
  datasourceName: string
}

export interface DashboardSheetDataSource {
  alias: string
  hasHeader: string
  refreshFields: string
  helperText: string
  datasourceName: string
}

interface Dashboard {
  template_report_id: string
  report_name_template: string
  datasources: DashboardDataSource[]
  sheets_datasources?: DashboardSheetDataSource[]
}

export interface ReportTemplate {
  id: string
  active: boolean
  deprecation_date?: string
  label: string
  category:
    | 'Getting started'
    | 'Forecasting'
    | 'Other'
    | 'Seasonal'
    | 'General'
    | 'Platform specific'
    | 'Google Analytics 4 Export'
    | 'Product analysis'
    | 'Search'
    | 'General'
  description: string
  image_url: string
  dashboard?: Dashboard
  authors: Author[]
  documentation: Documentation
  sources: Record<string, Sources>
  inputs?: InputSchemas
}

export const reportTemplateRegistry: Record<string, ReportTemplate> = {
  channel_grouping_performance_report_v2: ChannelGroupingPerformanceReportV2,
  facebook_visual_creative_report: FacebookVisualCreativeReport,
  shopping_insight_bestseller_report: ShoppingInsightBestSellerReport,
  gads_keywords_quality_score: GAdsKeywordQualityScore,
  pmax_breakdown_report_v2: PMaxBreakdownReportV2,
  shopping_insight_report_v2: ShoppingInsightReportV2,
  pmm_report: pmmReport,
  attribution_comparison_report: AttributionComparisonReport,
  dv360_performance_report: dv360performanceReport,
  black_friday_base_report: blackFridayBaseReport,
  arima_plus_report: ArimaPlusReport,
  reporting_base: ReportingBaseReport,
  search_synergies_report: SearchSynergiesReport,
  creative_insights_report: CreativeInsightsReport
}
