import { ChatMessageRole, DocumentSourceType, MEMORY_LENGTH, MIMIR_BASE_AGENT_ID } from './constants'
import confluenceLogo from 'public/assets/images/confluence.svg'
import googleDoc from 'public/assets/images/google_doc.png'
import googleSlidesIcon from 'public/assets/images/google_slides_icon.svg'
import slackIcon from 'public/assets/images/slack.svg'
import globeIcon from 'public/assets/images/globe.svg'
import alvieLearningCenterIcon from 'public/assets/images/alvie_learning_center_icon.svg'
import teamworkSpacesIcon from 'public/assets/images/teamwork_spaces_icon.png'
import { type MimirAgentMessage, type MimirAgentDocument } from 'shared/api/mimir'
import { type ChatMessage } from './types'

export const getDocumentSourceTypeMeta = (
  document: MimirAgentDocument
): { imageUrl: string; label: string; sourceCategoryParser?: (sourceCategory: string) => string } => {
  switch (document.sourceType) {
    case DocumentSourceType.confluence:
      return {
        imageUrl: confluenceLogo.src,
        label: 'Confluence',
        sourceCategoryParser: (sourceCategory: string) => {
          return sourceCategory.replace('Space: ', '')
        }
      }
    case DocumentSourceType.teamwork_spaces:
      return {
        imageUrl: teamworkSpacesIcon.src,
        label: 'Teamwork',
        sourceCategoryParser: (sourceCategory: string) => {
          return sourceCategory.replace('Space: ', '')
        }
      }
    case DocumentSourceType.google_doc:
      return {
        imageUrl: googleDoc.src,
        label: 'Google Doc',
        sourceCategoryParser: (sourceCategory: string) => {
          return sourceCategory.replace('Folder: /', '').split('/').join(' › ')
        }
      }
    case DocumentSourceType.google_slide:
      return {
        imageUrl: googleSlidesIcon.src,
        label: 'Google Slide',
        sourceCategoryParser: (sourceCategory: string) => {
          return sourceCategory.replace('Folder: /', '').split('/').join(' › ')
        }
      }
    case DocumentSourceType.slack:
      return {
        imageUrl: slackIcon.src,
        label: 'Slack',
        sourceCategoryParser: (sourceCategory: string) => {
          return sourceCategory.replace('Channel: ', '')
        }
      }
    case DocumentSourceType.intercom:
      return {
        imageUrl: alvieLearningCenterIcon.src,
        label: 'Alvie Learning Center'
      }
    case DocumentSourceType.web_page:
      return {
        imageUrl: document.sourceFaviconUri ?? globeIcon.src,
        label: 'Web Page'
      }
    default:
      return {
        imageUrl: 'https://cdn.mimirhq.com/assets/images/confluence.png',
        label: 'Unknown',
        sourceCategoryParser: (sourceCategory: string) => sourceCategory
      }
  }
}

export const prettifyUrlForSearchResults = (url: string): string => {
  const urlObj = new URL(url)
  const pathSegments = urlObj.pathname.split('/').filter((segment) => segment)

  const prettyPath = pathSegments.join(' › ')
  return `${urlObj.protocol}//${urlObj.host} › ${prettyPath}`
}

export const enrichSummaryWithReferences = (summary: string, relatedLinks: MimirAgentDocument[]): string => {
  return summary
    .replace(/<b>(.*?)<\/b>/g, '**$1**')
    .replace(/\[(\d+(?:, \d+)*)\]/g, (match, refNumbers) => {
      const individualRefs: string[] = refNumbers.split(', ').map((num: string) => {
        const refIndex = parseInt(num, 10) - 1
        return relatedLinks[refIndex] != null ? `[[${num}]](${relatedLinks[refIndex].uri})` : num
      })

      return individualRefs.length > 1 ? individualRefs.join('') : individualRefs[0]
    })
    .replace(/ \[/g, '[')
}

export const chatMessageFactory = ({
  role,
  text,
  context,
  relatedDocuments,
  avatarUrl,
  agentId
}: {
  role: ChatMessageRole
  text: string
  context?: string
  relatedDocuments?: MimirAgentDocument[]
  avatarUrl?: string
  agentId?: string
}): ChatMessage => {
  if (role === ChatMessageRole.user) {
    return {
      text,
      context,
      role,
      avatarUrl: avatarUrl ?? ''
    }
  }

  return {
    text,
    role,
    agentId: agentId ?? MIMIR_BASE_AGENT_ID,
    relatedDocuments
  }
}

export const generateMimirChatUrl = (context: ChatMessage[], message?: string): string => {
  const baseUrl = `${window.location.protocol}//${window.location.host}/mimir/chat`
  const queryParams: string[] = []

  if (context.length > 0) {
    const encodedContext = encodeURIComponent(JSON.stringify(context.map((msg) => ({ ...msg, relatedDocuments: [] }))))
    queryParams.push(`context=${encodedContext}`)
  }

  if (message != null) {
    const encodedMessage = encodeURIComponent(JSON.stringify(message))
    queryParams.push(`message=${encodedMessage}`)
  }

  const url = `${baseUrl}${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`
  return url
}

/**
 *  take the last X messages and format them for the context
 */
export const getContextFromMessages = (messages: ChatMessage[]): MimirAgentMessage[] => {
  return messages.slice(-MEMORY_LENGTH).map((message) => ({
    content: message.text,
    role: message.role
  }))
}
