import { useGate, type GateResult } from 'statsig-react'
import { FeatureFlagProvider as provider } from './provider'

type Flags = 'attribution-rba' | 'attribution-rba-mean-prior-access' | 'attribution-custom-spend-sources'

// Feature flags represent feature gates in statsig.com
export const FEATURE_FLAGS: Record<string, Flags> = {
  attributionRba: 'attribution-rba',
  attributionRbaMeanPriorAccess: 'attribution-rba-mean-prior-access',
  attributionRbaCustomDataSources: 'attribution-custom-spend-sources'
}

export const FeatureFlagProvider = provider

export const useFeatureFlag = (flag: Flags): GateResult => useGate(flag)
