import { type ActiveFilter } from 'shared/components/Filter'
import { type Filter } from 'shared/api/analytics'
import { DIMENSION_OPERATORS_WITH_TYPES } from 'shared/constants/filterOperators'

export const transformActiveFiltersToCirrusAPIFormat = (filters: ActiveFilter[]): Filter[] => {
  const cirrusFilters = filters.map((filter) => {
    const filterValue = filter.data

    if (typeof filterValue === 'string' || !Array.isArray(filterValue)) {
      return {
        field: filter.parameter.value,
        operator: filter.operator === DIMENSION_OPERATORS_WITH_TYPES.is.id ? '=' : '!=',
        value: typeof filterValue === 'string' ? filterValue : filterValue.value
      }
    }

    return {
      field: filter.parameter.value,
      operator: filter.operator === DIMENSION_OPERATORS_WITH_TYPES.one_of.id ? 'IN' : 'NOT_IN',
      value: filterValue.map((option) => option.value)
    }
  })

  return cirrusFilters
}
