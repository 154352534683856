import { SYSTEM_GENERATED_CONNECTORS_BQ_PROJECT_ID } from 'connectors/constants'
import {
  useMutation,
  type UseMutationResult,
  useQuery,
  type UseQueryResult,
  useQueryClient,
  type QueryClient,
  useQueries
} from 'react-query'
import { connectors, type fetch, clients, accounts, analytics } from 'shared/api'
import { type ConnectorResp, type ConnectorsResp } from 'shared/api/connectors'

export const queryKeys = {
  clientConnectors: 'clientConnectors',
  configStatus: 'configStatus',
  reportRegistry: 'reportRegistry',
  exploreConnectorsData: 'exploreConnectorsData',
  userDetails: 'userDetails',
  userDetailsByEmails: 'userDetailsByEmails',
  spreadsheetTabs: 'spreadsheetTabs',
  connectorConfigDagStatus: 'connectorConfigDagStatus'
}

const updateConfigSystemGenerated: (config: ConnectorResp) => ConnectorResp = (config) => {
  config.systemGenerated =
    config.systemGenerated ||
    config.targetProjectId === SYSTEM_GENERATED_CONNECTORS_BQ_PROJECT_ID ||
    (config.destinationTable ?? '').split('.')[0] === SYSTEM_GENERATED_CONNECTORS_BQ_PROJECT_ID
  return config
}

export const useQueryConnectorConfigs = (
  clientId: string,
  enabled: boolean = true
): UseQueryResult<connectors.ConnectorResp[], fetch.ClientError> => {
  return useQuery(
    [queryKeys.clientConnectors, clientId],
    async () => {
      const configsResponse = await connectors.getAllConnectorsForClient(clientId)
      return configsResponse.map(updateConfigSystemGenerated)
    },
    {
      enabled: clientId !== '' && enabled
    }
  )
}

const getUseCache = (queryClient: QueryClient, clientId: string): boolean => {
  const queriesData = queryClient.getQueriesData([queryKeys.configStatus, clientId])
  const useCache = queriesData.every((queryData) => queryData?.[1] == null)
  return useCache
}

export const useQueryConnectorConfigStatuses = (
  clientId: string
): UseQueryResult<analytics.ConfigStatusResp[], fetch.ClientError> => {
  const queryClient = useQueryClient()
  return useQuery([queryKeys.configStatus, clientId], async () => {
    const useCache = getUseCache(queryClient, clientId)
    return await analytics.postConfigStatuses('connectors_client_configs_status', clientId, useCache)
  })
}

export const useQueryConnectorConfigStatusById = (
  clientId: string,
  configId: number,
  enabled: boolean = true
): UseQueryResult<[analytics.ConfigStatusResp], fetch.ClientError> => {
  const queryClient = useQueryClient()
  return useQuery<[analytics.ConfigStatusResp], fetch.ClientError>({
    queryKey: [queryKeys.configStatus, clientId, configId],
    queryFn: async () => {
      const useCache = getUseCache(queryClient, clientId)
      return await analytics.postConfigStatusById('connectors_config_status', clientId, configId, useCache)
    },
    enabled
  })
}

export const useQueryConnectorConfigDagStatusById = (
  clientId: string,
  configId: number,
  enabled: boolean = true,
  useCache: boolean = true
): UseQueryResult<[analytics.DagStatusResp] | [], fetch.ClientError> => {
  return useQuery({
    queryKey: [queryKeys.connectorConfigDagStatus, clientId, configId, useCache],
    queryFn: async () =>
      await analytics.postConfigStatusById<[analytics.DagStatusResp] | []>(
        'connectors_dag',
        clientId,
        configId,
        useCache
      ),
    enabled: clientId !== '' && enabled
  })
}

export const useQueryReportRegistry = (
  clientId: string
): UseQueryResult<connectors.ReportRegistryResp, fetch.ClientError> => {
  return useQuery(
    [queryKeys.reportRegistry, clientId],
    async () => await connectors.getReportRegistryByClient(clientId),
    {
      enabled: clientId !== ''
    }
  )
}

export const useMutationCreateConnector = (): UseMutationResult<
  connectors.ConnectorResp,
  fetch.ClientError,
  connectors.ConnectorReq
> => {
  const queryClient = useQueryClient()

  return useMutation(connectors.postConnector, {
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeys.clientConnectors)
      void queryClient.invalidateQueries(queryKeys.configStatus)
    }
  })
}

export const useQueryConnectorConfigById = (
  clientId: string,
  configId: number,
  enabled: boolean = true
): UseQueryResult<connectors.ConnectorResp, fetch.ClientError> => {
  return useQuery(
    [queryKeys.clientConnectors, clientId, configId],
    async () => {
      const configResponse = await connectors.getConnectorForClient(clientId, configId)
      return updateConfigSystemGenerated(configResponse)
    },
    {
      enabled: clientId !== '' && enabled
    }
  )
}

export const useMutationUpdateConnector = (): UseMutationResult<
  connectors.ConnectorResp,
  fetch.ClientError,
  Partial<connectors.ConnectorReq>
> => {
  const queryClient = useQueryClient()

  return useMutation(connectors.updateConnector, {
    onMutate: async (updatedConfigData) => {
      await queryClient.cancelQueries({
        queryKey: [queryKeys.clientConnectors, updatedConfigData.clientId, updatedConfigData.configId]
      })

      const previousConfig = queryClient.getQueryData<ConnectorResp>([
        queryKeys.clientConnectors,
        updatedConfigData.clientId,
        updatedConfigData.configId
      ])

      previousConfig != null &&
        queryClient.setQueryData([queryKeys.clientConnectors, updatedConfigData.clientId, updatedConfigData.configId], {
          ...previousConfig,
          ...updatedConfigData
        })

      return { previousConfig, updatedConfigData }
    },
    onError: (_, __, context) => {
      context?.previousConfig != null &&
        queryClient.setQueryData(
          [queryKeys.clientConnectors, context?.updatedConfigData.clientId, context?.updatedConfigData.configId],
          context.previousConfig
        )
    },
    onSettled: () => {
      void queryClient.invalidateQueries(queryKeys.clientConnectors)
    }
  })
}

export const useMutationDeleteConnector = (): UseMutationResult<
  connectors.ConnectorResp,
  fetch.ClientError,
  connectors.ConfigIdConnectorReq
> => {
  const queryClient = useQueryClient()

  return useMutation(connectors.deleteConnector, {
    onMutate: async (deletedConfig) => {
      await queryClient.cancelQueries({ queryKey: [queryKeys.clientConnectors, deletedConfig.clientId] })

      const existingConfigs = queryClient.getQueryData([queryKeys.clientConnectors, deletedConfig.clientId])

      queryClient.setQueryData<ConnectorResp[]>([queryKeys.clientConnectors, deletedConfig.clientId], (oldConfigs) => {
        return oldConfigs != null ? oldConfigs.filter((config) => config.configId !== deletedConfig.configId) : []
      })

      return { existingConfigs }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([queryKeys.clientConnectors], context?.existingConfigs)
    },
    onSettled: () => {
      void queryClient.invalidateQueries(queryKeys.clientConnectors)
    }
  })
}

export const useMutationRunConnectorConfigNow = (): UseMutationResult<
  void,
  fetch.ClientError,
  connectors.ConfigIdConnectorReq
> => {
  const queryClient = useQueryClient()
  return useMutation(connectors.runConnector, {
    onSettled: () => {
      void queryClient.invalidateQueries(queryKeys.configStatus)
    }
  })
}

export const useQueryExploreConnectorsTable = (
  clientId: string,
  configId: number
): UseQueryResult<connectors.ConnectorExploreResp, fetch.ClientError> => {
  return useQuery(
    [queryKeys.exploreConnectorsData, clientId, configId],
    async () => {
      return await connectors.exploreConnectorsTable({ configId, clientId })
    },
    { useErrorBoundary: false }
  )
}

export const useQueriesUserProfilesFromConnectorConfigs = (
  connectorConfigs: ConnectorsResp | undefined
): Array<UseQueryResult<clients.UserDetailsResp | null>> => {
  return useQueries(
    connectorConfigs !== undefined
      ? connectorConfigs
          .filter((config) => config.updatedBy != null)
          .map((config) => {
            return {
              queryKey: [queryKeys.userDetailsByEmails, config.updatedBy],
              queryFn: async () => await clients.getUserDetailsByEmail(config.updatedBy, String(config.clientId))
            }
          })
      : []
  )
}

export const useQueryUserProfileFromConnectorConfig = (
  connectorConfig: ConnectorResp | undefined
): UseQueryResult<clients.UserDetailsResp | null> => {
  return useQuery({
    queryKey: [queryKeys.userDetails, connectorConfig?.updatedBy ?? ''],
    queryFn: async () =>
      await clients.getUserDetailsByEmail(connectorConfig?.updatedBy ?? '', String(connectorConfig?.clientId) ?? ''),
    enabled: connectorConfig !== undefined
  })
}

export const useQuerySpreadsheetMetadata = (
  clientId: string,
  accountId: number | undefined
): UseQueryResult<accounts.SpreadsheetMetadataResp, fetch.ClientError> => {
  return useQuery({
    queryKey: [queryKeys.spreadsheetTabs, clientId, accountId],
    queryFn: async () => {
      return await accounts.getGoogleSheetsAccountMetadata(clientId, accountId ?? 0)
    },
    enabled: accountId != null
  })
}

export const useQueryGcpMetadata = (
  clientId: string,
  accountId: number | undefined
): UseQueryResult<accounts.GCPMetadataResp, fetch.ClientError> => {
  return useQuery({
    queryKey: [clientId, accountId],
    queryFn: async () => {
      return await accounts.getGCPAccountMetadata(clientId, accountId ?? 0)
    },
    enabled: accountId != null,
    staleTime: Infinity,
    cacheTime: Infinity
  })
}
