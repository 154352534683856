import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  type UseMutationResult,
  type UseQueryResult
} from 'react-query'
import { clients, users, type fetch, type integrationAttributions } from 'shared/api'

type userId = string

export const queryKeys = {
  userDetails: 'userDetails',
  userDetailsByEmails: 'userDetailsByEmails'
}

export const useQueryUserProfile = (
  userId: string | number | undefined
): UseQueryResult<clients.UserDetailsResp, fetch.ClientError> => {
  const userIdString = userId?.toString()
  return useQuery([queryKeys.userDetails, userIdString], async () => await users.getUserDetails(userIdString ?? ''), {
    enabled: userIdString !== undefined && userIdString !== ''
  })
}
export const useQueriesUserProfilesFromIAConfigs = (
  iaConfigs: integrationAttributions.IntegrationAttributionsResp | undefined
): Array<UseQueryResult<clients.UserDetailsResp | null>> => {
  return useQueries(
    iaConfigs !== undefined
      ? iaConfigs.map((config) => {
          return {
            queryKey: [queryKeys.userDetailsByEmails, config.updatedBy],
            queryFn: async () => await clients.getUserDetailsByEmail(config.updatedBy, config.clientId)
          }
        })
      : []
  )
}

export const useUpdateUserProfile = (
  userId: string
): UseMutationResult<clients.UserDetailsResp, fetch.ClientError, users.UpdateUserReq> => {
  const queryClient = useQueryClient()
  return useMutation(users.updateUserProfile, {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(queryKeys.userDetails)
      queryClient.setQueryData(
        [queryKeys.userDetails, userId],
        (prevUserDetailsResp?: clients.UserDetailsResp | null) => {
          if (prevUserDetailsResp != null) {
            return {
              ...prevUserDetailsResp,
              ...updatedData
            }
          }
          return null
        }
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([queryKeys.userDetails, userId])
    }
  })
}
export const useDeleteUsers = (): UseMutationResult<users.UserResp, fetch.ClientError, userId> => {
  return useMutation({ mutationFn: async (userId) => await users.del(userId) })
}
