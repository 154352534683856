import { Avatar, SelectInput, SelectItem, Typography, Link, SelectInputListSubHeader } from '@precis-digital/kurama'
import { useFormContext } from 'react-hook-form'
import { AccordionFormStep, AccordionFormStepItem } from 'shared/components/AccordionFormStep'
import FormItem from 'shared/components/FormItem'
import { getNumberOfRequiredInputsPerSection } from 'shared/reactHookForm'
import { useTranslation } from 'shared/translations'
import type { AttributionFormData, ModelData, ModelType, StepProps } from '..'
import {
  GA4_MODEL_TYPES,
  MODEL_TYPES,
  ATTRIBUTION_MODEL_TYPES,
  platformsConversionSupported,
  MULTIPLE_PLATFORMS,
  CREDIT_INPUTS
} from '../constants'
import {
  formatApiConfigIntoFormData,
  getAllowedAttributionModelTypes,
  getChosenPlatformFromApi,
  getInputRules
} from '../utils'

import { useCurrentClient } from 'shared/context/ClientContext'
import { useQueryIntegratedAttributions } from 'attributionModel/api'
import type { Platforms } from 'shared/api/accounts'
import { getPlatformIconUrl } from '../AccountStep'
import { getPlatformDetailsByPlatform } from 'dataSource'
import { ASSETS_BASE_URL } from 'shared/constants'
import { ATTRIBUTION_MODELS_AVAIABLE_MODELS_LINK } from 'shared/constants/links'

const ModelStep: React.FC<StepProps> = ({
  optimizeBudget,
  stepNumber,
  title,
  isNewConfig,
  handleUpdate,
  toggleEdit,
  editModes,
  isCopyConfig
}): React.ReactElement => {
  const { t } = useTranslation('attributionModel')

  const {
    control,
    getValues,
    formState: { errors },
    setValue,
    reset,
    watch
  } = useFormContext<AttributionFormData>()

  const formData = getValues()

  const { currentClient } = useCurrentClient()

  const allowedAttributionModelTypes = getAllowedAttributionModelTypes(currentClient)
  const isOnlyRbaAllowed =
    allowedAttributionModelTypes.length === 1 && allowedAttributionModelTypes[0].value === CREDIT_INPUTS.RBA

  const { data: integratedAttributions } = useQueryIntegratedAttributions(currentClient.id)

  const getModelInputValue = (modelData?: ModelData): string | number | undefined => {
    if (modelData?.alvieModelId != null) return modelData?.alvieModelId
    if (modelData?.id != null) return modelData.id
    if (modelData?.platform != null) return modelData.platform
    return undefined
  }

  const setModel = (selectedValue: string | number | undefined): void => {
    const model: ModelData = {
      id: GA4_MODEL_TYPES.find((modelType) => modelType.value === selectedValue)?.value as ModelType,
      platform: platformsConversionSupported.find((platform) => platform === selectedValue) as Platforms,
      alvieModelId: integratedAttributions?.find((model) => model.id === selectedValue)?.id
    }
    if (model?.alvieModelId != null) {
      handleSelectAlvieModel(model)
    } else {
      reset({
        name: watch('name'),
        model,
        account: {
          otherAccounts: []
        }
      })
    }
  }

  const handleSelectAlvieModel = (model: ModelData): void => {
    const attributionFromId = integratedAttributions?.find((attribution) => attribution.id === model?.alvieModelId)
    if (attributionFromId != null) {
      const transformedDataFromApi = formatApiConfigIntoFormData(attributionFromId)
      reset(transformedDataFromApi)
    }
  }

  const chosenPlatforms = formData.account?.otherAccounts?.map((account) => account.platform) ?? []

  const getCurrentValue = (formData: AttributionFormData): string | undefined => {
    if (formData.isPlatformConversion) {
      getChosenPlatformFromApi(chosenPlatforms)
    }
    if (
      formData.model?.id !== null &&
      formData.model?.id !== undefined &&
      formData.isCreditAllocatorAndPerformanceCurve === true
    ) {
      return ATTRIBUTION_MODEL_TYPES.find((type) => type.value === formData.model?.id)?.text
    }
    if (formData.model?.id !== undefined) {
      if (formData.account.analyticsAccountPlatform !== 'ga4' && formData.model.id === 'native') {
        return 'Native'
      } else {
        return MODEL_TYPES.find((type) => type.value === formData.model?.id)?.text
      }
    }
  }

  if (isNewConfig && formData.model?.id === undefined && isOnlyRbaAllowed && !optimizeBudget) {
    setValue('model', {
      id: CREDIT_INPUTS.RBA as ModelType
    })
  }

  return (
    <AccordionFormStep stepName={t('form.step', { stepNumber })} title={title} expanded>
      <AccordionFormStepItem
        title={t('createModel.basic')}
        requiredFieldsTally={getNumberOfRequiredInputsPerSection({
          data: formData,
          sectionName: `model`,
          errors,
          getInputRules
        })}
        isCreateEvent={isNewConfig}
        expanded={!isNewConfig}
        t={t}
      >
        {optimizeBudget ? (
          <FormItem
            name="model"
            label={t('createModel.modelType')}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <SelectInput
                  fullWidth
                  {...field}
                  value={getModelInputValue(field.value) as string}
                  onChange={(event) => {
                    setModel(event.target.value as string | number)
                  }}
                >
                  <SelectInputListSubHeader>{t('createModel.modelTypeGa4')}</SelectInputListSubHeader>

                  {GA4_MODEL_TYPES.map((modelType) => {
                    return (
                      <SelectItem key={modelType.value} value={modelType.value}>
                        <Avatar imageUrl="/assets/images/google_analytics_4.svg" kind="image" size="small" />
                        {modelType.text}
                      </SelectItem>
                    )
                  })}
                  <SelectInputListSubHeader>{t('createModel.modelTypePlatform')}</SelectInputListSubHeader>
                  {platformsConversionSupported.map((platform) => {
                    return (
                      <SelectItem key={platform} value={platform}>
                        <Avatar
                          imageUrl={
                            platform === MULTIPLE_PLATFORMS
                              ? `${ASSETS_BASE_URL}/data_source.svg`
                              : getPlatformIconUrl(platform as Platforms) ?? ''
                          }
                          kind="image"
                          size="small"
                        />
                        {platform === MULTIPLE_PLATFORMS
                          ? MULTIPLE_PLATFORMS
                          : getPlatformDetailsByPlatform(platform)?.label}
                      </SelectItem>
                    )
                  })}
                </SelectInput>
              )
            }}
            editModes={editModes}
            currentValue={getCurrentValue(formData)}
            toggleEdit={toggleEdit}
            handleUpdate={handleUpdate}
            control={control}
            isCreateEvent={isNewConfig}
            {...(isCopyConfig && {
              canEdit: false
            })}
            helperText={t('createModel.boHelperText.modelType')}
          />
        ) : (
          <FormItem
            name="model.id"
            label={t('createModel.amModelType')}
            rules={{ required: true }}
            render={({ field }) => {
              const { value, onChange, ...other } = field
              return (
                <SelectInput
                  fullWidth
                  value={value ?? ''}
                  {...other}
                  onChange={(event) => {
                    reset({
                      name: watch('name'),
                      model: {
                        id: event.target.value as ModelType
                      },
                      account: {
                        otherAccounts: []
                      }
                    })
                  }}
                >
                  {allowedAttributionModelTypes.map((modelType) => {
                    return (
                      <SelectItem key={modelType.value} value={modelType.value}>
                        {modelType.text}
                      </SelectItem>
                    )
                  })}
                </SelectInput>
              )
            }}
            currentValue={ATTRIBUTION_MODEL_TYPES.find((type) => type.value === formData.model?.id)?.text}
            editModes={editModes}
            toggleEdit={toggleEdit}
            handleUpdate={handleUpdate}
            control={control}
            isCreateEvent={isNewConfig}
            helperText={
              <Typography variant="body3">
                {t('createModel.amHelperText.modelType')}{' '}
                <Link href={ATTRIBUTION_MODELS_AVAIABLE_MODELS_LINK} target="_blank" rel="noreferrer">
                  <Typography variant="body3">{t('createModel.amHelperText.modelTypeLink')}</Typography>
                </Link>
                .
              </Typography>
            }
            {...(isOnlyRbaAllowed && { canEdit: false })}
          />
        )}
      </AccordionFormStepItem>
    </AccordionFormStep>
  )
}

export default ModelStep
