import { type UseQueryResult, type UseMutationResult, useQuery } from 'react-query'
import { type fetch, analytics } from 'shared/api'
import useMutationAnalytics from 'shared/hooks/useMutationAnalytics'

export const queryKeys = {
  recommendationPlatforms: 'recommendationPlatforms',
  recommendationFeed: 'recommendationFeed'
}

export const useMutationActivityFeed = (): UseMutationResult<
  analytics.FeedResp[],
  fetch.ClientError,
  analytics.AnalyticsReq
> => {
  return useMutationAnalytics(analytics.postFeed)
}

export const useQueryPlatformList = (
  clientId: string
): UseQueryResult<analytics.FeedDistinctPlatforminResp[], fetch.ClientError> => {
  return useQuery(
    [queryKeys.recommendationPlatforms, clientId],
    async () =>
      await analytics.postFeed({
        collection: 'event_feed',
        report: 'event_feed',
        clientId,
        fields: [{ name: 'platform', aggregation: 'DISTINCT' }]
      }),
    {
      enabled: clientId !== ''
    }
  )
}
