import {
  useForm as reactHookUseForm,
  Controller as reactHookController,
  useController as reactHookUseController
} from 'react-hook-form'
import { path, uniq } from 'ramda'
import { type FieldPath, type FieldErrors, type FieldValues, type RegisterOptions } from 'react-hook-form'

export const useForm = reactHookUseForm

export const Controller = reactHookController

export const useController = reactHookUseController

export const getRequiredFields = <FormData extends FieldValues>(
  errors: FieldErrors<FormData>
): Array<FieldPath<FormData>> => {
  const requiredFields = []

  const errorStack = Object.keys(errors).map((key) => ({ key, value: errors[key] }))

  while (errorStack.length > 0) {
    const errorKeyAndValue = errorStack.pop()
    if (errorKeyAndValue != null) {
      const { key, value } = errorKeyAndValue

      if (value != null && value.type === 'required') {
        requiredFields.push(key)
      }

      if (value != null && typeof value === 'object') {
        errorStack.push(
          ...Object.entries(value).map(([subKey, subValue]) => ({
            key: `${key}.${subKey}`,
            value: subValue
          }))
        )
      }
    }
  }

  return requiredFields as Array<FieldPath<FormData>>
}

export interface InputRules<FormData extends FieldValues, T extends FieldPath<FormData> = FieldPath<FormData>>
  extends Omit<RegisterOptions<FormData, T>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> {}

export const getNumberOfRequiredInputsPerSection = <FormData extends FieldValues, T extends FieldPath<FormData>>({
  sectionName,
  data,
  errors,
  getInputRules,
  isCopyConfig
}: {
  sectionName: FieldPath<FormData>
  data: FormData
  errors: FieldErrors<FormData>
  getInputRules: ({
    fieldName,
    data,
    isCopyConfig
  }: {
    fieldName: FieldPath<FormData>
    data: FormData
    isCopyConfig?: boolean
  }) => InputRules<FormData, T>
  isCopyConfig?: boolean
}): [number, number] => {
  const sectionDataKey = sectionName.split('.')
  const sectionData = path<FormData>(sectionDataKey, data)
  const errorData = path<FieldErrors<FormData>>(sectionDataKey, errors)
  let numberOfRequiredInputs = 0
  let numberOfRequiredErrorInputs = 0

  const dataAndErrorKeys = uniq([...Object.keys(sectionData ?? {}), ...Object.keys(errorData ?? {})])
  dataAndErrorKeys.forEach((key) => {
    const fieldName = `${sectionName}.${key}` as FieldPath<FormData>
    if (getInputRules({ fieldName, data, isCopyConfig }).required === true) {
      numberOfRequiredInputs++
    }
    if (path<{ type: string }>([...sectionDataKey, key], errors)?.type === 'required') {
      numberOfRequiredErrorInputs++
    }
  })

  return [numberOfRequiredInputs - numberOfRequiredErrorInputs, numberOfRequiredInputs]
}
