import { PLATFORM } from 'dataSource'
import { type Platforms } from 'shared/api/accounts'

export const platformsSupportedBo: Platforms[] = [
  PLATFORM.DV360,
  PLATFORM.FACEBOOK,
  PLATFORM.LINKEDIN,
  PLATFORM.SNAPCHAT,
  PLATFORM.TIKTOK,
  PLATFORM.MICROSOFT_ADVERTISING,
  PLATFORM.GOOGLE
]

export const platformsSupportedReporting: Platforms[] = [
  PLATFORM.GA4,
  PLATFORM.GOOGLE,
  PLATFORM.FACEBOOK,
  PLATFORM.DV360,
  PLATFORM.MERCHANT_CENTER,
  PLATFORM.MICROSOFT_ADVERTISING,
  PLATFORM.TIKTOK,
  PLATFORM.LINKEDIN,
  PLATFORM.SNAPCHAT,
  PLATFORM.GOOGLE_SHEETS
]

export const platformsSupportedCustomGrouping: Platforms[] = [
  PLATFORM.GA4,
  PLATFORM.GOOGLE,
  PLATFORM.ANALYTICS,
  PLATFORM.DV360,
  PLATFORM.FACEBOOK,
  PLATFORM.LINKEDIN,
  PLATFORM.SNAPCHAT,
  PLATFORM.TIKTOK,
  PLATFORM.MICROSOFT_ADVERTISING
]

export const platformsSupportedConnectors: Platforms[] = [
  ...Object.values(PLATFORM).filter((platform) => platform !== PLATFORM.GCP && platform !== PLATFORM.GOOGLE_BIGQUERY)
]

export const platformsSupportedAttribution: Platforms[] = [
  PLATFORM.DV360,
  PLATFORM.FACEBOOK,
  PLATFORM.LINKEDIN,
  PLATFORM.SNAPCHAT,
  PLATFORM.TIKTOK,
  PLATFORM.MICROSOFT_ADVERTISING,
  PLATFORM.GOOGLE
]
