import React, { type ReactElement } from 'react'
import { type ControlledListViewFilter } from './types'
import { Grid, MultiSelectDropdown, SingleSelectDropdown, Typography, Skeleton, Button } from '@precis-digital/kurama'
import { ALL_OPTIONS_FILTER_VALUE } from './constants'
import { useTranslation } from 'shared/translations'
import { isNilOrEmpty } from 'shared/utils'

interface ListViewFiltersProps<T> {
  filters: Array<ControlledListViewFilter<T>>
  isLoading: boolean
  resetFilters: () => void
}

const ListViewFilters = <T,>({ filters, isLoading, resetFilters }: ListViewFiltersProps<T>): ReactElement => {
  const hasActiveFilters = filters.some(
    (filter) => !isNilOrEmpty(filter.value) && filter.value !== (filter.defaultValue ?? null)
  )
  const { t } = useTranslation('common')

  return (
    <Grid container gap="16px" alignItems="center">
      {isLoading ? <Skeleton height={24} width="8ch" /> : <Typography variant="body2">Show me:</Typography>}
      {filters.map((filter) => {
        if (isLoading) {
          return <Skeleton key={filter.title} height={40} width="158px" />
        }
        return <Filter filter={filter} key={filter.title} />
      })}

      {hasActiveFilters && !isLoading && (
        <Button variant="text" onClick={resetFilters}>
          {t('clear')}
        </Button>
      )}
    </Grid>
  )
}

interface FilterProps<T> {
  filter: ControlledListViewFilter<T>
}

const Filter = <T,>({ filter }: FilterProps<T>): ReactElement => {
  const value = filter.value == null ? undefined : filter.value
  if (filter.type === 'single-select') {
    return (
      <SingleSelectDropdown
        title={filter.title}
        buttonTitle={filter?.noFilterLabel ?? filter.title}
        options={[
          {
            label: filter?.noFilterLabel ?? 'All',
            value: ALL_OPTIONS_FILTER_VALUE
          },
          ...filter.options
        ]}
        value={(value as string) ?? ALL_OPTIONS_FILTER_VALUE}
        onSelect={(selectedValue) => {
          filter.onChange(selectedValue)
        }}
        buttonWidth={filter.buttonWidth}
      />
    )
  }

  if (filter.type === 'multi-select') {
    return (
      <MultiSelectDropdown
        title={filter.title}
        buttonTitle={filter?.noFilterLabel ?? filter.title}
        options={filter.options}
        value={value as string[]}
        onChange={(_, allSelections) => {
          filter.onChange(allSelections.map((selection) => selection.value))
        }}
        buttonWidth={filter.buttonWidth}
      />
    )
  }

  throw new Error(`Filter type ${filter.type as string} is not supported`)
}

export default ListViewFilters
