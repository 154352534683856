import { Slider } from '@mui/material'
import { styled } from '../../utils'
import { Theme, CSSObject } from '../theme'

export const StyledSlider = styled(Slider)(
  ({ theme, height }: { theme?: Theme; height?: string }): CSSObject => ({
    ...(height != null && { height }),
    '& .MuiSlider-track': {
      border: `1px solid ${theme?.palette.neutrals.stone100 ?? '#EEF4F6'}`,
      height: '24px',
      borderRadius: '16px',
      backgroundColor: theme?.palette.main.blue100
    },
    '& .MuiSlider-rail': {
      border: `1px solid ${theme?.palette.neutrals.stone100 ?? '#EEF4F6'}`,
      height: '24px',
      borderRadius: theme?.borderRadius.medium,
      backgroundColor: 'transparent',
      paddingRight: '16px'
    },
    '& .MuiSlider-thumb': {
      width: '40px',
      height: '40px',
      backgroundColor: theme?.palette.neutrals.white0,
      border: `1px solid ${theme?.palette.neutrals.stone100 ?? '#EEF4F6'}`,
      borderRadius: theme?.borderRadius.medium,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'none'
      },
      '::before': {
        boxShadow: 'none'
      }
    },
    '& .MuiSlider-mark': {
      height: '12px',
      width: '12px',
      borderRadius: '50%',
      backgroundColor: theme?.palette.neutrals.stone100,
      '&.MuiSlider-markActive': {
        opacity: 0
      }
    },
    '& .MuiSlider-markLabel': {
      marginLeft: '2%',
      ...(height != null && { top: height })
    },
    '&.Mui-disabled': {
      color: theme?.palette.neutrals.stone120,
      '& .MuiSlider-track': {
        backgroundColor: theme?.palette.neutrals.stone100
      },
      '& .MuiSlider-mark': {
        backgroundColor: theme?.palette.neutrals.stone100
      }
    }
  })
)
