import React from 'react'
import { Flyout } from '@precis-digital/kurama'
import InternalProfileFlyout from 'profile/components/Flyout/InternalProfile'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import { useAuth } from 'shared/context/AuthContext'
import ExternalProfileFlyout from './ExternalProfile'

interface AccountFlyoutProps {
  id?: string
}

export const AccountFlyout = ({ id }: AccountFlyoutProps): React.ReactElement => {
  const screen = useOverScreen('profileFlyout')
  const { currentUser } = useAuth()

  return (
    <Flyout
      onClose={(): void => {
        screen.remove()
      }}
      isExpanded={screen.visible}
    >
      {id?.toString() === currentUser?.id?.toString() ? (
        <InternalProfileFlyout selectedUserId={id} />
      ) : (
        <ExternalProfileFlyout selectedUserId={id} />
      )}
    </Flyout>
  )
}

export default OverScreen.create(AccountFlyout)
