import React from 'react'

export const DataInsights = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M16.94 8.84003C17.9231 8.84003 18.72 8.0431 18.72 7.06003C18.72 6.07696 17.9231 5.28003 16.94 5.28003C15.957 5.28003 15.16 6.07696 15.16 7.06003C15.16 8.0431 15.957 8.84003 16.94 8.84003Z"
        fill="currentColor"
      />
      <path
        d="M20 11.96H12.04V4C12.03 4 12.01 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 11.99 20 11.97 20 11.96Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
