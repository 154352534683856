import { useMemo, type ReactElement, useEffect } from 'react'
import { MemberTimestampCell, StatusCell, TextCell, TitleWithAvatarsCell } from 'shared/components/ListView'
import {
  useMutationUpdateAttribution,
  useMutationAttributionConfigStatusById,
  useQueryAttributionConfigStatuses,
  useQueryIntegratedAttribution,
  useQueryIntegratedAttributions
} from 'attributionModel/api'
import { PLATFORMS } from 'dataSource/constants'
import { useQueriesUserProfilesFromIAConfigs } from 'profile/api'
import { type ConfigStatusResp } from 'shared/api/analytics'
import { type AttributionResp } from 'shared/api/attributionModels'
import { type ListViewCellProps, type ListViewColumnDefinition } from 'shared/components/ListView/types'
import { useQueryString } from 'shared/components/Router'
import { makeToastWithLoading } from 'shared/components/Toaster'
import {
  ORDERED_TAB_STATUSES,
  getErrorCodeDetails,
  getStatusMarketingEvaluation,
  type ConfigStatus
} from 'shared/configStatuses'
import { ATTRIBUTION_MODELS_DOCUMENTATION_LINK } from 'shared/constants/links'
import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'
import useDagView from 'shared/hooks/useDagView'
import { useHasAccess } from 'shared/hooks/useHasAccess'
import { useTranslation } from 'shared/translations'
import { useQueryMember } from 'workspace/api'
import { transformAttributionRespToReq } from './EditOrCreateModel/utils'
import MoreMenu from './MoreMenu'
import { APP_ROUTES } from 'shared/routes'
import { getPlatformDetailsByPlatform } from 'dataSource'
import { useQueryClientAccountPlatform, useQueryClientAccounts } from 'dataSource/api'
import { useQueryChannelGroupings } from 'channelGrouping/api'
import { useMember } from 'workspace'
import { type Platforms } from 'shared/api/accounts'
import { hasAccountsWithSupportedPlatforms, hasPreRequisites } from 'shared/components/Prerequisite/utils'
import { platformsSupportedAttribution } from 'shared/components/Prerequisite/constants'
import TableRowLink from 'shared/components/ListView/Table/TableRowLink'
import { type RowLinkProps } from 'shared/components/ListView/Table'
import Start from 'shared/components/Start'

const getPlatformNames = (attribution: AttributionResp): string[] => {
  const platformNames: string[] = attribution.adAccounts.map((account) => account.platform)
  if (attribution.analyticsAccount != null) platformNames.push(attribution.analyticsAccount?.platform)
  return platformNames
}

const getAccountIconUrl = (platformName: string): string | undefined => {
  return PLATFORMS.find((platform) => platform.platform === platformName)?.iconUrl
}

const AttributionRowLink = ({ row, children }: RowLinkProps<ConfigWithStatus>): ReactElement => {
  return (
    <TableRowLink href={APP_ROUTES.attributionModels.dashboardPage({ configId: `${row.id}` })}>{children}</TableRowLink>
  )
}

export interface ConfigWithStatus extends AttributionResp {
  status?: ConfigStatusResp
  statusEnum?: ConfigStatus
}

const StartPage = (): ReactElement => {
  const { t, i18n } = useTranslation('attributionModel')
  const { t: tCommon } = useTranslation('common')

  const { query } = useQueryString()
  const { showDagView, shouldShowDagView, handleConfigError } = useDagView('attributionModel')
  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()

  const { data: integratedAttributions = [], isLoading } = useQueryIntegratedAttributions(currentClient.id)
  const { data: integratedAttributionsStatuses = [], isLoading: isLoadingIntegratedAttributionsStatuses } =
    useQueryAttributionConfigStatuses(currentClient.id)
  const { mutate: updateIntegrationAtributionModel } = useMutationUpdateAttribution()

  const platformGCP: Platforms = 'gcp'
  const platformAnalytics: Platforms = 'ga4'
  const patformUAnalytics: Platforms = 'analytics'
  const { data: clientAccountGCP, isLoading: isLoadingGCP } = useQueryClientAccountPlatform(
    currentClient.id,
    platformGCP
  )

  const { data: clientAccountAnalytics, isLoading: isLoadingGA4 } = useQueryClientAccountPlatform(
    currentClient.id,
    platformAnalytics
  )
  const { data: clientAccountUAnalytics, isLoading: isLoadingAnalytics } = useQueryClientAccountPlatform(
    currentClient.id,
    patformUAnalytics
  )

  const { data: clientAccounts, isLoading: isLoadingClientAccounts } = useQueryClientAccounts(currentClient.id)

  const { data: channelGroupings, isLoading: isLoadingChannelGroupings } = useQueryChannelGroupings(currentClient?.id)

  const filteredIntegratedAttributions = useMemo(
    () =>
      integratedAttributions
        .map((attribution) => {
          const status = integratedAttributionsStatuses?.find((status) => status.configId === attribution.id.toString())
          return {
            ...attribution,
            status,
            statusEnum: getStatusMarketingEvaluation(attribution.runSchedule, status?.status)
          }
        })
        .filter((attribution) => attribution.creditInput !== 'native'),
    [integratedAttributions, integratedAttributionsStatuses]
  )

  const configId = Number(query?.configId)

  const shouldShowDagViewPopup = shouldShowDagView(query)

  const {
    data: integratedAttribution,
    isSuccess: isIntegratedAttributionSuccess,
    isError: isIntegratedAttributionError,
    error
  } = useQueryIntegratedAttribution(configId, currentClient.id, shouldShowDagViewPopup)

  const { mutate: getAttributionConfigStatus, data: integratedAttributionStatus } =
    useMutationAttributionConfigStatusById()

  useEffect(() => {
    if (configId !== 0 && isIntegratedAttributionSuccess && integratedAttributionStatus == null) {
      getAttributionConfigStatus({ clientId: currentClient.id, modelId: configId })
    }
  }, [
    currentClient.id,
    configId,
    isIntegratedAttributionSuccess,
    integratedAttributionStatus,
    getAttributionConfigStatus
  ])

  if (isIntegratedAttributionSuccess && integratedAttribution != null && integratedAttributionStatus != null) {
    showDagView({
      configId: integratedAttribution.id,
      configName: integratedAttribution.name,
      isActive: integratedAttribution.runSchedule,
      configStatus: getStatusMarketingEvaluation(
        integratedAttribution.runSchedule,
        integratedAttributionStatus?.[0]?.status
      )
    })
  }

  if (isIntegratedAttributionError) {
    handleConfigError({
      statusCode: error.status_code,
      notFoundMessage: tCommon('notifications.configAccessError'),
      unexpectedErrorMessage: tCommon('notifications.configUnexpectedError')
    })
  }

  const userDetails = useQueriesUserProfilesFromIAConfigs(integratedAttributions)

  const { data: member, isLoading: isMemberLoading } = useQueryMember(currentClient.id, currentUser?.id ?? '')
  const hasEditorAccess = useHasAccess('editor', member?.role ?? 'viewer')

  const { role: memberRole } = useMember()

  const findAnalyticsLength =
    (clientAccountAnalytics !== undefined && clientAccountAnalytics.length > 0) ||
    (clientAccountUAnalytics !== undefined && clientAccountUAnalytics.length > 0)

  const { hasAllPreRequisites, hasCustomGrouping, hasDataSourceAccount, hasGcpAccount, hasAnalyticsAccount } =
    hasPreRequisites({
      gcpAccount: clientAccountGCP,
      channelGroupings,
      accounts: hasAccountsWithSupportedPlatforms(clientAccounts, platformsSupportedAttribution),
      analyticsAccount: findAnalyticsLength
    })

  const isLoadingData = isLoading || isMemberLoading || filteredIntegratedAttributions == null

  const isLoadingPreRequisites =
    isLoadingGCP || isLoadingGA4 || isLoadingAnalytics || isLoadingClientAccounts || isLoadingChannelGroupings

  const columns: Array<ListViewColumnDefinition<ConfigWithStatus>> = useMemo(
    () => [
      {
        Header: t('listTable.titles.modelName'),
        accessor: 'name',
        Cell: (props: ListViewCellProps<ConfigWithStatus>) => {
          const accounts = getPlatformNames(props.row.original)
          const avatars = accounts.map((account) => {
            return {
              name: getPlatformDetailsByPlatform(account).label,
              imageUrl: getAccountIconUrl(getPlatformDetailsByPlatform(account).platform)
            }
          })
          return (
            <TitleWithAvatarsCell
              title={props.row.original.name}
              avatars={avatars}
              avatarDescriber={t('listTable.table.connectedAccounts')}
              {...props}
            />
          )
        }
      },
      {
        Header: t('listTable.titles.model'),
        accessor: 'creditInput',
        Cell: (props: ListViewCellProps<ConfigWithStatus>) => {
          const modelType = props.row.original.creditInput
          return (
            <TextCell
              text={
                i18n?.exists(`attributionModel:dashboard.titles.${modelType}`)
                  ? t(`dashboard.titles.${modelType}`)
                  : t(`dashboard.titles.default`)
              }
              {...props}
            />
          )
        },
        align: 'center',
        width: '200px'
      },
      {
        Header: t('listTable.titles.lastEdited'),
        accessor: 'updatedAt',
        Cell: (props: ListViewCellProps<ConfigWithStatus>) => (
          <MemberTimestampCell
            {...props}
            timestamp={props.row.original.updatedAt}
            memberId={userDetails?.find((res) => res.data?.email === props.row.original.updatedBy)?.data?.id ?? ''}
          />
        ),
        align: 'center'
      },
      {
        Header: t('listTable.titles.status'),
        accessor: 'statusEnum',
        Cell: (props: ListViewCellProps<ConfigWithStatus>) => {
          const dagViewInfo = {
            dagViewType: 'attributionModel' as const,
            configId: props.row.original.id,
            configName: props.row.original.name,
            isActive: props.row.original.runSchedule,
            isPageWithConfigId: false
          }
          return (
            <StatusCell
              {...props}
              isLoadingStatus={isLoadingIntegratedAttributionsStatuses}
              status={props.row.original.statusEnum ?? 'unknown'}
              lastUpdated={props.row.original.status?.lastUpdated}
              errorCode={props.row.original.status?.errorCode}
              errorDetails={getErrorCodeDetails(
                props.row.original.status?.errorCode,
                props.row.original.status?.errorMessage,
                props.row.original?.targetProjectId,
                tCommon('dagView.attributionModel')
              )}
              dagViewInformation={dagViewInfo}
            />
          )
        },
        align: 'right',
        width: '120px'
      }
    ],
    [t, i18n, userDetails, isLoadingIntegratedAttributionsStatuses, tCommon]
  )

  return (
    <Start
      isLoadingPreRequisites={isLoadingPreRequisites}
      hasAllPreRequisites={hasAllPreRequisites}
      noPreRequisitesProps={{
        memberRole,
        t,
        hasGcpAccount,
        hasDataSourceAccount,
        hasCustomGrouping,
        hasAnalyticsAccount
      }}
      emptyStateProps={{
        title: t('emptyTable.header'),
        subtitle1: t('emptyTable.statement'),
        subtitle2: t('emptyTable.statement2'),
        educationalBlockProps: {
          title: t('emptyState.educationalBlock.title'),
          description: t('emptyState.educationalBlock.description'),
          documentationLinkHref: ATTRIBUTION_MODELS_DOCUMENTATION_LINK,
          documentationLinkText: t('emptyState.educationalBlock.linkText')
        },
        createNewButtonProps: {
          label: t('buttons.createNewModel'),
          hasEditorAccess,
          href: APP_ROUTES.attributionModels.createConfigPage
        }
      }}
      listViewProps={{
        isLoading: isLoadingData,
        title: t('meta.title'),
        createNewButtonProps: {
          label: t('buttons.createNewModel'),
          isLoading: isMemberLoading,
          hasEditorAccess,
          isLoadingPreRequisites,
          href: APP_ROUTES.attributionModels.createConfigPage
        },
        tabs: {
          accessor: 'statusEnum',
          orderedOptions: ORDERED_TAB_STATUSES,
          allOptionLabel: t('listTable.tabs.allStatuses'),
          otherOptionLabel: t('listTable.tabs.other')
        },
        tableProps: {
          data: filteredIntegratedAttributions ?? [],
          includePagination: true,
          columns,
          initialState: {
            pageIndex: 0,
            sortBy: [{ id: 'updatedAt', desc: true }]
          },
          RowLink: AttributionRowLink,
          renderMoreMenu: (row: ConfigWithStatus, closeMenu: () => void) => {
            return (
              <MoreMenu
                selectedModel={{
                  modelId: row.id,
                  name: row.name,
                  isActive: row.runSchedule,
                  status: row.statusEnum,
                  targetProjectId: row.targetProject,
                  targetDatasetId: row.targetDataset
                }}
                editPagePath={
                  row.channelGroupingId != null
                    ? APP_ROUTES.attributionModels.editConfigPage({ configId: `${row.id}` })
                    : APP_ROUTES.centralApp.attributionModels.editConfigPage({
                        configId: `${row.id}`
                      })
                }
                allowPause={row.channelGroupingId != null}
                onModelStatusChange={() => {
                  const { toastOnError, toastOnSuccess } = makeToastWithLoading()
                  const selectedConfig = integratedAttributions?.find(
                    (config) => Number(row.id) === Number(config.id)
                  ) as AttributionResp
                  updateIntegrationAtributionModel(
                    {
                      data: {
                        ...transformAttributionRespToReq(selectedConfig),
                        runSchedule: !row.runSchedule
                      },
                      configId: row.id
                    },
                    {
                      onSuccess: () => {
                        toastOnSuccess(t('form.configUpdated'))
                      },
                      onError: () => {
                        toastOnError(t('form.configNotUpdatedError'))
                      }
                    }
                  )
                }}
                hasEditorAccess={hasEditorAccess}
                path="attribution-models"
                onCloseMenu={closeMenu}
              />
            )
          }
        }
      }}
    />
  )
}

export default StartPage
