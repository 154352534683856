import { Grid, Toggle, Typography } from '@precis-digital/kurama'
import { useMutationAnalyticsAttributionChannelAggregated } from 'attributionModel/api'
import ComparisonFilters, { type AttributionModel } from 'attributionModel/components/Dashboard/ComparisonFilters'
import Filters from 'attributionModel/components/Dashboard/DetailedReportFilters'
import {
  ADSPEND,
  CHANNEL,
  CONVERSIONS,
  COSTPERACTION,
  DEFINED_MODELS,
  FIRSTSUBHEADER,
  IMPRESSIONS,
  REVENUE,
  ROAS,
  SECONDSUBHEADER
} from 'attributionModel/constants'
import { useEffect, useMemo, useState } from 'react'
import { useFilters, useSortBy, useTable, type Column } from 'react-table'
import { type AttributionResp } from 'shared/api/attributionModels'
import { ReportTable } from 'shared/components/ReportTable'
import { formatDateToString } from 'shared/dateFns'
import { useTranslation } from 'shared/translations'
import {
  getBasicTableColumns,
  getComparisonTableColumns,
  getTimePeriod,
  type DefinedAttributionModelType,
  type TimePeriod,
  type TimePeriodType
} from 'attributionModel/components/Dashboard/DetailedReportUtils'
import { useCurrentClient } from 'shared/context/ClientContext'
import { getAttributionChannelLevelQuery } from 'attributionModel/components/Dashboard/cirrusQueries'
import { transformDataForComparisionTable } from 'attributionModel/transformations'
import { type AttributionChannelAggregatedResponse } from 'shared/api/analytics'
import { type GlobalFilter } from 'attributionModel/components/Dashboard'
import { useQueryParameterState } from 'shared/hooks/useQueryParameterState'

export interface AttributionModelDetailedReport {
  channel: string
  revenue: number
  roas: number | null
  conversions: number
  adSpend: number
  costPerAction: number | null
  impressions: number
}

interface DetailedReportingProps {
  data: AttributionModelDetailedReport[]
  attributionData: AttributionChannelAggregatedResponse[]
  isDataLoading: boolean
  currentIntegratedAttribution: AttributionResp | undefined
  dataPrevTimePeriod: AttributionModelDetailedReport[]
  isMissingDataPrevTimePeriod: boolean
  dataPrevYearTimePeriod: AttributionModelDetailedReport[]
  isMissingDataPrevYearTimePeriod: boolean
  dateRange: {
    startDate: string
    endDate: string
  }
  currencyCode: string
  hasConversionsButNoRevenue: boolean
  selectedFilters: GlobalFilter
}

export interface ColumnProps {
  [CHANNEL]: string
  [ADSPEND]: number
  [REVENUE]: number
  [ROAS]: number
  [CONVERSIONS]: number
  [COSTPERACTION]: number
  [IMPRESSIONS]: number
}

export interface ComparisonColumnProps {
  [CHANNEL]: string
  [ADSPEND]: number
  [REVENUE]: Record<string, number>
  [ROAS]: Record<string, number>
  [CONVERSIONS]: Record<string, number>
  [COSTPERACTION]: Record<string, number>
  [IMPRESSIONS]: Record<string, number>
}

const columnNames = {
  [CHANNEL]: 'channel',
  [ADSPEND]: 'adSpend',
  [REVENUE]: 'revenue',
  [ROAS]: 'roas',
  [CONVERSIONS]: 'conversions',
  [COSTPERACTION]: 'costPerAction',
  [IMPRESSIONS]: 'impressions'
} as const

export type Metric = keyof Pick<
  typeof columnNames,
  'adSpend' | 'revenue' | 'roas' | 'conversions' | 'costPerAction' | 'impressions'
>

const timePeriodInitialValue: TimePeriod = {
  value: '',
  startDate: '',
  endDate: ''
}
const modelInitialValue: AttributionModel = {
  name: '',
  id: 0,
  type: ''
}
export type ComparisonType = 'timePeriod' | 'config'

const calcCompareData = (
  timePeriodValue: TimePeriodType,
  modelId: number,
  dataPrevTimePeriod: AttributionModelDetailedReport[],
  dataPrevYearTimePeriod: AttributionModelDetailedReport[],
  dataModelCompare: AttributionModelDetailedReport[]
): AttributionModelDetailedReport[] => {
  if (timePeriodValue === 'previousTimePeriod') {
    return dataPrevTimePeriod
  }
  if (timePeriodValue === 'samePeriodLastYear') {
    return dataPrevYearTimePeriod
  }

  if (modelId !== modelInitialValue.id) {
    return dataModelCompare
  }
  return []
}

function isDefinedAttributionModelType(value: any): value is DefinedAttributionModelType {
  return Boolean(Object.values(DEFINED_MODELS).find((model) => model.value === value))
}

const getDefaultMetricsFilter = (hasConversionsButNoRevenue: boolean): Record<Metric, boolean> => {
  const defaultMetricsFilter = {
    [ADSPEND]: true,
    [REVENUE]: true,
    [ROAS]: true,
    [CONVERSIONS]: true,
    [COSTPERACTION]: true,
    [IMPRESSIONS]: true
  }

  if (hasConversionsButNoRevenue) {
    defaultMetricsFilter[ROAS] = false
    defaultMetricsFilter[REVENUE] = false
  }

  return defaultMetricsFilter
}

export const DetailedReport = ({
  data,
  isDataLoading,
  currentIntegratedAttribution,
  dataPrevTimePeriod,
  isMissingDataPrevTimePeriod,
  dataPrevYearTimePeriod,
  isMissingDataPrevYearTimePeriod,
  dateRange: { startDate, endDate },
  currencyCode,
  hasConversionsButNoRevenue,
  attributionData,
  selectedFilters
}: DetailedReportingProps): React.ReactElement => {
  const { t } = useTranslation('attributionModel', { keyPrefix: 'dashboard' })

  const [metricsFilter, setMetricsFilter] = useQueryParameterState<Record<Metric, boolean>>(
    'mf',
    getDefaultMetricsFilter(hasConversionsButNoRevenue)
  )

  // console.log('>>>> metricsFilter', metricsFilter)

  // why do we have both?, isComparisonMode is the state of the toggle, isComparisonModeSelected is the state of the model
  // so we can show the comparison filters, before the user has picked a model
  const [timePeriod, setTimePeriod] = useQueryParameterState<TimePeriod>('cmtime', timePeriodInitialValue)
  const [model, setModel] = useQueryParameterState<AttributionModel>('cmmodel', modelInitialValue)

  // this just checks if a model has been picked, by comparing the current model to the initial model
  const hasComparisonModelBeenSelected =
    timePeriod.value !== timePeriodInitialValue.value || model.id !== modelInitialValue.id

  // this is the state of the toggle, and determines if the comparison filters are shown
  const [isComparisonMode, setIsComparisonMode] = useState<boolean>(hasComparisonModelBeenSelected)

  const compareType: ComparisonType = timePeriod.value !== '' ? 'timePeriod' : 'config'
  const {
    mutate: getCompareDetailedReport,
    data: compareModelAttributionResponse,
    isLoading: isCompareDetailedReportLoading
  } = useMutationAnalyticsAttributionChannelAggregated()
  const { currentClient } = useCurrentClient()

  const handleComparisonMode = (): void => {
    setIsComparisonMode(!isComparisonMode)
    setTimePeriod(timePeriodInitialValue)
    setModel(modelInitialValue)
  }

  const handleTimePeriod = (timePeriodValue: TimePeriodType): void => {
    const { startTimePeriod, endTimePeriod } = getTimePeriod(timePeriodValue, startDate, endDate)
    setTimePeriod({
      ...timePeriod,
      value: timePeriodValue,
      startDate: startTimePeriod,
      endDate: endTimePeriod
    })
    setModel(modelInitialValue)
  }

  const handleModel = (model: AttributionModel): void => {
    setModel({ ...model }) // clone the object to avoid mutating the state, so it triggers a re-render
    setTimePeriod(timePeriodInitialValue)

    // dont actually fetch if the model ID is the same as the based model ID
    // instead the calcCompareData will return the correct values
    if (model.id !== currentIntegratedAttribution?.id) {
      getCompareDetailedReport(
        getAttributionChannelLevelQuery(
          currentClient.id,
          model.id,
          startDate,
          endDate,
          currencyCode,
          model.type,
          selectedFilters
        )
      )
    }
  }

  // this is the initial fetch of the detailed report and if a comparison model is speecified in the url
  // then this will fetch the comparison data
  useEffect(() => {
    if (model.id !== modelInitialValue.id && model.id !== currentIntegratedAttribution?.id) {
      getCompareDetailedReport(
        getAttributionChannelLevelQuery(
          currentClient.id,
          model.id,
          startDate,
          endDate,
          currencyCode,
          model.type,
          selectedFilters
        )
      )
    }
    // this is only intended to run once, on loading it's a componetDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const compareData = useMemo(() => {
    let comparisonData: AttributionModelDetailedReport[] = []
    // the comparison is to another attribution on the same model
    if (model.id === currentIntegratedAttribution?.id) {
      comparisonData = transformDataForComparisionTable(attributionData, model.type)
    } else if (model.id !== currentIntegratedAttribution?.id && model.id !== modelInitialValue.id) {
      comparisonData = transformDataForComparisionTable(compareModelAttributionResponse ?? [], model.type)
    }

    return calcCompareData(timePeriod.value, model.id, dataPrevTimePeriod, dataPrevYearTimePeriod, comparisonData)
  }, [
    timePeriod.value,
    dataPrevTimePeriod,
    dataPrevYearTimePeriod,
    compareModelAttributionResponse,
    attributionData,
    currentIntegratedAttribution,
    model.id,
    model.type
  ])

  const mappedTableData = useMemo(() => {
    if (hasComparisonModelBeenSelected) {
      if (compareData === undefined || compareData.length === 0) {
        return []
      }
      const allChannels = [
        ...new Set([...data.map((item) => item.channel), ...compareData.map((item) => item.channel)])
      ]
      const result = allChannels.map((channel) => {
        const data1Item = data.find((item) => item.channel === channel)
        const data2Item = compareData.find((item) => item.channel === channel)
        const mappedChannels: Record<string, string | number | Record<string, number>> = {}
        Object.values(columnNames).forEach((key) => {
          if (key === CHANNEL) {
            mappedChannels[key] = channel
          } else {
            mappedChannels[key] = {
              [FIRSTSUBHEADER]: data1Item == null ? 0 : data1Item[key] ?? 0,
              [SECONDSUBHEADER]: data2Item == null ? 0 : data2Item[key] ?? 0
            }
          }
        })
        return mappedChannels
      })
      return result
    }
    return data.map((item) => {
      return Object.fromEntries(
        Object.entries(columnNames).map(([columnName, columnValue]) => {
          return [columnName, item[columnValue] ?? 0]
        })
      )
    })
  }, [data, hasComparisonModelBeenSelected, compareData])

  const columns: ReadonlyArray<Column<object>> = useMemo(() => {
    let firstSubHeaderLabel = ''
    let secondSubHeaderLabel = ''
    if (hasComparisonModelBeenSelected) {
      switch (compareType) {
        case 'timePeriod': {
          const { startTimePeriod, endTimePeriod } = getTimePeriod(timePeriod.value, startDate, endDate)
          firstSubHeaderLabel = `${formatDateToString({ date: startDate })} - ${formatDateToString({
            date: endDate
          })}`
          secondSubHeaderLabel = `${formatDateToString({ date: startTimePeriod })} - ${formatDateToString({
            date: endTimePeriod
          })}`
          break
        }
        case 'config': {
          firstSubHeaderLabel = currentIntegratedAttribution?.name ?? ''
          secondSubHeaderLabel = isDefinedAttributionModelType(model.type)
            ? t(`modelTypeTitles.${model.type}`)
            : model.name
          break
        }
      }
    }

    const tableColumns = hasComparisonModelBeenSelected
      ? getComparisonTableColumns({
          t,
          subgroup: { firstSubHeaderLabel, secondSubHeaderLabel },
          currencyCode
        })
      : (getBasicTableColumns({ t, currencyCode }) as any)

    const isAllMetricsSelected = Object.values(metricsFilter).every((value) => !value)
    return tableColumns.filter((column: any) => {
      if (column.accessor !== undefined && typeof column.accessor === 'string') {
        return column.accessor === CHANNEL ? true : isAllMetricsSelected || metricsFilter[column.accessor as Metric]
      }
      return false
    })
  }, [
    hasComparisonModelBeenSelected,
    t,
    currencyCode,
    metricsFilter,
    compareType,
    timePeriod.value,
    startDate,
    endDate,
    currentIntegratedAttribution?.name,
    model.type,
    model.name
  ])

  const renderFilters = (): React.ReactElement => {
    return (
      <Grid container>
        <Grid container item xs={12}>
          <>
            <Filters
              metricsFilter={metricsFilter}
              setMetricsFilter={setMetricsFilter}
              hasConversionsButNoRevenue={hasConversionsButNoRevenue}
            />
            <Grid item xs={3} display="inline-flex" justifyContent="end" alignItems="center" gap="16px">
              <Typography variant="body2">{t('compareThis')}</Typography>
              <Toggle
                aria-label={t('compareThis')}
                name="comparsionMode"
                onChange={handleComparisonMode}
                checked={isComparisonMode}
                defaultChecked
              />
            </Grid>
          </>
        </Grid>
        {isComparisonMode && (
          <ComparisonFilters
            timePeriod={timePeriod.value}
            handleTimePeriod={handleTimePeriod}
            model={model}
            handleModel={handleModel}
            currentIntegratedAttribution={currentIntegratedAttribution}
            isMissingDataPrevTimePeriod={isMissingDataPrevTimePeriod}
            isMissingDataPrevYearTimePeriod={isMissingDataPrevYearTimePeriod}
          />
        )}
      </Grid>
    )
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, footerGroups } = useTable(
    {
      columns,
      data: mappedTableData,
      initialState: {
        sortBy: [
          {
            id: 'channel',
            desc: false
          }
        ]
      }
    },
    useFilters,
    useSortBy
  )

  return (
    <>
      <Grid style={{ minHeight: '600px' }}>
        <Typography variant="h2">{t('detailedReporting')}</Typography>
        <ReportTable
          t={t}
          basic={!hasComparisonModelBeenSelected}
          tableProps={{
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            renderFilters,
            footerGroups
          }}
          isLoading={isDataLoading || isCompareDetailedReportLoading}
          basicColumnsIds={[CHANNEL]}
          stickyColumnsIds={[CHANNEL]}
        />
      </Grid>
    </>
  )
}

export default DetailedReport
