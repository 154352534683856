import { Grid, styled, Typography, HistoryIcon, Banner, DashboardFrame } from '@precis-digital/kurama'
import { formatDateToString, getPreviousTimePeriod, getPreviousYearTimePeriod } from 'shared/dateFns'
import {
  useMutationAnalyticsAttribution,
  useMutationAnalyticsAttributionChannelAggregated,
  useMutationAnalyticsAttributionDates,
  useMutationAttributionConfigStatusById,
  useQueryIntegratedAttribution,
  useQueryIntegratedAttributions
} from 'attributionModel/api'
import PerformanceSummaryBlocks, { type AttributionModelPerformanceSummary } from './Summary'
import { useCallback, useEffect, useMemo, useState } from 'react'
import BarChartComparison from 'attributionModel/components/Dashboard/BarChartComparison'
import { DetailedReport } from 'attributionModel/components/Dashboard/DetailedReport'
import { useTranslation } from 'shared/translations'
import { useCurrentClient } from 'shared/context/ClientContext'
import {
  type AttributionModelType,
  getRevenueConversionsFieldFromModelType
} from 'attributionModel/components/Dashboard/DetailedReportUtils'
import ProgressOverTime from './ProgressOverTime'
import { capitalize, isClientMismatchError, transformCamelToSnakeCase, defaultToZero } from 'shared/utils'
import {
  computeAttributionDateRange,
  computeAttributionMissingDates,
  getDefaultDateRange,
  getMissingDataInDateRange,
  allowOutsideRange,
  DEFAULT_END_DATE,
  DEFAULT_START_DATE
} from './attributionDateUtils'
import { hasValidFilters } from './utils'
import {
  transformDataForComparisionTable,
  transformPeformanceData,
  transformDataForBarChart,
  transformDataforTimeSeries
} from 'attributionModel/transformations'
import { useQueryString } from 'shared/components/Router'
import { MoreMenuDropdownBudgetOptimiserDashboard } from 'budgetOptimiser'
import useDagView from 'shared/hooks/useDagView'
import StatusWithBadge from 'shared/components/StatusWithBadge'
import { type ConfigStatus, getErrorCodeDetails, getStatusMarketingEvaluation } from 'shared/configStatuses'
import NotFoundPage from 'shared/components/Error/NotFoundPage'
import Filter, { type ActiveFilter } from 'shared/components/Filter'
import { DIMENSION_OPERATORS_WITH_TYPES } from 'shared/constants/filterOperators'
import { uniq } from 'ramda'
import { APP_ROUTES } from 'shared/routes'
import FilterListItemLabel from 'shared/components/Filter/ListItemLabel'
import { MissingDatesWarningBanner } from './MissingDatesBanner'
import { GA4_EXCLUSION_LIST } from 'attributionModel/constants'
import {
  getAttributionChannelLevelQuery,
  getTimeSeriesQuery
} from 'attributionModel/components/Dashboard/cirrusQueries'
import { useQueryParameterState } from 'shared/hooks/useQueryParameterState'

interface DashboardProps {
  modelId: number
}

export type SelectMetricsType = 'revenue' | 'conversions'

export type GlobalFilter = Array<ActiveFilter<'channel'>>

const operatorOptions = {
  [DIMENSION_OPERATORS_WITH_TYPES.is.id]: DIMENSION_OPERATORS_WITH_TYPES.is,
  [DIMENSION_OPERATORS_WITH_TYPES.not_is.id]: DIMENSION_OPERATORS_WITH_TYPES.not_is,
  [DIMENSION_OPERATORS_WITH_TYPES.one_of.id]: DIMENSION_OPERATORS_WITH_TYPES.one_of,
  [DIMENSION_OPERATORS_WITH_TYPES.not_one_of.id]: DIMENSION_OPERATORS_WITH_TYPES.not_one_of
}

const Dashboard = ({ modelId }: DashboardProps): React.ReactElement => {
  const { currentClient } = useCurrentClient()
  const { showDagView, shouldShowDagView } = useDagView('attributionModel', true)
  const { query } = useQueryString()

  const {
    data: integratedAttribution,
    isLoading: isLoadingIntegratedAttribution,
    isSuccess: isSuccessIntegratedAttribution,
    isError: isErrorIntegratedAttribution,
    error: errorIntegratedAttribution
  } = useQueryIntegratedAttribution(modelId, currentClient.id)

  const isBudgetOptimiserOnlyConfig = integratedAttribution?.creditAllocator === false

  const {
    mutate: getAttributionConfigStatus,
    data: integratedAttributionStatus,
    isLoading: isLoadingintegratedAttributionStatus
  } = useMutationAttributionConfigStatusById()

  const { isLoading: isLoadingIntegratedAttributions } = useQueryIntegratedAttributions(currentClient.id)

  useEffect(() => {
    if (modelId !== 0 && !isLoadingintegratedAttributionStatus && integratedAttributionStatus == null) {
      getAttributionConfigStatus({ clientId: currentClient.id, modelId })
    }
  }, [
    currentClient.id,
    modelId,
    isLoadingintegratedAttributionStatus,
    integratedAttributionStatus,
    getAttributionConfigStatus
  ])

  let status: ConfigStatus = 'unknown'

  if (integratedAttributionStatus != null && integratedAttribution != null) {
    status = getStatusMarketingEvaluation(integratedAttribution?.runSchedule, integratedAttributionStatus[0]?.status)
  }

  const { t: tAttributionModels } = useTranslation('attributionModel')
  const { t: tCommon } = useTranslation('common')

  if (
    isSuccessIntegratedAttribution &&
    integratedAttribution != null &&
    integratedAttributionStatus != null &&
    shouldShowDagView(query)
  ) {
    showDagView({
      configId: integratedAttribution.id,
      configName: integratedAttribution.name,
      isActive: integratedAttribution.runSchedule,
      configStatus: status
    })
  }

  /// this is from raw report...
  // const {mutate: getRawData, data: rawData = [], isLoading: isRawDataLoading} = useMutationAttributionReport()
  const {
    mutate: getAttributionDates,
    data: attributionDates = [],
    isLoading: isAttributionDatesLoading,
    isSuccess: isSuccessAttributionDates,
    isIdle: isAttributionDatesRequestIdle
  } = useMutationAnalyticsAttributionDates()

  const {
    mutate: getAttributionData,
    data: attributionData = [],
    isLoading: isAttributionDataLoading,
    isIdle: isAttributionDataRequestIdle
  } = useMutationAnalyticsAttributionChannelAggregated()

  const {
    mutate: getAttributionDataPreviousTimePeriod,
    data: attributionDataPreviousTimePeriod = [],
    isLoading: isAttributionDataPreviousTimePeriodLoading,
    isIdle: isAttributionDataPreviousTimePeriodRequestIdle
  } = useMutationAnalyticsAttributionChannelAggregated()

  const {
    mutate: getAttributionDataPreviousYearTimePeriod,
    data: attributionDataPreviousYearTimePeriod = [],
    isLoading: isAttributionDataPreviousYearTimePeriodLoading,
    isIdle: isAttributionDataPreviousYearTimePeriodRequestIdle
  } = useMutationAnalyticsAttributionChannelAggregated()

  const {
    mutate: getTimeSeriesData,
    data: timeSeriesData = [],
    isLoading: isTimeSeriesDataLoading,
    isIdle: isTimeSeriesDataRequestIdle
  } = useMutationAnalyticsAttribution()

  // loading condition for the dashboard
  const isAttributionLoaded = !(
    // dont render until the data is loaded, but also check if the data is empty
    (
      isAttributionDatesRequestIdle ||
      isAttributionDatesLoading ||
      isAttributionDataRequestIdle ||
      isAttributionDataPreviousTimePeriodRequestIdle ||
      isAttributionDataPreviousYearTimePeriodRequestIdle ||
      isLoadingIntegratedAttribution ||
      isAttributionDataLoading ||
      isAttributionDataPreviousTimePeriodLoading ||
      isAttributionDataPreviousYearTimePeriodLoading ||
      isLoadingIntegratedAttributions
    )
  )

  const isTimeSeriesDataLoaded = !(isTimeSeriesDataLoading || isTimeSeriesDataRequestIdle)

  const performanceSummaryData: AttributionModelPerformanceSummary = useMemo(() => {
    // aggreaate attribution data to get total spend and total conversions revenue
    return transformPeformanceData(integratedAttribution, attributionData)
  }, [integratedAttribution, attributionData])

  const performanceSummaryDataPrevTimePeriod: AttributionModelPerformanceSummary = useMemo(() => {
    return transformPeformanceData(integratedAttribution, attributionDataPreviousTimePeriod)
  }, [attributionDataPreviousTimePeriod, integratedAttribution])

  const attributionDateRange: { minDate: Date; maxDate: Date } = computeAttributionDateRange(attributionDates)
  const missingDates: string[] = computeAttributionMissingDates(attributionDates)

  useEffect(() => {
    const attributionDateField = [{ name: 'date', aggregation: 'DISTINCT' }]
    const modelFilter =
      integratedAttribution != null && !isBudgetOptimiserOnlyConfig
        ? [
            {
              field: transformCamelToSnakeCase(
                getRevenueConversionsFieldFromModelType(integratedAttribution.creditInput).conversions
              ),
              operator: 'IS NOT NULL'
            }
          ]
        : []

    integratedAttribution != null &&
      getAttributionDates({
        id: integratedAttribution.id.toString(),
        collection: 'attribution',
        report: 'attribution',
        clientId: currentClient.id,
        fields: attributionDateField,
        filters: modelFilter
      })
  }, [
    integratedAttribution?.id,
    currentClient.id,
    integratedAttribution?.creditInput,
    integratedAttribution,
    getAttributionDates,
    isBudgetOptimiserOnlyConfig
  ])

  // This is initially set to the default date range, but will be updated when the api fetches the data for the default date range

  // state that is stored in the url
  const [selectedFilters, setSelectedFilters] = useQueryParameterState<GlobalFilter>('q', [])
  const [selectedMetrics, setSelectedMetrics] = useQueryParameterState<SelectMetricsType>('m', 'revenue')

  const [[startDate, endDate], setDateRange] = useQueryParameterState<string[]>(
    'd',
    [
      formatDateToString({ date: DEFAULT_START_DATE, targetFormat: 'yyyy-MM-dd' }),
      formatDateToString({ date: DEFAULT_END_DATE, targetFormat: 'yyyy-MM-dd' })
    ],
    { removeWhenDefault: false }
  )

  const [missingDatesInDateRange, setMissingDatesInDateRange] = useState<string[]>([])
  const [missingDatesInComparisonDateRange, setMissingDatesInComparisonDateRange] = useState<string[]>([])
  const [hasConversionsButNoRevenue, setHasConversionsButNoRevenue] = useState<boolean>(false)

  const { calculatedStartDate, calculatedEndDate } = getDefaultDateRange(
    attributionDateRange,
    new Date(startDate),
    new Date(endDate)
  )

  // this is the first problem... should only update this on load...
  // you should only update the start and end date if the calculated start and end date need to be changed
  // so can set sane defaults and then need to check if those defaults dont work with the attribution data
  useEffect(() => {
    setDateRange([calculatedStartDate, calculatedEndDate])
  }, [calculatedStartDate, calculatedEndDate, setDateRange])

  const handleDateChange = useCallback(
    (startDate: string, endDate: string, currencyCode: string, modelType: AttributionModelType): void => {
      if (integratedAttribution != null) {
        getAttributionData(
          getAttributionChannelLevelQuery(
            currentClient.id,
            modelId,
            startDate,
            endDate,
            currencyCode,
            modelType,
            selectedFilters
          )
        )

        getAttributionDataPreviousTimePeriod(
          getAttributionChannelLevelQuery(
            currentClient.id,
            modelId,
            getPreviousTimePeriod(startDate, endDate)[0],
            getPreviousTimePeriod(startDate, endDate)[1],
            currencyCode,
            modelType,
            selectedFilters
          )
        )

        getAttributionDataPreviousYearTimePeriod(
          getAttributionChannelLevelQuery(
            currentClient.id,
            modelId,
            getPreviousYearTimePeriod(startDate, endDate)[0],
            getPreviousYearTimePeriod(startDate, endDate)[1],
            currencyCode,
            modelType,
            selectedFilters
          )
        )

        getTimeSeriesData(
          getTimeSeriesQuery(currentClient.id, modelId, startDate, endDate, currencyCode, modelType, selectedFilters)
        )
      }
    },
    [
      integratedAttribution,
      getAttributionData,
      currentClient.id,
      modelId,
      getAttributionDataPreviousTimePeriod,
      getAttributionDataPreviousYearTimePeriod,
      getTimeSeriesData,
      selectedFilters
    ]
  )

  useEffect(() => {
    if (
      // attributionDateSuccessOrError &&
      isSuccessIntegratedAttribution &&
      startDate != null &&
      endDate != null
    ) {
      handleDateChange(startDate, endDate, currentClient.currency, integratedAttribution.creditInput)
    }
  }, [
    isSuccessIntegratedAttribution,
    endDate,
    startDate,
    currentClient,
    handleDateChange,
    selectedFilters,
    integratedAttribution
  ])

  const modelType: AttributionModelType = integratedAttribution?.creditInput
  const lastEditedAt =
    integratedAttribution != null
      ? formatDateToString({
          date: integratedAttribution?.updatedAt
        })
      : ''

  useEffect(() => {
    if (
      GA4_EXCLUSION_LIST.includes(currentClient.id.toString()) ||
      (performanceSummaryData.revenue === 0 && performanceSummaryData.conversions > 0)
    ) {
      setHasConversionsButNoRevenue(true)
    }
  }, [currentClient.id, performanceSummaryData])

  const pausedConfig = integratedAttribution?.runSchedule === false

  const metricToShow = hasConversionsButNoRevenue ? 'conversions' : selectedMetrics

  const canFilter = attributionData.length > 0

  const showMissingDatesBanner =
    (missingDatesInDateRange != null && missingDatesInDateRange.length > 0) ||
    (missingDatesInComparisonDateRange != null && missingDatesInComparisonDateRange.length > 0)

  const isMissingDataPrevTimePeriod = defaultToZero(attributionDataPreviousTimePeriod?.length) === 0
  const isMissingDataPrevYearTimePeriod = defaultToZero(attributionDataPreviousYearTimePeriod?.length) === 0

  if (isErrorIntegratedAttribution && isClientMismatchError(errorIntegratedAttribution)) {
    return <NotFoundPage hasDashboardFrame returnPath={APP_ROUTES.attributionModels.basePage} />
  }

  if (isBudgetOptimiserOnlyConfig) {
    return <NotFoundPage hasDashboardFrame returnPath={APP_ROUTES.attributionModels.basePage} />
  }

  return (
    <DashboardFrame
      header={
        isLoadingIntegratedAttribution ? null : (
          <>
            {showMissingDatesBanner && (
              <Grid marginBottom="23px">
                <MissingDatesWarningBanner
                  selected={missingDatesInDateRange}
                  comparison={missingDatesInComparisonDateRange}
                />
              </Grid>
            )}
            {pausedConfig ? (
              <Grid marginBottom="30px">
                <Banner
                  LinkProps={{
                    href: APP_ROUTES.attributionModels.editConfigPage({ configId: `${modelId}` })
                  }}
                  variant="info"
                >
                  <span>{tAttributionModels('dashboard.banner')}</span>
                </Banner>
              </Grid>
            ) : null}

            <StyledHeader variant="h4">
              {modelType != null
                ? tAttributionModels(`dashboard.titles.${modelType}`) ?? tAttributionModels(`dashboard.titles.default`)
                : ''}
            </StyledHeader>

            <StyledModelName variant="h1">
              {integratedAttribution != null ? capitalize(integratedAttribution?.name) : ''}
            </StyledModelName>
            <StyledLastEdited>
              <HistoryIcon />
              <Typography variant="body2">
                {' '}
                {integratedAttribution != null
                  ? tCommon('lastEditedAt', {
                      date: lastEditedAt
                    })
                  : ''}
              </Typography>
            </StyledLastEdited>
          </>
        )
      }
      toolbar={
        integratedAttribution == null || !isAttributionLoaded ? null : (
          <StyledCalendarWrapper>
            <StatusWithBadge
              isLoading={isLoadingintegratedAttributionStatus}
              typographyVariant="h4"
              status={status}
              errorCode={integratedAttributionStatus?.[0]?.errorCode}
              errorDetails={getErrorCodeDetails(
                integratedAttributionStatus?.[0]?.errorCode,
                integratedAttributionStatus?.[0]?.errorMessage,
                integratedAttributionStatus?.[0]?.targetProjectId,
                tCommon('dagView.attributionModel')
              )}
              lastUpdated={integratedAttribution?.updatedAt}
              dagViewInformation={{
                dagViewType: 'attributionModel',
                configId: modelId,
                configName: integratedAttribution.name,
                isActive: integratedAttribution.runSchedule,
                isPageWithConfigId: true
              }}
            />
            {canFilter && (
              <Filter
                DateRangeCalendarProps={
                  isSuccessAttributionDates
                    ? {
                        displayFormat: 'MMM Do, YYYY',
                        onDateRangeSelected: (startDate, endDate) => {
                          if (startDate != null && endDate != null) {
                            setDateRange([startDate, endDate])
                            const missingDatesInDateRange = getMissingDataInDateRange(startDate, endDate, missingDates)
                            setMissingDatesInDateRange(missingDatesInDateRange)
                            const [prevTimePeriodStart, prevTimePeriodEnd] = getPreviousTimePeriod(startDate, endDate)
                            const missingDatesInComparisonDateRange = getMissingDataInDateRange(
                              prevTimePeriodStart,
                              prevTimePeriodEnd,
                              missingDates,
                              computeAttributionDateRange(attributionDates)
                            )
                            setMissingDatesInComparisonDateRange(missingDatesInComparisonDateRange)
                          }
                        },
                        dateRangeSelectedFormat: 'YYYY-MM-DD',
                        onCancel: () => {},
                        defaultStartDate: startDate,
                        defaultEndDate: endDate,
                        cancelText: tAttributionModels('buttons.cancel'),
                        applyText: tAttributionModels('buttons.applyDateRange'),
                        isOutsideRange: (date) => allowOutsideRange(date, attributionDateRange, missingDates)
                      }
                    : null
                }
                operatorOptions={operatorOptions}
                parameterOptions={[
                  {
                    value: 'channel',
                    label: 'Channel',
                    options: uniq(
                      attributionData.map((data) => ({
                        value: data.channel,
                        label: data.channel
                      }))
                    )
                  }
                ]}
                activeFilters={selectedFilters}
                onUpdateFilters={setSelectedFilters}
                parseFilterToChipLabel={(filter) => {
                  const operator = operatorOptions[filter.operator].label?.toLowerCase()

                  let value: string = ''
                  if (Array.isArray(filter.data)) {
                    value = filter.data.map((item) => item.label ?? item.value).join(', ')
                  } else {
                    value = typeof filter.data === 'string' ? filter.data : filter.data.label
                  }
                  return `${filter.parameter.label} ${operator} ${value?.toString() ?? ''}`
                }}
                renderListItemLabel={(filter) => {
                  return <FilterListItemLabel filter={filter} includeText={tAttributionModels('filter.include')} />
                }}
                t={tAttributionModels}
              />
            )}

            <Grid>
              <MoreMenuDropdownBudgetOptimiserDashboard
                configId={integratedAttribution.id}
                channelGroupingId={integratedAttribution.channelGroupingId}
                moreButton={capitalize(tAttributionModels('dashboard.more'))}
                editButton={tAttributionModels('dashboard.editModel')}
                createButton={tAttributionModels('buttons.createNewModel')}
                viewDetailedStatusButton={tAttributionModels('dashboard.viewDetailedStatus')}
                path="attribution-models"
                configName={integratedAttribution.name}
                isActive={integratedAttribution.runSchedule}
                configStatus={status}
                showMetricButton={
                  metricToShow === 'conversions' && !hasConversionsButNoRevenue
                    ? tAttributionModels('buttons.showRevenue')
                    : !hasConversionsButNoRevenue
                    ? tAttributionModels('buttons.showConversions')
                    : null
                }
                onSelectMetric={() => {
                  setSelectedMetrics(metricToShow === 'revenue' ? 'conversions' : 'revenue')
                }}
                isPageWithConfigId
              />
            </Grid>
          </StyledCalendarWrapper>
        )
      }
      summary={
        !isAttributionLoaded ? null : (
          // we can clean up this component signature so we can pass in the data directly
          <PerformanceSummaryBlocks
            roas={performanceSummaryData?.roas}
            revenue={performanceSummaryData?.revenue}
            spend={performanceSummaryData?.adSpend}
            costPerAction={performanceSummaryData?.costPerAction}
            conversions={performanceSummaryData?.conversions}
            startDate={startDate}
            endDate={endDate}
            selectedMetrics={metricToShow}
            roasPrevTimePeriod={performanceSummaryDataPrevTimePeriod?.roas}
            revenuePrevTimePeriod={performanceSummaryDataPrevTimePeriod?.revenue}
            spendPrevTimePeriod={performanceSummaryDataPrevTimePeriod?.adSpend}
            costPerActionPrevTimePeriod={performanceSummaryDataPrevTimePeriod?.costPerAction}
            conversionPrevTimePeriod={performanceSummaryDataPrevTimePeriod?.conversions}
            currencyCode={currentClient.currency}
          />
        )
      }
      content={[
        !isAttributionLoaded ? null : (
          <BarChartComparison
            currentReport={transformDataForBarChart(attributionData, integratedAttribution)}
            isDataLoaded={isAttributionLoaded}
            selectedMetrics={metricToShow}
            currencyCode={currentClient.currency}
            currentModelType={modelType}
            hasValidFilter={isSuccessIntegratedAttribution && hasValidFilters(integratedAttribution)}
            configName={capitalize(integratedAttribution?.name ?? 'current')}
          />
        ),
        !isAttributionLoaded ? null : (
          <DetailedReport
            isDataLoading={!isAttributionLoaded}
            dateRange={{ startDate, endDate }}
            currentIntegratedAttribution={integratedAttribution}
            data={transformDataForComparisionTable(attributionData, integratedAttribution?.creditInput)}
            attributionData={attributionData} // pass in the raw data, so you can show the Comparison to LNDA without making another call
            dataPrevTimePeriod={transformDataForComparisionTable(
              attributionDataPreviousTimePeriod,
              integratedAttribution?.creditInput
            )}
            isMissingDataPrevTimePeriod={isMissingDataPrevTimePeriod}
            dataPrevYearTimePeriod={transformDataForComparisionTable(
              attributionDataPreviousYearTimePeriod,
              integratedAttribution?.creditInput
            )}
            isMissingDataPrevYearTimePeriod={isMissingDataPrevYearTimePeriod}
            currencyCode={currentClient.currency}
            hasConversionsButNoRevenue={hasConversionsButNoRevenue}
            selectedFilters={selectedFilters}
          />
        ),
        !isTimeSeriesDataLoaded ? null : (
          <ProgressOverTime
            reports={transformDataforTimeSeries(timeSeriesData, integratedAttribution)}
            isDataLoaded={isTimeSeriesDataLoaded}
            selectedMetrics={metricToShow}
            currencyCode={currentClient.currency}
          />
        )
      ]}
    />
  )
}
export default Dashboard

const StyledHeader = styled(Typography)(() => ({
  marginBottom: '23px'
}))

const StyledCalendarWrapper = styled(Grid)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

const StyledModelName = styled(Typography)(() => ({
  marginBottom: '8px'
}))

const StyledLastEdited = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'inline-flex'
}))
