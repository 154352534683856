import {
  Grid,
  Typography,
  defaultTheme as theme,
  styled,
  type MultiSelectDropdownItem,
  Banner
} from '@precis-digital/kurama'
import React, { useEffect, useMemo, useState } from 'react'
import { format, subMonths } from 'date-fns'
import { useTranslation } from 'shared/translations'
import { useAuth } from 'shared/context/AuthContext'
import { RecommendationFrame, useFeed } from 'recommendations'
import ProgressOverTime from './ProgressOvertime'
import OverScreen from 'shared/overScreens'
import { useMutationStandardReportStatuses, useQueryStandardReports } from 'home/api'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useQueryString } from 'shared/components/Router'
import { getPreviousTimePeriod, getPeriodOfTimeToday, subDays, getPreviousYearTimePeriod } from 'shared/dateFns'
import { flattenHomePageStatsData, formatFeedDataForMimir, generateHomePageContext } from 'home/mimirUtils'
import MimirTriggerButton from 'shared/components/MimirTriggerButton'
import { useMimir } from 'shared/context/MimirContext'
import { type ActiveFilter } from 'shared/components/Filter'
import Layout from './Layout'
import Block from 'shared/components/Block'
import { useQueryClientAccounts } from 'dataSource/api'
import { type TopCardMetricsFlyoutProps } from './TopCardMetricsFlyout'
import { type EventOption, type ConversionEventsFlyoutProps } from './ConversionEventsFlyout'
import {
  type ConfigStatus,
  type ErrorCodeType,
  type ErrorDetails,
  STATUSES,
  getErrorCodeDetails,
  getStatusFromAPIStatusData
} from 'shared/configStatuses'
import {
  checkIfFilterConditionIsSatisfied,
  checkIfReportTemplateWithNoConversionEvents,
  checkIfValidStatusForShowingHomePageData,
  getSelectedStandardReportConfig,
  checkIfReportConfigAndStatusLoaded,
  checkIfReportDataLoadingViewConditionSatisfied,
  checkIfCustomContentConditionSatisfied,
  checkIfToolbarConditionSatisfied,
  transformFiltersToAPIFormat,
  checkIfReportDataUnknownErrorViewConditionSatisfied,
  checkIfNoReportDataViewConditionSatisfied,
  checkIfReportDataKnownErrorViewConditionSatisfied
} from './utils'
import ReportDataLoading from './ReportDataLoading'
import ReportDataError from './ReportDataError'
import { useHomePageData } from 'home/hooks/useHomePageData'
import {
  type Report,
  transformToFilters,
  type TransformToTimeSeriesByMetricAndBreakdownFn,
  type TransformToDetailedDataFn,
  type Filters
} from 'home/transformations'
import { convertSnakeCaseToCamelCase, objectKeys } from 'shared/utils'
import NoReport from './NoReport'
import DetailedReport from './DetailedReport'
import Summary from './Summary'
import { type ReportType } from 'shared/api/analytics'
import { APP_ROUTES } from 'shared/routes'
import { type Metric, reportsTypeParametersMap } from 'home/reportTemplateParameters'
import { type ReportingBaseMetric } from 'home/reportTemplateParameters/reportingBase'
import { type ChannelGroupingMetric } from 'home/reportTemplateParameters/channelGrouping'
import { type ReportConfig } from './SwitchReportFlyout'
import { LOCAL_STORAGE_KEYS, get as getFromStore, store as saveToStore } from 'shared/lstore'
import {
  CONVERSION_EVENT_DEFAULT,
  REPORT_TEMPLATE_WITH_CONVERSION_EVENTS,
  STATUSES_TO_SHOW_BANNER_FOR
} from 'home/constants'
import NoData from './NoData'
import Toolbar from './Toolbar'
import useDagView from 'shared/hooks/useDagView'
export type TimePeriodType = 'previous_year' | 'previous_period'

const defaultFilters: Filters = {
  markets: new Set(),
  channels: new Set()
}

const Dashboard = (): React.ReactElement => {
  const { t } = useTranslation('home')
  const { t: tCommon } = useTranslation('common')
  const { t: tRecommendation } = useTranslation('recommendation')
  const {
    query: { reportId: queryReportId, clientId: queryClientId },
    pushQuery
  } = useQueryString()

  const { currentClient } = useCurrentClient()
  const { currentUser } = useAuth()
  const { setDataContext } = useMimir()
  const {
    data: dataSources,
    isLoading: isLoadingDataSources,
    isSuccess: isDataSourcesSuccess
  } = useQueryClientAccounts(currentClient.id)
  const {
    cards: content,
    feedData = [],
    groupedData,
    cardsContainerRef
  } = useFeed({
    currentClient,
    dataSources: {
      data: dataSources,
      isLoading: isLoadingDataSources,
      isSuccess: isDataSourcesSuccess
    },

    options: {
      filters: [{ field: 'timestamp', operator: '>', value: format(subDays(new Date(), 2), 'yyyy-MM-dd') }]
    }
  })
  const [userSelectedPeriodType, setUserSelectedPeriodType] = useState<TimePeriodType>('previous_period')

  const [periodTypeToApply, setPeriodTypeToApply] = useState<TimePeriodType>(userSelectedPeriodType)

  const defaultEndDate = format(subDays(new Date(), 1), 'yyyy-MM-dd')
  const defaultStartDate = format(subMonths(new Date(), 1), 'yyyy-MM-dd')
  const [selectedDate, setSelectedDate] = useState({
    startDate: defaultStartDate,
    endDate: defaultEndDate
  })

  const [selectedFilters, setSelectedFilters] = useState<Array<ActiveFilter<'market' | 'channel'>>>([])
  const [performanceDataContextForMimir, setPerformanceDataContextForMimir] = useState<string | undefined>(undefined)
  const [eventOptions, setEventOptions] = useState<EventOption[]>()

  const [selectedTopCardMetrics, setSelectedTopCardMetrics] = useState<Metric[]>([])

  const [selectedConversionEvents, setSelectedConversionEvents] = useState<MultiSelectDropdownItem[]>()

  const [hasExistingConversionEvent, setHasExistingConversionEvent] = useState<boolean>(false)
  const [selectedStandardReportConfigId, setSelectedStandardReportConfigId] = useState<string | undefined>(
    queryReportId as string
  )

  const previousTimePeriod = useMemo(() => {
    return periodTypeToApply === 'previous_period'
      ? getPreviousTimePeriod(selectedDate.startDate, selectedDate.endDate)
      : getPreviousYearTimePeriod(selectedDate.startDate, selectedDate.endDate)
  }, [periodTypeToApply, selectedDate.endDate, selectedDate.startDate])

  const { data: standardReportsConfigs = [], isSuccess: hasSuccessfullyLoadedStandardReportsConfig } =
    useQueryStandardReports(currentClient.id)

  const {
    data: reportConfigStatuses,
    mutate: getStandardReportStatuses,
    isLoading: isLoadingReportStatuses,
    isIdle: getStandardReportStatusesRequestIsIdle
  } = useMutationStandardReportStatuses()

  const {
    getHomePageData,
    resetHomePageData,
    reportDataAnalytics,
    previousDataAnalytics,
    totalsGroupedByDate,
    totalsData,
    previousTotalsData,
    isDataLoaded: isHomePageDataLoaded,
    isDataError,
    isDataNotFoundError,
    isFilterOptionsLoaded,
    filterOptionsData,
    isConversionNamesAndSourcesLoaded,
    getFilterOptions,
    getConversionNamesAndSources,
    conversionNameAndSources,
    isConversionNamesAndSourcesError
  } = useHomePageData()

  const standardReportConfigsFilteredByTemplateId = useMemo(() => {
    if (standardReportsConfigs.length > 0) {
      return standardReportsConfigs.filter((config) => objectKeys(reportsTypeParametersMap).includes(config.templateId))
    }
    return []
  }, [standardReportsConfigs])

  const selectedStandardReportConfig = getSelectedStandardReportConfig(
    standardReportConfigsFilteredByTemplateId,
    selectedStandardReportConfigId ??
      getFromStore(LOCAL_STORAGE_KEYS.SELECTED_REPORT_ID_FOR_CLIENT_HOMEPAGE(currentClient.id))
  )

  const isReportConfigAndStatusLoaded = checkIfReportConfigAndStatusLoaded({
    hasSuccessfullyLoadedStandardReportsConfig,
    isLoadingReportStatuses,
    getStandardReportStatusesRequestIsIdle
  })

  useEffect(() => {
    if (selectedStandardReportConfig != null && queryClientId === selectedStandardReportConfig.clientId.toString()) {
      if (queryReportId == null || queryReportId !== selectedStandardReportConfig.configId.toString()) {
        void pushQuery({
          reportId: selectedStandardReportConfig.configId.toString()
        })
      } else if (hasSuccessfullyLoadedStandardReportsConfig && selectedStandardReportConfig == null) {
        void pushQuery({}, true)
      }
    }
  }, [
    hasSuccessfullyLoadedStandardReportsConfig,
    pushQuery,
    queryClientId,
    queryReportId,
    selectedStandardReportConfig
  ])

  const reportParameters = useMemo(() => {
    if (selectedStandardReportConfig != null) {
      return reportsTypeParametersMap[selectedStandardReportConfig.templateId]
    }
  }, [selectedStandardReportConfig])

  useEffect(() => {
    getStandardReportStatuses(currentClient.id)
  }, [currentClient.id, getStandardReportStatuses])

  useEffect(() => {
    if (selectedStandardReportConfig != null) {
      saveToStore(
        LOCAL_STORAGE_KEYS.SELECTED_REPORT_ID_FOR_CLIENT_HOMEPAGE(currentClient.id),
        selectedStandardReportConfig.configId.toString()
      )

      const periodType = (getFromStore(
        LOCAL_STORAGE_KEYS.HOME_PAGE_COMPARISON_PERIOD_SELECTION(selectedStandardReportConfig.configId.toString())
      ) ?? 'previous_period') as TimePeriodType

      setUserSelectedPeriodType(periodType)
      setPeriodTypeToApply(periodType)
    }
  }, [currentClient.id, selectedStandardReportConfig])

  useEffect(() => {
    if (selectedStandardReportConfig != null && reportParameters != null) {
      getFilterOptions({
        clientId: currentClient.id,
        targetCurrencyCode: currentClient.currency,
        id: selectedStandardReportConfig.configId.toString(),
        report: selectedStandardReportConfig.templateId as ReportType,
        filters: reportParameters.customDataFilters ?? [],
        ...reportParameters.filterVariables
      })

      setHasExistingConversionEvent(
        REPORT_TEMPLATE_WITH_CONVERSION_EVENTS.includes(selectedStandardReportConfig.templateId) &&
          reportParameters.getSelectedConversionEvents?.(selectedStandardReportConfig.configId.toString()) != null
      )

      setSelectedTopCardMetrics(reportParameters.getSelectedTopCardMetrics())
    }
  }, [currentClient.currency, currentClient.id, getFilterOptions, reportParameters, selectedStandardReportConfig])

  useEffect(() => {
    if (
      selectedStandardReportConfig != null &&
      REPORT_TEMPLATE_WITH_CONVERSION_EVENTS.includes(selectedStandardReportConfig.templateId) &&
      reportParameters != null &&
      reportParameters.getSelectedConversionEvents?.(selectedStandardReportConfig.configId.toString()) == null
    ) {
      getConversionNamesAndSources({
        clientId: currentClient.id,
        targetCurrencyCode: currentClient.currency,
        id: selectedStandardReportConfig.configId.toString(),
        report: selectedStandardReportConfig.templateId as ReportType,
        filters: [
          {
            field: 'date',
            operator: 'BETWEEN',
            value: [selectedDate.startDate, selectedDate.endDate]
          }
        ],
        ...reportParameters.conversionEventVariables
      })
    }
  }, [
    currentClient.currency,
    currentClient.id,
    getConversionNamesAndSources,
    reportParameters,
    selectedDate.endDate,
    selectedDate.startDate,
    selectedStandardReportConfig
  ])

  const isReportTemplateWithNoConversionEvents =
    checkIfReportTemplateWithNoConversionEvents(selectedStandardReportConfig)

  useEffect(() => {
    if (
      selectedStandardReportConfig != null &&
      reportParameters != null &&
      (isReportTemplateWithNoConversionEvents || selectedConversionEvents != null)
    ) {
      getHomePageData({
        currentTimePeriod: [selectedDate.startDate, selectedDate.endDate],
        previousTimePeriod,
        clientId: currentClient.id,
        reportId: selectedStandardReportConfig.configId,
        currency: currentClient.currency,
        reportType: selectedStandardReportConfig.templateId as ReportType,
        reportAnalyticsVariables: reportParameters.reportAnalyticsVariables,
        totalVariables: reportParameters.totalsVariables,
        customFilters: [
          ...transformFiltersToAPIFormat({
            selectedMarketAndChannelFilters: selectedFilters,
            selectedConversionEvents
          }),
          ...(reportParameters.customDataFilters ?? [])
        ]
      })
    }
  }, [
    currentClient.currency,
    currentClient.id,
    getHomePageData,
    isReportTemplateWithNoConversionEvents,
    previousTimePeriod,
    reportParameters,
    selectedConversionEvents,
    selectedDate.endDate,
    selectedDate.startDate,
    selectedFilters,
    selectedStandardReportConfig
  ])

  const isConversionNamesAndSourcesSuccessfullyLoaded = isConversionNamesAndSourcesLoaded

  useEffect(() => {
    if (
      selectedStandardReportConfig != null &&
      REPORT_TEMPLATE_WITH_CONVERSION_EVENTS.includes(selectedStandardReportConfig.templateId) &&
      (isConversionNamesAndSourcesSuccessfullyLoaded || hasExistingConversionEvent) &&
      reportParameters != null
    ) {
      const options = conversionNameAndSources?.map((conversionNameAndSource) => {
        return {
          label: conversionNameAndSource.conversionName,
          value: conversionNameAndSource.conversionName.toString(),
          subCategory: conversionNameAndSource.conversionSource,
          numberOfConversions: conversionNameAndSource.sumConversions
        }
      })

      options != null && setEventOptions(options)

      const defaultConversionEvent =
        options?.find(
          (option) =>
            option.value === CONVERSION_EVENT_DEFAULT.name && option.subCategory === CONVERSION_EVENT_DEFAULT.source
        ) ?? options?.[0]
      const defaultConversionEvents = defaultConversionEvent != null ? [defaultConversionEvent] : []

      const conversionEvents =
        reportParameters.getSelectedConversionEvents?.(selectedStandardReportConfig.configId.toString()) ??
        defaultConversionEvents

      setSelectedConversionEvents(conversionEvents)

      reportParameters.storeSelectedConversionEvents?.(
        selectedStandardReportConfig.configId.toString(),
        conversionEvents
      )
    }
  }, [
    conversionNameAndSources,
    hasExistingConversionEvent,
    isConversionNamesAndSourcesSuccessfullyLoaded,
    reportParameters,
    selectedStandardReportConfig
  ])

  const reports: Report[] = useMemo(() => {
    if (reportDataAnalytics.length > 0 && reportParameters != null) {
      return reportParameters.transformations.transformToReport(reportDataAnalytics)
    }
    return []
  }, [reportDataAnalytics, reportParameters])

  const reportsForPreviousTimePeriod: Report[] = useMemo(() => {
    if (previousDataAnalytics.length > 0 && reportParameters != null) {
      return reportParameters?.transformations.transformToReport(previousDataAnalytics)
    }
    return []
  }, [previousDataAnalytics, reportParameters])

  const allFilters: Filters = useMemo(() => {
    if (filterOptionsData.length > 0 && reportParameters != null) {
      const mappedFilterOptions = filterOptionsData.map((filter) => {
        const marketFieldVariableName = convertSnakeCaseToCamelCase(
          reportParameters.marketFieldVariableName
        ) as keyof typeof filter
        const channelFieldVariableName = convertSnakeCaseToCamelCase(
          reportParameters.channelFieldVariableName
        ) as keyof typeof filter

        return {
          market: filter[marketFieldVariableName] as string,
          channel: filter[channelFieldVariableName] as string
        }
      })

      return transformToFilters(mappedFilterOptions)
    }
    return defaultFilters
  }, [filterOptionsData, reportParameters])

  const {
    selectedReportStatus,
    selectedReportErrorCode,
    selectedReportErrorDetails,
    selectedReportStatusMessage,
    selectedReportStatusLastUpdated
  } = useMemo(() => {
    const statusInfo: {
      selectedReportStatus?: ConfigStatus
      selectedReportErrorCode?: ErrorCodeType | null
      selectedReportErrorDetails?: ErrorDetails | null
      selectedReportStatusMessage?: string | null
      selectedReportStatusLastUpdated?: string | null
    } = {
      selectedReportStatus: undefined,
      selectedReportErrorCode: undefined,
      selectedReportErrorDetails: undefined,
      selectedReportStatusMessage: undefined,
      selectedReportStatusLastUpdated: undefined
    }
    if (selectedStandardReportConfig == null || !isReportConfigAndStatusLoaded) {
      return statusInfo
    }

    const selectedReportConfigStatus = reportConfigStatuses?.find(
      (status) => status.configId === selectedStandardReportConfig.configId.toString()
    )
    statusInfo.selectedReportStatus = getStatusFromAPIStatusData(
      selectedStandardReportConfig.runSchedule,
      selectedReportConfigStatus?.status
    )
    statusInfo.selectedReportStatusMessage = selectedReportConfigStatus?.message
    statusInfo.selectedReportErrorCode = selectedReportConfigStatus?.errorCode
    statusInfo.selectedReportErrorDetails = getErrorCodeDetails(
      selectedReportConfigStatus?.errorCode,
      selectedReportConfigStatus?.errorMessage,
      selectedReportConfigStatus?.targetProjectId,
      tCommon('dagView.reportingSolution')
    )
    statusInfo.selectedReportStatusLastUpdated = selectedReportConfigStatus?.lastUpdated

    return statusInfo
  }, [isReportConfigAndStatusLoaded, reportConfigStatuses, selectedStandardReportConfig, tCommon])

  const isValidStatusForShowingHomePageData = checkIfValidStatusForShowingHomePageData(selectedReportStatus)

  const { query } = useQueryString()
  const { showDagView, shouldShowDagView } = useDagView('reportingSolution', true)
  const shouldShowDagViewPopup = shouldShowDagView(query)

  if (selectedStandardReportConfig != null && shouldShowDagViewPopup && selectedReportStatus != null) {
    showDagView({
      configId: selectedStandardReportConfig.configId,
      configName: selectedStandardReportConfig.name,
      isActive: selectedStandardReportConfig.runSchedule,
      configStatus: selectedReportStatus
    })
  }

  const canFilter = checkIfFilterConditionIsSatisfied(isValidStatusForShowingHomePageData, filterOptionsData.length)

  const parameterOptions = useMemo(() => {
    return reportParameters != null
      ? [
          {
            value: reportParameters.marketFieldVariableName,
            label: t('filter.market'),
            options: Array.from(allFilters?.markets ?? []).map((market) => ({
              value: market.id,
              label: market.name
            }))
          },
          {
            value: reportParameters.channelFieldVariableName,
            label: t('filter.channel'),
            options: Array.from(allFilters?.channels ?? []).map((channel) => ({
              value: channel.id,
              label: channel.name
            }))
          }
        ]
      : []
  }, [allFilters?.channels, allFilters?.markets, reportParameters, t])

  useEffect(() => {
    if (reportsForPreviousTimePeriod.length > 0 && reports.length > 0) {
      const [totalsData, channelData, marketData] = flattenHomePageStatsData(reports, reportsForPreviousTimePeriod)

      const [previousStartDate, previousPeriodEndDate] = previousTimePeriod

      const context = generateHomePageContext(
        selectedDate.startDate,
        selectedDate.endDate,
        previousStartDate,
        previousPeriodEndDate,
        currentClient.name,
        currentClient.currency,
        totalsData,
        channelData,
        marketData
      )

      setPerformanceDataContextForMimir(context)
      setDataContext(context)
    }
  }, [
    reports,
    reportsForPreviousTimePeriod,
    selectedDate,
    currentClient.currency,
    currentClient.name,
    setDataContext,
    previousTimePeriod
  ])

  const isConversionNameAndSourceConditionSatisfied =
    isConversionNamesAndSourcesSuccessfullyLoaded ||
    hasExistingConversionEvent ||
    isReportTemplateWithNoConversionEvents

  const hasSuccessfullyLoadedNecessaryData =
    isReportConfigAndStatusLoaded && isHomePageDataLoaded && isConversionNameAndSourceConditionSatisfied

  const isNoReportViewConditionSatisfied = selectedStandardReportConfig == null

  const isReportDataLoadingViewConditionSatisfied = checkIfReportDataLoadingViewConditionSatisfied({
    status: selectedReportStatus,
    isDataNotFoundError
  })

  const isReportDataKnownErrorViewConditionSatisfied = checkIfReportDataKnownErrorViewConditionSatisfied({
    status: selectedReportStatus,
    isDataNotFoundError
  })

  const isReportDataUnknownErrorViewConditionSatisfied = checkIfReportDataUnknownErrorViewConditionSatisfied({
    status: selectedReportStatus,
    isDataError,
    isReportDataLoadingViewConditionSatisfied
  })

  const isNoReportDataViewConditionSatisfied = checkIfNoReportDataViewConditionSatisfied({
    hasSuccessfullyLoadedNecessaryData,
    isDataError,
    status: selectedReportStatus,
    dataAnalyticsCount: reportDataAnalytics.length
  })

  const isNonErrorViewConditionSatisfied =
    isNoReportViewConditionSatisfied ||
    isReportDataLoadingViewConditionSatisfied ||
    isNoReportDataViewConditionSatisfied

  const isCustomContentConditionSatisfied = checkIfCustomContentConditionSatisfied({
    isReportConfigAndStatusLoaded,
    shouldShowErrorView: isReportDataKnownErrorViewConditionSatisfied || isReportDataUnknownErrorViewConditionSatisfied,
    shouldShowNoDataView: isNoReportDataViewConditionSatisfied,
    shouldShowCustomNonErrorView: isNonErrorViewConditionSatisfied
  })

  const isToolbarConditionSatisfied = checkIfToolbarConditionSatisfied({
    isCustomContentConditionSatisfied,
    isReportDataLoaded: isReportConfigAndStatusLoaded,
    isFilterOptionsLoaded,
    isConversionNameAndSourceConditionSatisfied
  })

  const handleReportSelected = (config: ReportConfig): void => {
    setSelectedStandardReportConfigId(config.id.toString())
    void pushQuery({
      reportId: config.id.toString()
    })

    OverScreen.remove('switchReportHomeFlyout')
    resetHomePageData()
    setSelectedFilters([])
    setSelectedConversionEvents(undefined)
  }

  function handleSwitchReports(): void {
    void OverScreen.show('switchReportHomeFlyout', {
      configs: standardReportConfigsFilteredByTemplateId.map((config) => ({
        id: config.configId,
        name: config.name,
        subtitle: config.configId,
        templateId: config.templateId,
        isSelected: config.configId.toString() === selectedStandardReportConfig?.configId.toString()
      })),
      onSelected: handleReportSelected
    })
  }

  const handleSelectTopCardMetrics = (): void => {
    void OverScreen.show<TopCardMetricsFlyoutProps>('topCardMetricsFlyout', {
      t,
      metricOptions: reportParameters?.metrics.map((metric) => ({
        value: metric,
        label: t(metric)
      })),
      selectedMetrics: selectedTopCardMetrics,
      setSelectedMetrics: setSelectedTopCardMetrics,
      metricsStorageKey: reportParameters?.localStorageKeyForMetrics
    })
  }

  const handleSelectConversionEvents = (): void => {
    void OverScreen.show<ConversionEventsFlyoutProps>('conversionEventsFlyout', {
      t,
      allEventsOption: {
        value: 'all',
        label: t('allConversionEvents')
      },
      currentEventOptions: eventOptions,
      setCurrentEventOptions: setEventOptions,
      reportId: selectedStandardReportConfig?.configId.toString(),
      selectedEvents: selectedConversionEvents,
      setSelectedEvents: setSelectedConversionEvents,
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate,
      reportType: selectedStandardReportConfig?.templateId,
      conversionEventVariables: reportParameters?.conversionEventVariables,
      storeSelectedConversionEvents: reportParameters?.storeSelectedConversionEvents
    })
  }

  const greetingKey = t(`greetings.${getPeriodOfTimeToday()}`)

  const changeDateRange = (startDate: string | null, endDate: string | null): void => {
    setSelectedDate({
      startDate: startDate ?? defaultStartDate,
      endDate: endDate ?? defaultEndDate
    })
  }

  const handleApplyDateRangeClick = (): void => {
    setPeriodTypeToApply(userSelectedPeriodType)
    selectedStandardReportConfig != null &&
      saveToStore(
        LOCAL_STORAGE_KEYS.HOME_PAGE_COMPARISON_PERIOD_SELECTION(selectedStandardReportConfig.configId.toString()),
        userSelectedPeriodType
      )
  }

  const customContentBasedOnDataOrError = isCustomContentConditionSatisfied ? (
    <>
      {isNonErrorViewConditionSatisfied && (
        <StyledBlockEmptyStateContainer>
          <Typography variant="h3">{t('performanceData')}</Typography>
          {isNoReportViewConditionSatisfied && <NoReport />}
          {isReportDataLoadingViewConditionSatisfied && <ReportDataLoading />}
          {isNoReportDataViewConditionSatisfied && <NoData />}
        </StyledBlockEmptyStateContainer>
      )}

      {isReportDataKnownErrorViewConditionSatisfied && (
        <ReportDataError
          selectedMetrics={selectedTopCardMetrics}
          report={selectedStandardReportConfig}
          errorType="status"
        />
      )}

      {isReportDataUnknownErrorViewConditionSatisfied && (
        <ReportDataError selectedMetrics={selectedTopCardMetrics} errorType="unknown" />
      )}
    </>
  ) : null

  const toolbar = (
    <>
      {!isNoReportViewConditionSatisfied && (
        <Toolbar
          selectedStandardReportConfig={selectedStandardReportConfig}
          statusInfo={{
            selectedReportStatus,
            selectedReportErrorCode,
            selectedReportErrorDetails,
            selectedReportStatusLastUpdated,
            selectedReportStatusMessage
          }}
          filterProps={{
            canFilter,
            selectedFilters,
            setSelectedFilters,
            parameterOptions,
            selectedDate,
            changeDateRange,
            handleApplyDateRangeClick,
            setUserSelectedPeriodType,
            userSelectedPeriodType,
            additionalCustomControls: (
              <MimirTriggerButton
                userFacingMessage="Help me better understand the insights and recommendations of the performance data on this page."
                questionPrompt="You are Senior Data Analyst for Digital Marketing Firm who provides key insights and recommendations to CMOs at major companies. First write key insights including information about relevant Channel and Market changes that affect the total results make sure to include relevant metrics. Then write an executive summary of the data and insights and next actions. Be clear and concise."
                context={performanceDataContextForMimir ?? ''}
                variant="outlined"
                disabled={performanceDataContextForMimir == null}
              />
            )
          }}
          moreMenuProps={{
            canSwitchReports: standardReportConfigsFilteredByTemplateId.length > 1,
            handleSwitchReports,
            disableConversionEvents: eventOptions?.length === 0 || isConversionNamesAndSourcesError,
            handleSelectTopCardMetrics,
            handleSelectConversionEvents,
            topCardMetricsCount: selectedTopCardMetrics.length,
            conversionEventsCount: selectedConversionEvents?.length
          }}
        />
      )}
    </>
  )

  const detailedReport =
    hasSuccessfullyLoadedNecessaryData && reportParameters != null ? (
      <DetailedReport
        reports={reports}
        summaryData={reportParameters.transformations.transformToSummary(totalsData)}
        timePeriod={[selectedDate.startDate, selectedDate.endDate]}
        metrics={reportParameters.metrics}
        metricToPreviousMetricMap={reportParameters.metricsToPreviousMetricsMap}
        previousTimePeriod={previousTimePeriod}
        reportsForPreviousTimePeriod={reportsForPreviousTimePeriod}
        transformToDetailedReportData={
          reportParameters.transformations.transformToDetailedReportData as TransformToDetailedDataFn<Report>
        }
      />
    ) : null

  return (
    <Grid>
      <Layout
        summaryCardsCount={selectedTopCardMetrics.length}
        header={
          <Grid container marginBottom={theme.spacing(8)} rowGap="24px" direction="column">
            {selectedReportStatus != null &&
              STATUSES_TO_SHOW_BANNER_FOR.includes(selectedReportStatus) &&
              !isReportDataKnownErrorViewConditionSatisfied &&
              selectedStandardReportConfig != null && (
                <Banner
                  LinkProps={{
                    href: `${APP_ROUTES.reportingSolutions.editConfigPage({
                      configId: selectedStandardReportConfig.configId.toString()
                    })}?clientId=${currentClient.id}`,
                    target: '_blank'
                  }}
                  variant="warning"
                >
                  <Typography variant="body1">
                    {selectedReportStatus === STATUSES.paused ? t('configPausedNotice') : t('configErrorNotice')}
                  </Typography>
                </Banner>
              )}
            <Typography variant="h1">
              {greetingKey}, {currentUser?.name}
            </Typography>
          </Grid>
        }
        toolbar={isToolbarConditionSatisfied ? toolbar : null}
        content={
          customContentBasedOnDataOrError ??
          (hasSuccessfullyLoadedNecessaryData && reportParameters != null ? (
            <>
              <Summary
                startDate={selectedDate.startDate}
                endDate={selectedDate.endDate}
                summaryData={reportParameters.transformations.transformToSummary(totalsData)}
                summaryDataForPreviousTimePeriod={reportParameters.transformations.transformToSummary(
                  previousTotalsData
                )}
                progressOverTimeRecords={reportParameters.transformations.transformToTimeSeries(
                  totalsGroupedByDate,
                  selectedTopCardMetrics as Array<ReportingBaseMetric & ChannelGroupingMetric>
                )}
                previousTimePeriod={previousTimePeriod}
              />
              <ProgressOverTime
                reports={reports}
                metrics={reportParameters?.metrics}
                reportsForPreviousTimePeriod={reportsForPreviousTimePeriod}
                timePeriod={[selectedDate.startDate, selectedDate.endDate]}
                selectedPeriodType={periodTypeToApply}
                transformToTimeSeriesByMetricAndBreakdown={
                  reportParameters.transformations
                    .transformToTimeSeriesByMetricAndBreakdown as TransformToTimeSeriesByMetricAndBreakdownFn<
                    Report,
                    Metric
                  >
                }
              />
            </>
          ) : null)
        }
        additionalContent={isCustomContentConditionSatisfied ? <></> : detailedReport}
        rightAlignedContent={
          <StyledRecommendationsBlock>
            <RecommendationFrame
              header={
                <>
                  <Typography variant="h3">{tRecommendation('titles.recommendations')}</Typography>
                  <MimirTriggerButton
                    userFacingMessage="Which of the recommendations shown on this page are most important to address?"
                    questionPrompt="First write 5 key insights based on the summary statistics. Then write an executive summary of the data and insights and next actions. Be clear and concise."
                    context={formatFeedDataForMimir(feedData, dataSources)}
                    disabled={groupedData.length === 0}
                  />
                </>
              }
              actions={<></>}
              content={content}
              ref={cardsContainerRef}
            />
          </StyledRecommendationsBlock>
        }
      />
    </Grid>
  )
}

const StyledRecommendationsBlock = styled(Block)(() => ({
  height: '100%',
  justifyContent: 'flex-start',
  button: {
    alignSelf: 'flex-start'
  }
}))

const StyledBlockEmptyStateContainer = styled(Block)(() => ({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  height: 'calc(100% - 16px)',
  gap: '24px',
  '& > h3': {
    alignSelf: 'flex-start'
  }
}))

export default Dashboard
