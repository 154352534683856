import { Grid, Typography, styled, Tooltip } from '@precis-digital/kurama'
import React, { type ReactNode } from 'react'
import { RetainDefaultQueryLink } from 'shared/components/Router'

export interface Field {
  value: ReactNode
  label: string
}

const CopyConfigTooltip = ({
  title,
  configPath,
  configName,
  fields
}: {
  title: string
  configPath: string
  configName: string
  fields: Field[]
}): React.ReactElement => {
  return (
    <Tooltip
      title={title}
      kind="multiline"
      placement="bottom-start"
      width="fit-content"
      maxWidth="600px"
      body={
        <Grid display="flex" flexDirection="column" gap="4px">
          {fields.map(({ value, label }) => (
            <Grid key={label} display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Grid display="flex" justifyContent="space-between" gap="8px">
                <StyledTypography1LineClamp variant="body2">{label}:</StyledTypography1LineClamp>
              </Grid>
              {typeof value === 'string' ? (
                <Grid display="flex" justifyContent="space-between" gap="8px">
                  <StyledTypography1LineClamp variant="body2">{value}</StyledTypography1LineClamp>
                </Grid>
              ) : (
                value
              )}
            </Grid>
          ))}
        </Grid>
      }
    >
      <Grid>
        <Typography variant="body2">{title}</Typography>
        <RetainDefaultQueryLink href={configPath}>
          <Typography variant="body2">{configName}</Typography>
        </RetainDefaultQueryLink>
      </Grid>
    </Tooltip>
  )
}

export default CopyConfigTooltip

export const StyledTypography1LineClamp = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))
