import { useMutation, type UseMutationResult, type UseMutationOptions } from 'react-query'
import { RETRY_COUNT_ANALYTICS, retryDelayFn, retryFn } from 'shared/reactQuery'

const useMutationAnalytics = <TData, TError, TVariables>(
  mutationFn: (variables: TVariables) => Promise<TData>,
  options?: UseMutationOptions<TData, TError, TVariables>
): UseMutationResult<TData, TError, TVariables> => {
  return useMutation<TData, TError, TVariables>(mutationFn, {
    retry: retryFn(RETRY_COUNT_ANALYTICS),
    retryDelay: retryDelayFn,
    ...options
  })
}

export default useMutationAnalytics
