import React from 'react'
import { PercentageChip } from 'shared/components/PercentageChip'
import type { CellProps, Column, ColumnInstance, FooterProps, Row } from 'react-table'
import {
  ADSPEND,
  CHANNEL,
  CONVERSIONS,
  COSTPERACTION,
  type DIFFERENCE,
  FIRSTSUBHEADER,
  REVENUE,
  ROAS,
  SECONDSUBHEADER,
  IMPRESSIONS
} from 'attributionModel/constants'
import Channel from 'attributionModel/components/Dashboard/Channel'
import { type ColumnProps, type ComparisonColumnProps } from 'attributionModel/components/Dashboard/DetailedReport'
import { Typography } from '@precis-digital/kurama'
import { type AttributionModel } from './ComparisonFilters'
import { type CreditInput, type IntegrationAttributionsResp } from 'shared/api/attributionModels'
import { type FormatIds } from 'shared/numberFormat'
import { ExpandedNumberFormat } from 'shared/components/NumberFormat'
import { calculateCostPerAction, calculateRoas } from 'shared/utils'
import { DEFAULT_DATA_COLUMN_PROPERTIES, differenceColumnSortTypeFn } from 'shared/reactTable'
import { DIFFERENCE_HEADER } from 'shared/reactTable/constants'
import { getPreviousTimePeriod, getPreviousYearTimePeriod } from 'shared/dateFns'

export type TimePeriodType = 'samePeriodLastYear' | 'previousTimePeriod' | ''

interface OriginalRow {
  [key: string]: number | string | SubHeader
  [REVENUE]: number | SubHeader
  [ADSPEND]: number | SubHeader
  [CONVERSIONS]: number | SubHeader
  [IMPRESSIONS]: number | SubHeader
  [ROAS]: number | SubHeader
  [COSTPERACTION]: number | SubHeader
  [CHANNEL]: string
}

export interface TimePeriod {
  value: TimePeriodType
  startDate: string
  endDate: string
}
export interface GroupedColumnProps {
  id: keyof ColumnProps
  firstSubHeaderLabel: string
  secondSubHeaderLabel: string
  currencyCode?: string
}
interface ComparisonTableColumnsProps {
  t: (string: string) => string
  subgroup: {
    firstSubHeaderLabel: string
    secondSubHeaderLabel: string
  }
  currencyCode: string
}

type NestedColumn = Column<ComparisonColumnProps> & {
  columns?: Array<Column<OriginalRow>>
}

interface BasicTableColumnsProps {
  t: (string: string) => string
  currencyCode: string
}

interface SubHeader {
  [FIRSTSUBHEADER]: number
  [SECONDSUBHEADER]: number
  [DIFFERENCE]: number
}

export const getTimePeriod = (
  timePeriodValue: TimePeriodType,
  startDate: string,
  endDate: string
): Record<string, string> => {
  let startTimePeriod: string = ''
  let endTimePeriod: string = ''
  switch (timePeriodValue) {
    case 'samePeriodLastYear': {
      ;[startTimePeriod, endTimePeriod] = getPreviousYearTimePeriod(startDate, endDate)
      break
    }
    case 'previousTimePeriod': {
      ;[startTimePeriod, endTimePeriod] = getPreviousTimePeriod(startDate, endDate)
      break
    }
    default: {
      startTimePeriod = startDate
      endTimePeriod = endDate
    }
  }
  return { startTimePeriod, endTimePeriod }
}

const calcFooter = (
  rows: Array<Row<OriginalRow>>,
  column: ColumnInstance<OriginalRow>,
  id: string,
  currencyCode?: string
): React.ReactElement => {
  if (id.startsWith(ROAS)) {
    const totalRevenue = rows.reduce((sum, row) => {
      const headerValue = id.includes(FIRSTSUBHEADER)
        ? (row.original[REVENUE] as SubHeader)[FIRSTSUBHEADER]
        : id.includes(SECONDSUBHEADER)
        ? (row.original[REVENUE] as SubHeader)[SECONDSUBHEADER]
        : (row.original[REVENUE] as number)
      return headerValue + sum
    }, 0)
    const totalSpend = rows.reduce((sum, row) => {
      const headerValue = id.includes(FIRSTSUBHEADER)
        ? (row.original[ADSPEND] as SubHeader)[FIRSTSUBHEADER]
        : id.includes(SECONDSUBHEADER)
        ? (row.original[ADSPEND] as SubHeader)[SECONDSUBHEADER]
        : (row.original[ADSPEND] as number)
      return headerValue + sum
    }, 0)
    const totalRoas = calculateRoas(totalRevenue, totalSpend)
    return <ExpandedNumberFormat value={totalRoas} id={ROAS as FormatIds} />
  }

  if (id.startsWith(COSTPERACTION)) {
    const totalConversions = rows.reduce((sum, row) => {
      const headerValue = id.includes(FIRSTSUBHEADER)
        ? (row.original[CONVERSIONS] as SubHeader)[FIRSTSUBHEADER]
        : id.includes(SECONDSUBHEADER)
        ? (row.original[CONVERSIONS] as SubHeader)[SECONDSUBHEADER]
        : (row.original[CONVERSIONS] as number)
      return headerValue + sum
    }, 0)
    const totalSpend = rows.reduce((sum, row) => {
      const headerValue = id.includes(FIRSTSUBHEADER)
        ? (row.original[ADSPEND] as SubHeader)[FIRSTSUBHEADER]
        : id.includes(SECONDSUBHEADER)
        ? (row.original[ADSPEND] as SubHeader)[SECONDSUBHEADER]
        : (row.original[ADSPEND] as number)
      return headerValue + sum
    }, 0)
    const totalCPA = calculateCostPerAction(totalSpend, totalConversions)
    return <ExpandedNumberFormat value={totalCPA} id={COSTPERACTION as FormatIds} currencyCode={currencyCode} />
  }

  if (id.includes(FIRSTSUBHEADER) || id.includes(SECONDSUBHEADER)) {
    id = id.split('_')[0]
  }

  // normal sum colmn
  const total = rows.reduce((sum: number, row) => {
    const headerValue = column.id.includes(FIRSTSUBHEADER)
      ? (row.original[id] as SubHeader)[FIRSTSUBHEADER]
      : column.id.includes(SECONDSUBHEADER)
      ? (row.original[id] as SubHeader)[SECONDSUBHEADER]
      : (row.original[id] as number)
    return headerValue + sum
  }, 0)
  return <ExpandedNumberFormat value={total} id={id as FormatIds} currencyCode={currencyCode} />
}

const calcDifferenceFooter = (
  rows: Array<Row<OriginalRow>>,
  column: ColumnInstance<OriginalRow>
): React.ReactElement => {
  if (column.id.startsWith(ROAS)) {
    const totalRevenueFirstHeader = rows.reduce(
      (sum: number, row) => (row.original[REVENUE] as SubHeader)[FIRSTSUBHEADER] + sum,
      0
    )
    const totalRevenueSecondHeader = rows.reduce(
      (sum: number, row) => (row.original[REVENUE] as SubHeader)[SECONDSUBHEADER] + sum,
      0
    )

    const totalSpendFirstHeader = rows.reduce(
      (sum: number, row) => (row.original[ADSPEND] as SubHeader)[FIRSTSUBHEADER] + sum,
      0
    )
    const totalSpendSecondHeader = rows.reduce(
      (sum: number, row) => (row.original[ADSPEND] as SubHeader)[SECONDSUBHEADER] + sum,
      0
    )

    const totalRoasFirstHeader = calculateRoas(totalRevenueFirstHeader, totalSpendFirstHeader)
    const totalRoasSecondHeader = calculateRoas(totalRevenueSecondHeader, totalSpendSecondHeader)

    return <PercentageChip previousValue={totalRoasSecondHeader} presentValue={totalRoasFirstHeader} />
  }

  if (column.id.startsWith(COSTPERACTION)) {
    const totalConversionsFirstHeader = rows.reduce(
      (sum: number, row) => (row.original[CONVERSIONS] as SubHeader)[FIRSTSUBHEADER] + sum,
      0
    )
    const totalConversionsSecondHeader = rows.reduce(
      (sum: number, row) => (row.original[CONVERSIONS] as SubHeader)[SECONDSUBHEADER] + sum,
      0
    )

    const totalSpendFirstHeader = rows.reduce(
      (sum: number, row) => (row.original[ADSPEND] as SubHeader)[FIRSTSUBHEADER] + sum,
      0
    )
    const totalSpendSecondHeader = rows.reduce(
      (sum: number, row) => (row.original[ADSPEND] as SubHeader)[SECONDSUBHEADER] + sum,
      0
    )

    const totalCPAFirstHeader = calculateCostPerAction(totalSpendFirstHeader, totalConversionsFirstHeader)
    const totalCPASecondHeader = calculateCostPerAction(totalSpendSecondHeader, totalConversionsSecondHeader)

    return <PercentageChip previousValue={totalCPASecondHeader} presentValue={totalCPAFirstHeader} reverseColorScheme />
  }

  const totalColumnFirstHeader = rows.reduce(
    (sum: number, row) => (row.values[column.id][FIRSTSUBHEADER] as number) + sum,
    0
  )
  const totalColumnSecondHeader = rows.reduce(
    (sum: number, row) => (row.values[column.id][SECONDSUBHEADER] as number) + sum,
    0
  )
  return <PercentageChip previousValue={totalColumnSecondHeader} presentValue={totalColumnFirstHeader} />
}

const getSubGroupColumns = ({
  id,
  firstSubHeaderLabel,
  secondSubHeaderLabel,
  currencyCode
}: GroupedColumnProps): Array<Column<OriginalRow>> => [
  {
    id: `${id}_firstHeader`,
    Header: firstSubHeaderLabel,
    accessor: (originalRow: OriginalRow) =>
      typeof originalRow[id] === 'object' ? (originalRow[id] as SubHeader)[FIRSTSUBHEADER] : originalRow[id],
    Cell: ({ value }: { value: number }) => {
      return <ExpandedNumberFormat value={value} id={id as FormatIds} currencyCode={currencyCode} />
    },
    Footer: ({ rows, column }) => calcFooter(rows, column, `${id}_firstHeader`, currencyCode)
  },
  {
    id: `${id}_secondHeader`,
    Header: secondSubHeaderLabel,
    accessor: (originalRow: OriginalRow) =>
      typeof originalRow[id] === 'object' ? (originalRow[id] as SubHeader)[SECONDSUBHEADER] : originalRow[id],
    Cell: ({ value }: { value: number }) => (
      <ExpandedNumberFormat value={value} id={id as FormatIds} currencyCode={currencyCode} />
    ),
    Footer: ({ rows, column }) => calcFooter(rows, column, `${id}_secondHeader`, currencyCode)
  },
  {
    id: `${id}_difference`,
    Header: DIFFERENCE_HEADER,
    accessor: (originalRow: OriginalRow) => originalRow[id],
    Cell: ({ value }: { value: SubHeader }) => (
      <PercentageChip
        previousValue={value[SECONDSUBHEADER]}
        presentValue={value[FIRSTSUBHEADER]}
        reverseColorScheme={id === 'costPerAction'}
      />
    ),
    sortType: differenceColumnSortTypeFn,
    Footer: ({ rows, column }) => calcDifferenceFooter(rows, column)
  }
]
export const getBasicTableColumns = ({ t, currencyCode }: BasicTableColumnsProps): Array<Column<OriginalRow>> => {
  return [
    {
      ...DEFAULT_DATA_COLUMN_PROPERTIES,
      Header: t('channels'),
      accessor: CHANNEL,
      Cell: ({ value }: CellProps<OriginalRow>) => <Channel value={value} />
    },
    {
      ...DEFAULT_DATA_COLUMN_PROPERTIES,
      Header: t('adSpend'),
      accessor: ADSPEND,
      Cell: ({ value }: CellProps<OriginalRow>) => (
        <ExpandedNumberFormat value={value} id={ADSPEND} currencyCode={currencyCode} />
      ),
      Footer: ({ rows, column }) => calcFooter(rows, column, ADSPEND, currencyCode)
    },
    {
      ...DEFAULT_DATA_COLUMN_PROPERTIES,
      Header: t('revenue'),
      accessor: REVENUE,
      Cell: ({ value }: CellProps<OriginalRow>) => (
        <ExpandedNumberFormat value={value} id={REVENUE} currencyCode={currencyCode} />
      ),
      Footer: ({ rows, column }: FooterProps<OriginalRow>) => calcFooter(rows, column, REVENUE, currencyCode)
    },
    {
      ...DEFAULT_DATA_COLUMN_PROPERTIES,
      Header: t('roas'),
      accessor: ROAS,
      Cell: ({ value }: CellProps<OriginalRow>) => <ExpandedNumberFormat value={value} id={ROAS} />,
      Footer: ({ rows, column }: FooterProps<OriginalRow>) => calcFooter(rows, column, ROAS, currencyCode)
    },
    {
      ...DEFAULT_DATA_COLUMN_PROPERTIES,
      Header: t('conversions'),
      accessor: CONVERSIONS,
      Cell: ({ value }: CellProps<OriginalRow>) => <ExpandedNumberFormat value={value} id={CONVERSIONS} />,
      Footer: ({ rows, column }: FooterProps<OriginalRow>) => calcFooter(rows, column, CONVERSIONS, currencyCode)
    },
    {
      ...DEFAULT_DATA_COLUMN_PROPERTIES,
      Header: t('costPerAction'),
      accessor: COSTPERACTION,
      Cell: ({ value }: CellProps<OriginalRow>) => (
        <ExpandedNumberFormat value={value} id="costPerAction" currencyCode={currencyCode} />
      ),
      Footer: ({ rows, column }: FooterProps<OriginalRow>) => calcFooter(rows, column, COSTPERACTION, currencyCode)
    },
    {
      ...DEFAULT_DATA_COLUMN_PROPERTIES,
      Header: t('impressions'),
      accessor: IMPRESSIONS,
      Cell: ({ value }: CellProps<OriginalRow>) => <ExpandedNumberFormat value={value} id={IMPRESSIONS} />,
      Footer: ({ rows, column }: FooterProps<OriginalRow>) => calcFooter(rows, column, IMPRESSIONS, currencyCode)
    }
  ]
}

export const getComparisonTableColumns = ({
  t,
  subgroup: { firstSubHeaderLabel, secondSubHeaderLabel },
  currencyCode
}: ComparisonTableColumnsProps): NestedColumn[] => {
  return [
    {
      Header: t('channels'),
      accessor: CHANNEL,
      Cell: ({ value }: { value: string }) => <Channel value={value} />
    },
    {
      Header: <Typography variant="h3">{t(ADSPEND)}</Typography>,
      accessor: ADSPEND,
      columns: getSubGroupColumns({
        id: ADSPEND,
        firstSubHeaderLabel,
        secondSubHeaderLabel,
        currencyCode
      })
    },
    {
      Header: <Typography variant="h3">{t(REVENUE)}</Typography>,
      accessor: REVENUE,
      columns: getSubGroupColumns({
        id: REVENUE,
        firstSubHeaderLabel,
        secondSubHeaderLabel,
        currencyCode
      })
    },
    {
      Header: <Typography variant="h3">{t(ROAS)}</Typography>,
      accessor: ROAS,
      columns: getSubGroupColumns({
        id: ROAS,
        firstSubHeaderLabel,
        secondSubHeaderLabel
      })
    },
    {
      Header: <Typography variant="h3">{t(CONVERSIONS)}</Typography>,
      accessor: CONVERSIONS,
      columns: getSubGroupColumns({
        id: CONVERSIONS,
        firstSubHeaderLabel,
        secondSubHeaderLabel
      })
    },
    {
      Header: <Typography variant="h3">{t(COSTPERACTION)}</Typography>,
      accessor: COSTPERACTION,
      columns: getSubGroupColumns({
        id: COSTPERACTION,
        firstSubHeaderLabel,
        secondSubHeaderLabel,
        currencyCode
      })
    },
    {
      Header: <Typography variant="h3">{t(IMPRESSIONS)}</Typography>,
      accessor: IMPRESSIONS,
      columns: getSubGroupColumns({
        id: IMPRESSIONS,
        firstSubHeaderLabel,
        secondSubHeaderLabel
      })
    }
  ]
}

export const getAttributionModelTypes = (
  integratedAttributions: IntegrationAttributionsResp
): Record<CreditInput, AttributionModel[]> => {
  const attributionModelTypes: Record<CreditInput, AttributionModel[]> = {
    xgboost: [],
    ddav2: [],
    native: [],
    rba: [],
    lnda: []
  }

  for (const integratedAttribution of integratedAttributions) {
    const creditInput = integratedAttribution.creditInput
    if (attributionModelTypes[creditInput] !== undefined) {
      attributionModelTypes[creditInput].push({
        name: integratedAttribution.name,
        id: integratedAttribution.id,
        type: integratedAttribution.creditInput
      })
    } else {
      attributionModelTypes[creditInput] = [
        {
          name: integratedAttribution.name,
          id: integratedAttribution.id,
          type: integratedAttribution.creditInput
        }
      ]
    }
  }

  return attributionModelTypes
}

export type AttributionModelType =
  | DefinedAttributionModelType
  | 'ddav2'
  | 'xgboost'
  | 'native'
  | 'rba'
  | 'lnda'
  | undefined
  | ''

export type DefinedAttributionModelType = 'lnda' | 'ga4Default' | 'ga4Lnda'

export type AttributionModelRevenueField =
  | 'ddaV2Revenue'
  | 'iaUserBasedReallocationRevenue'
  | 'lndaRevenue'
  | 'ga4DefaultAttributionRevenue'
  | 'ga4LndaAttributionRevenue'
  | 'regressionBasedAttributionRevenue'

export type AttributionModelConversionsField =
  | 'ddaV2Conversions'
  | 'iaUserBasedReallocationConversions'
  | 'lndaConversions'
  | 'ga4DefaultAttributionConversions'
  | 'ga4LndaAttributionConversions'
  | 'regressionBasedAttributionConversions'

export interface RevenueConversionsField {
  revenue: AttributionModelRevenueField
  conversions: AttributionModelConversionsField
}

export const getRevenueConversionsFieldFromModelType = (modelType: AttributionModelType): RevenueConversionsField => {
  switch (modelType) {
    case 'ddav2':
      return { revenue: 'ddaV2Revenue', conversions: 'ddaV2Conversions' }
    case 'xgboost':
      return { revenue: 'iaUserBasedReallocationRevenue', conversions: 'iaUserBasedReallocationConversions' }
    case 'rba':
      return { revenue: 'regressionBasedAttributionRevenue', conversions: 'regressionBasedAttributionConversions' }
    case 'lnda':
      return { revenue: 'lndaRevenue', conversions: 'lndaConversions' }
    case 'ga4Default':
      return { revenue: 'ga4DefaultAttributionRevenue', conversions: 'ga4DefaultAttributionConversions' }
    case 'ga4Lnda':
      return { revenue: 'ga4LndaAttributionRevenue', conversions: 'ga4LndaAttributionConversions' }
    default:
      throw new Error(`Invalid model type ${modelType?.toString() ?? ''}`)
  }
}
