import { PLATFORM } from 'dataSource'
import { isEmpty, not } from 'ramda'
import type { FieldPath } from 'react-hook-form'
import { type ClientAccountsResp } from 'shared/api/accounts'
import type {
  FilterConditionRes,
  AttributionModelReq,
  AttributionResp,
  ChannelGroupingMetadata
} from 'shared/api/attributionModels'
import { type DimensionType, type OperatorType } from 'shared/constants/filterOperators'
import type { InputRules } from 'shared/reactHookForm'
import type { AttributionFormData, Channel, ModelType, Rule } from '.'
import {
  DEFAULT_CONVERSION_EVENT_FACEBOOK,
  MULTIPLE_PLATFORMS,
  OLD_BING_ADS_PLATFORM_ID,
  DEFAULT_LOOKBACK_DAYS,
  platformsSupported,
  EXPECTED_ROAS_REDISTRIBUTION_DEFAULT,
  AD_STOCK_REDISTRIBUTION_DEFAULT,
  RECENCY_WEIGHTING_DEFAULT,
  INCLUDE_INTERCEPT_DEFAULT,
  ATTRIBUTION_MODEL_TYPES,
  DEFAULT_ATTRIBUTION_CURRENCY,
  CREDIT_INPUTS
} from './constants'
import { isNilOrEmpty, trimWhitespace } from 'shared/utils'
import { validateDataset } from 'connectors/components/EditOrCreateNewPage/utils'
import { type Client } from 'shared/context/ClientContext'

export const getInputRules = <T extends FieldPath<AttributionFormData>>({
  fieldName,
  data,
  isCopyConfig
}: {
  fieldName: T
  data?: AttributionFormData
  isCopyConfig?: boolean
}): InputRules<AttributionFormData, T> => {
  const STATIC_INPUT_RULES: Partial<Record<FieldPath<AttributionFormData>, InputRules<AttributionFormData, T>>> = {
    'model.id': {
      required: true
    },
    'account.analyticsAccountId': {
      required: true
    },
    'account.otherAccounts': {
      required: true
    },
    'datasets.gcp': {
      required: true
    },
    'datasets.source': {
      validate: (value) => {
        return validateDataset((value ?? '') as string)
      }
    },
    'datasets.target': {
      required: true,
      validate: (value) => {
        return validateDataset((value ?? '') as string)
      }
    },
    'channelGrouping.channelGroupingId': {
      required: true
    },
    'general.lookbackDays': {
      required: true
    },
    'general.currency': {
      required: true
    },
    'general.intercept': {
      required: false
    },
    'general.weighting': {
      required: false
    }
  }

  if (
    data?.model?.platform !== undefined ||
    (isCopyConfig === true && data?.account?.analyticsAccountPlatform === undefined)
  ) {
    STATIC_INPUT_RULES['account.analyticsAccountId'] = {
      required: false
    }
    STATIC_INPUT_RULES['model.id'] = {
      required: false
    }
    STATIC_INPUT_RULES['model.platform'] = {
      required: true
    }
  }

  if (data?.model?.id === 'rba') {
    STATIC_INPUT_RULES['general.intercept'] = {
      required: true
    }
    STATIC_INPUT_RULES['general.weighting'] = {
      required: true
    }
  }

  return STATIC_INPUT_RULES[fieldName] ?? {}
}

export function getCreditAllocatorLogic(
  isOptimizable: boolean | undefined,
  formData: AttributionFormData,
  isCopyConfig?: boolean
): boolean {
  if (isOptimizable === true) {
    if (formData.model?.alvieModelId !== undefined && isCopyConfig === undefined) {
      return true
    }
    return false
  } else {
    return true
  }
}

export function isRBA(formData: AttributionFormData): boolean {
  return formData.model?.id === 'rba'
}

export const allowToEdit = (
  optimizeBudget: boolean,
  formData: AttributionFormData,
  isCopyConfig?: boolean
): boolean => {
  if (!optimizeBudget) return true

  if (formData.isCreditAllocatorAndPerformanceCurve === true && isCopyConfig === undefined) return false

  return true
}

export const getFacebookConversionEvent = (
  accountPlatform: string,
  formData: AttributionFormData
): string | undefined =>
  accountPlatform === 'facebook'
    ? formData.account.conversionEventFacebook ?? DEFAULT_CONVERSION_EVENT_FACEBOOK
    : undefined

export const getAccountsWithSupportedPlatforms = (
  accounts: ClientAccountsResp[] | undefined
): ClientAccountsResp[] | undefined => {
  return accounts?.filter((account) => platformsSupported.includes(account.platform))
}

export const getChosenPlatformFromApi = (platforms: string[]): string => {
  const uniquePlatforms = [...new Set(platforms)]
  const chosenMultiplePlatforms = uniquePlatforms.length > 1 ? MULTIPLE_PLATFORMS : ''
  return uniquePlatforms.length === 1 ? uniquePlatforms[0] : chosenMultiplePlatforms
}

export const formatFormDataIntoApiConfig = (
  formData: AttributionFormData,
  activate: boolean,
  clientId: number,
  isOptimizable?: boolean,
  isNewConfig?: boolean,
  isCopyConfig?: boolean
): AttributionModelReq => {
  const selectedAnalyticsAccountFilters =
    formData.account.filters?.filter(
      (filter) => Number(filter.selectedAccount) === Number(formData.account.analyticsAccountId)
    ) ?? []

  const getOtherAccountFilter = (accountId: string): Rule[] =>
    formData.account.filters?.filter((filter) => Number(accountId) === Number(filter.selectedAccount)) ?? []

  const rules = selectedAnalyticsAccountFilters.map((filter) => {
    return [
      {
        field: filter.selectedDimension,
        operator: filter.operator,
        value: filter.value.toString()
      }
    ]
  })

  const getAnalyticsAccount = (): Partial<AttributionModelReq> => {
    if (
      formData.model.platform === undefined &&
      !formData.isPlatformConversion &&
      formData.account.analyticsAccountName != null
    ) {
      return {
        analyticsAccount: {
          accountId: Number(formData.account.analyticsAccountId),
          platform: formData.account.analyticsAccountPlatform as string,
          ...(isEmpty(rules) ? {} : { filterConditions: [{ name: formData.account.analyticsAccountName, rules }] }),
          conversionField: formData.account.conversionEventAnalytics
        }
      }
    }
    return {}
  }

  const getCustomDataSources = (): AttributionModelReq['customDataSources'] => {
    return formData.customDataSources?.map((source) => {
      return {
        platform: source.selectedPlatform,
        accountId: source.accountId,
        worksheetId: source.worksheetId,
        datasetId: source.datasetId,
        tableId: source.tableId,
        type: source.type
      }
    })
  }

  const getAdAccounts = (): AttributionModelReq['adAccounts'] => {
    return (
      formData.account.otherAccounts?.map((account) => {
        const rules = getOtherAccountFilter(account.id).map((filter) => {
          return [
            {
              field: filter.selectedDimension,
              operator: filter.operator,
              value: filter.value.toString()
            }
          ]
        })
        return {
          accountId: parseInt(account.id),
          platform: account.platform === PLATFORM.MICROSOFT_ADVERTISING ? OLD_BING_ADS_PLATFORM_ID : account.platform,
          conversionField: getFacebookConversionEvent(account.platform, formData),
          ...(isEmpty(rules) ? {} : { filterConditions: [{ name: account.label, rules }] })
        }
      }) ?? []
    )
  }

  const getChannelGroupingMetadata = (): AttributionModelReq['channelGroupingMetadata'] => {
    return formData.channelGrouping.channels.reduce((result: ChannelGroupingMetadata, channel: Channel) => {
      result[channel.id] = {
        exclude: not(channel?.include),
        impressionChannel: channel.impression ?? false,
        reallocation: channel.redistribution ?? 0,
        meanPrior: isRBA(formData) ? channel.expectedROAS ?? EXPECTED_ROAS_REDISTRIBUTION_DEFAULT : undefined,
        adStock: isRBA(formData) ? channel.adStock ?? AD_STOCK_REDISTRIBUTION_DEFAULT : undefined
      }

      return result
    }, {})
  }

  return {
    name: trimWhitespace(formData.name),
    runSchedule: activate,
    clientId,
    creditInput:
      formData.model.platform !== undefined && isOptimizable === true ? 'native' : (formData.model.id as ModelType),
    creditAllocator:
      isNewConfig === false
        ? formData.isCreditAllocator
        : getCreditAllocatorLogic(isOptimizable, formData, isCopyConfig),
    performanceCurves: true,
    targetDataset: formData.datasets.target,
    sourceDataset: isNilOrEmpty(formData.datasets.source) ? formData.datasets.target : formData.datasets.source,
    sourceProjectId: Number(formData.datasets.gcp),
    targetProjectId: Number(formData.datasets.gcp),
    targetCurrency: formData.general?.currency ?? DEFAULT_ATTRIBUTION_CURRENCY,
    ...getAnalyticsAccount(),
    adAccounts: getAdAccounts(),
    customDataSources: isRBA(formData) ? getCustomDataSources() : undefined,
    channelGroupingId: formData.channelGrouping.channelGroupingId,
    channelGroupingMetadata: getChannelGroupingMetadata(),
    lookbackDays:
      formData.general?.lookbackDays === undefined ? DEFAULT_LOOKBACK_DAYS : Number(formData.general.lookbackDays),
    xgboostLookback:
      getCreditAllocatorLogic(isOptimizable, formData, isCopyConfig) && !isRBA(formData)
        ? Number(formData.general?.lookbackDays)
        : undefined,
    xgboostModelLookback:
      getCreditAllocatorLogic(isOptimizable, formData, isCopyConfig) && !isRBA(formData)
        ? Number(formData.general?.lookbackDays)
        : undefined,
    reallocations: formData.channelGrouping.channels.map((channel) => {
      return {
        channel: channel.name,
        allocation: channel.redistribution ?? 0
      }
    }),
    includeIntercept: isRBA(formData) ? formData.general?.intercept ?? INCLUDE_INTERCEPT_DEFAULT : undefined,
    recencyWeighting: isRBA(formData) ? Number(formData.general?.weighting ?? RECENCY_WEIGHTING_DEFAULT) : undefined
  }
}

export const formatApiConfigIntoFormData = (config: AttributionResp): AttributionFormData => {
  const formData: AttributionFormData = {
    name: config.name,
    model: {
      id: config.creditInput,
      alvieModelId: config.id
    },
    account: {
      analyticsAccountId: config.analyticsAccount?.accountId.toString(),
      analyticsAccountPlatform: config.analyticsAccount?.platform,
      conversionEventAnalytics: config.analyticsAccount?.conversionField ?? '',
      conversionEventFacebook: config.adAccounts.find((adAccount) => adAccount.platform === 'facebook')
        ?.conversionField,
      analyticsAccountName: config.analyticsAccount?.accountName ?? '',
      filters: [],
      otherAccounts: config.adAccounts.map((adAccount) => {
        return {
          id: adAccount.accountId.toString(),
          platform: adAccount.platform,
          label: adAccount.accountName
        }
      })
    },
    customDataSources: config.customDataSources?.map((source) => {
      return {
        selectedPlatform: source.platform,
        accountName: source.accountName,
        sourceAuthAccount: source.sourceAuthAccount,
        accountId: source.accountId,
        worksheetId: source.worksheetId,
        datasetId: source.datasetId,
        tableId: source.tableId,
        type: source.type
      }
    }),
    datasets: {
      target: config.targetDataset,
      source: config.sourceDataset,
      gcp: config.sourceProjectId.toString()
    },
    channelGrouping: {
      channelGroupingId: config.channelGroupingId as number,
      channels: []
    },
    general: {
      lookbackDays:
        config.lookbackDays === undefined ? DEFAULT_LOOKBACK_DAYS.toString() : config.lookbackDays.toString(),
      currency: config.targetCurrency ?? DEFAULT_ATTRIBUTION_CURRENCY
    },
    isCreditAllocatorAndPerformanceCurve: config.creditAllocator && config.performanceCurves,
    isPlatformConversion: config.analyticsAccount === undefined && config.adAccounts.length >= 1,
    isCreditAllocator: config.creditAllocator
  }
  if (isRBA(formData) && formData.general != null) {
    formData.general.intercept = config.includeIntercept ?? INCLUDE_INTERCEPT_DEFAULT
    formData.general.weighting = config.recencyWeighting ?? RECENCY_WEIGHTING_DEFAULT
  }

  if (config.analyticsAccount?.filterConditions != null) {
    config.analyticsAccount.filterConditions[0]?.rules.forEach((rule) => {
      const filter: Rule = {
        selectedAccount: config.analyticsAccount?.accountId.toString() ?? '',
        selectedDimension: rule[0].field,
        operator: rule[0].operator as DimensionType | OperatorType,
        value: rule[0].value
      }
      formData.account.filters?.push(filter)
    })
  }
  config.adAccounts.forEach((adAccount) => {
    if (adAccount.filterConditions != null) {
      adAccount.filterConditions[0]?.rules.forEach((rule) => {
        const filter: Rule = {
          selectedAccount: adAccount.accountId.toString(),
          selectedDimension: rule[0].field,
          operator: rule[0].operator as DimensionType | OperatorType,
          value: rule[0].value
        }
        formData.account.filters?.push(filter)
      })
    }
  })

  if (config.channelGroupingMetadata != null) {
    Object.entries(config.channelGroupingMetadata).forEach(([channelId, metadata]) => {
      const channel: Channel = {
        name: channelId.toLowerCase(),
        id: channelId.toLowerCase(),
        include: !metadata.exclude,
        impression: metadata.impressionChannel,
        redistribution: metadata.reallocation,
        dataSources: []
      }
      if (isRBA(formData)) {
        channel.expectedROAS = metadata.meanPrior ?? EXPECTED_ROAS_REDISTRIBUTION_DEFAULT
        channel.adStock = metadata.adStock ?? AD_STOCK_REDISTRIBUTION_DEFAULT
      }

      formData.channelGrouping.channels?.push(channel)
    })
  }

  return formData
}

const parseChannelGroupingMetadataForApi = (
  channelGroupingMetadata?: ChannelGroupingMetadata,
  isRba?: boolean
): ChannelGroupingMetadata => {
  if (channelGroupingMetadata == null) return {}
  const result: ChannelGroupingMetadata = {}
  Object.entries(channelGroupingMetadata).forEach(([channelId, value]) => {
    // Some fields are returned from the API but should not be sent back
    // This ensures we only send back fields that are relevant
    // The channel id is case sensitive and should always be lowercase
    result[channelId.toLowerCase()] = {
      exclude: value.exclude,
      impressionChannel: value.impressionChannel,
      reallocation: value.reallocation
    }

    if (isRba === true) {
      result[channelId.toLowerCase()].meanPrior = value.meanPrior
      result[channelId.toLowerCase()].adStock = value.adStock
    }
  })
  return result
}

export const transformAttributionRespToReq = (newAttribution: AttributionResp): AttributionModelReq => {
  const result: AttributionModelReq = {
    name: newAttribution.name,
    clientId: Number(newAttribution.clientId),
    creditInput: newAttribution.creditInput,
    lookbackDays: Number(newAttribution.lookbackDays),
    runSchedule: newAttribution.runSchedule,
    adAccounts: newAttribution.adAccounts.map((adAccount) => ({
      accountId: adAccount.accountId,
      platform: adAccount.platform,
      conversionField: adAccount.conversionField,
      filterConditions:
        adAccount.filterConditions.length > 0
          ? [
              {
                name: adAccount.filterConditions[0].name,
                rules: adAccount.filterConditions[0].rules.map((ruleSet) =>
                  ruleSet.map((rule) => ({
                    field: rule.field,
                    operator: rule.operator,
                    value: rule.value
                  }))
                )
              }
            ]
          : undefined,
      clickField: adAccount.clickField
    })),
    sourceProjectId: Number(newAttribution.sourceProjectId),
    targetProjectId: Number(newAttribution.targetProjectId),
    sourceDataset: newAttribution.sourceDataset,
    targetDataset: newAttribution.targetDataset,
    creditAllocator: newAttribution.creditAllocator,
    performanceCurves: newAttribution.performanceCurves,
    reallocations: newAttribution.reallocations,
    channelGroupingId: Number(newAttribution.channelGroupingId),
    targetCurrency: newAttribution.targetCurrency,
    channelGroupingMetadata: parseChannelGroupingMetadataForApi(
      newAttribution.channelGroupingMetadata,
      newAttribution.creditInput === CREDIT_INPUTS.RBA
    )
  }
  if (newAttribution.analyticsAccount != null) {
    result.analyticsAccount = {
      accountId: newAttribution.analyticsAccount.accountId,
      platform: newAttribution.analyticsAccount.platform,
      conversionField: newAttribution.analyticsAccount.conversionField,
      filterConditions:
        (newAttribution.analyticsAccount.filterConditions?.length ?? 0) > 0
          ? (newAttribution.analyticsAccount.filterConditions?.map((filterCondition) => ({
              name: filterCondition.name,
              rules: filterCondition.rules.map((ruleSet) =>
                ruleSet.map((rule) => ({
                  field: rule.field,
                  operator: rule.operator,
                  value: rule.value
                }))
              )
            })) as FilterConditionRes[])
          : []
    }
  }
  if (newAttribution.xgboostLookback != null) {
    result.xgboostLookback = Number(newAttribution.xgboostLookback)
  }
  if (newAttribution.xgboostModelLookback != null) {
    result.xgboostModelLookback = Number(newAttribution.xgboostModelLookback)
  }
  if (newAttribution.includeIntercept != null) {
    result.includeIntercept = newAttribution.includeIntercept
  }
  if (newAttribution.recencyWeighting != null) {
    result.recencyWeighting = Number(newAttribution.recencyWeighting)
  }
  return result
}

export const getAllowedAttributionModelTypes = (
  currentClient: Client
): Array<(typeof ATTRIBUTION_MODEL_TYPES)[number]> => {
  return ATTRIBUTION_MODEL_TYPES.filter((modelType) => {
    if (modelType.value === CREDIT_INPUTS.RBA && !currentClient.featurePlan.attributionModels.rba) {
      return false
    }

    if (
      modelType.value === CREDIT_INPUTS.XGBOOST &&
      !currentClient.featurePlan.attributionModels.integratedAttribution
    ) {
      return false
    }

    if (modelType.value === CREDIT_INPUTS.DDAV2 && !currentClient.featurePlan.attributionModels.ddaV2) {
      return false
    }

    return true
  })
}

export const checkShouldShowAnalyticsAccount = (isCopyConfig: boolean, analyticsAccountPlatform?: string): boolean => {
  const shouldShowAnalyticsAccount: boolean = isCopyConfig && analyticsAccountPlatform === undefined
  return shouldShowAnalyticsAccount
}
