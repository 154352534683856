import {
  Avatar,
  Grid,
  type FlowChartNode,
  Typography,
  CheckIcon,
  AttributionModelsIcon,
  Link,
  InfoIcon,
  styled,
  CancelIcon,
  defaultTheme as theme,
  ClockIcon,
  BudgetOptimiserIcon,
  Badge,
  Divider,
  PresentationBoardIcon,
  ConnectDataSourcesIcon
} from '@precis-digital/kurama'
import { Placeholder } from '@precis-digital/kurama/src/components/Icons/assets/Placeholder'
import { type Theme } from '@precis-digital/kurama/src/components/theme'
import { getConnectorSourceMeta } from 'connectors/utils'
import { clone } from 'ramda'
import { type ReactNode } from 'react'
import { type ClientAccountsResp } from 'shared/api/accounts'
import { type E2EStatus } from 'shared/api/analytics'
import { type ConnectorResp } from 'shared/api/connectors'
import { type StandardReportsResp } from 'shared/api/standardReports'
import {
  getStatusFromAPIStatusData,
  type DagNode,
  type ConfigStatus,
  STATUS_COLOR_MAP,
  getErrorCodeDetails
} from 'shared/configStatuses'
import { ALVIE_APPS_REGISTRY } from 'shared/constants/apps'
import { objectKeys } from 'shared/utils'
import StatusTooltipBody from './StatusTooltipBody'
import { type IntegrationAttributionsResp } from 'shared/api/attributionModels'
import { formatDateTimeToAtFormat } from 'shared/dateFns'
import { getReportTemplateById } from 'reportingSolution/utils'

const DEFAULT_ICON_AND_STATUS_COLOR = {
  statusIcon: <InfoIcon />,
  statusColor: theme.palette.neutrals.stone100
}

const getColorAndIcon = (status: E2EStatus, theme: Theme): { statusIcon: ReactNode; statusColor: string } => {
  switch (status) {
    case 'completed':
      return {
        statusIcon: <CheckIcon />,
        statusColor: theme.palette.semantic.success100
      }
    case 'failed':
      return {
        statusIcon: <CancelIcon />,
        statusColor: theme.palette.semantic.error100
      }
    case 'running':
    case 'queued':
      return {
        statusIcon: <ClockIcon />,
        statusColor: theme.palette.semantic.warning100
      }
    default:
      return DEFAULT_ICON_AND_STATUS_COLOR
  }
}

interface FlowChartNodeDetails {
  node: DagNode
  configName: string
  labelIcon: ReactNode
  statusIcon: ReactNode
  statusColor: string
  status: ConfigStatus
  link: string
  tooltipTitle: string
  labelText: string
  subLabelText?: string
  platform?: string
  platformIconUrl?: string
  isActive?: boolean
  targetGCPProjectID?: string
  t: (key: string, options?: Record<string, unknown> | undefined) => string
}

const createNode = ({
  node,
  configName,
  labelIcon,
  statusIcon,
  statusColor,
  status,
  link,
  tooltipTitle,
  labelText: nodeLabel,
  subLabelText: nodeSubLabel,
  platformIconUrl,
  isActive,
  targetGCPProjectID,
  t
}: FlowChartNodeDetails): FlowChartNode => {
  const badgeColor = STATUS_COLOR_MAP[status]
  const serviceToIconMap = {
    connectors: (
      <StyledIconWrapper>
        <ConnectDataSourcesIcon />
      </StyledIconWrapper>
    ),
    transform: (
      <StyledIconWrapper>
        <PresentationBoardIcon />
      </StyledIconWrapper>
    ),
    attribution_etl: (
      <StyledIconWrapper>
        <AttributionModelsIcon />
      </StyledIconWrapper>
    ),
    attribution_model: (
      <StyledIconWrapper>
        <AttributionModelsIcon />
      </StyledIconWrapper>
    ),
    bo: (
      <StyledIconWrapper>
        <BudgetOptimiserIcon />
      </StyledIconWrapper>
    )
  }
  const serviceToPipelineTypeMap = {
    connectors: t('dagView.connector'),
    transform: t('dagView.reportingSolution'),
    attribution_etl: t('dagView.attributionModel'),
    attribution_model: t('dagView.attributionModel'),
    bo: t('dagView.budgetOptimiser')
  }
  return {
    id: node.id,
    position: node.position,
    labelIcon,
    ...(isActive === false
      ? DEFAULT_ICON_AND_STATUS_COLOR
      : {
          statusColor,
          statusIcon
        }),
    label: (
      <Grid container padding="8px" direction="column">
        <Typography variant="body2">{nodeLabel != null && nodeLabel}</Typography>
        <Typography style={{ color: theme.palette.neutrals.stone150 }} variant="body3">
          {nodeSubLabel}
        </Typography>
      </Grid>
    ),
    tooltipContent: {
      width: '100%',
      titleBodyGap: '0px',
      title: (
        <StyledTooltipTitleGrid container alignItems="center">
          <Grid item xs={2}>
            {platformIconUrl != null ? (
              <Avatar size="medium" kind="image" imageUrl={platformIconUrl} />
            ) : (
              serviceToIconMap[node.data.service]
            )}
          </Grid>
          <Grid item xs={10}>
            <StyledTitleTypographyText variant="body2">
              <strong>{tooltipTitle}</strong>
            </StyledTitleTypographyText>
            <Grid container direction="row" alignItems="center">
              <StyledTypographyText title={configName} variant="body3">
                <Link style={{ display: 'flex' }} href={link} target="_blank" rel="noreferrer">
                  <StyledTypographyText variant="body3">{configName}</StyledTypographyText>
                </Link>
              </StyledTypographyText>
            </Grid>
          </Grid>
        </StyledTooltipTitleGrid>
      ),
      body: (
        <StyledToolTipBodyGrid>
          <Divider color={theme.palette.neutrals.stone150} />
          <StyledLastUpdateAndBadgeContainer hasLastUpdate={status !== 'paused'}>
            {status !== 'paused' && (
              <>
                <Typography variant="body3">
                  {t('lastUpdate')}:{' '}
                  {node.data.lastUpdated != null ? formatDateTimeToAtFormat(node.data.lastUpdated) : t('unknown')}
                </Typography>
                <Badge color={badgeColor ?? 'stone'} />
              </>
            )}
          </StyledLastUpdateAndBadgeContainer>
          <StatusTooltipBody
            status={status}
            hasLinkToDagView={false}
            errorCode={node.data.errorCode}
            errorDetails={getErrorCodeDetails(
              node.data.errorCode,
              node.data.errorMessage,
              targetGCPProjectID,
              serviceToPipelineTypeMap[node.data.service]
            )}
          />
        </StyledToolTipBodyGrid>
      ),
      allowOverflow: true,
      maxHeight: '48vh',
      placement: 'top'
    }
  }
}

const sortNodesAndReassignPositions = (flowChartNodeDetails: FlowChartNodeDetails[]): FlowChartNode[] => {
  const nodesGroupedByLevels = flowChartNodeDetails.reduce<
    Record<
      number,
      Array<{
        id: string
        labelText: string
        subLabelText?: string
        platform?: string
        position: { x: number; y: number }
      }>
    >
  >((acc, nodeDetails) => {
    if (acc[nodeDetails.node.level] == null) {
      acc[nodeDetails.node.level] = []
    }
    acc[nodeDetails.node.level].push({
      id: nodeDetails.node.id,
      labelText: nodeDetails.labelText,
      subLabelText: nodeDetails.subLabelText,
      platform: nodeDetails.platform,
      position: nodeDetails.node.position
    })

    return acc
  }, {})

  const levelsSortedByPosition = clone(nodesGroupedByLevels)

  objectKeys(nodesGroupedByLevels).forEach((level) => {
    nodesGroupedByLevels[level].sort((a, b) => {
      if (b.platform != null && a.platform != null && a.platform !== b.platform) {
        return a.platform.localeCompare(b.platform)
      }

      if (a.labelText !== b.labelText) {
        return a.labelText.localeCompare(b.labelText)
      }

      return a.subLabelText?.localeCompare(b.subLabelText ?? '') ?? 0
    })

    levelsSortedByPosition[level].sort((a, b) => {
      return a.position.y - b.position.y
    })
  })

  const flowChartNodes = flowChartNodeDetails.map((nodeDetails) => {
    const sortedNodeIndex = nodesGroupedByLevels[nodeDetails.node.level].findIndex((n) => n.id === nodeDetails.node.id)
    let newNodePosition = nodeDetails.node.position

    if (sortedNodeIndex !== -1) {
      const sortedNode = levelsSortedByPosition[nodeDetails.node.level][sortedNodeIndex]
      newNodePosition = sortedNode.position
    }

    return createNode({
      ...nodeDetails,
      node: {
        ...nodeDetails.node,
        position: newNodePosition
      }
    })
  })

  return flowChartNodes
}
export const getDAGNodeDetails = ({
  nodes,
  accounts,
  connectorConfigs,
  reportingSolutionConfigs,
  attributionConfigs,
  t,
  clientId
}: {
  nodes: DagNode[]
  accounts: ClientAccountsResp[]
  connectorConfigs: ConnectorResp[]
  reportingSolutionConfigs: StandardReportsResp
  attributionConfigs: IntegrationAttributionsResp
  t: (key: string, options?: Record<string, unknown> | undefined) => string
  clientId: string
}): FlowChartNode[] => {
  const flowChartNodeDetails: FlowChartNodeDetails[] = nodes.map((node) => {
    let status = getStatusFromAPIStatusData(true, node.data.status)
    const statusIconAndColor = getColorAndIcon(node.data.status, theme)

    if (node.data.service === 'connectors') {
      const connectorConfig = connectorConfigs.find(
        (connectorConfig) => connectorConfig.configId.toString() === node.data.configId
      )
      const account = accounts.find((account) => account.externalAccountId === connectorConfig?.accountId)

      const platformMeta = getConnectorSourceMeta(connectorConfig?.service ?? '')

      const configName = connectorConfig?.job ?? node.data.configId

      if (connectorConfig != null) {
        status = getStatusFromAPIStatusData(connectorConfig.runSchedule, node.data.status)
      }

      return {
        node,
        configName,
        labelIcon:
          platformMeta?.iconUrl != null ? <Avatar size="small" kind="image" imageUrl={platformMeta.iconUrl} /> : null,
        ...statusIconAndColor,
        t,
        status,
        link: `${ALVIE_APPS_REGISTRY['bifrost-extract-connectors'].uri}/${node.data.configId}?clientId=${clientId}`,
        tooltipTitle: platformMeta?.label ?? node.data.configId,
        platform: platformMeta?.label,
        labelText: account?.name ?? connectorConfig?.accountId ?? node.data.configId,
        subLabelText: connectorConfig?.report,
        isActive: connectorConfig?.runSchedule,
        platformIconUrl: platformMeta?.iconUrl,
        targetGCPProjectID: connectorConfig?.targetProjectId
      }
    } else if (node.data.service === 'transform') {
      const reportingSolutionConfig = reportingSolutionConfigs.find(
        (config) => config.configId.toString() === node.data.configId
      )

      const configName = reportingSolutionConfig?.name ?? node.data.configId

      if (reportingSolutionConfig != null) {
        status = getStatusFromAPIStatusData(reportingSolutionConfig.runSchedule, node.data.status)
      }

      return {
        node,
        labelIcon: <PresentationBoardIcon />,
        labelText: reportingSolutionConfig?.name ?? node.data.configId,
        subLabelText: reportingSolutionConfig?.templateId,
        ...statusIconAndColor,
        tooltipTitle:
          getReportTemplateById(reportingSolutionConfig?.templateId ?? '')?.label ?? t('dagView.reportingSolution'),
        link: `${ALVIE_APPS_REGISTRY['precis-reporting-solutions'].uri}/${node.data.configId}?clientId=${clientId}`,
        configName,
        isActive: reportingSolutionConfig?.runSchedule,
        t,
        status,
        targetGCPProjectID: reportingSolutionConfig?.targetProjectId
      }
    } else if (node.data.service === 'attribution_etl' || node.data.service === 'attribution_model') {
      const attributionConfig = attributionConfigs.find((config) => config.id.toString() === node.data.configId)
      const configName = attributionConfig?.name ?? node.data.configId
      return {
        node,
        configName,
        labelIcon: <AttributionModelsIcon />,
        labelText: configName,
        subLabelText:
          node.data.service === 'attribution_etl' ? t('dagView.preprocessing') : t('dagView.attributionModel'),
        ...statusIconAndColor,
        tooltipTitle:
          node.data.service === 'attribution_etl'
            ? t('dagView.attributionPreProcessing')
            : t('dagView.attributionModel'),
        link: `${ALVIE_APPS_REGISTRY['attribution-models'].uri}/${node.data.configId}/dashboard?clientId=${clientId}`,
        status,
        targetGCPProjectID: attributionConfig?.targetProjectId,
        t
      }
    } else if (node.data.service === 'bo') {
      const attributionConfig = attributionConfigs.find((config) => config.id.toString() === node.data.configId)
      const configName = attributionConfig?.name ?? node.data.configId
      return {
        node,
        configName,
        labelIcon: <BudgetOptimiserIcon />,
        labelText: configName,
        subLabelText: t('dagView.budgetOptimiser'),
        ...statusIconAndColor,
        tooltipTitle: t('dagView.budgetOptimiser'),
        link: `${ALVIE_APPS_REGISTRY['budget-optimiser'].uri}/${node.data.configId}/dashboard?clientId=${clientId}`,
        status,
        targetGCPProjectID: attributionConfig?.targetProjectId,
        t
      }
    }

    return {
      node,
      labelText: node.data.configId,
      labelIcon: <Placeholder />,
      status,
      configName: node.data.configId,
      tooltipTitle: node.data.configId,
      subLabelText: node.data.service,
      ...statusIconAndColor,
      t,
      link: ''
    }
  })

  return sortNodesAndReassignPositions(flowChartNodeDetails)
}

const StyledTypographyText = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

const StyledTitleTypographyText = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

const StyledLastUpdateAndBadgeContainer = styled(Grid)<{ hasLastUpdate: boolean }>(({ hasLastUpdate }) => ({
  display: 'flex',
  justifyContent: hasLastUpdate ? 'space-between' : 'flex-end',
  alignItems: 'center',
  marginBottom: '16px',
  gap: '16px'
}))

const StyledToolTipBodyGrid = styled(Grid)(() => ({
  width: '100%',
  maxWidth: '264px',
  overflow: 'hidden'
}))

const StyledTooltipTitleGrid = styled(Grid)(() => ({
  alignItems: 'center',
  maxWidth: '264px',
  display: 'flex'
}))

const StyledIconWrapper = styled(Grid)(({ theme }) => ({
  svg: {
    color: theme.palette.neutrals.white100
  }
}))
