import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography, Input, Divider, styled, Toggle } from '@precis-digital/kurama'
import {
  StyledFlyout,
  StyledTitle,
  StyledDescription,
  StyledProfileItem,
  EditInputsContainer,
  EditInputContainer
} from 'profile/components/Flyout/styles'
import Dots from 'shared/components/Loader/Dots'
import { useAuth } from 'shared/context/AuthContext'
import { StyledAvatarContainer } from 'workspace/components/Flyout/styles'
import { useQueryUserProfile, useUpdateUserProfile } from 'profile/api'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'shared/translations'
import { INPUT_RULES } from 'profile/formRules'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useQueryMembers, useQueryClientsWithField } from 'workspace/api'
import { capitalize, noop } from 'shared/utils'
import OverScreen from 'shared/overScreens'
import { AvatarWithLabel } from 'shared/components/AvatarWithLabel'

interface IUser {
  name: string
  email: string
  phone: string
  companyName: string
  jobTitle: string
  showInternalFeatures: boolean
}

interface IFlyoutProps {
  selectedUserId?: string
}

const InternalProfileFlyout = ({ selectedUserId }: IFlyoutProps): React.ReactElement => {
  const { currentUser, updateUserName } = useAuth()
  const { currentClient } = useCurrentClient()
  const idToFetch = selectedUserId !== undefined ? selectedUserId : currentUser?.id
  const {
    data: userDetails,
    isLoading: isUserDetailsLoading,
    refetch
  } = useQueryUserProfile(idToFetch ?? (currentUser?.id as string))
  const { mutate: updateUser } = useUpdateUserProfile(currentUser?.id?.toString() ?? '')
  const { data: members } = useQueryMembers(currentClient?.id)
  const { data: clientsWithSoloAdmin, isLoading: isClientsWithSoloAdminLoading } = useQueryClientsWithField(
    true,
    'is_solo_admin'
  )

  const isSoloAdminInOtherWorkspace = (): boolean | undefined => {
    if (clientsWithSoloAdmin != null) {
      const soloAdminClients = Boolean(
        clientsWithSoloAdmin.filter((client) => client.isSoloAdmin === true && client.id !== currentClient.id).length
      )
      return soloAdminClients
    }
  }

  const openPopup = (): void => {
    if (isSoloAdminInOtherWorkspace() === true) {
      void OverScreen.show('unableToDeleteAccountPopup')
    } else {
      void OverScreen.show('deleteAccountPopup')
    }
  }

  const workspaceUser = members?.find((member) => {
    return member.id.toString() === idToFetch?.toString()
  })

  const role = workspaceUser?.role

  const DEFAULT_VALUES = {
    name: userDetails?.name ?? '',
    email: userDetails?.email ?? '',
    phone: userDetails?.phone ?? '',
    companyName: userDetails?.companyName ?? '',
    jobTitle: userDetails?.jobTitle ?? '',
    avatarUrl: userDetails?.avatarUrl,
    showInternalFeatures: userDetails?.showInternalFeatures
  }
  const [isEditName, setIsEditName] = useState<boolean>(false)
  const [isEditPhone, setIsEditPhone] = useState<boolean>(false)
  const [isEditCompany, setIsEditCompany] = useState<boolean>(false)
  const [isEditJob, setIsEditJob] = useState<boolean>(false)

  const { t } = useTranslation('user')

  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    clearErrors
  } = useForm<IUser>({
    defaultValues: {
      ...DEFAULT_VALUES
    }
  })

  useEffect(() => {
    reset(userDetails)
  }, [reset, userDetails])

  const handleSave = (closeFunction: React.Dispatch<React.SetStateAction<boolean>>): void => {
    const values = getValues()
    const updateData = {
      id: currentUser?.id ?? '',
      name: values.name,
      email: values.email,
      phone: values.phone,
      companyName: values.companyName,
      jobTitle: values.jobTitle,
      showInternalFeatures: values.showInternalFeatures
    }
    updateUserName(values.name)
    updateUser(updateData, {
      async onSuccess() {
        await refetch()
      }
    })
    closeFunction(false)
  }

  return isUserDetailsLoading || isClientsWithSoloAdminLoading ? (
    <Dots />
  ) : (
    <StyledFlyout>
      <StyledAvatarContainer>
        <AvatarWithLabel size="large" user={userDetails} />
        <Typography variant="h2">{userDetails?.name}</Typography>
        {currentClient !== undefined && (
          <Typography variant="body3">
            {role == null
              ? t('descriptions.undefinedUser', { workspace: currentClient.name })
              : t('descriptions.user', {
                  role: capitalize(role),
                  workspace: currentClient.name
                })}
          </Typography>
        )}
      </StyledAvatarContainer>
      <Divider />
      <>
        <StyledTitle variant="h3">{t('titles.publicProfile')}</StyledTitle>
        <StyledDescription variant="body1">{t('descriptions.publicProfile')}</StyledDescription>
      </>
      <StyledProfileItem>
        {isEditName ? (
          <Grid>
            <Typography variant="h5">{t('titles.displayName')}</Typography>
            <EditInputsContainer>
              <EditInputContainer>
                <Controller
                  name="name"
                  control={control}
                  rules={INPUT_RULES.name}
                  render={({ field: { name, value } }) => {
                    return (
                      <Input
                        name="name"
                        value={value}
                        onChange={(e) => {
                          setValue(name, e.target.value)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSave(setIsEditName)
                          }
                        }}
                        helperText={errors.name?.message}
                        {...(errors.name?.message !== undefined && { color: 'error' })}
                      />
                    )
                  }}
                />
              </EditInputContainer>
              <Button
                onClick={() => {
                  handleSave(setIsEditName)
                }}
                scheme="light"
                variant="filled"
              >
                {t('buttons.save')}
              </Button>
              <Button
                onClick={() => {
                  reset(
                    {
                      ...DEFAULT_VALUES,
                      name: DEFAULT_VALUES.name
                    },
                    {
                      keepErrors: true
                    }
                  )
                  setIsEditName(false)
                  clearErrors('name')
                }}
                scheme="light"
                variant="tonal"
              >
                {t('buttons.cancel')}
              </Button>
            </EditInputsContainer>
          </Grid>
        ) : (
          <>
            <Grid>
              <Typography variant="body2">{t('titles.displayName')}</Typography>
              <StyledTypographyProfileValue variant="body3" hasValue={DEFAULT_VALUES.name !== ''}>
                {DEFAULT_VALUES.name === '' ? t('descriptions.notProvided') : DEFAULT_VALUES.name}
              </StyledTypographyProfileValue>
            </Grid>
            <Button
              variant="text"
              onClick={() => {
                setIsEditName(true)
              }}
            >
              {DEFAULT_VALUES.name !== '' ? t('buttons.change') : t('buttons.add')}
            </Button>
          </>
        )}
      </StyledProfileItem>
      <Divider />

      <StyledProfileItem>
        <Grid>
          <Typography variant="body2">{t('titles.email')}</Typography>
          <StyledTypographyProfileValue variant="body3" hasValue={DEFAULT_VALUES.email !== ''}>
            {DEFAULT_VALUES.email === '' ? t('descriptions.notProvided') : DEFAULT_VALUES.email}
          </StyledTypographyProfileValue>
        </Grid>
      </StyledProfileItem>
      <Divider />
      <StyledProfileItem>
        {isEditPhone ? (
          <Grid>
            <Typography variant="h5">{t('titles.phoneNumber')}</Typography>
            <EditInputsContainer>
              <EditInputContainer>
                <Controller
                  name="phone"
                  control={control}
                  rules={INPUT_RULES.phone}
                  render={({ field: { name, value } }) => {
                    return (
                      <Input
                        name="phone"
                        value={value}
                        onChange={(e) => {
                          setValue(name, e.target.value)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            void handleSubmit(async () => {
                              handleSave(setIsEditPhone)
                            })()
                          }
                        }}
                        helperText={errors.phone?.message}
                        {...(errors.phone?.message !== undefined && { color: 'error' })}
                      />
                    )
                  }}
                />
              </EditInputContainer>
              <Button
                onClick={() => {
                  void handleSubmit(async () => {
                    handleSave(setIsEditPhone)
                  })()
                }}
                scheme="light"
                variant="filled"
              >
                {t('buttons.save')}
              </Button>
              <Button
                onClick={() => {
                  reset(
                    {
                      ...DEFAULT_VALUES,
                      phone: DEFAULT_VALUES.phone
                    },
                    {
                      keepErrors: true
                    }
                  )
                  setIsEditPhone(false)
                  clearErrors('phone')
                }}
                scheme="light"
                variant="tonal"
              >
                {t('buttons.cancel')}
              </Button>
            </EditInputsContainer>
          </Grid>
        ) : (
          <>
            <Grid>
              <Typography variant="body2">{t('titles.phoneNumber')}</Typography>
              <StyledTypographyProfileValue variant="body3" hasValue={DEFAULT_VALUES.phone !== ''}>
                {DEFAULT_VALUES.phone === '' ? t('descriptions.notProvided') : DEFAULT_VALUES.phone}
              </StyledTypographyProfileValue>
            </Grid>
            <Button
              variant="text"
              onClick={() => {
                setIsEditPhone(true)
              }}
            >
              {DEFAULT_VALUES.phone !== '' ? t('buttons.change') : t('buttons.add')}
            </Button>
          </>
        )}
      </StyledProfileItem>
      <Divider />
      <StyledProfileItem>
        {isEditCompany ? (
          <Grid>
            <Typography variant="h5">{t('titles.companyName')}</Typography>
            <EditInputsContainer>
              <EditInputContainer>
                <Controller
                  name="companyName"
                  control={control}
                  rules={INPUT_RULES.name}
                  render={({ field: { name, value } }) => {
                    return (
                      <Input
                        name="companyName"
                        value={value}
                        onChange={(e) => {
                          setValue(name, e.target.value)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            void handleSubmit(async () => {
                              handleSave(setIsEditCompany)
                            })()
                          }
                        }}
                        helperText={errors.companyName?.message}
                        {...(errors.companyName?.message !== undefined && { color: 'error' })}
                      />
                    )
                  }}
                />
              </EditInputContainer>
              <Button
                onClick={() => {
                  void handleSubmit(async () => {
                    handleSave(setIsEditCompany)
                  })()
                }}
                scheme="light"
                variant="filled"
              >
                {t('buttons.save')}
              </Button>
              <Button
                onClick={() => {
                  reset(
                    {
                      ...DEFAULT_VALUES,
                      companyName: DEFAULT_VALUES.companyName
                    },
                    {
                      keepErrors: true
                    }
                  )
                  setIsEditCompany(false)
                  clearErrors('companyName')
                }}
                scheme="light"
                variant="tonal"
              >
                {t('buttons.cancel')}
              </Button>
            </EditInputsContainer>
          </Grid>
        ) : (
          <>
            <Grid>
              <Typography variant="body2">{t('titles.companyName')}</Typography>
              <StyledTypographyProfileValue variant="body3" hasValue={DEFAULT_VALUES.companyName !== ''}>
                {DEFAULT_VALUES.companyName === '' ? t('descriptions.notProvided') : DEFAULT_VALUES.companyName}
              </StyledTypographyProfileValue>
            </Grid>
            <Button
              variant="text"
              onClick={() => {
                setIsEditCompany(true)
              }}
            >
              {DEFAULT_VALUES.companyName !== '' ? t('buttons.change') : t('buttons.add')}
            </Button>
          </>
        )}
      </StyledProfileItem>
      <Divider />
      <StyledProfileItem>
        {isEditJob ? (
          <Grid>
            <Typography variant="h5">{t('titles.jobTitle')}</Typography>
            <EditInputsContainer>
              <EditInputContainer>
                <Controller
                  name="jobTitle"
                  control={control}
                  rules={INPUT_RULES.jobTitle}
                  render={({ field: { name, value } }) => {
                    return (
                      <Input
                        name="jobTitle"
                        value={value}
                        onChange={(e) => {
                          setValue(name, e.target.value)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            void handleSubmit(async () => {
                              handleSave(setIsEditJob)
                            })()
                          }
                        }}
                        helperText={errors.jobTitle?.message}
                        {...(errors.jobTitle?.message !== undefined && { color: 'error' })}
                      />
                    )
                  }}
                />
              </EditInputContainer>
              <Button
                onClick={() => {
                  void handleSubmit(async () => {
                    handleSave(setIsEditJob)
                  })()
                }}
                scheme="light"
                variant="filled"
              >
                {t('buttons.save')}
              </Button>
              <Button
                onClick={() => {
                  reset(
                    {
                      ...DEFAULT_VALUES,
                      jobTitle: DEFAULT_VALUES.jobTitle
                    },
                    {
                      keepErrors: true
                    }
                  )
                  setIsEditJob(false)
                  clearErrors('jobTitle')
                }}
                scheme="light"
                variant="tonal"
              >
                {t('buttons.cancel')}
              </Button>
            </EditInputsContainer>
          </Grid>
        ) : (
          <>
            <Grid>
              <Typography variant="body2">{t('titles.jobTitle')}</Typography>
              <StyledTypographyProfileValue variant="body3" hasValue={DEFAULT_VALUES.jobTitle !== ''}>
                {DEFAULT_VALUES.jobTitle === '' ? t('descriptions.notProvided') : DEFAULT_VALUES.jobTitle}
              </StyledTypographyProfileValue>
            </Grid>
            <Button
              variant="text"
              onClick={() => {
                setIsEditJob(true)
              }}
            >
              {DEFAULT_VALUES.jobTitle !== '' ? t('buttons.change') : t('buttons.add')}
            </Button>
          </>
        )}
      </StyledProfileItem>
      {currentUser?.hasInternalFeatureAccess === true && (
        <>
          <Divider />
          <Grid display="flex" flexDirection="column" gap="4px">
            <StyledTitle variant="h3">{t('titles.internalFeatures')}</StyledTitle>
            <Grid display="flex" gap="16px" marginRight="8px">
              <Typography variant="body1">{t('descriptions.internalFeatures')}</Typography>
              <Controller
                name="showInternalFeatures"
                control={control}
                rules={INPUT_RULES.phone}
                render={({ field: { name, value } }) => {
                  return (
                    <Toggle
                      name="showInternalFeatures"
                      checked={value}
                      onChange={(e) => {
                        setValue(name, e.target.checked)
                        handleSave(noop)
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <StyledTypographyAnnotation variant="body3">
              {t('descriptions.internalFeaturesAnnotation')}
            </StyledTypographyAnnotation>
          </Grid>
        </>
      )}

      <Divider />
      <StyledTitle variant="h3">{t('titles.deleteAccount')}</StyledTitle>
      <StyledDescription variant="body1">{t('descriptions.deleteAccount')}</StyledDescription>

      <Button variant="outlined" scheme="light" onClick={openPopup}>
        {t('buttons.deleteAccount')}
      </Button>
    </StyledFlyout>
  )
}

const StyledTypographyProfileValue = styled(Typography)<{ hasValue?: boolean }>(({ hasValue, theme }) => ({
  color: hasValue === false ? theme?.palette.neutrals.stone150 : 'initial'
}))

const StyledTypographyAnnotation = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone150,
  fontStyle: 'italic',
  marginTop: '8px'
}))

export default InternalProfileFlyout
