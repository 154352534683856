import { useQueryUserProfile } from 'profile/api'
import { useAuth } from 'shared/context/AuthContext'

/**
 * Hook to determine if internal features should be shown
 * @returns boolean
 */
export const useShouldShowInternalFeatures = (): boolean => {
  const { currentUser } = useAuth()
  const { data: userDetails } = useQueryUserProfile(currentUser?.id)

  return currentUser?.hasInternalFeatureAccess === true && userDetails?.showInternalFeatures === true
}
