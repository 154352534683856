import { type ParsedUrlQuery } from 'querystring'
import { anyPass, defaultTo, isEmpty, isNil } from 'ramda'
import { type ClientError } from 'shared/api/fetch'
import { formatToPercent } from 'shared/numberFormat'
import { type Entries } from 'type-fest'
import { promisify } from 'util'
export { default as logger } from '../logger'
export { get as getFromStore, remove as removeToStore, store as saveToStore } from '../lstore'
export * from './stringFormatter'

export const noop = (): void => {}

export const noopAsync = async (): Promise<void> => {}

export const reloadCurrentPage = (): void => {
  if (typeof window !== 'undefined') {
    window.location.reload()
  }
}

export const redirectToPage = (url: string): void => {
  if (typeof window !== 'undefined') {
    window.location.assign(url)
  }
}
export const pause = promisify((arg: number, cb: () => void) => setTimeout(cb, arg))

export const assertApiReqRes = (req: unknown, res: unknown): void => {
  if (req == null) {
    throw new Error('request is not available')
  }
  if (res == null) {
    throw new Error('response is not available')
  }
}

export const openNewTab = (url: string): void => {
  window.open(url, '_blank')
}

export const getReturnTo = (returnTo?: string | null): string => {
  const currentLocation = window.location.toString()
  return returnTo ?? currentLocation.replace(new URL(currentLocation).origin, '')
}

export const isValidReturnToUrl = (url: string | undefined, baseUrl: string): boolean => {
  if (url == null) {
    return false
  }
  try {
    return new URL(url, baseUrl) !== null
  } catch (_) {
    return false
  }
}
export const defaultToZero = defaultTo(0)

export const filterRecordsByDateRange = <T extends Record<string, string | number>>({
  records,
  startDate,
  endDate,
  dateField
}: {
  records: T[]
  startDate: string
  endDate: string
  dateField: string
}): T[] => {
  return records.filter((record) => {
    const reportDate = new Date(record[dateField])
    return reportDate >= new Date(startDate) && reportDate <= new Date(endDate)
  })
}
export const calDifferenceRelativeToPrevious = (current: number, previous: number): number => {
  return (current - previous) / (previous > 0 ? previous : 1)
}

export const isClientEnvironment = (): boolean => typeof window !== 'undefined'

export const objectKeys = <T extends Record<string, unknown>>(obj: T): Array<keyof T> =>
  Object.keys(obj) as Array<keyof T>

/**
 * Type-safe Object.entries
 */
export const objectEntries = <T extends object>(obj: T): Entries<T> => Object.entries(obj) as Entries<T>

/**
 * Type-safe Object.fromEntries
 */
export const objectFromEntries = <T extends ReadonlyArray<readonly [PropertyKey, unknown]>>(
  entries: T
): { [K in T[number] as K[0]]: K[1] } => {
  return Object.fromEntries(entries) as { [K in T[number] as K[0]]: K[1] }
}

export const calculateRoas = (revenue: number, spend: number): number => {
  return spend === 0 || revenue === 0 ? 0 : revenue / spend
}

export const calculateCostPerAction = (spend: number, conversion: number): number => {
  return conversion === 0 || spend === 0 ? 0 : spend / conversion
}

export const calculateAverageOverValue = (revenue: number, conversion: number): number => {
  return conversion === 0 ? 0 : revenue / conversion
}

export const calculateCostPerClick = (spend: number, clicks: number): number => {
  return clicks === 0 ? 0 : spend / clicks
}

export const calculateCostOfSales = (spend: number, revenue: number): number => {
  return revenue === 0 ? 0 : spend / revenue
}

export const calculateConversionRate = (conversion: number, clicks: number): number => {
  return clicks === 0 ? 0 : conversion / clicks
}

export const calculateClickThroughRate = (clicks: number, impressions: number): number => {
  return impressions === 0 ? 0 : clicks / impressions
}

export const calculateCostPerThousandImpressions = (spend: number, impressions: number): number => {
  return impressions === 0 ? 0 : spend / (impressions / 1000)
}

export const isNilOrEmpty = (value: unknown): value is null | undefined => anyPass([isNil, isEmpty])(value)

export const copyToClipboard = async (text: string): Promise<void> => {
  await navigator.clipboard.writeText(text)
}

export const pasteFromClipboard = async (): Promise<string> => {
  return await navigator.clipboard.readText()
}

export const transformCamelToSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`)

export const isClientMismatchError = (error: ClientError): boolean => {
  return error.message.includes('Client ID does not match')
}

export const getBigQueryTableDeepLink = (projectId: string, datasetId: string, tableId: string): string => {
  return `https://console.cloud.google.com/bigquery?project=${projectId}&p=${projectId}&d=${datasetId}&t=${tableId}&page=table`
}

export const sortOptionInAlphabeticalOrder = <T extends { label: string }>(a: T, b: T): number =>
  a.label.localeCompare(b.label)

export const getQueryParamsAndPathnameFromUrl = (
  url: string,
  baseUrl: string
): { queryParams: ParsedUrlQuery; pathname: string } => {
  const urlObj = new URL(url, baseUrl)
  const urlParams = new URLSearchParams(urlObj?.search)

  const params: Record<string, string> = {}
  for (const param of urlParams) {
    params[param[0]] = param[1]
  }

  return {
    queryParams: params,
    pathname: urlObj?.pathname
  }
}

export type PercentageStatus = 'positive' | 'negative' | 'equal'
export type CustomSymbol = '▲+' | '▼-' | '⬤ '

const customSymbol: Record<PercentageStatus, CustomSymbol> = {
  positive: '▲+',
  negative: '▼-',
  equal: '⬤ '
}

export const getPercentageDisplayValueAndStatus = (
  presentValue: number,
  previousValue: number
): {
  displayValue: string
  percentageStatus: PercentageStatus
} => {
  let displayValue: string
  let percentageStatus: PercentageStatus

  if (previousValue === 0 && presentValue !== 0) {
    displayValue = '▲+N/A'
    percentageStatus = 'positive'
  } else {
    const ratioDifference: number = calDifferenceRelativeToPrevious(presentValue, previousValue)

    if (Math.abs(ratioDifference) < 0.01) {
      percentageStatus = 'equal'
    } else {
      percentageStatus = ratioDifference > 0 ? 'positive' : 'negative'
    }

    displayValue = `${customSymbol[percentageStatus]}${formatToPercent(Math.abs(ratioDifference))}`
  }
  return { displayValue, percentageStatus }
}

export const getCopyConfigDetails = (
  query: ParsedUrlQuery
): {
  configId: number
  isCopyConfig: boolean
} => {
  const configId = typeof query?.from === 'string' ? parseInt(query.from, 10) : NaN
  const isCopyConfig = !isNaN(configId)
  return {
    configId,
    isCopyConfig
  }
}

export const isNewNonCopyConfigCheck = ({
  isNewConfig,
  isCopyConfig
}: {
  isNewConfig: boolean
  isCopyConfig: boolean
}): boolean => isNewConfig && !isCopyConfig
