/****
 * 2024-09-29
 * Malik is in the middle of optimizing the code and removivng
 * a lot of things so a lot of this is probably not used anymore
 *
 ***/

import type { AttributionChannelAggregatedResponse, AttributionResponse } from 'shared/api/analytics'
import { defaultToZero } from '../shared/utils'
import { formatDateToString } from 'shared/dateFns'
import { type SelectMetricsType } from 'attributionModel/components/Dashboard'
import type { AttributionResp } from 'shared/api/attributionModels'
import type { BarChartAttributionBaseReport } from './components/Dashboard/BarChartComparison'
import type { AttributionModelDetailedReport } from './components/Dashboard/DetailedReport'
import {
  type AttributionModelType,
  getRevenueConversionsFieldFromModelType
} from './components/Dashboard/DetailedReportUtils'
import type { AttributionModelPerformanceSummary } from './components/Dashboard/Summary'

export interface AttributionBaseReport {
  revenue: number
  conversions: number
  impressions: number
  spend: number
  date: string
  channel: string
}

interface AggRecordType {
  date: string
  revenue?: number
  conversions?: number
}

export interface LineData {
  [key: string]: number | string
  x: string
}

export const transformAttributionToTimeSeriesData = (
  data: AttributionBaseReport[],
  metric: SelectMetricsType,
  channels: string[]
): LineData[] => {
  const attributionDataSchemaMetric: 'revenue' | 'conversions' = metric === 'conversions' ? 'conversions' : metric

  const lineDataByChannel: Record<string, LineData[]> = {}

  channels.forEach((channel) => {
    // filter by selected channel and extract the metric and date
    const tsRecords: AggRecordType[] = data
      .filter((record) => record.channel === channel)
      .map((record) => ({
        [metric]: record[attributionDataSchemaMetric],
        date: record.date
      }))

    // aggregate by date and channel
    const aggregatedRecords: Record<string, AggRecordType> = {}
    tsRecords.forEach((record) => {
      const aggRecord = aggregatedRecords[record.date]
      if (aggRecord != null) {
        aggregatedRecords[record.date] = {
          [metric]: defaultToZero(aggRecord[metric]) + defaultToZero(record[metric]),
          date: record.date
        }
      } else {
        aggregatedRecords[record.date] = record
      }
    })

    // sort by date and format the date
    const sortedAggregateRecords: LineData[] = Object.values(aggregatedRecords)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .map((record) => ({
        [metric]: record[metric] as number,
        dateFull: formatDateToString({ date: record.date }),
        x: formatDateToString({ date: record.date, targetFormat: 'MMM do' })
      }))

    lineDataByChannel[channel] = sortedAggregateRecords
  })

  // join line data by x
  const lineData: LineData[] = []
  const xValues: string[] = []
  channels.forEach((channel) => {
    lineDataByChannel[channel].forEach((record) => {
      if (!xValues.includes(record.x)) {
        xValues.push(record.x)
        lineData.push({ x: record.x, dateFull: record.dateFull, [channel]: record[metric] })
      } else {
        const existingRecord = lineData.find((r) => r.x === record.x)
        if (existingRecord != null) {
          existingRecord[channel] = record[metric]
        }
      }
    })
  })

  return lineData
} // Simple Tranformations on the Data

export const convertFieldToSumField = (field: string): keyof Omit<AttributionChannelAggregatedResponse, 'channel'> => {
  // add 'sum' as a prefix and capitalize the first letter of field
  const prefix = 'sum'
  const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1)
  return `${prefix}${capitalizedField}` as keyof Omit<AttributionChannelAggregatedResponse, 'channel'>
}

export const transformDataForComparisionTable = (
  attributionData: AttributionChannelAggregatedResponse[],
  attributionModelType: AttributionModelType
): AttributionModelDetailedReport[] => {
  const { revenue, conversions } = getRevenueConversionsFieldFromModelType(attributionModelType)

  const revenueField = convertFieldToSumField(revenue)
  const conversionsField = convertFieldToSumField(conversions)

  return attributionData.map((item) => {
    const revenue: number = defaultToZero(Number(item[revenueField]))
    const conversions: number = defaultToZero(Number(item[conversionsField]))
    const spend: number = item.sumSpend ?? 0
    const impressions: number = item.sumImpressions ?? 0
    return {
      channel: item.channel,
      revenue,
      roas: spend === 0 ? null : revenue / spend,
      conversions,
      adSpend: spend,
      costPerAction: conversions === 0 ? null : spend / conversions,
      impressions
    }
  })
}

export const transformPeformanceData = (
  integratedAttribution: AttributionResp | undefined,
  attributionData: AttributionChannelAggregatedResponse[]
): AttributionModelPerformanceSummary => {
  if (integratedAttribution == null) {
    return {
      adSpend: 0,
      conversions: 0,
      revenue: 0,
      roas: undefined,
      costPerAction: undefined
    }
  }

  const { revenue, conversions } = getRevenueConversionsFieldFromModelType(integratedAttribution?.creditInput)

  const revenueField = convertFieldToSumField(revenue)
  const conversionsField = convertFieldToSumField(conversions)

  const totalSpend = attributionData.reduce((acc, curr) => {
    return acc + (curr.sumSpend ?? 0)
  }, 0)

  const totalConversions = attributionData.reduce((acc, curr) => {
    return acc + (curr[conversionsField] ?? 0)
  }, 0)

  const totalRevenue = attributionData.reduce((acc, curr) => {
    return acc + Number(curr[revenueField])
  }, 0)

  return {
    adSpend: totalSpend,
    conversions: totalConversions,
    revenue: totalRevenue,
    roas: defaultToZero(totalSpend) === 0 ? undefined : totalRevenue / totalSpend,
    costPerAction: defaultToZero(totalConversions) === 0 ? undefined : totalSpend / totalConversions
  }
}

export const transformDataForBarChart = (
  attributionData: AttributionChannelAggregatedResponse[],
  integratedAttribution: AttributionResp | undefined
): BarChartAttributionBaseReport[] => {
  const { revenue, conversions } = getRevenueConversionsFieldFromModelType(integratedAttribution?.creditInput)

  const revenueField = convertFieldToSumField(revenue)
  const conversionsField = convertFieldToSumField(conversions)

  return attributionData.map((item) => {
    return {
      revenue: item[revenueField] ?? 0,
      conversions: item[conversionsField] ?? 0,
      spend: item.sumSpend ?? 0,
      channel: item.channel,
      impressions: item.sumImpressions ?? 0,
      ga4DefaultAttributionRevenue: item[convertFieldToSumField('ga4DefaultAttributionRevenue')] ?? 0,
      ga4DefaultAttributionConversions: item[convertFieldToSumField('ga4DefaultAttributionConversions')] ?? 0,
      lndaRevenue: item[convertFieldToSumField('lndaRevenue')] ?? 0,
      lndaConversions: item[convertFieldToSumField('lndaConversions')] ?? 0,
      ga4LndaAttributionRevenue: item[convertFieldToSumField('ga4LndaAttributionRevenue')] ?? 0,
      ga4LndaAttributionConversions: item[convertFieldToSumField('ga4LndaAttributionConversions')] ?? 0
    }
  })
}

export const transformDataforTimeSeries = (
  attributionData: AttributionResponse[],
  integratedAttribution: AttributionResp | undefined
): AttributionBaseReport[] => {
  const { revenue: revenueField, conversions: conversionsField } = getRevenueConversionsFieldFromModelType(
    integratedAttribution?.creditInput
  )

  return attributionData.map((item) => {
    return {
      date: item.date ?? '1900-01-01',
      channel: item.channel,
      revenue: item[revenueField] ?? 0,
      conversions: item[conversionsField] ?? 0,
      impressions: 0,
      spend: 0
    }
  })
}
