import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Grid,
  Input,
  Link,
  SelectInput,
  SelectItem,
  Tooltip,
  Typography
} from '@precis-digital/kurama'
import { PLATFORM } from 'dataSource'
import { getPlatformDetailsByPlatform } from 'dataSource/utils'
import { useQueryClientAccountPlatform, useQueryClientAccounts } from 'dataSource/api'
import { isEmpty } from 'ramda'
import { useFormContext, type ControllerRenderProps } from 'react-hook-form'
import type { ClientAccountsResp, Platforms } from 'shared/api/accounts'
import { AccordionFormStep, AccordionFormStepItem } from 'shared/components/AccordionFormStep'
import FormItem from 'shared/components/FormItem'
import { DIMENSION_OPERATORS } from 'shared/constants/filterOperators'
import { useCurrentClient } from 'shared/context/ClientContext'
import OverScreen from 'shared/overScreens'
import { getNumberOfRequiredInputsPerSection } from 'shared/reactHookForm'
import { useTranslation } from 'shared/translations'
import type { AttributionFormData, StepProps } from '..'
import { DEFAULT_CONVERSION_EVENT, DEFAULT_CONVERSION_EVENT_FACEBOOK, MULTIPLE_PLATFORMS } from '../constants'
import {
  allowToEdit,
  getAccountsWithSupportedPlatforms,
  getChosenPlatformFromApi,
  getInputRules,
  checkShouldShowAnalyticsAccount
} from '../utils'
import { getLabelsForPlatform, type FilterFormValues } from './FilterFlyout'
import { type CustomDataSourcesFormData } from './CustomDataSourcesFlyout'
import { APP_ROUTES } from 'shared/routes'
import { BUDGET_OPTIMISER_FACEBOOK_CONVERSION_ACTIONS_LINK } from 'shared/constants/links'
import { useQueryChannelGroupings } from 'channelGrouping/api'
import { FEATURE_FLAGS, useFeatureFlag } from 'shared/featureFlag'
import { ASSETS_BASE_URL } from 'shared/constants'
const GOOGLE_BIGQUERY_ICON_URL = `${ASSETS_BASE_URL}/google_bigquery.svg`

export interface OtherAccountOption {
  label: string
  id: string
  platform: string
}

const AccountSetup = ({
  stepNumber,
  title,
  isPreviousOrCurrentStep,
  isNewConfig,
  handleUpdate,
  toggleEdit,
  editModes,
  optimizeBudget,
  isCopyConfig
}: StepProps): React.ReactElement => {
  const { t } = useTranslation('attributionModel')
  const { currentClient } = useCurrentClient()
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext<AttributionFormData>()

  const { account, model, isPlatformConversion } = getValues()
  const formData = getValues()

  const platformAnalytics: Platforms = PLATFORM.GA4

  const { data: clientAccountAnalytics } = useQueryClientAccountPlatform(currentClient.id, platformAnalytics)

  const { data: clientAccounts } = useQueryClientAccounts(currentClient.id)

  const { data: channelGroupings } = useQueryChannelGroupings(currentClient?.id)

  const { value: canViewAttributionRbaCustomDataSources } = useFeatureFlag(
    FEATURE_FLAGS.attributionRbaCustomDataSources
  )

  const accountsWithSupportedPlatforms = getAccountsWithSupportedPlatforms(clientAccounts)

  const filteredAccounts = clientAccounts?.filter(
    (account) =>
      account.platform !== PLATFORM.GA4 &&
      account.platform !== PLATFORM.ANALYTICS &&
      (account.platform !== PLATFORM.GOOGLE || !account.canManageClients)
  )

  const filterData = account?.filters ?? []
  const spendSourceData = watch('customDataSources') ?? []

  const addFormData = (data: FilterFormValues): void => {
    const formFilters = [data, ...filterData]
    setValue('account.filters', formFilters)
  }

  const addCustomDataSources = (data: CustomDataSourcesFormData): void => {
    const CustomDataSources = [data, ...spendSourceData]
    setValue('customDataSources', CustomDataSources)
  }

  const resetFormData = (): void => {
    setValue('account.filters', [])
  }

  const removeFormData = (index: number): void => {
    setValue(
      'account.filters',
      filterData.filter((_, i) => i !== index)
    )
  }

  const filterFormData = getValues()

  const handleOnFilterClick = (): void => {
    void OverScreen.show('attributionFilterFlyout', {
      accounts: getChosenAccounts(),
      onSubmit: (data: FilterFormValues) => {
        addFormData(data)
      }
    })
  }

  const handleOnCustomDataSourcesClick = (): void => {
    void OverScreen.show('attributionCustomDataSourcesFlyout', {
      onSubmit: (data: CustomDataSourcesFormData) => {
        addCustomDataSources(data)
      }
    })
  }

  const getAccountPlatform = (id?: string): Platforms | undefined => {
    return clientAccounts?.find((account) => Number(account.id) === Number(id))?.platform
  }

  const getAccountName = (id?: string | number): string => {
    const toReturn =
      clientAccounts?.find((account) => (typeof id === 'string' ? account.id.toString() === id : account.id === id))
        ?.name ?? ''
    return toReturn
  }

  const getDimensionLabel = (id?: string, accountId?: string): string => {
    return (
      getLabelsForPlatform(getAccountPlatform(accountId) as Platforms)?.find((dimension) => dimension.id === id)
        ?.label ?? ''
    )
  }

  const getOperatorLabel = (id?: string): string => {
    return Object.values(DIMENSION_OPERATORS).find((condition) => condition.id === id)?.label ?? ''
  }

  const chipLabels = (data: FilterFormValues): string => {
    const label = []
    if (data.selectedAccount != null) label.push(getAccountName(data.selectedAccount))
    if (data.selectedDimension != null) label.push(getDimensionLabel(data.selectedDimension, data.selectedAccount))
    if (data.operator != null) label.push(getOperatorLabel(data.operator))
    if (data.value != null) label.push(data.value)
    const toReturn = label
      .map((item, index) => (index === 0 ? `${item} • ` : index === 1 ? `${item}` : `, ${item}`))
      .join('')
    return toReturn
  }

  const getChosenAccounts = (): ClientAccountsResp[] => {
    const chosenAccounts: ClientAccountsResp[] = []
    if (account?.analyticsAccountId !== undefined) {
      const selectedAnalyticsAccount = clientAccountAnalytics?.find(
        (a) => Number(a.id) === Number(account.analyticsAccountId)
      )
      if (selectedAnalyticsAccount != null) chosenAccounts.push(selectedAnalyticsAccount)
    }
    if (Array.isArray(account?.otherAccounts)) {
      account.otherAccounts.forEach((account: OtherAccountOption) => {
        const selectedAccount = filteredAccounts?.find((a) => Number(a.id) === Number(account.id))
        if (selectedAccount != null) chosenAccounts.push(selectedAccount)
      })
    }
    return chosenAccounts
  }

  const getAccountFromId = (id?: string): ClientAccountsResp | undefined => {
    return clientAccounts?.find((account) => account.id === Number(id))
  }

  const shouldShowAnalyticsAccount: boolean = checkShouldShowAnalyticsAccount(
    isCopyConfig,
    account?.analyticsAccountPlatform
  )
  const addedPlatformConversion = isNewConfig
    ? model?.platform !== undefined || shouldShowAnalyticsAccount
    : isPlatformConversion

  const isFacebook =
    model?.platform === PLATFORM.FACEBOOK ||
    account?.otherAccounts?.some((account) => account.platform === PLATFORM.FACEBOOK)

  const isGa4 = getAccountPlatform(account?.analyticsAccountId) === PLATFORM.GA4

  const chosenAndSupportedAccounts = accountsWithSupportedPlatforms?.filter(
    (account) => account.platform === model?.platform
  )

  const chosenPlatforms = formData.account?.otherAccounts?.map((account) => account.platform) ?? []

  const chosenAndSupportedAccountsForEdit = accountsWithSupportedPlatforms?.filter(
    (clientAccount) => clientAccount.platform === getChosenPlatformFromApi(chosenPlatforms)
  )

  const chosenSupportedAccountIfNewConfig =
    isNewConfig && !isCopyConfig ? chosenAndSupportedAccounts : chosenAndSupportedAccountsForEdit

  const ifNewConfig = isNewConfig
    ? model?.platform === MULTIPLE_PLATFORMS
    : getChosenPlatformFromApi(chosenPlatforms) === MULTIPLE_PLATFORMS

  const onlyFilterWhenChosenAPLatform = ifNewConfig ? accountsWithSupportedPlatforms : chosenSupportedAccountIfNewConfig

  const filteredAccountsToShow = addedPlatformConversion
    ? onlyFilterWhenChosenAPLatform
    : accountsWithSupportedPlatforms

  const filteredAccountsToShowWithIdAsString = filteredAccountsToShow?.map((account) => ({
    ...account,
    id: account.id.toString()
  }))

  const sortAccount = filteredAccountsToShowWithIdAsString?.sort((a, b) => {
    if (a.platform < b.platform) {
      return -1
    }
    if (a.platform > b.platform) {
      return 1
    }
    return a.name.localeCompare(b.name)
  })

  const showHelperTextWhenMissingPlatform = isEmpty(filteredAccountsToShow)
    ? t('createModel.noPlatformAccounts', { missingPlatform: model?.platform })
    : undefined

  const findchannelGroupingFromId = channelGroupings?.find((channelGrouping) => {
    return channelGrouping.id === filterFormData.channelGrouping?.channelGroupingId
  })

  const allChannelGroupingDetails = findchannelGroupingFromId?.channelGrouping

  const getIfPlatformIsNotMatching = (platform: string): boolean => {
    return !Object.keys(allChannelGroupingDetails ?? {}).includes(platform)
  }

  const handleChipDelete = (
    field: ControllerRenderProps<AttributionFormData, 'customDataSources'>,
    index: number
  ): void => {
    const newValue = [...(field.value ?? [])]
    newValue.splice(index, 1)
    field.onChange(newValue)
  }

  return (
    <AccordionFormStep stepName={t('form.step', { stepNumber })} title={title} expanded={isPreviousOrCurrentStep}>
      <AccordionFormStepItem
        expanded={!isNewConfig}
        t={t}
        title={t('createModel.basic')}
        requiredFieldsTally={getNumberOfRequiredInputsPerSection({
          data: filterFormData,
          sectionName: `account`,
          errors,
          getInputRules,
          isCopyConfig
        })}
        isCreateEvent={isNewConfig}
      >
        {!addedPlatformConversion && (
          <FormItem
            name="account.analyticsAccountId"
            label={t('createModel.accountHeader')}
            rules={getInputRules({ fieldName: 'account.analyticsAccountId' })}
            control={control}
            helperText={t('createModel.sharedHelperText.accountHeader')}
            {...(isEmpty(clientAccountAnalytics) && {
              helperText: (
                <>
                  {t('form.noGoogleAnalyticsAccount')}{' '}
                  <Link href={APP_ROUTES.dataSources.basePage} style={{ fontSize: '0.95em' }}>
                    {t('form.addGoogleAnalyticsAccount')}
                  </Link>
                </>
              )
            })}
            render={({ field: { value, ...otherFields } }) => {
              return (
                <SelectInput
                  fullWidth
                  disabled={isEmpty(clientAccountAnalytics)}
                  value={value ?? ''}
                  {...otherFields}
                  onChange={(event) => {
                    if (event.target.value != null) {
                      const account = getAccountFromId(event.target.value as string)
                      setValue('account.analyticsAccountId', event.target.value as string)
                      setValue('account.analyticsAccountName', account?.name as string)
                      setValue('account.analyticsAccountPlatform', account?.platform as string)
                    }
                  }}
                >
                  {clientAccountAnalytics?.map((account) => {
                    return (
                      <SelectItem
                        key={account.accountId}
                        value={account.id}
                        disabled={isNewConfig ? false : getIfPlatformIsNotMatching(account.platform)}
                      >
                        <Grid display="flex" gap="6px">
                          <PlatformAvatar account={account} />
                          {account.name}
                        </Grid>
                      </SelectItem>
                    )
                  })}
                </SelectInput>
              )
            }}
            editModes={editModes}
            toggleEdit={toggleEdit}
            handleUpdate={handleUpdate}
            isCreateEvent={isNewConfig}
            canEdit={allowToEdit(optimizeBudget, filterFormData)}
            currentValue={
              clientAccountAnalytics?.find((option) => {
                return Number(option.id) === Number(getValues('account.analyticsAccountId'))
              })?.name
            }
          />
        )}
        <FormItem
          name="account.otherAccounts"
          helperText={
            showHelperTextWhenMissingPlatform != null
              ? showHelperTextWhenMissingPlatform
              : optimizeBudget
              ? t('createModel.boHelperText.selectOtherAccounts')
              : t('createModel.amHelperText.selectOtherAccounts')
          }
          label={t('createModel.selectOtherAccounts', {
            accountType: getPlatformDetailsByPlatform(model?.platform)?.label
          })}
          rules={getInputRules({ fieldName: 'account.otherAccounts' })}
          {...(isEmpty(filteredAccountsToShow) && {
            helperText: (
              <>
                {t('form.noOtherAccounts')}{' '}
                <Link href={APP_ROUTES.dataSources.basePage} style={{ fontSize: '0.95em' }}>
                  {t('form.addOtherAccounts')}
                </Link>
              </>
            )
          })}
          render={({ field }) => {
            const valueOption =
              field.value?.map((option) => ({
                value: option.id,
                label: option.label,
                id: option.id,
                platform: option.platform,
                icon: (
                  <Avatar
                    imageUrl={getPlatformIconUrl(getPlatformDetailsByPlatform(option.platform)?.platform) ?? ''}
                    kind="image"
                    size="small"
                  />
                )
              })) ?? []
            return (
              <Autocomplete
                options={
                  sortAccount?.map((account, index) => ({
                    value: account.id,
                    label: account.name,
                    disabled: isNewConfig ? false : getIfPlatformIsNotMatching(account.platform),
                    id: account.id,
                    platform: account.platform,
                    icon: <PlatformAvatar account={account} />,
                    subCategory: getPlatformDetailsByPlatform(account.platform)?.label,
                    annotation: account.id
                  })) ?? []
                }
                value={valueOption}
                multiple
                fullWidth
                allowCustomInput={false}
                onChange={(event, newValue) => {
                  field.onChange(newValue)
                }}
              />
            )
          }}
          editModes={editModes}
          toggleEdit={toggleEdit}
          handleUpdate={handleUpdate}
          control={control}
          isCreateEvent={isNewConfig}
          canEdit={allowToEdit(optimizeBudget, filterFormData)}
          currentValue={
            <>
              {account?.otherAccounts?.map((account: OtherAccountOption) => {
                const accountDetails = getAccountFromId(account.id)

                return (
                  <Grid display="flex" gap="8px" marginTop="8px" key={account.id}>
                    <Tooltip
                      title={`${accountDetails?.name as string} (${accountDetails?.externalAccountId as string})`}
                      kind="singleline"
                      placement="bottom-start"
                    >
                      <Grid>
                        <Chip
                          avatarSrc={getPlatformIconUrl(getPlatformDetailsByPlatform(account.platform)?.platform)}
                          label={account.label}
                        />
                      </Grid>
                    </Tooltip>
                  </Grid>
                )
              })}
            </>
          }
        />
        {model?.id === 'rba' && canViewAttributionRbaCustomDataSources && (
          <FormItem
            name="customDataSources"
            label={t('createModel.addCustomDataSources')}
            helperText={t('createModel.sharedHelperText.addCustomDataSources')}
            canEdit={allowToEdit(optimizeBudget, filterFormData)}
            handleUpdate={handleUpdate}
            toggleEdit={toggleEdit}
            control={control}
            isCreateEvent={isNewConfig}
            currentValue={
              <>
                {getValues('customDataSources')?.map((data, index) => {
                  const avatarSrc =
                    data.selectedPlatform != null ? getPlatformIconUrl(data.selectedPlatform) : undefined
                  const chipLabel = `${data.accountName ?? ''}.${data.datasetId ?? ''}.${data.tableId ?? ''}`
                  return (
                    <Grid
                      marginTop="8px"
                      key={`${data.selectedPlatform ?? ''}-${data.datasetId ?? ''}-${data.tableId ?? ''}`}
                    >
                      <Chip label={chipLabel} avatarSrc={avatarSrc} />
                    </Grid>
                  )
                })}
              </>
            }
            render={({ field }) => {
              return (
                <Grid display="flex" flexDirection="column" justifyContent="start">
                  {field.value?.map((data, index) => {
                    const avatarSrc =
                      data.selectedPlatform != null ? getPlatformIconUrl(data.selectedPlatform) : undefined
                    const chipLabel = `${data.accountName ?? ''}.${data.datasetId ?? ''}.${data.tableId ?? ''}`
                    return (
                      <Grid marginTop="8px" key={chipLabel}>
                        <Chip
                          label={chipLabel}
                          onDelete={() => {
                            handleChipDelete(field, index)
                          }}
                          avatarSrc={avatarSrc}
                        />
                      </Grid>
                    )
                  })}
                  <Grid display="flex" width="100%" marginTop="20px" gap="8px">
                    <Button variant="filled" onClick={handleOnCustomDataSourcesClick}>
                      {t('createModel.addCustomDataSources')}
                    </Button>
                  </Grid>
                </Grid>
              )
            }}
            editModes={editModes}
          />
        )}
      </AccordionFormStepItem>
      <AccordionFormStepItem title={t('createModel.advanced')}>
        <FormItem
          name="account.filters"
          label={t('createModel.addFilters')}
          rules={{ required: false }}
          helperText={t('createModel.sharedHelperText.addFilters')}
          currentValue={
            <>
              {account?.filters?.map((filter) => (
                <Grid marginTop="8px" key={filter.selectedAccount}>
                  <Chip
                    avatarSrc={getPlatformIconUrl(getAccountPlatform(filter.selectedAccount))}
                    label={chipLabels(filter)}
                  />
                </Grid>
              ))}
            </>
          }
          canEdit={allowToEdit(optimizeBudget, filterFormData)}
          handleUpdate={handleUpdate}
          toggleEdit={toggleEdit}
          control={control}
          isCreateEvent={isNewConfig}
          render={({ field }) => {
            return (
              <Grid display="flex" flexDirection="column" justifyContent="start">
                {filterData.map((data, index) => {
                  const avatarSrc =
                    data.selectedAccount != null
                      ? getPlatformIconUrl(getAccountPlatform(data.selectedAccount))
                      : undefined
                  return Object.keys(filterData).length > 0 ? (
                    <Grid marginTop="8px">
                      <Chip
                        label={chipLabels(data)}
                        onDelete={() => {
                          removeFormData(index)
                        }}
                        avatarSrc={avatarSrc}
                      />
                    </Grid>
                  ) : null
                })}

                <Grid display="flex" width="100%" marginTop="20px" gap="8px">
                  <Button variant="filled" onClick={handleOnFilterClick}>
                    {' '}
                    {t('createModel.addFilter')}
                  </Button>
                  <Button
                    disabled={filterData.length === 0}
                    variant="tonal"
                    scheme="light"
                    onClick={() => {
                      resetFormData()
                    }}
                  >
                    {' '}
                    {t('createModel.clearFilter')}
                  </Button>
                </Grid>
              </Grid>
            )
          }}
          editModes={editModes}
        />
        {!optimizeBudget ? (
          <>
            {isGa4 && (
              <FormItem
                name="account.conversionEventAnalytics"
                label={t('createModel.conversionEvent')}
                defaultValue={DEFAULT_CONVERSION_EVENT}
                rules={{ required: false }}
                helperText={t('createModel.amHelperText.conversionEvent')}
                render={({ field }) => {
                  return (
                    <Grid display="flex" flexDirection="column" justifyContent="start">
                      <Grid width="100%" marginTop="8px" marginBottom="25px">
                        <Input {...field} fullWidth />
                      </Grid>
                    </Grid>
                  )
                }}
                editModes={editModes}
                currentValue={getValues('account.conversionEventAnalytics')}
                toggleEdit={toggleEdit}
                handleUpdate={handleUpdate}
                control={control}
                canEdit
                isCreateEvent={isNewConfig}
              />
            )}
          </>
        ) : (
          <>
            {isFacebook === true && (
              <FormItem
                name="account.conversionEventFacebook"
                label={t('createModel.conversionEventFB')}
                defaultValue={DEFAULT_CONVERSION_EVENT_FACEBOOK}
                rules={{ required: false }}
                helperText={
                  <Typography variant="body3">
                    {t('createModel.boHelperText.conversionEventFB')}{' '}
                    <Link href={BUDGET_OPTIMISER_FACEBOOK_CONVERSION_ACTIONS_LINK} target="_blank" rel="noreferrer">
                      <Typography variant="body3">{t('createModel.boHelperText.conversionEventFBLink')}</Typography>
                    </Link>
                    .
                  </Typography>
                }
                render={({ field }) => {
                  const { value, ...other } = field
                  return (
                    <Grid display="flex" flexDirection="column" justifyContent="start">
                      <Grid width="100%" marginTop="8px" marginBottom="25px">
                        <Input value={value ?? DEFAULT_CONVERSION_EVENT_FACEBOOK} {...other} fullWidth />
                      </Grid>
                    </Grid>
                  )
                }}
                editModes={editModes}
                currentValue={getValues('account.conversionEventFacebook')}
                toggleEdit={toggleEdit}
                handleUpdate={handleUpdate}
                control={control}
                canEdit
                isCreateEvent={isNewConfig}
              />
            )}
          </>
        )}
      </AccordionFormStepItem>
    </AccordionFormStep>
  )
}

export default AccountSetup

export const getPlatformIconUrl = (platform?: Platforms): string | undefined => {
  if (platform == null) return undefined
  if (platform === PLATFORM.GOOGLE_BIGQUERY) return GOOGLE_BIGQUERY_ICON_URL
  const dataSourceMeta = getPlatformDetailsByPlatform(platform)
  return dataSourceMeta?.iconUrl
}

type PlatformOnly = Pick<ClientAccountsResp, 'platform'>

interface PlatformAvatarProps {
  account: ClientAccountsResp | PlatformOnly | undefined
}

export const PlatformAvatar = ({ account }: PlatformAvatarProps): React.ReactElement => {
  const imageUrl = getPlatformIconUrl(account?.platform)
  if (imageUrl == null) return <></>
  return <Avatar imageUrl={imageUrl} kind="image" size="small" />
}
