import React, { useMemo, type ReactElement, useEffect } from 'react'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  type CellProps,
  type Row,
  type IdType,
  type FilterValue,
  type PluginHook
} from 'react-table'
import { type ControlledListViewFilter, type ControlledListViewTabs, type ListViewColumnDefinition } from '../types'
import { Grid } from '@precis-digital/kurama'
import TableBody from './TableBody'
import DefaultCell from './TableCells/DefaultCell'
import TableHeader from './TableHeader'
import Pagination from './Pagination'
import { type controlledFilterValues } from '../hooks'
import { EXACT_TEXT_FILTER_TYPE } from 'shared/reactTable/constants'
import EmptyTableBody from './EmptyTableBody'

export interface RowLinkProps<T extends object> {
  children: React.ReactNode
  row: T
}
export interface ListViewTableProps<T extends object> {
  data: T[]
  isLoading: boolean
  columns: Array<ListViewColumnDefinition<T>>
  includePagination?: boolean
  initialState?: Partial<{
    pageIndex: number
    sortBy: Array<{
      id: string
      desc: boolean
    }>
    filters: Array<{
      id: string
      value: FilterValue
    }>
    hiddenColumns?: Array<IdType<T>>
  }>
  onRowClick?: (row: T) => void
  RowLink?: (props: RowLinkProps<T>) => ReactElement
  renderMoreMenu?: (row: T, closeMenu: () => void) => ReactElement
  filterValues?: controlledFilterValues<T>
  controlledFilters?: Array<ControlledListViewFilter<T>>
  tabsValues?: ControlledListViewTabs<T>
  title?: string
}

const ListViewTable = <T extends object>({
  data,
  columns,
  includePagination,
  initialState,
  isLoading,
  onRowClick,
  RowLink,
  renderMoreMenu,
  filterValues,
  controlledFilters,
  tabsValues,
  title
}: ListViewTableProps<T>): ReactElement => {
  const parsedColumns = useMemo(() => {
    const hiddenColumns = initialState?.hiddenColumns ?? []
    let columnsWithNoWidth = 0
    let percentageToShare = 100
    columns.forEach((column) => {
      if (hiddenColumns.includes((column.id ?? column.accessor) as IdType<T>)) return
      if (column.width == null) columnsWithNoWidth += 1
      if (typeof column.width === 'string') {
        if (column.width.endsWith('%')) {
          percentageToShare -= parseFloat(column.width.replace('%', ''))
        }
      }
    })

    const multiSelectFilters = controlledFilters?.filter((filter) => filter.type === 'multi-select')

    return columns.map((column) => {
      const copiedColumn = { ...column }

      if (multiSelectFilters != null) {
        const multiSelectFilter = multiSelectFilters.find(
          (filter) => filter.accessor === (column.id ?? column.accessor)
        )
        if (multiSelectFilter != null) {
          copiedColumn.filter = 'multiSelect'
        }
      }
      if (tabsValues != null && tabsValues.accessor === (column.id ?? column.accessor)) {
        copiedColumn.filter = EXACT_TEXT_FILTER_TYPE
      }

      if (!hiddenColumns.includes((column.id ?? column.accessor) as IdType<T>)) {
        copiedColumn.parsedWidth = column.width != null ? column.width : `${percentageToShare / columnsWithNoWidth}%`
        if (copiedColumn.Cell == null) {
          const CellComponent = (props: CellProps<T>): ReactElement => <DefaultCell {...props} />
          copiedColumn.Cell = CellComponent
        }
      }

      return copiedColumn
    })
  }, [columns, controlledFilters, initialState?.hiddenColumns, tabsValues])

  const singleSelectFilter = <T extends object>(
    rows: Array<Row<T>>,
    columnIds: Array<IdType<T>>,
    filterValue: FilterValue
  ): Array<Row<T>> => {
    const id = columnIds[0]
    if (filterValue == null || filterValue?.length === 0) return rows

    return rows.filter((row) => {
      const rowValue = row.values[id]
      return filterValue === rowValue
    })
  }

  const multiSelectFilter = <T extends object>(
    rows: Array<Row<T>>,
    columnIds: Array<IdType<T>>,
    filterValue: FilterValue
  ): Array<Row<T>> => {
    const id = columnIds[0]
    if (filterValue == null || filterValue?.length === 0) return rows

    return rows.filter((row) => {
      const rowValue = row.values[id]
      return filterValue?.includes(rowValue)
    })
  }

  const filterTypes = useMemo(
    () => ({
      singleSelect: singleSelectFilter,
      multiSelect: multiSelectFilter
    }),
    []
  )

  const tableHooks: Array<PluginHook<T>> = [useFilters, useSortBy]

  if (includePagination === true) {
    tableHooks.push(usePagination)
  }

  const table = useTable<T>(
    {
      columns: parsedColumns,
      data,
      initialState,
      filterTypes
    },
    ...tableHooks
  )

  useEffect(() => {
    if (filterValues != null) {
      Object.keys(filterValues).forEach((key) => {
        table.setFilter(key, filterValues[key])
      })
    }
  }, [filterValues, table])

  useEffect(() => {
    if (tabsValues != null) {
      table.setFilter(
        tabsValues.accessor,
        tabsValues.options.find((option) => option.tabValue === tabsValues.value)?.value
      )
    }
  }, [tabsValues, table])

  const hasData = table?.rows?.length > 0

  return (
    <Grid container>
      <TableHeader
        headerGroup={table.headerGroups[0]}
        isClickable={onRowClick != null || RowLink != null}
        hasMoreMenu={renderMoreMenu != null}
        isLoading={isLoading}
      />

      {!hasData && !isLoading && <EmptyTableBody entity={title} />}

      <TableBody
        page={includePagination === true ? table.page : table.rows}
        prepareRow={table.prepareRow}
        onRowClick={onRowClick}
        RowLink={RowLink}
        isLoading={isLoading}
        renderMoreMenu={renderMoreMenu}
      />

      {hasData && includePagination === true && !isLoading && (
        <Pagination
          pageIndex={table.state.pageIndex}
          setPageSize={table.setPageSize}
          pageSize={table.state.pageSize}
          rowCount={table.rows.length}
          gotoPage={table.gotoPage}
        />
      )}
    </Grid>
  )
}

export default ListViewTable
