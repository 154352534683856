import { forwardRef, useEffect, useMemo, useState } from 'react'

import { FormProvider, useForm, useWatch, type FieldPath } from 'react-hook-form'
import FormHeader from 'shared/components/Form/FormHeader'
import StatusWithBadge from 'shared/components/StatusWithBadge'
import AccountSetup, { type OtherAccountOption } from './AccountStep'

import { Button, Card, Dropdown, Grid, MoreIcon, Tooltip, Typography, styled } from '@precis-digital/kurama'
import {
  useMutationCreateAttribution,
  useMutationUpdateAttribution,
  useQueryAttributionConfigStatuses,
  useQueryIntegratedAttribution
} from 'attributionModel/api'
import { type DataSourceItem } from 'channelGrouping/types'
import { useQueriesUserProfilesFromIAConfigs } from 'profile/api'
import { isEmpty, not } from 'ramda'

import FormProgress from 'shared/components/FormProgress'
import MemberTimestampLine from 'shared/components/MemberTimestampLine'
import { useChangePath } from 'shared/components/Router'
import { makeToastWithLoading } from 'shared/components/Toaster'
import { getErrorCodeDetails, getStatusMarketingEvaluation } from 'shared/configStatuses'
import type { DimensionType, OperatorType } from 'shared/constants/filterOperators'
import { useCurrentClient } from 'shared/context/ClientContext'
import useCurrentFormStep from 'shared/hooks/useCurrentFormStep'
import { useHasAccess } from 'shared/hooks/useHasAccess'
import { getRequiredFields } from 'shared/reactHookForm'
import { useTranslation } from 'shared/translations'
import MoreMenu from '../MoreMenu'
import ChannelGroupingStep from './ChannelGroupingStep'
import DatasetStep from './DatasetsStep'
import GeneralStep from './GeneralStep'
import ModelStep from './ModelStep'
import { allowToEdit, formatApiConfigIntoFormData, formatFormDataIntoApiConfig } from './utils'
import { APP_ROUTES } from 'shared/routes'
import { useQueryBudgetOptimiserConfigStatuses } from 'budgetOptimiser/api'
import { type ClientError } from 'shared/api/fetch'
import { useMember } from 'workspace'
import EditOrCreatePageLayout from 'shared/components/EditOrCreatePageLayout'
import NotFoundPage from 'shared/components/Error/NotFoundPage'
import { type CustomDataSourcesFormData } from './AccountStep/CustomDataSourcesFlyout'
import CopyConfigTooltip, { type Field as CopyConfigTooltipField } from 'shared/components/CopyConfigTooltip'
import { isNewNonCopyConfigCheck } from 'shared/utils'

export interface Channel {
  name: string
  id: string
  include?: boolean
  impression?: boolean
  redistribution?: number
  dataSources?: DataSourceItem[]
  expectedROAS?: number
  adStock?: number
}

export interface Rule {
  selectedAccount: string
  selectedDimension: string
  operator: DimensionType | OperatorType
  value: string
}

export type ModelType = 'ddav2' | 'xgboost' | 'native' | 'lnda' | 'rba'

export interface ModelData {
  id?: ModelType
  alvieModelId?: number
  platform?: string
}
export interface AttributionFormData {
  name: string
  model: ModelData
  customDataSources?: CustomDataSourcesFormData[]
  account: {
    analyticsAccountId?: string
    analyticsAccountPlatform?: string
    analyticsAccountName?: string
    otherAccounts?: OtherAccountOption[]
    filters?: Rule[]
    conversionEventAnalytics: string
    conversionEventFacebook?: string
  }
  datasets: {
    gcp: string
    target: string
    source: string
  }
  channelGrouping: {
    channelGroupingId: number
    channels: Channel[]
  }
  general?: {
    lookbackDays: string
    currency: string
    intercept?: boolean
    weighting?: number
  }
  isCreditAllocatorAndPerformanceCurve?: boolean
  isPlatformConversion: boolean
  isCreditAllocator: boolean
}

interface BaseStepProps {
  optimizeBudget: boolean
  stepNumber: number
  title: string
  handleUpdate?: HandleUpdateFunction
  defaultValues?: AttributionFormData
  toggleEdit?: ToggleEditFunction
  editModes?: Record<string, boolean>
  isPreviousOrCurrentStep: boolean
}

type HandleUpdateFunction = (fieldName: FieldPath<AttributionFormData>, onSuccessHandler?: () => void) => void
type ToggleEditFunction = (fieldName: FieldPath<AttributionFormData>) => void

interface CreateStepProps extends BaseStepProps {
  isNewConfig: true
  isCopyConfig: false
}
interface EditStepProps extends BaseStepProps {
  isNewConfig: false
  handleUpdate: HandleUpdateFunction
  toggleEdit: ToggleEditFunction
  editModes: Record<string, boolean>
  isCopyConfig: boolean
}

interface CreateCopyStepProps extends BaseStepProps {
  isNewConfig: true
  isCopyConfig: true
}

export type StepProps = CreateStepProps | EditStepProps | CreateCopyStepProps

const EditOrCreateModel = ({
  optimizeBudget = false,
  isNewConfig = true,
  modelId,
  isCopyConfig = false
}: {
  optimizeBudget?: boolean
  isNewConfig: boolean
  modelId?: number
  isCopyConfig?: boolean
}): React.ReactElement => {
  const { t: tCommon } = useTranslation('common')

  const { currentClient } = useCurrentClient()

  const isNewNonCopyConfig = isNewNonCopyConfigCheck({
    isNewConfig,
    isCopyConfig
  })

  const { mutate: createIntegrationAttributionModel, isLoading: isCreating } = useMutationCreateAttribution()
  const { mutate: updateIntegrationAttributionModel } = useMutationUpdateAttribution()
  const { data: config, isSuccess: isConfigSuccess } = useQueryIntegratedAttribution(
    modelId ?? 0,
    currentClient?.id,
    !isNewNonCopyConfig
  )

  const isBudgetOptimiserOnlyConfig = config?.creditAllocator === false

  const userDetails = useQueriesUserProfilesFromIAConfigs(config != null ? [config] : undefined)

  const configData = useMemo(() => {
    if (isConfigSuccess) {
      return formatApiConfigIntoFormData(config)
    }
  }, [isConfigSuccess, config])

  const useFormDataAndFunctions = useForm<AttributionFormData>({
    defaultValues: configData,
    mode: 'onChange',
    shouldUnregister: optimizeBudget ? false : isNewNonCopyConfig,
    values: configData
  })

  const { getValues, formState, control, trigger: triggerValidation, handleSubmit } = useFormDataAndFunctions

  const formData = getValues()

  const FIELDS_TO_WITH_DEPENDENT_FIELDS: Array<FieldPath<AttributionFormData>> = [
    'model',
    'account',
    'datasets',
    'channelGrouping',
    'general'
  ]

  const requiredFields = getRequiredFields(formState.errors)
  const dataToWatch = useWatch({
    name: FIELDS_TO_WITH_DEPENDENT_FIELDS,
    control
  })

  useEffect(() => {
    void triggerValidation()
  }, [triggerValidation, dataToWatch])

  const [editModes, setEditModes] = useState<Record<string, boolean>>({})

  const toggleEdit = (fieldName: string): void => {
    setEditModes((prevModes) => {
      return {
        ...prevModes,
        [fieldName]: !prevModes[fieldName]
      }
    })
  }

  const arrangedFormSteps: Array<{
    key: keyof AttributionFormData
    stepTitle: string
    StepComponent: React.FC<StepProps>
  }> = useMemo(
    () => [
      {
        key: 'model',
        stepTitle: 'Model',
        StepComponent: ModelStep
      },
      {
        key: 'account',
        stepTitle: 'Account',
        StepComponent: AccountSetup
      },
      {
        key: 'datasets',
        stepTitle: 'Datasets',
        StepComponent: DatasetStep
      },
      {
        key: 'channelGrouping',
        stepTitle: 'Channel Grouping',
        StepComponent: ChannelGroupingStep
      },
      ...(formData.model?.id !== 'rba'
        ? [
            {
              key: 'general' as keyof AttributionFormData,
              stepTitle: 'General',
              StepComponent: GeneralStep
            }
          ]
        : [])
    ],
    [formData.model?.id]
  )

  const { t, i18n } = useTranslation('attributionModel')

  const currentStep = useCurrentFormStep(arrangedFormSteps, formState, isCopyConfig)

  const getInputLabels = ({
    fieldName,
    data
  }: {
    fieldName: FieldPath<AttributionFormData>
    data: AttributionFormData
  }): string => {
    const STATIC_INPUT_LABELS: Partial<Record<FieldPath<AttributionFormData>, string>> = {
      name: 'Model name',
      'model.id': 'Model type',
      account: 'Account Label',
      datasets: 'Datasets Label',
      channelGrouping: 'Channel Grouping Label',
      general: 'General Label',
      'datasets.gcp': 'Google Cloud project',
      'datasets.target': 'Target dataset',
      'datasets.source': 'Source dataset',
      'account.analyticsAccountId': 'Google Analytics account',
      'account.analyticsAccountPlatform': 'Google Analytics Platform',
      'account.analyticsAccountName': 'Google Analytics Name',
      'account.conversionEventAnalytics': 'Conversion event',
      'account.conversionEventFacebook': 'Conversion event',
      'channelGrouping.channelGroupingId': 'Channel grouping',
      'channelGrouping.channels': 'Channels',
      'general.lookbackDays': 'Lookback days'
    }

    return STATIC_INPUT_LABELS[fieldName] ?? ''
  }

  const { changePath } = useChangePath()

  const handleFormErrors = (defaultErrorMessage: string, error: ClientError): void => {
    const { toastOnError } = makeToastWithLoading({ persistThroughRouteChange: true })

    const errorMessage = error.message.toLowerCase()
    const isChannelGroupingError = errorMessage.includes('channel grouping is missing required data source')

    toastOnError(isChannelGroupingError ? t('form.configChannelGroupingMismatch') : defaultErrorMessage)
  }

  const handleSave = async (activate: boolean = true): Promise<void> => {
    const { toastOnSuccess } = makeToastWithLoading({ persistThroughRouteChange: true })
    const isOptimizable = optimizeBudget
    await handleSubmit((data) => {
      const config = formatFormDataIntoApiConfig(
        data,
        activate,
        Number(currentClient.id),
        isOptimizable,
        isNewConfig,
        isCopyConfig
      )
      createIntegrationAttributionModel(config, {
        onSuccess: async () => {
          toastOnSuccess(t('form.configCreated'))
          await changePath(optimizeBudget ? APP_ROUTES.budgetOptimiser.basePage : APP_ROUTES.attributionModels.basePage)
        },
        onError: (error) => {
          handleFormErrors(t('form.configNotCreatedError'), error)
        }
      })
    })()
  }

  const handleEdit: HandleUpdateFunction = (name, onSuccessHandler) => {
    void useFormDataAndFunctions.handleSubmit((data) => {
      const isOptimizable = optimizeBudget
      const editedConfig = formatFormDataIntoApiConfig(
        data,
        config?.runSchedule ?? false,
        Number(currentClient.id),
        isOptimizable,
        isNewConfig
      )
      const { toastOnSuccess } = makeToastWithLoading()
      updateIntegrationAttributionModel(
        {
          data: editedConfig,
          configId: config?.id as number
        },
        {
          onSuccess: () => {
            onSuccessHandler?.()
            toastOnSuccess(t('form.configUpdated'))
          },
          onError: (error) => {
            handleFormErrors(t('form.configNotUpdatedError'), error)
          }
        }
      )
    })()
  }

  const handleModelStatusChange = (): void => {
    void useFormDataAndFunctions.handleSubmit((data) => {
      const isOptimizable = optimizeBudget
      const editedConfig = formatFormDataIntoApiConfig(data, true, Number(currentClient.id), isOptimizable, isNewConfig)
      const { toastOnSuccess, toastOnError } = makeToastWithLoading()
      updateIntegrationAttributionModel(
        {
          data: { ...editedConfig, runSchedule: not(config?.runSchedule) },
          configId: config?.id as number
        },
        {
          onSuccess: () => {
            toastOnSuccess(t('form.configUpdated'))
          },
          onError: () => {
            toastOnError(t('form.configNotUpdatedError'))
          }
        }
      )
    })()
  }

  const ButtonsActivateOrDeactivate = forwardRef<HTMLDivElement>(function ButtonsActivateOrDeactivate(
    props,
    ref
  ): React.ReactElement {
    return (
      <Grid display="flex" gap="8px" {...props} ref={ref}>
        <Button
          variant="filled"
          disabled={!formState.isValid || isCreating}
          onClick={(event) => {
            event.stopPropagation()
            void handleSave(true)
          }}
        >
          {t('buttons.activateNow')}
        </Button>
        <Button
          disabled={!formState.isValid || isCreating}
          variant="text"
          scheme="light"
          onClick={(event) => {
            event.stopPropagation()
            void handleSave(false)
          }}
        >
          {t('buttons.saveWithoutActivating')}
        </Button>
      </Grid>
    )
  })
  const { role: memberRole } = useMember()

  const [moreOptionsDropdownAnchorEl, setMoreOptionsDropdownAnchorEl] = useState<HTMLButtonElement | null>(null)

  const hasEditorAccess = useHasAccess('editor', memberRole)

  const { data: integratedAttributionsStatuses, isLoading: isLoadingIntegratedAttributionsStatuses } =
    useQueryAttributionConfigStatuses(currentClient.id, !isNewConfig && !optimizeBudget)

  const { data: budgetOptimiserStatus, isLoading: isLoadingBudgetOptimiserStatus } =
    useQueryBudgetOptimiserConfigStatuses(currentClient.id, !isNewConfig && optimizeBudget)

  const configStatusAttributionModels = integratedAttributionsStatuses?.find(
    (status) => status.configId === config?.id?.toString()
  )

  const configStatusBudgetOptimiser = budgetOptimiserStatus?.find(
    (status) => status.configId === config?.id?.toString()
  )

  const configStatus = optimizeBudget ? configStatusBudgetOptimiser : configStatusAttributionModels

  const status = !isNewConfig
    ? getStatusMarketingEvaluation(config?.runSchedule as boolean, configStatus?.status)
    : 'new'

  const isNewConfigOrExistingConfigLoaded = isNewNonCopyConfig || isConfigSuccess

  const copyOfElement = useMemo(() => {
    if (!isCopyConfig || config == null) return undefined

    const modelType = config.creditInput
    const fields: CopyConfigTooltipField[] = [
      {
        value: config.name,
        label: t('copyConfigurationFromTooltip.name')
      },
      {
        value: i18n?.exists(`attributionModel:dashboard.titles.${modelType}`)
          ? t(`dashboard.titles.${modelType}`)
          : t(`dashboard.titles.default`),
        label: t('copyConfigurationFromTooltip.modelType')
      },
      {
        value: config.createdBy,
        label: t('copyConfigurationFromTooltip.createdBy')
      }
    ]

    return (
      <CopyConfigTooltip
        fields={fields}
        configName={config.name}
        title={t('copyConfigurationFromTooltip.thisIsCopiedFrom')}
        configPath={APP_ROUTES.attributionModels.editConfigPage({ configId: config.id.toString() })}
      />
    )
  }, [isCopyConfig, config, t, i18n])

  if (isConfigSuccess && !optimizeBudget && isBudgetOptimiserOnlyConfig) {
    return <NotFoundPage hasDashboardFrame returnPath={APP_ROUTES.attributionModels.basePage} />
  }

  return (
    <FormProvider {...useFormDataAndFunctions}>
      <EditOrCreatePageLayout
        formInformation={
          isNewConfigOrExistingConfigLoaded ? (
            <Grid
              display="flex"
              flexDirection="column"
              gap="24px"
              item
              alignSelf="flex-start"
              position="sticky"
              top={0}
            >
              {isNewConfig && (
                <FormProgress
                  steps={arrangedFormSteps.map((formStep) => formStep.stepTitle)}
                  currentStep={currentStep}
                  t={t}
                />
              )}
            </Grid>
          ) : null
        }
        formHeader={
          isNewConfigOrExistingConfigLoaded ? (
            <FormHeader<AttributionFormData>
              title={
                allowToEdit(optimizeBudget, formData)
                  ? getInputLabels({ fieldName: 'name', data: getValues() })
                  : getValues().name
              }
              extraInfo={copyOfElement}
              fieldName="name"
              isEditable={allowToEdit(optimizeBudget, formData, isCopyConfig)}
              status={
                <StatusWithBadge
                  isLoading={isLoadingBudgetOptimiserStatus || isLoadingIntegratedAttributionsStatuses}
                  typographyVariant="h5"
                  status={status}
                  errorCode={configStatus?.errorCode}
                  errorDetails={getErrorCodeDetails(
                    configStatus?.errorCode,
                    configStatus?.errorMessage,
                    configStatus?.targetProjectId,
                    tCommon('dagView.attributionModel')
                  )}
                  lastUpdated={configStatus?.lastUpdated as string}
                  dagViewInformation={{
                    dagViewType: 'attributionModel',
                    configId: config?.id,
                    configName: config?.name,
                    isActive: config?.runSchedule,
                    isPageWithConfigId: true
                  }}
                />
              }
              lastEditedText={
                !isNewConfig &&
                config != null && (
                  <MemberTimestampLine
                    t={t}
                    timestamp={new Date(config.updatedAt).getTime()}
                    memberId={userDetails[0].data?.id}
                  />
                )
              }
              handleUpdate={handleEdit}
              isCreateEvent={isNewConfig}
              {...(!isNewConfig && {
                moreButton: (
                  <Button
                    variant="text"
                    aria-label="more"
                    onClick={(event) => {
                      event.stopPropagation()
                      setMoreOptionsDropdownAnchorEl(event.currentTarget)
                    }}
                    rightIcon={<MoreIcon />}
                  >
                    {t('more')}
                  </Button>
                )
              })}
            />
          ) : null
        }
        formBody={arrangedFormSteps.map(({ stepTitle, StepComponent }, index) => {
          const stepNumber = index + 1
          return isNewConfigOrExistingConfigLoaded ? (
            <StepComponent
              key={index}
              stepNumber={stepNumber}
              title={stepTitle}
              editModes={editModes}
              defaultValues={configData}
              toggleEdit={toggleEdit}
              handleUpdate={handleEdit}
              isNewConfig={isNewConfig}
              isPreviousOrCurrentStep={!isNewConfig || stepNumber <= currentStep}
              optimizeBudget={optimizeBudget}
              isCopyConfig={isCopyConfig}
            />
          ) : null
        })}
        formFooter={
          isNewConfig && (
            <Card>
              <Grid display="flex" flexDirection="column" alignItems="flex-start" gap="16px" alignSelf="stretch">
                <Typography variant="h3">{t('createModel.footerHead')}</Typography>
                <Typography variant="body1">{t('createModel.footerText')}</Typography>
                <Grid display="flex" gap="10px">
                  {isEmpty(requiredFields) ? (
                    <ButtonsActivateOrDeactivate />
                  ) : (
                    <Tooltip
                      title={t('form.missingRequiredFields')}
                      kind="multiline"
                      body={
                        <Grid>
                          <StyledUl>
                            {requiredFields.map((fieldName, index) => {
                              const label = getInputLabels({ fieldName, data: getValues() })
                              return label !== '' ? <li key={index}>{label}</li> : null
                            })}
                          </StyledUl>
                        </Grid>
                      }
                    >
                      <ButtonsActivateOrDeactivate />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Card>
          )
        }
      />

      {!isNewConfig && (
        <Dropdown
          anchorEl={moreOptionsDropdownAnchorEl}
          open={moreOptionsDropdownAnchorEl != null}
          onClose={() => {
            setMoreOptionsDropdownAnchorEl(null)
          }}
        >
          <MoreMenu
            selectedModel={{
              modelId: config?.id as number,
              name: config?.name,
              isActive: config?.runSchedule,
              status,
              targetProjectId: config?.targetProject as string,
              targetDatasetId: config?.targetDataset as string
            }}
            onModelStatusChange={handleModelStatusChange}
            onCloseMenu={() => {
              setMoreOptionsDropdownAnchorEl(null)
            }}
            onDeleteConfig={() => {
              void changePath(APP_ROUTES.attributionModels.basePage)
            }}
            hasEditorAccess={hasEditorAccess}
            path={optimizeBudget ? 'budget-optimiser' : 'attribution-models'}
            allowPause={config?.channelGroupingId != null}
            allowEdit={false}
            isPageWithConfigId
            allowDelete={formData.isCreditAllocatorAndPerformanceCurve === true}
          />
        </Dropdown>
      )}
    </FormProvider>
  )
}

export default EditOrCreateModel
const StyledUl = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: `0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`
}))
