import { useQuery, type UseMutationResult, type UseQueryResult } from 'react-query'
import { analytics, budgetOptimser, type fetch } from 'shared/api'
import { type ReportType } from 'shared/api/analytics'
import { type AttributionResp, type IntegrationAttributionsResp } from 'shared/api/attributionModels'
import useMutationAnalytics from 'shared/hooks/useMutationAnalytics'

export const queryKeys = {
  budgetOptimiser: 'budgetOptimiser',
  budgetOptimiserDagStatus: 'budgetOptimiserDagStatus',
  budgetOptimiserConfigStatus: 'budgetOptimiserConfigStatus'
}

export const useQueryBudgetOptimisers = (
  clientId: string
): UseQueryResult<IntegrationAttributionsResp, fetch.ClientError> => {
  return useQuery(
    [queryKeys.budgetOptimiser, clientId],
    async () => await budgetOptimser.getBudgetOptimiserConfigs(clientId),
    {
      enabled: clientId !== ''
    }
  )
}

export const useQueryBudgetOptimiserById = (
  clientId: string,
  configId: number,
  enabled: boolean = true
): UseQueryResult<AttributionResp, fetch.ClientError> => {
  return useQuery(
    [queryKeys.budgetOptimiser, clientId, configId],
    async () => await budgetOptimser.getBudgetOptimiserConfig(clientId, configId),
    {
      enabled: clientId !== '' && enabled
    }
  )
}

export const useMutationDashboard = (): UseMutationResult<
  analytics.BudgetOptimiserResp[],
  fetch.ClientError,
  analytics.ReqOptimalsDetails
> => {
  return useMutationAnalytics(analytics.postBudgetOptimiser)
}

export const useMutationDetailedReport = (): UseMutationResult<
  analytics.BudgetOptmisersTotalsResp[],
  fetch.ClientError,
  analytics.ReqTotalDetails
> => {
  return useMutationAnalytics(analytics.postBudgetOptimiserTotals)
}

export const useMutationPreprocData = (): UseMutationResult<
  analytics.BudgetOptimiserPreprocResp[],
  fetch.ClientError,
  analytics.ReqPreprocDetails
> => {
  return useMutationAnalytics(analytics.postBudgetOptimiserPreproc)
}
export const useMutationBudgetOptimizerOptimalsDates = (): UseMutationResult<
  analytics.DatesInBudgetOptimiserOptimalsResp[],
  fetch.ClientError,
  analytics.ReqOptimalsDetails
> => {
  return useMutationAnalytics(analytics.postGetDatesInBudgetOptimizerOptimals)
}

export const useMutationCurrencyConversionRates = (): UseMutationResult<
  analytics.CurrencyConversionRatesResp[],
  fetch.ClientError,
  analytics.ReqCurrencyConversionRatesDetails
> => {
  return useMutationAnalytics(analytics.postGetCurrencyConversionRates)
}

export const useQueryBudgetOptimiserDagStatusByModelId = (
  clientId: string,
  modelId: number,
  enabled: boolean = true,
  useCache: boolean = true,
  reportType: ReportType = 'budget_optimiser_non_native_dag'
): UseQueryResult<[analytics.DagStatusResp] | [], fetch.ClientError> => {
  return useQuery(
    [queryKeys.budgetOptimiserDagStatus, clientId, modelId, useCache],
    async () =>
      await analytics.postConfigStatusById<[analytics.DagStatusResp] | []>(reportType, clientId, modelId, useCache),
    {
      enabled: clientId !== '' && enabled
    }
  )
}

export const useQueryBudgetOptimiserConfigStatuses = (
  clientId: string,
  enabled: boolean = true
): UseQueryResult<analytics.ConfigStatusResp[], fetch.ClientError> => {
  return useQuery(
    [queryKeys.budgetOptimiserConfigStatus, clientId],
    async () => {
      return await analytics.postConfigStatuses('budget_optimiser_client_configs_status', clientId, true)
    },
    { enabled }
  )
}

export const useQueryBudgetOptimiserConfigStatusById = (
  clientId: string,
  modelId: number,
  enabled: boolean = true
): UseQueryResult<[analytics.ConfigStatusResp], fetch.ClientError> => {
  return useQuery(
    [queryKeys.budgetOptimiserConfigStatus, clientId, modelId],
    async () => {
      return await analytics.postConfigStatusById('budget_optimiser_config_status', clientId, modelId, true)
    },
    {
      enabled
    }
  )
}
