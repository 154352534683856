import { DATA_SOURCE_METRICS, type Metric } from 'channelGrouping/constants/metrics'
import { getDataSourceMetric } from 'channelGrouping/utils'
import { PLATFORMS } from 'dataSource'
import { useMemo, useState } from 'react'
import { type Platforms } from 'shared/api/accounts'
import { LOCAL_STORAGE_KEYS, get, store } from 'shared/lstore'

const getSelectedMetricsFromLocalStorage = (): Record<Platforms, string> => {
  let metricsFromLocalStorage: Partial<Record<Platforms, string>> = {}
  try {
    metricsFromLocalStorage = JSON.parse(get(LOCAL_STORAGE_KEYS.CHANNEL_GROUPING_METRIC_SELECTION) ?? '{}')
  } catch (e) {}

  const selectedMetrics: Partial<Record<Platforms, string>> = {}

  Object.entries(DATA_SOURCE_METRICS).forEach(([dataSource, metrics]) => {
    const dataSourceKey = dataSource as Platforms

    if (PLATFORMS.find((platform) => platform.platform === dataSourceKey)?.isSupportedByChannelGrouping !== true) {
      return
    }

    if (metricsFromLocalStorage[dataSourceKey] != null) {
      selectedMetrics[dataSourceKey] = metricsFromLocalStorage[dataSourceKey]
    } else {
      selectedMetrics[dataSourceKey] = metrics[0].id
    }
  })

  return selectedMetrics as Record<Platforms, string>
}

export const useMetricsSelectionState = (
  dataSource?: Platforms
): {
  selectedMetric: Metric
  allSelectedMetrics: Record<Platforms, Metric>
  setSelectedMetric: (metric: Metric, dataSource: Platforms) => void
} => {
  const localStorageMetrics = useMemo(() => getSelectedMetricsFromLocalStorage(), [])

  const setSelectedMetricsObject = (metrics: Record<Platforms, string>): void => {
    store(LOCAL_STORAGE_KEYS.CHANNEL_GROUPING_METRIC_SELECTION, JSON.stringify(metrics))
  }

  const [selectedMetricId, setSelectedMetricId] = useState<string>(
    dataSource != null ? localStorageMetrics[dataSource] : ''
  )
  const [allSelectedMetricIds, setAllSelectedMetricIds] = useState<Record<Platforms, string>>(localStorageMetrics)

  const setSelectedMetric = (metric: Metric, dataSourceToSet: Platforms): void => {
    setSelectedMetricId(metric.id)
    const updatedMetrics = { ...allSelectedMetricIds, [dataSourceToSet]: metric.id }
    setAllSelectedMetricIds(updatedMetrics)
    setSelectedMetricsObject(updatedMetrics)
  }

  const allSelectedMetrics: Partial<Record<Platforms, Metric>> = {}

  Object.entries(allSelectedMetricIds).forEach(([dataSource, metricId]) => {
    allSelectedMetrics[dataSource as Platforms] =
      getDataSourceMetric(dataSource as Platforms, metricId) ?? DATA_SOURCE_METRICS[dataSource as Platforms][0]
  })

  return {
    selectedMetric:
      getDataSourceMetric(dataSource as Platforms, selectedMetricId) ?? DATA_SOURCE_METRICS[dataSource as Platforms][0],
    allSelectedMetrics: allSelectedMetrics as Record<Platforms, Metric>,
    setSelectedMetric
  }
}
