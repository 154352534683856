import {
  type UseQueryResult,
  useQuery,
  type UseMutationResult,
  useQueryClient,
  useMutation,
  type QueryClient
} from 'react-query'
import { standardReports, type fetch, analytics } from 'shared/api'

export const queryKeys = {
  reportingSolutionsConfigs: 'reportingSolutionsConfigs',
  reportingSolutionsConfigsStatuses: 'reportingSolutionsConfigsStatuses',
  reportingSolutionsDagStatus: 'reportingSolutionsDagStatus'
}

export const useQueryReportingSolutionsConfigs = (
  clientId: string,
  enabled: boolean = true
): UseQueryResult<standardReports.StandardReportsResp, fetch.ClientError> => {
  return useQuery(
    [queryKeys.reportingSolutionsConfigs, clientId],
    async () => await standardReports.getStandardReports(clientId),
    {
      enabled: clientId !== '' && enabled
    }
  )
}

const getUseCache = (queryClient: QueryClient, clientId: string): boolean => {
  const queriesData = queryClient.getQueriesData([queryKeys.reportingSolutionsConfigsStatuses, clientId])
  const useCache = queriesData.every((queryData) => queryData?.[1] == null)
  return useCache
}

export const useQueryReportingSolutionsConfigsStatuses = (
  clientId: string
): UseQueryResult<analytics.ConfigStatusResp[], fetch.ClientError> => {
  const queryClient = useQueryClient()
  return useQuery([queryKeys.reportingSolutionsConfigsStatuses, clientId], async () => {
    const useCache = getUseCache(queryClient, clientId)
    return await analytics.postConfigStatuses('transform_client_configs_status', clientId, useCache)
  })
}

export const useQueryReportingSolutionsConfigsStatusById = (
  clientId: string,
  configId: number,
  enabled: boolean = true
): UseQueryResult<[analytics.ConfigStatusResp] | [], fetch.ClientError> => {
  const queryClient = useQueryClient()
  return useQuery(
    [queryKeys.reportingSolutionsConfigsStatuses, clientId, configId],
    async () => {
      const useCache = getUseCache(queryClient, clientId)
      return await analytics.postConfigStatusById('transform_config_status', clientId, configId, useCache)
    },
    {
      enabled
    }
  )
}

export const useQueryReportingSolutionDagStatusById = (
  clientId: string,
  configId: number,
  enabled: boolean = true,
  useCache: boolean = true
): UseQueryResult<[analytics.DagStatusResp] | [], fetch.ClientError> => {
  return useQuery({
    queryKey: [queryKeys.reportingSolutionsDagStatus, clientId, configId, useCache],
    queryFn: async () =>
      await analytics.postConfigStatusById<[analytics.DagStatusResp] | []>(
        'transform_dag',
        clientId,
        configId,
        useCache
      ),
    enabled: clientId !== '' && enabled
  })
}
export const useMutationCreateReportingSolutions = (): UseMutationResult<
  standardReports.StandardReportResp,
  fetch.ClientError,
  standardReports.StandardReportReq
> => {
  const queryClient = useQueryClient()

  return useMutation(standardReports.createStandardReport, {
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeys.reportingSolutionsConfigs)
      void queryClient.invalidateQueries(queryKeys.reportingSolutionsConfigsStatuses)
    }
  })
}

interface UpdateRequest {
  data: standardReports.StandardReportReq
  configId: number
}

export const useMutationUpdateReportingSolutions = (): UseMutationResult<
  standardReports.StandardReportResp,
  fetch.ClientError,
  UpdateRequest
> => {
  const queryClient = useQueryClient()

  return useMutation(async (params) => await standardReports.updateStandardReport(params.data, params.configId), {
    onMutate: async (updatedConfigData) => {
      await queryClient.cancelQueries({
        queryKey: [queryKeys.reportingSolutionsConfigs, updatedConfigData.data.clientId, updatedConfigData.configId]
      })
      const previousConfig = queryClient.getQueryData<standardReports.StandardReportResp>([
        queryKeys.reportingSolutionsConfigs,
        updatedConfigData.data.clientId,
        updatedConfigData.configId
      ])

      queryClient.setQueryData(
        [queryKeys.reportingSolutionsConfigs, updatedConfigData.data.clientId, updatedConfigData.configId],
        {
          ...previousConfig,
          ...updatedConfigData.data
        }
      )

      return { previousConfig, updatedConfigData }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        [
          queryKeys.reportingSolutionsConfigs,
          context?.updatedConfigData.data.clientId,
          context?.updatedConfigData.configId
        ],
        context?.previousConfig
      )
    },
    onSettled: () => {
      void queryClient.invalidateQueries(queryKeys.reportingSolutionsConfigs)
    }
  })
}

export const useQueryReportingSolutionConfigsById = (
  clientId: string,
  configId: number,
  enabled: boolean = true
): UseQueryResult<standardReports.StandardReportResp, fetch.ClientError> => {
  return useQuery(
    [queryKeys.reportingSolutionsConfigs, clientId, configId],
    async () => await standardReports.getStandardReportById(clientId, configId),
    {
      enabled
    }
  )
}

export const useMutationRunReportConfigNow = (): UseMutationResult<
  void,
  fetch.ClientError,
  standardReports.ClientConfigIdReq
> => {
  const queryClient = useQueryClient()
  return useMutation(standardReports.runStandardReportConfig, {
    onSettled: () => {
      void queryClient.invalidateQueries(queryKeys.reportingSolutionsConfigsStatuses)
    }
  })
}

export const useMutationDeleteReportConfig = (): UseMutationResult<
  standardReports.StandardReportResp,
  fetch.ClientError,
  standardReports.ClientConfigIdReq
> => {
  const queryClient = useQueryClient()

  return useMutation(standardReports.deleteStandardReport, {
    onMutate: async (deletedConfig) => {
      await queryClient.cancelQueries({ queryKey: [queryKeys.reportingSolutionsConfigs] })

      const existingConfigs = queryClient.getQueryData([queryKeys.reportingSolutionsConfigs, deletedConfig.clientId])

      queryClient.setQueryData<standardReports.StandardReportsResp>(
        [queryKeys.reportingSolutionsConfigs, deletedConfig.clientId],
        (oldConfigs) => {
          return oldConfigs != null ? oldConfigs.filter((config) => config.configId !== deletedConfig.configId) : []
        }
      )

      return { existingConfigs }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([queryKeys.reportingSolutionsConfigs], context?.existingConfigs)
    },
    onSettled: () => {
      void queryClient.invalidateQueries(queryKeys.reportingSolutionsConfigs)
    }
  })
}
