import { useQueryPrecisApps } from 'precisApp/api'
import { useQueryUserProfile } from 'profile/api'
import { useAuth } from 'shared/context/AuthContext'
import { NoClient as defaultClient, useCurrentClient } from 'shared/context/ClientContext'
import { useQueryMember } from 'workspace/api'

const useDashboardDataPreloader = (): {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  isPreloaded: boolean
} => {
  const { currentUser, isSuccess: isAuthSuccess, isLoading: isLoadingAuth } = useAuth()
  const {
    isSuccess: isClientSuccess,
    isLoading: isLoadingClient,
    currentClient,
    error: clientError
  } = useCurrentClient()
  const {
    isSuccess: isMemberSuccess,
    isLoading: isLoadingMember,
    error: memberError
  } = useQueryMember(currentClient.id, currentUser?.id ?? '')

  const {
    isSuccess: isPrecisAppsSuccess,
    error: precisAppsError,
    isLoading: isLoadingPrecisApps
  } = useQueryPrecisApps(currentClient.id, currentUser?.hasInternalFeatureAccess === true)

  const {
    isSuccess: isUserProfileSuccess,
    isLoading: isLoadingUserProfile,
    error: userProfileError
  } = useQueryUserProfile(currentUser?.id)

  const isLoading = isLoadingAuth || isLoadingClient || isLoadingMember || isLoadingPrecisApps || isLoadingUserProfile

  const isValidClient =
    currentClient.id !== defaultClient.id &&
    currentClient.name !== defaultClient.name &&
    currentClient.featurePlan != null &&
    currentClient.featurePlan.name !== defaultClient.featurePlan.name

  const isSuccess =
    isClientSuccess &&
    isMemberSuccess &&
    isAuthSuccess &&
    isUserProfileSuccess &&
    (isPrecisAppsSuccess || (currentUser != null && currentUser?.hasInternalFeatureAccess === false))

  return {
    isLoading,
    isSuccess,
    isError: clientError != null || memberError != null || precisAppsError != null || userProfileError != null,
    isPreloaded: !isLoading && (isValidClient || clientError != null || !isAuthSuccess)
  }
}

export default useDashboardDataPreloader
