import { Banner, Grid, Link, Tooltip, Typography } from '@precis-digital/kurama'
import { condenseMissingDatesToText } from 'attributionModel/components/Dashboard/attributionDateUtils'
import { useTranslation } from 'shared/translations'

interface MissingDatesBannerProps {
  selected: string[]
  comparison: string[]
}

export const MissingDatesWarningBanner = ({ selected, comparison }: MissingDatesBannerProps): React.ReactElement => {
  const { t } = useTranslation('attributionModel', { keyPrefix: 'dashboard.missingDataWarning' })
  if (selected.length > 0 && comparison.length > 0) {
    const tooltipBody = (
      <Grid display="flex" direction="column" gap="4px">
        <Typography variant="body3">{t('selected')} </Typography>
        {condenseMissingDatesToText(selected)}
        <Typography variant="body3">{t('comparison')} </Typography>
        {condenseMissingDatesToText(comparison)}
      </Grid>
    )
    const missingDatesTooltip = (
      <Tooltip kind="singleline" title={tooltipBody} placement="top-start">
        <Link>{t('dates')}</Link>
      </Tooltip>
    )
    return (
      <Banner variant="error">
        <Typography>
          {t('some')} {missingDatesTooltip} {t('selectedAndComparison')}
        </Typography>
      </Banner>
    )
  } else if (selected.length > 0) {
    const missingDatesTooltip = (
      <Tooltip kind="singleline" title={condenseMissingDatesToText(selected)} placement="top-start">
        <Link>{t('dates')}</Link>
      </Tooltip>
    )
    return (
      <Banner variant="error">
        {t('some')} {missingDatesTooltip} {t('selectedDateRange')}
      </Banner>
    )
  } else if (comparison.length > 0) {
    const missingDatesTooltip = (
      <Tooltip kind="singleline" title={condenseMissingDatesToText(comparison)} placement="top-start">
        <Link>{t('dates')}</Link>
      </Tooltip>
    )
    return (
      <Banner variant="error">
        {t('some')} {missingDatesTooltip} {t('comparisonDateRange')}
      </Banner>
    )
  } else {
    return <></>
  }
}
