import React from 'react'
import {
  Accordion,
  ArrowRightIcon,
  Avatar,
  Button,
  Card,
  ChevronDownIcon,
  Divider,
  Grid,
  Typography,
  styled
} from '@precis-digital/kurama'
import { PLATFORMS, type IPlatform } from 'dataSource/constants'
import { useTranslation } from 'shared/translations'
import OverScreen from 'shared/overScreens'
import { useShouldShowInternalFeatures } from 'shared/hooks/useShouldShowInternalFeatures'

const UserOrServiceAccountSelectionAccordion = ({
  platform,
  setSelectedDataSource
}: {
  platform: IPlatform
  setSelectedDataSource: (platform: IPlatform | null) => void
}): React.ReactElement => {
  const { t } = useTranslation('dataSource', { keyPrefix: 'popups.connectNewDataSource' })
  const [expandedAccordion, setExpandedAccordion] = React.useState<string | null>(null)
  const shouldShowInternalFeatures = useShouldShowInternalFeatures()
  const handleAccordionToggle = (platformLabel: string): void => {
    setExpandedAccordion((prevLabel) => (prevLabel === platformLabel ? null : platformLabel))
  }
  const handleServiceAccountAccessClick = (): void => {
    const selectedPlatform = PLATFORMS.find((platform) => platform.label === expandedAccordion)
    if (selectedPlatform != null) {
      void OverScreen.show('serviceAccountAccessPopup', { datasource: selectedPlatform })
    }
  }

  return (
    <StyledDataSourcePlate
      onClick={() => {
        if (!platform.supportsServiceAccountAccess) {
          setSelectedDataSource(platform)
        }
      }}
    >
      <StyledAccordion
        key={platform.label}
        expanded={expandedAccordion === platform.label}
        onChange={() => {
          handleAccordionToggle(platform.label)
        }}
        expandIcon={<ChevronDownIcon />}
        Summary={
          <StyledAccordionSummary>
            <Avatar imageUrl={platform.iconUrl} kind="image" />
            <Typography variant="h3">{platform.label}</Typography>
          </StyledAccordionSummary>
        }
        Details={
          <>
            <StyledAuthDetailsCard onClick={handleServiceAccountAccessClick}>
              <StyledOption>
                <Typography variant="h4">{t('authenticateServiceAccount')}</Typography>
                <Button onClick={handleServiceAccountAccessClick} variant="tonal" leftIcon={<ArrowRightIcon />} />
              </StyledOption>
              <Divider />
              <Typography variant="body3">
                {shouldShowInternalFeatures
                  ? t('authenticateServiceAccountExplainer')
                  : t('authenticateServiceAccountExplainerOnlyOption')}
              </Typography>
            </StyledAuthDetailsCard>

            {shouldShowInternalFeatures && (
              <StyledAuthDetailsCard
                onClick={() => {
                  setSelectedDataSource(platform)
                }}
              >
                {' '}
                <StyledOption>
                  <Typography variant="h4">{t('authenticateUserAccount')}</Typography>
                  <Button
                    onClick={() => {
                      setSelectedDataSource(platform)
                    }}
                    variant="tonal"
                    leftIcon={<ArrowRightIcon />}
                  />
                </StyledOption>
                <Divider />
                <Typography variant="body3">{t('authenticateUserAccountExplainer')}</Typography>
              </StyledAuthDetailsCard>
            )}
          </>
        }
      />
    </StyledDataSourcePlate>
  )
}

export const StyledAccordionSummary = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%'
}))

export const StyledOption = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
}))

export const StyledHeader = styled(Grid)(() => ({
  textAlign: 'center'
}))
export const StyledDataSourcePlate = styled(Card)(() => ({
  justifyContent: 'initial',
  '& h3': {
    marginLeft: '20px'
  },
  '& svg': {
    marginLeft: 'auto'
  }
}))

export const StyledAuthDetailsCard = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  textAlign: 'left',
  width: '100%',
  '& > *': {
    textAlign: 'left'
  },
  paddingBottom: '24px'
}))

export const StyledAccordion = styled(Accordion)(() => ({
  '.MuiAccordionDetails-root': {
    padding: '0',
    '.MuiAccordionSummary-content': {
      margin: '0'
    }
  },
  '&.MuiAccordion-root:before': {
    content: 'none' // remove divider
  },
  '.MuiAccordionSummary-gutters': {
    minHeight: '40px'
  }
}))

export default UserOrServiceAccountSelectionAccordion
