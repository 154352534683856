import {
  DropdownList,
  Divider,
  TrashIcon,
  DropdownItem,
  Avatar,
  MappingIcon,
  PauseIcon,
  PlayIcon,
  EditIcon,
  SendIcon,
  Button,
  PlusIcon,
  CopyIcon,
  type TooltipProps
} from '@precis-digital/kurama'
import {
  useMutationDeleteReportConfig,
  useMutationRunReportConfigNow,
  useMutationUpdateReportingSolutions
} from 'reportingSolution/api'
import { useCurrentClient } from 'shared/context/ClientContext'
import OverScreen from 'shared/overScreens'
import { useTranslation } from 'shared/translations'
import { transformConfigToRequest } from './EditOrCreatePage/utils'
import { ASSETS_BASE_URL } from 'shared/constants'
import { createDashboardLinkHandler } from './EditOrCreatePage/CreateDashboardLinkHandler'
import { type ReportingSolutionConfigWithStatus } from './StartPage'
import { makeToastWithLoading } from 'shared/components/Toaster'
import { STATUSES, getStatusFromAPIStatusData } from 'shared/configStatuses'
import useDagView from 'shared/hooks/useDagView'
import { getBigQueryTableDeepLink } from 'shared/utils'
import { getDataSourceTableName, getReportTemplateById } from '../utils'
import { UnStyledRetainDefaultQueryLink } from 'shared/components/Router'
import { APP_ROUTES } from 'shared/routes'
import { sendGTMEvent } from 'shared/googleTagManager'
import { UnstyledLink } from 'shared/components/UnstyledLink'

interface MoreMenuDropDownProps {
  selectedConfig: ReportingSolutionConfigWithStatus
  accountProjectId?: number
  hasEditorAccess?: boolean
  onDeleteConfig?: () => void
  closeMenu?: () => void
  isPageWithConfigId?: boolean
  showEditOption?: boolean
}

const MoreMenuDropDown = ({
  selectedConfig,
  accountProjectId,
  hasEditorAccess = false,
  onDeleteConfig,
  closeMenu,
  showEditOption = true,
  isPageWithConfigId = false
}: MoreMenuDropDownProps): React.ReactElement => {
  const { t } = useTranslation('reportingSolutions')
  const { t: tCommon } = useTranslation('common')

  const { currentClient } = useCurrentClient()

  const { mutate: runReportConfigNow } = useMutationRunReportConfigNow()
  const { mutate: updateReportConfig } = useMutationUpdateReportingSolutions()
  const { mutate: deleteReportConfig } = useMutationDeleteReportConfig()
  const { showDagView } = useDagView('reportingSolution', isPageWithConfigId)

  const tableDeeplink =
    selectedConfig.templateId != null
      ? (() => {
          const template = getReportTemplateById(selectedConfig.templateId)
          const tableName = getDataSourceTableName(
            selectedConfig.name,
            template?.id ?? '',
            template?.dashboard?.datasources?.[0]?.tableId ?? ''
          )
          return getBigQueryTableDeepLink(selectedConfig.targetProjectId, selectedConfig.targetDataset, tableName)
        })()
      : null
  const disabledPropsBasedOnUserAccess: {
    disabled: boolean
    tooltipProps?: TooltipProps
  } = {
    disabled: !hasEditorAccess,
    ...(!hasEditorAccess && { tooltipProps: { kind: 'singleline', title: tCommon('noPermissionMessage') } })
  }
  const isActiveConfig = selectedConfig.statusEnum === STATUSES.active

  const handleDeleteReportConfig = (e: MouseEvent): void => {
    const { toastOnSuccess, toastOnError } = makeToastWithLoading()
    deleteReportConfig(
      { clientId: currentClient.id, configId: selectedConfig.configId },
      {
        onSuccess: () => {
          onDeleteConfig?.()
          toastOnSuccess(t('popups.delete.success', { id: selectedConfig.configId }))
        },
        onError: (error) => {
          toastOnError(`${t('popups.delete.error')}: ${error.message}`)
        }
      }
    )
  }

  return (
    <DropdownList minWidth="300">
      {showEditOption && hasEditorAccess && (
        <UnStyledRetainDefaultQueryLink
          href={APP_ROUTES.reportingSolutions.editConfigPage({ configId: selectedConfig.configId.toString() })}
        >
          <DropdownItem
            onClick={() => {
              closeMenu?.()
            }}
            icon={<EditIcon />}
          >
            {t('moreOptions.editConfiguration')}
          </DropdownItem>
        </UnStyledRetainDefaultQueryLink>
      )}
      {hasEditorAccess && (
        <UnStyledRetainDefaultQueryLink
          href={APP_ROUTES.reportingSolutions.createConfigPage}
          query={{ from: selectedConfig.configId.toString() }}
        >
          <DropdownItem
            icon={<CopyIcon />}
            onClick={() => {
              closeMenu?.()
            }}
            tooltipProps={{ kind: 'singleline', title: t('moreOptions.copyThisConfigurationTooltip') }}
          >
            {t('moreOptions.copyThisConfiguration')}
          </DropdownItem>
        </UnStyledRetainDefaultQueryLink>
      )}
      <DropdownItem
        onClick={() => {
          if (selectedConfig == null) return
          const { toastOnSuccess, toastOnError } = makeToastWithLoading()
          updateReportConfig(
            {
              configId: selectedConfig?.configId,
              data: transformConfigToRequest({
                config: {
                  ...selectedConfig,
                  runSchedule: !selectedConfig.runSchedule,
                  targetProjectId: accountProjectId?.toString() ?? ''
                },
                clientId: currentClient.id
              })
            },
            {
              onSuccess: () => {
                toastOnSuccess(selectedConfig.runSchedule ? t('pauseConfigSuccess') : t('activateConfigSuccess'))
              },
              onError: () => {
                toastOnError(t('errorUpdatingConfig'))
              },
              onSettled: () => {
                closeMenu?.()
              }
            }
          )
        }}
        icon={selectedConfig?.runSchedule ?? false ? <PauseIcon /> : <PlayIcon />}
        {...disabledPropsBasedOnUserAccess}
      >
        {selectedConfig?.runSchedule ?? false ? t('moreOptions.pauseConfig') : t('moreOptions.activateConfig')}
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          if (selectedConfig != null) {
            sendGTMEvent({ event: 'run_now_click', product: 'reporting_solutions' })
            const { toastOnSuccess, toastOnError } = makeToastWithLoading()
            updateReportConfig(
              {
                configId: selectedConfig?.configId,
                data: transformConfigToRequest({
                  config: {
                    ...selectedConfig,
                    runSchedule: true,
                    targetProjectId: accountProjectId?.toString() ?? ''
                  },
                  clientId: currentClient.id
                })
              },
              {
                onSuccess: () => {
                  runReportConfigNow(
                    { configId: selectedConfig.configId, clientId: currentClient.id },
                    {
                      onSuccess: () => {
                        toastOnSuccess(t('runNowSuccess', { id: selectedConfig.configId }))
                      },
                      onError: () => {
                        toastOnError(t('runNowError', { id: selectedConfig.configId }))
                      },
                      onSettled: () => {
                        closeMenu?.()
                      }
                    }
                  )
                },
                onError: () => {
                  toastOnError(t('runNowError', { id: selectedConfig.configId }))
                }
              }
            )
          }
        }}
        icon={<SendIcon />}
        {...disabledPropsBasedOnUserAccess}
      >
        {t('moreOptions.runNow')}
      </DropdownItem>

      <DropdownItem
        onClick={() => {
          closeMenu?.()
          showDagView({
            configId: selectedConfig.configId,
            configName: selectedConfig.name,
            isActive: selectedConfig.runSchedule,
            configStatus: getStatusFromAPIStatusData(selectedConfig.runSchedule, selectedConfig.status?.status)
          })
        }}
        icon={<MappingIcon />}
      >
        {t('moreOptions.viewDetailedStatus')}
      </DropdownItem>
      <Divider />
      {tableDeeplink != null && (
        <UnstyledLink href={tableDeeplink} target="_blank" rel="noreferrer">
          <DropdownItem
            onClick={() => {
              closeMenu?.()
            }}
            icon={<Avatar kind="image" imageUrl={`${ASSETS_BASE_URL}/google_bigquery.svg`} size="small" />}
          >
            {t('moreOptions.goToTable')}
          </DropdownItem>
        </UnstyledLink>
      )}
      <DropdownItem
        icon={<Avatar kind="image" imageUrl={`${ASSETS_BASE_URL}/looker_studio.png`} size="small" />}
        onClick={() => {
          closeMenu?.()
          sendGTMEvent({ event: 'create_dashboard_click', product: 'reporting_solutions' })
          createDashboardLinkHandler(selectedConfig)
        }}
        disabled={!isActiveConfig}
        {...(!isActiveConfig && { tooltipProps: { kind: 'singleline', title: t('activeConfigActionMessage') } })}
      >
        {t('moreOptions.createDashboard')}
      </DropdownItem>
      {hasEditorAccess && (
        <>
          <Divider />
          <DropdownItem
            onClick={() => {
              closeMenu?.()
              void OverScreen.show('deletePopup', {
                deleteConfigProps: {
                  id: selectedConfig.configId,
                  name: selectedConfig.name,
                  onDelete: handleDeleteReportConfig
                },
                t
              })
            }}
            icon={<TrashIcon />}
          >
            {t('moreOptions.deleteConfiguration')}
          </DropdownItem>
        </>
      )}
      {isPageWithConfigId && (
        <>
          <Divider />
          <Button
            href={APP_ROUTES.reportingSolutions.createConfigPage}
            LinkComponent={UnStyledRetainDefaultQueryLink}
            fullWidth
            scheme="light"
            variant="filled"
            onClick={() => {
              closeMenu?.()
            }}
            rightIcon={<PlusIcon />}
            {...disabledPropsBasedOnUserAccess}
          >
            {t('listView.createNew')}
          </Button>
        </>
      )}
    </DropdownList>
  )
}

export default MoreMenuDropDown
