import {
  transformToDetailedReportData,
  transformToReport,
  transformToReportingBaseTimeSeriesByMetricAndBreakdown,
  transformToSummary,
  transformToTimeSeries
} from 'home/transformations/reportingBase'
import { LOCAL_STORAGE_KEYS, get as getFromStore, store as saveToStore } from 'shared/lstore'
import { type ReportParameters } from '.'
import { reportingBaseAPIVariables } from 'home/api'

export const reportingBaseMetrics = [
  'spend',
  'conversionValue',
  'roas',
  'conversions',
  'costPerAction',
  'videoViews',
  'clicks',
  'viewableImpressions',
  'impressions',
  'sessions',
  'clickThroughRate',
  'conversionRate',
  'costPerClick',
  'costOfSales',
  'costPerThousandImpressions',
  'averageOrderValue'
] as const

export type ReportingBaseMetric = (typeof reportingBaseMetrics)[number]

export interface ReportingBaseColumnProps {
  channel: string
  market: string
  spend: number
  conversionValue: number
  previousSpend: number
  previousConversionValue: number
  roas: number
  previousRoas: number
  conversions: number
  previousConversions: number
  costPerAction: number
  previousCostPerAction: number
  viewableImpressions: number
  impressions: number
  videoViews: number
  sessions: number
  costPerClick: number
  clickThroughRate: number
  conversionRate: number
  costOfSales: number
  costPerThousandImpressions: number
  averageOrderValue: number
  clicks: number
  previousClicks: number
  previousViewableImpressions: number
  previousImpressions: number
  previousVideoViews: number
  previousSessions: number
  previousCostPerClick: number
  previousClickThroughRate: number
  previousConversionRate: number
  previousCostOfSales: number
  previousCostPerThousandImpressions: number
  previousAverageOrderValue: number
}

export const reportingBaseParameters: ReportParameters = {
  ...reportingBaseAPIVariables,
  transformations: {
    transformToReport,
    transformToDetailedReportData,
    transformToSummary,
    transformToTimeSeries,
    transformToTimeSeriesByMetricAndBreakdown: transformToReportingBaseTimeSeriesByMetricAndBreakdown
  },
  metrics: reportingBaseMetrics,
  getSelectedConversionEvents: (reportId) => {
    const selectedConversionEvents = getFromStore(
      LOCAL_STORAGE_KEYS.REPORTING_BASE_HOME_PAGE_CONVERSION_EVENTS_SELECTION(reportId)
    )
    return selectedConversionEvents != null ? JSON.parse(selectedConversionEvents) : undefined
  },
  storeSelectedConversionEvents: (reportId, selectedConversionEvents) => {
    saveToStore(
      LOCAL_STORAGE_KEYS.REPORTING_BASE_HOME_PAGE_CONVERSION_EVENTS_SELECTION(reportId),
      JSON.stringify(selectedConversionEvents)
    )
  },
  getSelectedTopCardMetrics: () => {
    const existingMetricsSelection = getFromStore(
      LOCAL_STORAGE_KEYS.REPORTING_BASE_HOME_PAGE_SUMMARY_CARDS_METRIC_SELECTION
    )
    return existingMetricsSelection != null
      ? JSON.parse(existingMetricsSelection)
      : ['spend', 'conversionValue', 'conversions', 'costPerAction', 'roas']
  },
  localStorageKeyForMetrics: LOCAL_STORAGE_KEYS.REPORTING_BASE_HOME_PAGE_SUMMARY_CARDS_METRIC_SELECTION,
  metricsToPreviousMetricsMap: {
    spend: 'previousSpend',
    conversionValue: 'previousConversionValue',
    roas: 'previousRoas',
    conversions: 'previousConversions',
    costPerAction: 'previousCostPerAction',
    viewableImpressions: 'previousViewableImpressions',
    impressions: 'previousImpressions',
    videoViews: 'previousVideoViews',
    sessions: 'previousSessions',
    costPerClick: 'previousCostPerClick',
    clickThroughRate: 'previousClickThroughRate',
    conversionRate: 'previousConversionRate',
    costOfSales: 'previousCostOfSales',
    costPerThousandImpressions: 'previousCostPerThousandImpressions',
    averageOrderValue: 'previousAverageOrderValue',
    clicks: 'previousClicks'
  }
}
