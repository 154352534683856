import { Grid, Typography, defaultTheme } from '@precis-digital/kurama'
import Image from 'next/image'
import settingsIcon from 'public/assets/images/settings.svg'
import { useTranslation } from 'shared/translations'

const EmptyTableBody = ({ entity }: { entity?: string }): React.ReactElement => {
  const { t } = useTranslation('common')
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      paddingTop={defaultTheme.spacing(3)}
      gap={defaultTheme.spacing(5)}
    >
      <Image src={settingsIcon.src} alt="settings" width={335} height={169} />
      <Grid container direction="column" alignItems="center" maxWidth={400} textAlign="center">
        <Typography variant="h5">{t('noDataMatchingFilter', { entityType: entity?.toLowerCase() })}</Typography>
        <Typography variant="body3">{t('noDataMatchingFilterPrompt')}</Typography>
      </Grid>
    </Grid>
  )
}

export default EmptyTableBody
