import { SelectInput, SelectItem } from '@precis-digital/kurama'
import { useFormContext } from 'react-hook-form'
import { AccordionFormStep, AccordionFormStepItem } from 'shared/components/AccordionFormStep'
import FormItem from 'shared/components/FormItem'
import { useTranslation } from 'shared/translations'
import type { AttributionFormData, StepProps } from '..'
import { CREDIT_INPUTS, LOOKBACK_DAYS } from '../constants'
import { useCurrentClient } from 'shared/context/ClientContext'
import { currencies } from 'shared/constants/currency'

const GeneralStep = ({
  stepNumber,
  title,
  isPreviousOrCurrentStep,
  handleUpdate,
  toggleEdit,
  isNewConfig,
  editModes,
  optimizeBudget
}: StepProps): React.ReactElement => {
  const { t } = useTranslation('attributionModel')
  const { control, getValues } = useFormContext<AttributionFormData>()
  const formData = getValues()

  const { currentClient } = useCurrentClient()

  const isRBA = formData.model?.id === CREDIT_INPUTS.RBA

  if (isRBA && !optimizeBudget) {
    return <></>
  }
  return (
    <AccordionFormStep
      isLastStep
      stepName={t('form.step', { stepNumber })}
      title={title}
      expanded={isPreviousOrCurrentStep}
    >
      <AccordionFormStepItem title={t('createModel.advanced')} isCreateEvent={isNewConfig}>
        {(!isRBA || optimizeBudget) && (
          <FormItem
            name="general.lookbackDays"
            label={t('createModel.lookbackDays')}
            rules={{ required: true }}
            defaultValue="30"
            render={({ field }) => {
              return (
                <SelectInput fullWidth {...field}>
                  {LOOKBACK_DAYS.map((lookbackDay) => {
                    return (
                      <SelectItem key={lookbackDay} value={lookbackDay}>
                        {lookbackDay}
                      </SelectItem>
                    )
                  })}
                </SelectInput>
              )
            }}
            editModes={editModes}
            toggleEdit={toggleEdit}
            handleUpdate={handleUpdate}
            currentValue={formData.general?.lookbackDays}
            control={control}
            canEdit
            isCreateEvent={isNewConfig}
            helperText={t('createModel.sharedHelperText.lookbackDays')}
          />
        )}
        {(optimizeBudget || !isRBA) && (
          <FormItem
            name="general.currency"
            label={t('createModel.currency')}
            rules={{ required: true }}
            defaultValue={currentClient?.currency}
            render={({ field }) => {
              return (
                <SelectInput fullWidth {...field}>
                  {currencies.map((currency) => {
                    return (
                      <SelectItem key={currency.code} value={currency.code}>
                        {currency.name} ({currency.code})
                      </SelectItem>
                    )
                  })}
                </SelectInput>
              )
            }}
            editModes={editModes}
            toggleEdit={toggleEdit}
            handleUpdate={handleUpdate}
            currentValue={formData.general?.currency}
            control={control}
            canEdit
            isCreateEvent={isNewConfig}
            helperText={
              optimizeBudget ? t('createModel.boHelperText.currency') : t('createModel.amHelperText.currency')
            }
          />
        )}
      </AccordionFormStepItem>
    </AccordionFormStep>
  )
}

export default GeneralStep
