import { type AttributionModelType } from 'attributionModel/components/Dashboard/DetailedReportUtils'
import { type AnalyticsReq } from 'shared/api/analytics'
import { type GlobalFilter } from 'attributionModel/components/Dashboard/index'
import { transformActiveFiltersToCirrusAPIFormat } from 'shared/components/Filter/transform'
export const getAttributionChannelLevelQuery = (
  currentClientId: string,
  modelId: number,
  startDate: string,
  endDate: string,
  currencyCode: string,
  modelType: AttributionModelType,
  globalFilters: GlobalFilter
): AnalyticsReq => {
  const cirrusFilters = transformActiveFiltersToCirrusAPIFormat(globalFilters)
  const attributionFieldsAggByDate = [
    { name: 'ga4_default_attribution_revenue', aggregation: 'SUM' },
    { name: 'ga4_default_attribution_conversions', aggregation: 'SUM' },

    { name: 'ga4_lnda_attribution_revenue', aggregation: 'SUM' },
    { name: 'ga4_lnda_attribution_conversions', aggregation: 'SUM' },

    { name: 'channel' },
    { name: 'spend', aggregation: 'SUM' },
    { name: 'impressions', aggregation: 'SUM' }
  ]

  if (modelType === 'ddav2') {
    // dda v2 models have both ddv2_revenue and dda_v2_conversions and lnda_revenue and lnda_conversions
    attributionFieldsAggByDate.push({ name: 'dda_v2_revenue', aggregation: 'SUM' })
    attributionFieldsAggByDate.push({ name: 'dda_v2_conversions', aggregation: 'SUM' })
    attributionFieldsAggByDate.push({ name: 'lnda_revenue', aggregation: 'SUM' })
    attributionFieldsAggByDate.push({ name: 'lnda_conversions', aggregation: 'SUM' })
  }

  if (modelType === 'xgboost') {
    // xgboost models have both ia_user_based_reallocation_revenue and ia_user_based_reallocation_conversions and lnda_revenue and lnda_conversions
    attributionFieldsAggByDate.push({ name: 'ia_user_based_reallocation_revenue', aggregation: 'SUM' })
    attributionFieldsAggByDate.push({ name: 'ia_user_based_reallocation_conversions', aggregation: 'SUM' })
    attributionFieldsAggByDate.push({ name: 'lnda_revenue', aggregation: 'SUM' })
    attributionFieldsAggByDate.push({ name: 'lnda_conversions', aggregation: 'SUM' })
  }

  if (modelType === 'rba') {
    attributionFieldsAggByDate.push({ name: 'regression_based_attribution_revenue', aggregation: 'SUM' })
    attributionFieldsAggByDate.push({ name: 'regression_based_attribution_conversions', aggregation: 'SUM' })
  }

  return {
    id: modelId.toString(),
    collection: 'attribution',
    report: 'attribution',
    clientId: currentClientId,
    fields: attributionFieldsAggByDate,
    filters: [
      {
        field: 'date',
        operator: 'BETWEEN',
        value: [startDate, endDate]
      },
      ...cirrusFilters
    ],
    targetCurrencyCode: currencyCode,
    limit: 50000,
    // orderBy: { fields: ['date'], direction: 'DESC' }
    groupBy: ['channel']
  }
}

export const getTimeSeriesQuery = (
  currentClientId: string,
  modelId: number,
  startDate: string,
  endDate: string,
  currencyCode: string,
  modelType: AttributionModelType,
  globalFilters: GlobalFilter
): AnalyticsReq => {
  const cirrusFilters = transformActiveFiltersToCirrusAPIFormat(globalFilters)
  const fields = [{ name: 'date' }, { name: 'channel' }]

  if (modelType === 'ddav2') {
    // dda v2 models have both ddv2_revenue and dda_v2_conversions and lnda_revenue and lnda_conversions
    fields.push({ name: 'dda_v2_revenue' })
    fields.push({ name: 'dda_v2_conversions' })
  }

  if (modelType === 'xgboost') {
    // xgboost models have both ia_user_based_reallocation_revenue and ia_user_based_reallocation_conversions and lnda_revenue and lnda_conversions
    fields.push({ name: 'ia_user_based_reallocation_revenue' })
    fields.push({ name: 'ia_user_based_reallocation_conversions' })
  }

  if (modelType === 'rba') {
    fields.push({ name: 'regression_based_attribution_revenue' })
    fields.push({ name: 'regression_based_attribution_conversions' })
  }

  return {
    id: modelId.toString(),
    collection: 'attribution',
    report: 'attribution',
    clientId: currentClientId,
    fields,
    filters: [
      {
        field: 'date',
        operator: 'BETWEEN',
        value: [startDate, endDate]
      },
      ...cirrusFilters
    ],
    targetCurrencyCode: currencyCode,
    limit: 50000
  }
}
