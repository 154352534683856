import {
  Avatar,
  Button,
  Divider,
  DropdownItem,
  DropdownList,
  EditIcon,
  MappingIcon,
  PauseIcon,
  PlayIcon,
  PlusIcon,
  TrashIcon,
  CopyIcon
} from '@precis-digital/kurama'
import { attributionTableID, budgetOptimiserTableID } from 'attributionModel/constants'
import { useMutationDeleteConfig } from 'attributionModel/api'
import { type ReactElement } from 'react'
import { UnStyledRetainDefaultQueryLink } from 'shared/components/Router'
import { makeToastWithLoading } from 'shared/components/Toaster'
import { type ConfigStatus } from 'shared/configStatuses'
import { ASSETS_BASE_URL } from 'shared/constants'
import { useCurrentClient } from 'shared/context/ClientContext'
import useDagView from 'shared/hooks/useDagView'
import OverScreen from 'shared/overScreens'
import { APP_ROUTES } from 'shared/routes'
import { useTranslation } from 'shared/translations'
import { getBigQueryTableDeepLink } from 'shared/utils'
import { UnstyledLink } from 'shared/components/UnstyledLink'

interface MoreMenuProps {
  selectedModel: {
    modelId: number
    name?: string
    isActive?: boolean
    status?: ConfigStatus
    targetProjectId: string
    targetDatasetId: string
  }
  path: string
  hasEditorAccess?: boolean
  onDeleteConfig?: () => void
  onModelStatusChange?: () => void
  allowPause?: boolean
  editPagePath?: string
  onCloseMenu?: () => void
  allowEdit?: boolean
  allowDelete?: boolean
  isPageWithConfigId?: boolean
}

const MoreMenu = ({
  selectedModel,
  path,
  onCloseMenu,
  hasEditorAccess = false,
  allowEdit = true,
  allowPause = true,
  onModelStatusChange,
  onDeleteConfig,
  allowDelete = true,
  isPageWithConfigId = false,
  editPagePath
}: MoreMenuProps): ReactElement => {
  const translationKey = path === 'budget-optimiser' ? 'budgetOptimiser' : 'attributionModel'
  const { t } = useTranslation(translationKey)
  const { showDagView } = useDagView('attributionModel')
  const { currentClient } = useCurrentClient()
  const { mutate: deleteConnectorConfig } = useMutationDeleteConfig()

  const modelName = selectedModel.name
  const modelIsActive = selectedModel.isActive
  const modelStatus = selectedModel.status

  const notAllowDeleteAndActivateConfig = !allowDelete || path !== 'budget-optimiser'

  const tableId =
    path === 'attribution-models'
      ? attributionTableID(selectedModel.modelId)
      : budgetOptimiserTableID(selectedModel.modelId)

  const handleDelete = (e: MouseEvent): void => {
    const { toastOnError, toastOnSuccess } = makeToastWithLoading()
    deleteConnectorConfig(
      { clientId: currentClient.id, modelId: selectedModel.modelId },
      {
        onSuccess: () => {
          onDeleteConfig?.()
          toastOnSuccess(t('popups.delete.success', { id: selectedModel.modelId }))
        },
        onError: (error) => {
          toastOnError(`${t('popups.delete.error')}: ${error.message}`)
        }
      }
    )
  }

  const copyModelPath = path === 'budget-optimiser' ? APP_ROUTES.budgetOptimiser : APP_ROUTES.attributionModels

  return (
    <DropdownList>
      {allowEdit && (
        <UnStyledRetainDefaultQueryLink href={editPagePath ?? `/${path}/${selectedModel.modelId}`}>
          <DropdownItem
            onClick={() => {
              onCloseMenu?.()
            }}
            icon={<EditIcon />}
          >
            {t('dashboard.editModel')}
          </DropdownItem>
        </UnStyledRetainDefaultQueryLink>
      )}
      {allowPause && (
        <>
          {notAllowDeleteAndActivateConfig ? (
            <DropdownItem
              onClick={() => {
                onModelStatusChange?.()
                onCloseMenu?.()
              }}
              icon={selectedModel.isActive ?? false ? <PauseIcon /> : <PlayIcon />}
            >
              {selectedModel.isActive ?? false ? t('dashboard.pauseConfig') : t('dashboard.activateConfig')}
            </DropdownItem>
          ) : (
            <DropdownItem
              disabled
              onClick={() => {
                onModelStatusChange?.()
                onCloseMenu?.()
              }}
              tooltipProps={{
                kind: 'singleline',
                title: t('moreOptions.pauseTooltip')
              }}
              icon={selectedModel.isActive ?? false ? <PauseIcon /> : <PlayIcon />}
            >
              {selectedModel.isActive ?? false ? t('dashboard.pauseConfig') : t('dashboard.activateConfig')}
            </DropdownItem>
          )}
        </>
      )}
      <UnStyledRetainDefaultQueryLink
        href={copyModelPath.createConfigPage}
        query={{ from: selectedModel.modelId.toString() }}
      >
        <DropdownItem
          disabled={!notAllowDeleteAndActivateConfig}
          icon={<CopyIcon />}
          onClick={() => {
            onCloseMenu?.()
          }}
          tooltipProps={{
            kind: 'singleline',
            title: notAllowDeleteAndActivateConfig
              ? t('moreOptions.copyThisModelTooltip')
              : t('moreOptions.copyThisModelDisabledTooltip')
          }}
        >
          {t('moreOptions.copyThisModel')}
        </DropdownItem>
      </UnStyledRetainDefaultQueryLink>

      {modelName != null && modelIsActive != null && modelStatus != null && (
        <DropdownItem
          onClick={() => {
            showDagView({
              configId: selectedModel.modelId,
              configName: modelName,
              isActive: modelIsActive,
              configStatus: modelStatus
            })
            onCloseMenu?.()
          }}
          icon={<MappingIcon />}
        >
          {t('dashboard.viewDetailedStatus')}
        </DropdownItem>
      )}
      <Divider />
      <UnstyledLink
        href={getBigQueryTableDeepLink(selectedModel.targetProjectId, selectedModel.targetDatasetId, tableId)}
        target="_blank"
        rel="noreferrer"
      >
        <DropdownItem
          onClick={() => {
            onCloseMenu?.()
          }}
          icon={<Avatar kind="image" imageUrl={`${ASSETS_BASE_URL}/google_bigquery.svg`} size="small" />}
        >
          {t('moreOptions.goToTable')}
        </DropdownItem>
      </UnstyledLink>

      {hasEditorAccess && (
        <>
          <Divider />
          {notAllowDeleteAndActivateConfig ? (
            <DropdownItem
              onClick={() => {
                onCloseMenu?.()
                void OverScreen.show('deletePopup', {
                  deleteConfigProps: {
                    id: selectedModel.modelId,
                    name: selectedModel.name,
                    onDelete: handleDelete
                  },
                  t
                })
              }}
              tooltipProps={{
                kind: 'singleline',
                title: path === 'attribution-models' ? t('moreOptions.deleteTooltipAm') : undefined
              }}
              icon={<TrashIcon />}
            >
              {t('moreOptions.deleteConfiguration')}
            </DropdownItem>
          ) : (
            <DropdownItem
              disabled
              onClick={() => {
                onCloseMenu?.()
                void OverScreen.show('deletePopup', {
                  deleteConfigProps: {
                    id: selectedModel.modelId,
                    name: selectedModel.name,
                    onDelete: handleDelete
                  },
                  t,
                  path
                })
              }}
              tooltipProps={{
                kind: 'singleline',
                title: t('moreOptions.deleteTooltipBo')
              }}
              icon={<TrashIcon />}
            >
              {t('moreOptions.deleteConfiguration')}
            </DropdownItem>
          )}
        </>
      )}
      {isPageWithConfigId && (
        <>
          <Divider />
          <UnStyledRetainDefaultQueryLink
            disabled={!hasEditorAccess}
            href={APP_ROUTES.attributionModels.createConfigPage}
          >
            <Button
              fullWidth
              scheme="light"
              variant="filled"
              onClick={() => {
                onCloseMenu?.()
              }}
              rightIcon={<PlusIcon />}
              disabled={!hasEditorAccess}
            >
              {t('buttons.createNewModel')}
            </Button>
          </UnStyledRetainDefaultQueryLink>
        </>
      )}
    </DropdownList>
  )
}

export default MoreMenu
