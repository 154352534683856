import { Button, MimirIcon } from '@precis-digital/kurama'
import React, { type ReactElement } from 'react'
import { useMimir } from 'shared/context/MimirContext'
import { useShouldShowInternalFeatures } from 'shared/hooks/useShouldShowInternalFeatures'

interface MimirTriggerButtonProps {
  userFacingMessage: string
  questionPrompt?: string
  context?: string
  disabled?: boolean
  variant?: 'text' | 'outlined' | 'tonal' | 'filled'
}

const MimirTriggerButton = ({
  userFacingMessage,
  questionPrompt,
  context,
  disabled = false,
  variant = 'text'
}: MimirTriggerButtonProps): ReactElement => {
  const { askMimir } = useMimir()
  const shouldShowInternalFeatures = useShouldShowInternalFeatures()

  const handleButtonClick = (): void => {
    askMimir({
      userFacingMessage,
      questionPrompt,
      clearHistory: true,
      customDataContext: context
    })
  }

  return shouldShowInternalFeatures ? (
    <Button variant={variant} disabled={disabled} onClick={handleButtonClick} leftIcon={<MimirIcon />} />
  ) : (
    <></>
  )
}

export default MimirTriggerButton
