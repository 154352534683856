import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownList,
  EditIcon,
  Grid,
  MappingIcon,
  MoreIcon,
  PlusIcon,
  TargetIcon,
  styled
} from '@precis-digital/kurama'
import { useState } from 'react'
import { UnStyledRetainDefaultQueryLink } from 'shared/components/Router'
import { type ConfigStatus } from 'shared/configStatuses'
import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'
import useDagView from 'shared/hooks/useDagView'
import { useHasAccess } from 'shared/hooks/useHasAccess'
import { APP_ROUTES } from 'shared/routes'
import { useQueryMember } from 'workspace'

interface MoreMenuDropdownProps {
  configId: number
  configName?: string
  isActive?: boolean
  configStatus?: ConfigStatus
  editButton: string
  createButton: string
  path?: string
  moreButton: string
  viewDetailedStatusButton?: string
  showMetricButton?: string | null
  onSelectMetric?: () => void
  channelGroupingId?: number
  isPageWithConfigId?: boolean
}

const MoreMenuDropdownBudgetOptimiserDashboard = ({
  configId,
  editButton,
  createButton,
  path,
  moreButton,
  isActive,
  configName,
  configStatus,
  showMetricButton,
  onSelectMetric,
  viewDetailedStatusButton,
  channelGroupingId,
  isPageWithConfigId = false
}: MoreMenuDropdownProps): React.ReactElement => {
  const { showDagView } = useDagView('attributionModel', true)
  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()
  const { data: member } = useQueryMember(currentClient?.id ?? '', currentUser?.id ?? '')
  const hasEditorAccess = useHasAccess('editor', member?.role ?? 'viewer')

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()
    setDropdownAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setDropdownAnchorEl(null)
  }

  let url = `${APP_ROUTES.centralApp.basePage}/${path ?? ''}/${configId}`
  if (channelGroupingId != null) {
    url =
      path === 'budget-optimiser'
        ? APP_ROUTES.budgetOptimiser.editConfigPage({ configId: `${configId}` })
        : APP_ROUTES.attributionModels.editConfigPage({ configId: `${configId}` })
  }

  const handleEditClick = (): void => {
    setDropdownAnchorEl(null)
  }

  const isDropdownOpen = !(dropdownAnchorEl == null)
  return (
    <>
      <Button variant="outlined" onClick={handleClick} rightIcon={<MoreIcon />}>
        {moreButton}
      </Button>
      <Dropdown anchorEl={dropdownAnchorEl} open={isDropdownOpen} onClose={handleClose}>
        <DropdownList minWidth="300px">
          {showMetricButton != null && onSelectMetric != null && (
            <DropdownItem
              onClick={() => {
                onSelectMetric()
                setDropdownAnchorEl(null)
              }}
              icon={<TargetIcon />}
            >
              {showMetricButton}
            </DropdownItem>
          )}
          <UnStyledRetainDefaultQueryLink href={url}>
            <DropdownItem
              onClick={() => {
                handleEditClick()
              }}
              icon={<EditIcon />}
            >
              {editButton}
            </DropdownItem>
          </UnStyledRetainDefaultQueryLink>
          {viewDetailedStatusButton != null && configName != null && isActive != null && configStatus != null && (
            <DropdownItem
              onClick={() => {
                showDagView({
                  configId,
                  configName,
                  isActive,
                  configStatus
                })
                setDropdownAnchorEl(null)
              }}
              icon={<MappingIcon />}
            >
              {viewDetailedStatusButton}
            </DropdownItem>
          )}
          {isPageWithConfigId && (
            <>
              <Divider />
              <Button
                href={APP_ROUTES.budgetOptimiser.createConfigPage}
                LinkComponent={UnStyledRetainDefaultQueryLink}
                fullWidth
                scheme="light"
                variant="filled"
                onClick={handleClose}
                rightIcon={<PlusIcon />}
                disabled={!hasEditorAccess}
              >
                {createButton}
              </Button>
            </>
          )}
        </DropdownList>
      </Dropdown>
    </>
  )
}

export default MoreMenuDropdownBudgetOptimiserDashboard

export const StyledDropdownButton = styled(Grid)(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'start',
  width: '100%',
  paddingTop: '20px'
}))
