import { subDays } from 'date-fns'
import {
  type Sources,
  type StandardReportReq,
  type Source,
  type Inputs,
  type StandardReportResp,
  type SourceType,
  type Input
} from 'shared/api/standardReports'
import {
  type DV360Settings,
  type BingSettings,
  type FacebookSettings,
  type GA4Settings,
  type GadsSettings,
  type ReportFormData,
  type GoogleAnalyticsSettings,
  type GoogleSearchConsoleSettings,
  type TikTokSettings,
  type SnapchatSettings,
  type LinkedinSettings
} from '.'
import { formatDateToString } from 'shared/dateFns'
import { getReportTemplateById } from 'reportingSolution/utils'
import { INPUTS_FIELD, INPUTS_FIELD_TYPES } from './constants'
import { isNil, path } from 'ramda'
import { type ReportTemplate, type CaseStatementDimension, type InputSchema } from 'reportingSolution/constants'
import { type DeepPartial, type FieldPath } from 'react-hook-form'
import toSnakeKeys from 'snakecase-keys'
import { type ClientAccountsResp } from 'shared/api/accounts'
import { isClientMismatchError, isNilOrEmpty, objectKeys, trimWhitespace } from 'shared/utils'
import { type InputRules } from 'shared/reactHookForm'
import { getConnectorSourceMeta } from 'connectors/utils'
import { validateDataset } from 'connectors/components/EditOrCreateNewPage/utils'
import { type ClientError } from 'shared/api/fetch'

export const getDefaultFillFromDate = (defaultDaysLookBack: number): Date => {
  return subDays(new Date(), defaultDaysLookBack)
}

export const transformFormDataForAPI = (clientId: string, data: ReportFormData): StandardReportReq => {
  const sources: Sources = {}
  const inputs: Inputs = {}
  data.dataSources.forEach((dataSource) => {
    let platformSpecificSourceData: Partial<Source> = {}
    if (dataSource.platform === 'merchant_center') {
      platformSpecificSourceData = {
        projectId: data.dataTarget.basicSettings.targetProjectExternalId,
        dataset: 'precis_connectors'
      }
    }
    sources[dataSource.platform] = dataSource.accounts.map((account) => ({
      ...platformSpecificSourceData,
      accountId: account.externalId,
      account: account.id
    }))
  })

  const selectedReport = getReportTemplateById(data.reportTemplate.id)
  Object.keys(selectedReport?.sources ?? {}).forEach((platform) => {
    if (sources[platform] == null) {
      sources[platform] = []
    }
  })

  type InputValue = string | number | boolean | undefined

  type Input = Record<string, InputValue>

  if (selectedReport?.inputs != null) {
    Object.entries(selectedReport.inputs).forEach(([platform, platformInputs]) => {
      if (platform !== 'other') {
        inputs[platform] = inputs[platform] ?? []

        platformInputs.forEach((platformInput) => {
          const fieldId = INPUTS_FIELD[platformInput.id].split('.')
          const parsedInput: Input = {}

          const fieldValue = path<InputValue>(fieldId, data)

          parsedInput[platformInput.id] = fieldValue

          inputs[platform].push(parsedInput)
        })
      }
    })
  }

  if (data.otherSettings != null) {
    inputs.other = []

    inputs.other = objectKeys(data.otherSettings)
      .filter((key) => {
        return data.otherSettings[key] != null
      })
      .map((key) => {
        let fieldValue = data.otherSettings[key]

        if (key === 'ga4MigrationDate' && fieldValue != null) {
          fieldValue = formatDateToString({
            date: fieldValue,
            targetFormat: 'yyyy-MM-dd'
          })
        }

        return {
          [key]: fieldValue
        }
      })
  }

  return {
    clientId: parseInt(clientId, 10),
    targetDataset: data.dataTarget.basicSettings.targetDataset,
    templateId: data.reportTemplate.id,
    targetProjectId: data.dataTarget.basicSettings.targetProjectId,
    schedule: data.dataTarget.advancedSettings.schedule,
    runSchedule: data.runSchedule,
    name: trimWhitespace(data.reportName),
    isPremium: data.isPremium,
    sources,
    inputs
  }
}

const getDefaultInputValuesForPlatform = (platform: string, templateId: string): Record<string, string> => {
  const selectedReport = getReportTemplateById(templateId)
  const inputs = selectedReport?.inputs?.[platform]

  if (inputs == null) return {}

  return inputs.reduce((acc, input) => {
    return {
      ...acc,
      [input.id]: input.defaultValue ?? ''
    }
  }, {})
}

const getOtherSettingsForForm = (otherSettingsInput: Input[]): ReportFormData['otherSettings'] => {
  const otherSettings = otherSettingsInput
    .map((input) => {
      const entry = Object.entries(input)?.[0]
      return entry ?? []
    })
    .filter((input) => {
      const value = input?.[1]
      return value != null
    })

  return Object.fromEntries(otherSettings)
}

export const transformAPIDataForForm = (
  reportingSolutionConfigData: StandardReportResp,
  clientAccounts: ClientAccountsResp[]
): ReportFormData => {
  const gcpProject = clientAccounts.find(
    (account) => account.externalAccountId === reportingSolutionConfigData.targetProjectId
  )

  const transformedSources = toSnakeKeys(reportingSolutionConfigData.sources)

  const selectedReport = getReportTemplateById(reportingSolutionConfigData.templateId)
  const snakeKeysTemplateSources = toSnakeKeys(selectedReport?.sources as { [K in SourceType]?: Source[] })
  Object.keys(snakeKeysTemplateSources).forEach((platformKey) => {
    const platform = platformKey as keyof typeof transformedSources
    if (transformedSources[platform] == null) {
      transformedSources[platform] = []
    }
  })

  return {
    dataSources: Object.entries(transformedSources).map(([platform, platformSources]) => {
      return {
        platform,
        accounts: platformSources.map((source) => {
          const matchingAccount = clientAccounts.find((account) => account.id === source.account)

          const label =
            matchingAccount != null
              ? `${matchingAccount.name} (${matchingAccount.externalAccountId})`
              : source.accountId ?? ''

          return {
            id: source.account,
            externalId: source.accountId ?? '',
            label
          }
        })
      }
    }),
    dataSourceSettings: {
      gads: reportingSolutionConfigData.inputs.gads?.reduce((acc: Record<keyof GadsSettings, string>, input) => {
        const entries = Object.entries(input)
        if (entries.length === 0) {
          return acc
        }
        const [key, value] = entries[0]
        return { ...acc, [key]: value }
      }, getDefaultInputValuesForPlatform('gads', reportingSolutionConfigData.templateId) as Record<keyof GadsSettings, string>),
      facebook: reportingSolutionConfigData.inputs.facebook?.reduce(
        (acc: Record<keyof FacebookSettings, string>, input) => {
          const entries = Object.entries(input)
          if (entries.length === 0) {
            return acc
          }
          const [key, value] = entries[0]
          return { ...acc, [key]: value }
        },
        getDefaultInputValuesForPlatform('facebook', reportingSolutionConfigData.templateId) as Record<
          keyof FacebookSettings,
          string
        >
      ),
      bing: reportingSolutionConfigData.inputs.bing?.reduce((acc: Record<keyof BingSettings, string>, input) => {
        const entries = Object.entries(input)
        if (entries.length === 0) {
          return acc
        }
        const [key, value] = entries[0]
        return { ...acc, [key]: value }
      }, getDefaultInputValuesForPlatform('bing', reportingSolutionConfigData.templateId) as Record<keyof BingSettings, string>),
      dv360: reportingSolutionConfigData.inputs.dv360?.reduce((acc: Record<keyof DV360Settings, string>, input) => {
        const entries = Object.entries(input)
        if (entries.length === 0) {
          return acc
        }
        const [key, value] = entries[0]
        return { ...acc, [key]: value }
      }, getDefaultInputValuesForPlatform('dv360', reportingSolutionConfigData.templateId) as Record<keyof DV360Settings, string>),
      google_analytics: reportingSolutionConfigData.inputs.googleAnalytics?.reduce(
        (acc: Record<keyof GoogleAnalyticsSettings, string>, input) => {
          const entries = Object.entries(input)
          if (entries.length === 0) {
            return acc
          }
          const [key, value] = entries[0]
          return { ...acc, [key]: value }
        },
        getDefaultInputValuesForPlatform('google_analytics', reportingSolutionConfigData.templateId) as Record<
          keyof GoogleAnalyticsSettings,
          string
        >
      ),
      ga4: reportingSolutionConfigData.inputs.ga4?.reduce((acc: Record<keyof GA4Settings, string>, input) => {
        const entries = Object.entries(input)
        if (entries.length === 0) {
          return acc
        }
        const [key, value] = entries[0]
        return { ...acc, [key]: value }
      }, getDefaultInputValuesForPlatform('ga4', reportingSolutionConfigData.templateId) as Record<keyof GA4Settings, string>),
      google_search_console: reportingSolutionConfigData.inputs.googleSearchConsole?.reduce(
        (acc: Record<keyof GoogleSearchConsoleSettings, string>, input) => {
          const entries = Object.entries(input)
          if (entries.length === 0) {
            return acc
          }
          const [key, value] = entries[0]
          return { ...acc, [key]: value }
        },
        getDefaultInputValuesForPlatform('google_search_console', reportingSolutionConfigData.templateId) as Record<
          keyof GoogleSearchConsoleSettings,
          string
        >
      ),
      tiktok: reportingSolutionConfigData.inputs.tiktok?.reduce((acc: Record<keyof TikTokSettings, string>, input) => {
        const entries = Object.entries(input)
        if (entries.length === 0) {
          return acc
        }
        const [key, value] = entries[0]
        return { ...acc, [key]: value }
      }, getDefaultInputValuesForPlatform('tiktok', reportingSolutionConfigData.templateId) as Record<keyof TikTokSettings, string>),
      snapchat: reportingSolutionConfigData.inputs.snapchat?.reduce(
        (acc: Record<keyof SnapchatSettings, string>, input) => {
          const entries = Object.entries(input)
          if (entries.length === 0) {
            return acc
          }
          const [key, value] = entries[0]
          return { ...acc, [key]: value }
        },
        getDefaultInputValuesForPlatform('snapchat', reportingSolutionConfigData.templateId) as Record<
          keyof SnapchatSettings,
          string
        >
      ),
      linkedin: reportingSolutionConfigData.inputs.linkedin?.reduce(
        (acc: Record<keyof LinkedinSettings, string>, input) => {
          const entries = Object.entries(input)
          if (entries.length === 0) {
            return acc
          }
          const [key, value] = entries[0]
          return { ...acc, [key]: value }
        },
        getDefaultInputValuesForPlatform('linkedin', reportingSolutionConfigData.templateId) as Record<
          keyof LinkedinSettings,
          string
        >
      )
    },
    reportTemplate: {
      id: reportingSolutionConfigData.templateId
    },
    reportName: reportingSolutionConfigData.name,
    dataTarget: {
      basicSettings: {
        gcpAccount: gcpProject?.id.toString() ?? '',
        targetDataset: reportingSolutionConfigData.targetDataset,
        targetAuthAccount: reportingSolutionConfigData.targetAuthAccount,
        targetProjectId: gcpProject?.id.toString() ?? '',
        targetProjectExternalId: reportingSolutionConfigData.targetProjectId
      },
      advancedSettings: {
        schedule: reportingSolutionConfigData.schedule
      }
    },
    otherSettings: getOtherSettingsForForm(reportingSolutionConfigData.inputs.other ?? []),
    runSchedule: reportingSolutionConfigData.runSchedule,
    isPremium: reportingSolutionConfigData.isPremium
  }
}

export interface ReportInput {
  id: string
  label: string
  type: string
  required: boolean
  options?: Array<{ value: string; label: string }>
  dimensions?: CaseStatementDimension[]
  defaultValue?: string | number
  name: FieldPath<ReportFormData>
  helperText?: string
  InputComponent: React.FC<any>
}

export const getReportInputs = (selectedReportInput: InputSchema[] | undefined): ReportInput[] => {
  if (selectedReportInput == null) return []

  return selectedReportInput
    .map((input) => {
      const name = INPUTS_FIELD[input.id]
      const InputComponent = INPUTS_FIELD_TYPES[input.type]

      if (isNil(name) || isNil(InputComponent)) return null

      return {
        id: input.id,
        label: input.label,
        type: input.type,
        helperText: input.helperText,
        required: input.required,
        options: input.options,
        defaultValue: input.defaultValue,
        dimensions: input.dimensions,
        name,
        InputComponent
      }
    })
    .filter((input) => input != null) as ReportInput[]
}

export const transformConfigToRequest = ({
  clientId,
  config
}: {
  clientId: string
  config: StandardReportResp
}): StandardReportReq => {
  const parsedSources = Object.fromEntries(
    Object.entries(config.sources).map(([platform, sources]) => [
      platform,
      sources.map((source) => {
        let platformSpecificSourceData: Partial<Source> = {}

        if (platform === 'merchantCenter') {
          platformSpecificSourceData = {
            projectId: source.projectId,
            dataset: source.dataset
          }
        }

        return {
          account: source.account,
          accountId: source.accountId,
          ...platformSpecificSourceData
        }
      })
    ])
  )

  return {
    clientId: parseInt(clientId, 10),
    targetDataset: config.targetDataset,
    templateId: config.templateId,
    targetProjectId: config.targetProjectId,
    schedule: config.schedule,
    runSchedule: config.runSchedule,
    name: config.name,
    isPremium: config.isPremium,
    sources: parsedSources,
    inputs: config.inputs
  }
}

type FieldInputRulesMap<T extends FieldPath<ReportFormData>> = Partial<
  Record<FieldPath<ReportFormData>, InputRules<ReportFormData, T>>
>

const DATASOURCE_ACCOUNT_PATTERN = /^dataSources\.\d+\.accounts$/

/**
 * Returns the name of the platform field given the accounts field name
 * @param {FieldPath<ReportFormData>} fieldName - The name of the accounts field. Example 'dataSources.0.accounts'
 * @param {ReportFormData} data - The form data object
 * @returns {string} - The name of the platform
 */

const getDataSourcePlatform = (fieldName: FieldPath<ReportFormData>, data: ReportFormData): string | undefined => {
  const dataSourcePath = fieldName.split('.').slice(0, 2)
  const dataSourcePlatformPath = [...dataSourcePath, 'platform']
  return path<string>(dataSourcePlatformPath, data)
}

export const getInputRules = <T extends FieldPath<ReportFormData>>({
  fieldName,
  data
}: {
  fieldName: T
  data: ReportFormData
}): InputRules<ReportFormData, T> => {
  const STATIC_INPUT_RULES: FieldInputRulesMap<T> = {
    'reportTemplate.id': {
      required: true
    },
    'dataTarget.basicSettings.gcpAccount': {
      required: true
    },
    'dataTarget.basicSettings.targetDataset': {
      required: true,
      validate: (value) => {
        return validateDataset(value as string)
      }
    },
    'dataTarget.advancedSettings.schedule': {
      required: true
    }
  }

  const staticInputFieldRule = STATIC_INPUT_RULES[fieldName]

  if (staticInputFieldRule != null) {
    return staticInputFieldRule
  }

  const reportTemplateId = data.reportTemplate?.id
  const selectedReport = !isNilOrEmpty(reportTemplateId) ? getReportTemplateById(reportTemplateId) : undefined

  if (selectedReport != null) {
    const dynamicPlatformInputRules = Array.from(new Set(Object.keys(selectedReport.sources))).reduce<
      FieldInputRulesMap<T>
    >((acc, platform) => {
      if (DATASOURCE_ACCOUNT_PATTERN.test(fieldName)) {
        const dataPlatform = getDataSourcePlatform(fieldName, data)

        if (dataPlatform === platform) {
          acc[fieldName] = {
            required: !(selectedReport.sources[platform]?.optional ?? false)
          }
        }
      }

      const inputs = getReportInputs(selectedReport.inputs?.[platform])

      const fieldInput = inputs.find((input) => input.name === fieldName)

      if (fieldInput != null) {
        acc[fieldName] = {
          required: fieldInput.required
        }
      }

      return acc
    }, {})

    const dynamicPlatformFieldRule = dynamicPlatformInputRules[fieldName]

    if (dynamicPlatformFieldRule != null) {
      return dynamicPlatformFieldRule
    }

    const otherFieldInput = getReportInputs(selectedReport.inputs?.other).find((input) => input.name === fieldName)

    if (otherFieldInput != null) {
      return {
        required: otherFieldInput.required
      }
    }
  }

  return {}
}

export const getInputLabels = ({
  fieldName,
  data,
  t,
  attachPlatform = false
}: {
  fieldName: FieldPath<ReportFormData>
  data: ReportFormData
  isCreateEvent?: boolean
  attachPlatform?: boolean
  t: (key: string, options?: Record<string, unknown> | undefined) => string
}): string => {
  const reportTemplateId = data.reportTemplate?.id
  const selectedReport = !isNilOrEmpty(reportTemplateId) ? getReportTemplateById(reportTemplateId) : undefined

  const STATIC_INPUT_LABELS: Partial<Record<FieldPath<ReportFormData>, string>> | undefined = {
    'reportTemplate.id': t('form.selectReport'),
    'dataTarget.basicSettings.gcpAccount': t('form.selectProject', { platform: 'Google Cloud' }),
    'dataTarget.basicSettings.targetDataset': t('form.bigQueryOutputDataset'),
    'dataTarget.advancedSettings.schedule': t('form.selectSchedule'),
    reportName: t('form.reportName')
  }

  const staticInputLabels = STATIC_INPUT_LABELS[fieldName]

  if (staticInputLabels != null) {
    return staticInputLabels
  }

  if (selectedReport != null) {
    const dynamicInputLabels = Array.from(new Set(Object.keys(selectedReport.sources))).reduce<
      Partial<Record<FieldPath<ReportFormData>, string>>
    >((acc, platform) => {
      const platformMeta = getConnectorSourceMeta(platform)

      if (DATASOURCE_ACCOUNT_PATTERN.test(fieldName)) {
        const dataPlatform = getDataSourcePlatform(fieldName, data)

        if (dataPlatform === platform) {
          acc[fieldName] =
            attachPlatform && platformMeta != null
              ? t('form.selectPlatformDataSources', { platform: platformMeta.label })
              : t('form.selectDataSources')
        }
      }

      const inputs = getReportInputs(selectedReport.inputs?.[platform])

      const fieldInput = inputs.find((input) => input.name === fieldName)

      if (fieldInput != null) {
        acc[fieldName] =
          platformMeta != null && attachPlatform ? `${platformMeta.label} ${fieldInput.label}` : fieldInput.label
      }

      return acc
    }, {})

    const dynamicInputLabel = dynamicInputLabels[fieldName]

    if (dynamicInputLabel != null) {
      return dynamicInputLabel
    }

    const otherFieldInput = getReportInputs(selectedReport.inputs?.other).find((input) => input.name === fieldName)

    if (otherFieldInput != null) {
      return otherFieldInput.label
    }
  }

  return ''
}

/**
 * Sorts the passed in templates first by the category and then by the label.
 * The category 'Getting Started' is always first.
 * @param templates
 * @returns the templates sorted
 */
export const sortTemplates = (templates: ReportTemplate[]): ReportTemplate[] => {
  return templates.sort((a, b) => {
    if (a.category === 'Getting started' && b.category !== 'Getting started') {
      return -1
    } else if (a.category !== 'Getting started' && b.category === 'Getting started') {
      return 1
    } else if (a.category === b.category) {
      return a.label.localeCompare(b.label)
    } else {
      return a.category.localeCompare(b.category)
    }
  })
}

export const shouldShowNotFoundPage = ({
  reportingSolutionsError,
  reportingSolutionsConfig,
  reportTemplate
}: {
  reportingSolutionsError: ClientError | null
  reportingSolutionsConfig?: StandardReportResp
  reportTemplate?: ReportTemplate | null
}): boolean => {
  return (
    (reportingSolutionsError != null && isClientMismatchError(reportingSolutionsError)) ||
    (reportingSolutionsConfig?.templateId != null && reportTemplate == null)
  )
}

export const checkIfFormIsReadyToShow = ({
  isNewNonCopyConfig,
  isReportingSolutionsSuccess,
  formData
}: {
  isNewNonCopyConfig: boolean
  isReportingSolutionsSuccess: boolean
  formData: DeepPartial<ReportFormData>
}): boolean => {
  return isNewNonCopyConfig || (isReportingSolutionsSuccess && !isNilOrEmpty(formData))
}
